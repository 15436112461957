import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { db, firebase } from '../../../firebase/Firebase'
import '../../../styles/GlobalStyle.css'
import ColType from '../../.././Types'
import { Layout, Icon, Menu, Progress, Select, Button, notification } from 'antd'
import PersonAvatar from '../../../customcomponents/PersonAvatar';
const Option = Select.Option;
var moment = require('moment')

const successMessage = (description) => {
    notification.open({
        type: 'success',
        message: description,
        placement: 'bottomRight'
    })
}

const errorMessage = (description) => {
    notification.open({
        type: 'error',
        message: description,
        placement: 'bottomRight'
    })
}


class HTMLAssessment extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            error: null,
            formData: {},
            validationErrors: {},
            isSubmitted: false,
            originalHtml: props.htmlContent || '',
            instanceId: `html-assessment-${Date.now()}`, // Unique ID for CSS scoping
            checkboxStates: {}, // New state to track checkbox values directly
            studentId: undefined,
            emojiRating: null, // Add state for emoji rating
            prefillCompleted: false // Track if prefill has been completed
        };

        // Rest of constructor remains the same
        this.formContainerRef = React.createRef();
        this.formElement = null;
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.resetForm = this.resetForm.bind(this);
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
        this.handleEmojiRatingChange = this.handleEmojiRatingChange.bind(this);
    }

    componentDidMount() {
        if (this.state.originalHtml) {
            this.renderHtmlContent();
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.htmlContent !== this.props.htmlContent) {
            this.setState({
                originalHtml: this.props.htmlContent,
                isSubmitted: false,
                formData: {},
                validationErrors: {},
                checkboxStates: {}, // Reset checkbox states
                instanceId: `html-assessment-${Date.now()}`, // Generate new ID on content change
                emojiRating: null // Reset emoji rating
            }, this.renderHtmlContent);
        }
    }

    renderHtmlContent() {
        try {
            this.setState({ isLoading: true, error: null });

            if (this.formContainerRef.current && this.state.originalHtml) {
                // Use the instance ID from state for CSS scoping
                this.formContainerRef.current.id = this.state.instanceId;

                // Extract and process any style tags from the HTML
                const htmlContent = this.processCssStyles(this.state.originalHtml, this.state.instanceId);

                // Insert the processed HTML content
                this.formContainerRef.current.innerHTML = htmlContent;

                // Process all forms in the document
                this.processFormsAndAttachEventHandlers();
            }

            this.setState({ isLoading: false });
        } catch (err) {
            console.error('Error rendering HTML content:', err);
            this.setState({
                isLoading: false,
                error: err.message
            });
        }
    }

    processCssStyles(htmlContent, instanceId) {
        // Create a temporary div to parse the HTML
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = htmlContent;

        // Find all style tags
        const styleTags = tempDiv.querySelectorAll('style');

        // If there are no style tags, return the original HTML
        if (styleTags.length === 0) {
            return htmlContent;
        }

        // Process each style tag
        styleTags.forEach(styleTag => {
            // Get the CSS content
            const cssContent = styleTag.textContent;

            // Create a new scoped CSS by prefixing all selectors with the instance ID
            const scopedCss = this.scopeCssSelectors(cssContent, `#${instanceId}`);

            // Replace the original CSS with the scoped version
            styleTag.textContent = scopedCss;
        });

        // Return the modified HTML content
        return tempDiv.innerHTML;
    }

    scopeCssSelectors(cssContent, scopePrefix) {
        // Handle nested rules like @media or @keyframes
        const handleNestedRules = (css) => {
            return css.replace(
                /(@media[^{]+|@keyframes[^{]+)({)([\s\S]+?)}/g,
                (match, atRule, openBrace, content) => {
                    // Process the content inside the at-rule
                    const processedContent = this.scopeCssSelectors(content, scopePrefix);
                    return `${atRule}${openBrace}${processedContent}}`;
                }
            );
        };

        // First handle nested rules
        const processedWithNestedRules = handleNestedRules(cssContent);

        // Then process standard rules (but skip those inside already processed at-rules)
        return processedWithNestedRules.replace(
            /([^{}]+)({[^{}]*})/g,
            (match, selectors, rules) => {
                // Skip if this is inside an at-rule (already processed)
                if (selectors.trim().startsWith('@')) {
                    return match;
                }

                // Process each selector group
                const scopedSelectors = selectors
                    .split(',')
                    .map(selector => {
                        selector = selector.trim();

                        // Skip @-rules
                        if (selector.startsWith('@')) {
                            return selector;
                        }

                        // Add the scope prefix to the selector
                        return `${scopePrefix} ${selector}`;
                    })
                    .join(', ');

                // Return the scoped selector with its rules
                return `${scopedSelectors}${rules}`;
            }
        );
    }

    processFormsAndAttachEventHandlers() {
        // Find the form element (expecting exactly one form)
        const forms = this.formContainerRef.current.querySelectorAll('form');

        if (forms.length === 0) {
            // If no form tags exist, add a warning
            console.warn('No form element found in the provided HTML content');
            // Treat the container as a form
            this.setupEventHandlers(this.formContainerRef.current);
        } else {
            // There should be exactly one form, but handle multiple just in case
            if (forms.length > 1) {
                console.warn(`Found ${forms.length} forms, expected 1. Processing the first form only.`);
            }

            // Setup the first form found
            this.setupEventHandlers(forms[0]);

            // Focus the handling on the form area
            this.formElement = forms[0];
        }

        // Find the target element (form or container)
        const targetElement = this.formElement || this.formContainerRef.current;

        // Check for any existing submit button (either with type="submit" or with class "form-submit-button")
        const existingSubmitButton = targetElement.querySelector('button[type="submit"], input[type="submit"], .form-submit-button');

        // Add emoji rating right before our submit button
        this.addEmojiRating(targetElement, existingSubmitButton);

        if (!existingSubmitButton) {
            this.addSubmitButton(targetElement);
        } else {
            existingSubmitButton.className = 'ant-btn lg-btn ant-btn-primary inline-block';
            existingSubmitButton.style = '';
            // If there's an existing submit button, make sure it has our event handler
            existingSubmitButton.addEventListener('click', (e) => {
                e.preventDefault(); // Prevent default form submission
                this.handleFormSubmit();
            });
        }

        // Add CSS for the loading spinner animation
        this.addSpinnerStyles();

        // At the end of the method, check if we need to prefill data
        if (this.state.studentId && !this.state.prefillCompleted) {
            this.prefillStudentData(this.state.studentId);
        }
    }

    prefillStudentData(studentId) {
        // Check if the student has already completed the assessment
        if (this.props.quiz &&
            this.props.quiz.manualGrades &&
            this.props.quiz.manualGrades[studentId] &&
            this.props.quiz.manualGrades[studentId].graded) {

            // Get the student's previous answers
            const studentData = this.props.quiz.manualGrades[studentId];

            // Get emoji rating if available
            let emojiRating = null;
            if (this.props.quiz.studentSurveys &&
                this.props.quiz.studentSurveys[studentId] &&
                this.props.quiz.studentSurveys[studentId].completed) {
                emojiRating = this.props.quiz.studentSurveys[studentId].qualitative;
            }

            // For checkbox states, we need to handle it differently since we're working with indices
            // in chosenAnswers but the DOM has actual IDs/names
            this.setState({
                emojiRating: emojiRating,
                prefillCompleted: true
                // We'll handle checkbox states differently
            }, () => {
                // Update the DOM after state is set
                this.updateDOMWithPrefillDataByIndex(studentData.chosenAnswers || {});
            });
        } else {
            // Mark prefill as completed even if no data
            this.setState({
                prefillCompleted: true
            });
        }
    }

    updateDOMWithPrefillDataByIndex = (chosenAnswers) => {
        if (!this.formContainerRef.current) return;

        // Get the form element or container
        const targetElement = this.formElement || this.formContainerRef.current;

        // Get all checkboxes in the form in document order
        const checkboxes = Array.from(targetElement.querySelectorAll('input[type="checkbox"]'));

        // Object to store updated checkbox states
        const updatedCheckboxStates = {};

        // Update each checkbox based on its index
        checkboxes.forEach((checkbox, index) => {
            // Get the value from chosenAnswers using the index as string key
            const isChecked = chosenAnswers[index.toString()] === true;

            // Set the checkbox state
            checkbox.checked = isChecked;

            // Get ID or name for state tracking
            const id = checkbox.id || checkbox.name;
            if (id) {
                updatedCheckboxStates[id] = isChecked;
            }

            // Update any parent elements that might need styling
            const checkboxLabel = checkbox.closest('label');
            if (checkboxLabel) {
                if (isChecked) {
                    checkboxLabel.classList.add('checked');
                } else {
                    checkboxLabel.classList.remove('checked');
                }
            }
        });

        // Update our state to match what we've set in the DOM
        this.setState({ checkboxStates: updatedCheckboxStates });

        // Update emoji rating if available
        if (this.state.emojiRating) {
            const radioButtons = targetElement.querySelectorAll('.emoji-rating-radio');
            radioButtons.forEach(radio => {
                if (radio.value === this.state.emojiRating) {
                    radio.checked = true;

                    // Update visual styles for the emoji container
                    const emojiContainer = radio.closest('label').querySelector('div[style*="border"]');
                    if (emojiContainer) {
                        emojiContainer.style.border = '2px solid #1890ff';
                        emojiContainer.style.boxShadow = '0 0 5px #1890ff';
                    }
                }
            });
        }
    }

    setupEventHandlers(formElement) {
        if (formElement) {
            // Prevent default form submission
            formElement.addEventListener('submit', (e) => {
                e.preventDefault();
                this.handleFormSubmit();
            });

            // Attach event handlers to all checkboxes
            const checkboxes = formElement.querySelectorAll('input[type="checkbox"]');
            checkboxes.forEach(checkbox => {
                const id = checkbox.id || checkbox.name;
                if (!id) return;

                // Initialize checkbox state in our state object
                this.setState(prevState => ({
                    checkboxStates: {
                        ...prevState.checkboxStates,
                        [id]: checkbox.checked
                    }
                }));

                // Add change event listener
                checkbox.addEventListener('change', (e) => {
                    this.handleCheckboxChange(id, e.target.checked);
                });
            });
        }
    }

    handleCheckboxChange(id, isChecked) {
        // Update our state with the new checkbox value
        this.setState(prevState => ({
            checkboxStates: {
                ...prevState.checkboxStates,
                [id]: isChecked
            }
        }));

        console.log(`Checkbox ${id} changed to: ${isChecked}`);
    }

    // New method to handle emoji rating changes
    handleEmojiRatingChange(rating) {
        this.setState({ emojiRating: rating });
        console.log(`Emoji rating changed to: ${rating}`);

        // Find and update all radio buttons in the emoji rating group
        const targetElement = this.formElement || this.formContainerRef.current;
        const radioButtons = targetElement.querySelectorAll('.emoji-rating-radio');

        radioButtons.forEach(radio => {
            if (radio.value === rating) {
                radio.checked = true;
            } else {
                radio.checked = false;
            }
        });

        // Clear any validation errors for emoji rating
        const emojiContainer = targetElement.querySelector('.emoji-rating-container');
        if (emojiContainer) {
            // Remove error message
            const errorContainer = emojiContainer.querySelector('.emoji-error-container');
            if (errorContainer) {
                errorContainer.innerHTML = '';
            }

            // Remove red borders from emoji options
            const emojiOptions = emojiContainer.querySelectorAll('div[style*="border"]');
            emojiOptions.forEach(option => {
                if (option.style.borderColor === 'red') {
                    option.style.border = '2px solid #e8e8e8';
                }
            });
        }
    }

    addSpinnerStyles() {
        // Add keyframes for spinner animation if not present
        if (!document.getElementById('spinner-keyframes')) {
            const styleSheet = document.createElement('style');
            styleSheet.id = 'spinner-keyframes';
            styleSheet.textContent = `
        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
      `;
            document.head.appendChild(styleSheet);
        }
    }

    // New method to add emoji rating
    addEmojiRating(targetElement, existingSubmitButton) {
        const emojiRatingContainer = document.createElement('div');
        emojiRatingContainer.className = 'emoji-rating-container';
        emojiRatingContainer.style.marginTop = '20px';
        emojiRatingContainer.style.marginBottom = '20px';
        emojiRatingContainer.style.textAlign = 'center';

        emojiRatingContainer.innerHTML = `
            <div style="margin-bottom: 10px; font-weight: bold;">Student response to: How do you feel about this test? <span style="color: red;">*</span></div>
            <div style="display: flex; justify-content: center; gap: 20px;">
                <label style="cursor: pointer; display: inline-flex; flex-direction: column; align-items: center;">
                    <input type="radio" name="emojiRating" value="good" class="emoji-rating-radio" style="display: none;">
                    <div style="height: 100px; width: 100px; display: flex; align-items: center; justify-content: center; border: 2px solid #e8e8e8; border-radius: 5px;">
                        <div style="font-size: 64px;">😀</div>
                    </div>
                </label>
                <label style="cursor: pointer; display: inline-flex; flex-direction: column; align-items: center;">
                    <input type="radio" name="emojiRating" value="somewhatGood" class="emoji-rating-radio" style="display: none;">
                    <div style="height: 100px; width: 100px; display: flex; align-items: center; justify-content: center; border: 2px solid #e8e8e8; border-radius: 5px;">
                        <div style="font-size: 64px;">😐</div>
                    </div>
                </label>
                <label style="cursor: pointer; display: inline-flex; flex-direction: column; align-items: center;">
                    <input type="radio" name="emojiRating" value="notGood" class="emoji-rating-radio" style="display: none;">
                    <div style="height: 100px; width: 100px; display: flex; align-items: center; justify-content: center; border: 2px solid #e8e8e8; border-radius: 5px;">
                        <div style="font-size: 64px;">☹️</div>
                    </div>
                </label>
            </div>
            <div class="emoji-error-container" style="height: 20px; margin-top: 5px;"></div>
        `;

        // Handle different cases of where to insert the emoji rating
        if (existingSubmitButton) {
            // If there's a submit button, insert before it
            const buttonParent = existingSubmitButton.parentNode;
            // Check if the button is directly in the target element or nested
            if (buttonParent === targetElement) {
                // Button is directly in target element
                targetElement.insertBefore(emojiRatingContainer, existingSubmitButton);
            } else {
                // Button is in a container, insert before the container if possible
                if (buttonParent.parentNode === targetElement) {
                    targetElement.insertBefore(emojiRatingContainer, buttonParent);
                } else {
                    // Fallback: just append to the target element
                    targetElement.appendChild(emojiRatingContainer);
                }
            }
        } else {
            // If there's no submit button yet, just append to the target element
            // It will appear before our custom submit button that gets added later
            targetElement.appendChild(emojiRatingContainer);
        }

        // Add click event listeners to handle the radio button selection
        const radioButtons = emojiRatingContainer.querySelectorAll('input[type="radio"]');
        radioButtons.forEach(radio => {
            radio.addEventListener('change', (e) => {
                // Update the state
                this.handleEmojiRatingChange(e.target.value);

                // Visual feedback - highlight selected emoji
                const labels = emojiRatingContainer.querySelectorAll('label');
                labels.forEach(label => {
                    const labelRadio = label.querySelector('input[type="radio"]');
                    const emojiContainer = label.querySelector('div[style*="border"]');

                    if (labelRadio.checked) {
                        emojiContainer.style.border = '2px solid #1890ff';
                        emojiContainer.style.boxShadow = '0 0 5px #1890ff';
                    } else {
                        emojiContainer.style.border = '2px solid #e8e8e8';
                        emojiContainer.style.boxShadow = 'none';
                    }
                });
            });
        });
    }

    addSubmitButton(targetElement) {
        const submitBtn = document.createElement('div');
        submitBtn.className = 'submit-button-container';
        submitBtn.style.marginTop = '20px';
        submitBtn.style.textAlign = 'center';

        submitBtn.innerHTML = `
      <button 
        type="button" 
        class="form-submit-button" 
        style="background-color: #3f51b5; color: white; padding: 10px 20px; border: none; border-radius: 5px; cursor: pointer; font-weight: bold; font-size: 16px;"
      >
        Submit Assessment
      </button>
    `;

        // Append to the target element (either the form or the container)
        targetElement.appendChild(submitBtn);

        // Add click event listener to the button
        const button = submitBtn.querySelector('.form-submit-button');
        if (button) {
            button.addEventListener('click', this.handleFormSubmit);
        }
    }

    collectFormData() {
        const data = {
            items: [],
            // Start with our tracked checkbox states
            ...this.state.checkboxStates,
            // Add emoji rating to data
            emojiRating: this.state.emojiRating
        };

        if (!this.formContainerRef.current) return data;

        Object.keys(this.state.checkboxStates).forEach(id => {
            data.items.push({ name: id, value: this.state.checkboxStates[id] });
        });

        // Get the form element if it exists, otherwise use the container
        const targetElement = this.formElement || this.formContainerRef.current;

        // Get all form elements (except checkboxes since we handle them separately)
        const inputs = targetElement.querySelectorAll('input:not([type="checkbox"]):not([name="emojiRating"]), select, textarea');

        // Process each input
        inputs.forEach(input => {
            const name = input.name || input.id;

            if (!name) {
                console.warn('Found form element without name or id attribute:', input);
                return; // Skip elements without name or id
            }

            if (input.type === 'radio') {
                data[name] = input.checked;
                data.items.push({ name, value: input.value });
            } else {
                data[name] = input.value;
                data.items.push({ name, value: input.value });
            }
        });

        // Create structured data for sections and questions
        data.sections = this.buildSectionData(targetElement);

        return data;
    }

    buildSectionData(container) {
        const sections = {};
        const sectionsElements = container.querySelectorAll('.exam-section');

        sectionsElements.forEach((section, index) => {
            const sectionTitle = section.querySelector('h2')?.textContent || `Section ${index + 1}`;
            sections[sectionTitle] = {
                questions: {}
            };

            // Find all questions in this section
            const questions = section.querySelectorAll('.question');
            questions.forEach((question, qIndex) => {
                // Find the question text - look for Question label first
                let questionText = '';
                const questionLabels = Array.from(question.querySelectorAll('strong')).filter(el =>
                    el.textContent?.includes('Question'));

                if (questionLabels.length > 0) {
                    questionText = questionLabels[0].textContent || `Question ${qIndex + 1}`;
                } else {
                    // Fallback to any strong tag
                    questionText = question.querySelector('strong')?.textContent || `Question ${qIndex + 1}`;
                }

                // Find all checkboxes for this question
                const checkboxes = question.querySelectorAll('input[type="checkbox"]');

                // Use our stored states instead of DOM checked attribute
                const checkboxData = Array.from(checkboxes).map(cb => {
                    const id = cb.id || cb.name;

                    return {
                        id: id,
                        // Get the state from our tracked state object
                        checked: this.state.checkboxStates[id] || false,
                        label: cb.parentElement?.querySelector('label')?.textContent || ''
                    };
                });

                sections[sectionTitle].questions[questionText] = {
                    checkboxes: checkboxData
                };
            });
        });

        return sections;
    }

    validateForm(data) {
        // For this assessment form, we don't need to validate checkboxes
        // as they represent completed assessment items that might not all be checked
        const errors = {};

        // Check only if the required fields specified in props are present
        const requiredFields = this.props.requiredFields || [];
        requiredFields.forEach(field => {
            if (!data[field] && !errors[field]) {
                errors[field] = `${field} is required`;
            }
        });

        // Always require emoji rating
        if (!data.emojiRating) {
            errors.emojiRating = 'Please select before submitting';
        }

        return errors;
    }

    handleFormSubmit() {
        if (!this.state.studentId) {
            errorMessage('Please select a student.')
            return;
        }

        // Collect the form data (using our tracked checkboxes)
        const data = this.collectFormData();
        console.log('Raw form data on submit:', data);

        // Validate if needed
        const errors = this.validateForm(data);
        this.setState({ validationErrors: errors });

        // Create a summary of the assessment results
        const summary = this.createAssessmentSummary(data);

        // Add summary to the form data
        data.summary = summary;

        // Save the data to our state
        this.setState({ formData: data });

        if (Object.keys(errors).length === 0) {
            // Call custom onSubmit prop if provided
            if (this.props.onSubmit) {
                this.props.onSubmit(data);
            }

            let gradeObj = {
                chosenAnswers: {},
                chosenAnswersBaseQuiz: {},
                finalGrade: {
                    correct: 0,
                    total: data.items.length,
                    grade: 0,
                },
                graded: true,
                manualGrade: true,
                manualGradeTimestamp: firebase.firestore.FieldValue.serverTimestamp(),
            };

            data.items.forEach((item, idx) => {
                gradeObj.chosenAnswers[idx] = item.value;
                gradeObj.chosenAnswersBaseQuiz[idx] = item.value;
                if (item.value) {
                    gradeObj.finalGrade.correct += 1;
                }
            });
            gradeObj.finalGrade.grade = gradeObj.finalGrade.correct / gradeObj.finalGrade.total;


            let manualGrades = {};
            if (this.props.quiz.hasOwnProperty('manualGrades')) {
                manualGrades = this.props.quiz.manualGrades;
            }

            manualGrades[this.state.studentId] = gradeObj;

            this.props.quiz.studentSurveys[this.state.studentId] = {
                qualitative: data.emojiRating,
                completed: true,
            };

            db.collection(ColType.cleverAssessments)
                .doc(this.props.quiz.id)
                .update({
                    manualGrades: manualGrades,
                    studentSurveys: this.props.quiz.studentSurveys
                }).then(() => {
                    this.setState({
                        submitting: false,
                    })
                    //successMessage('Grade added.')

                }).catch((error) => {
                    this.setState({
                        submitting: false,
                    })
                    //errorMessage('An error occurred.');
                });

            // Show success message
            this.setState({ isSubmitted: true });
        } else {
            // Show validation errors
            this.highlightErrors(errors);
        }
    }

    createAssessmentSummary(data) {
        if (!data.sections) {
            return { message: "No assessment data found." };
        }

        const summary = {
            title: "South Carolina Kindergarten ELA Oral Assessment Summary",
            totalQuestions: 0,
            answeredCorrectly: 0,
            sectionResults: {},
            emojiRating: data.emojiRating // Add emoji rating to summary
        };

        // Process each section
        Object.keys(data.sections).forEach(sectionTitle => {
            const section = data.sections[sectionTitle];
            summary.sectionResults[sectionTitle] = {
                totalQuestions: 0,
                answeredCorrectly: 0,
                questions: {}
            };

            // Process each question in the section
            Object.keys(section.questions).forEach(questionText => {
                const question = section.questions[questionText];

                // Count total checkboxes as individual questions
                const totalCheckboxes = question.checkboxes.length;
                const checkedCheckboxes = question.checkboxes.filter(cb => cb.checked).length;

                summary.totalQuestions += totalCheckboxes;
                summary.answeredCorrectly += checkedCheckboxes;

                summary.sectionResults[sectionTitle].totalQuestions += totalCheckboxes;
                summary.sectionResults[sectionTitle].answeredCorrectly += checkedCheckboxes;

                // Store question-specific results
                summary.sectionResults[sectionTitle].questions[questionText] = {
                    answered: checkedCheckboxes > 0,
                    answeredCorrectly: checkedCheckboxes
                };
            });
        });

        // Calculate overall percentage
        summary.percentage = summary.totalQuestions > 0
            ? Math.round((summary.answeredCorrectly / summary.totalQuestions) * 100)
            : 0;

        // Calculate section percentages
        Object.keys(summary.sectionResults).forEach(sectionTitle => {
            const section = summary.sectionResults[sectionTitle];
            section.percentage = section.totalQuestions > 0
                ? Math.round((section.answeredCorrectly / section.totalQuestions) * 100)
                : 0;
        });

        return summary;
    }

    highlightErrors(errors) {
        if (!this.formContainerRef.current) return;

        // Remove any existing error highlights
        const prevHighlights = this.formContainerRef.current.querySelectorAll('.validation-error');
        prevHighlights.forEach(el => {
            el.remove();
        });

        // Create a copy of errors without emoji rating for the top summary
        const topSummaryErrors = { ...errors };
        delete topSummaryErrors.emojiRating;

        // Add new error highlights
        Object.keys(errors).forEach(field => {
            if (field === 'emojiRating') {
                // Special handling for emoji rating errors
                const emojiContainer = this.formContainerRef.current.querySelector('.emoji-rating-container');
                if (emojiContainer) {
                    // Find the error container within the emoji section
                    const errorContainer = emojiContainer.querySelector('.emoji-error-container');
                    if (errorContainer) {
                        // Add error message in the designated container
                        const errorElement = document.createElement('div');
                        errorElement.className = 'validation-error';
                        errorElement.style.color = 'red';
                        errorElement.style.fontSize = '14px';
                        errorElement.style.fontWeight = 'bold';
                        errorElement.textContent = errors[field];
                        errorContainer.appendChild(errorElement);
                    }

                    // Add red border to all emoji options to highlight the entire section
                    const emojiOptions = emojiContainer.querySelectorAll('div[style*="border"]');
                    emojiOptions.forEach(option => {
                        option.style.border = '2px solid red';
                    });
                }
            } else {
                const element = this.formContainerRef.current.querySelector(`[name="${field}"], #${field}`);

                if (element) {
                    // Add red border
                    element.style.border = '2px solid red';

                    // Add error message below the field
                    const errorElement = document.createElement('div');
                    errorElement.className = 'validation-error';
                    errorElement.style.color = 'red';
                    errorElement.style.fontSize = '12px';
                    errorElement.style.marginTop = '4px';
                    errorElement.textContent = errors[field];

                    element.parentNode.insertBefore(errorElement, element.nextSibling);
                }
            }
        });

        // Add a general error message at the top if there are any other errors besides emoji rating
        if (Object.keys(topSummaryErrors).length > 0) {
            const errorSummary = document.createElement('div');
            errorSummary.className = 'validation-error error-summary';
            errorSummary.style.backgroundColor = '#ffebee';
            errorSummary.style.color = '#c62828';
            errorSummary.style.padding = '10px';
            errorSummary.style.borderRadius = '5px';
            errorSummary.style.marginBottom = '20px';
            errorSummary.innerHTML = `
              <p style="font-weight: bold; margin: 0 0 5px 0">Please fix the following errors:</p>
              <ul style="margin: 0 0 0 20px; padding: 0;">
                ${Object.values(topSummaryErrors).map(err => `<li>${err}</li>`).join('')}
              </ul>
            `;

            // Insert at the beginning of the form
            const firstChild = this.formContainerRef.current.firstChild;
            this.formContainerRef.current.insertBefore(errorSummary, firstChild);

            // Scroll to the top of the form to see the error summary
            this.formContainerRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }

    resetForm() {
        // Reset the submitted state and generate a new instance ID
        this.setState({
            isSubmitted: false,
            instanceId: `html-assessment-${Date.now()}`,  // New ID to ensure clean CSS scoping
            checkboxStates: {}, // Reset checkbox states
            emojiRating: null, // Reset emoji rating
            prefillCompleted: false, // Reset prefill flag
            studentId: undefined,
        }, () => {
            // Reset the form HTML
            if (this.formContainerRef.current) {
                // Apply the new instance ID
                this.formContainerRef.current.id = this.state.instanceId;

                // Process the HTML content with the new ID
                const processedHtml = this.processCssStyles(this.state.originalHtml, this.state.instanceId);
                this.formContainerRef.current.innerHTML = processedHtml;

                // Re-process forms and add submit button
                this.processFormsAndAttachEventHandlers();
            }

            // Clear the state
            this.setState({
                formData: {},
                validationErrors: {}
            });
        });
    }

    renderSuccessMessage() {
        // Get the assessment summary if available
        const summary = this.state.formData?.summary;

        return (
            <div style={{
                backgroundColor: '#e8f5e9',
                border: '1px solid #66bb6a',
                color: '#2e7d32',
                padding: '20px',
                borderRadius: '5px',
                textAlign: 'center'
            }}>
                <h2 style={{ marginTop: 0 }}>Assessment Submitted Successfully!</h2>

                {summary && (
                    <div style={{ textAlign: 'left', marginTop: '20px', marginBottom: '20px' }}>
                        <h3>{summary.title}</h3>
                        <p><strong>Overall Score:</strong> {summary.answeredCorrectly} / {summary.totalQuestions} ({summary.percentage}%)</p>

                        {/* Display emoji rating if available */}
                        {summary.emojiRating && (
                            <p><strong>Student's response to: How do you feel about this test?:</strong> {
                                summary.emojiRating === 'good' ? '😀' :
                                    summary.emojiRating === 'somewhatGood' ? '😐' :
                                        summary.emojiRating === 'notGood' ? '☹️' :
                                            'Not rated'
                            }</p>
                        )}

                        {Object.keys(summary.sectionResults).map((sectionTitle, index) => {
                            const section = summary.sectionResults[sectionTitle];
                            return (
                                <div key={index} style={{ marginTop: '15px' }}>
                                    <h4 style={{ marginBottom: '5px' }}>{sectionTitle}</h4>
                                    <p style={{ margin: '0 0 5px 0' }}>
                                        Score: {section.answeredCorrectly} / {section.totalQuestions} ({section.percentage}%)
                                    </p>
                                </div>
                            );
                        })}
                    </div>
                )}

                <p>Thank you for completing this assessment.</p>
                <button
                    onClick={this.resetForm}
                    style={{
                        backgroundColor: '#2e7d32',
                        color: 'white',
                        border: 'none',
                        padding: '8px 16px',
                        borderRadius: '4px',
                        cursor: 'pointer',
                        marginTop: '10px'
                    }}
                >
                    Reset Assessment
                </button>
            </div>
        );
    }

    renderLoadingSpinner() {
        return (
            <div style={{ padding: '2rem', textAlign: 'center' }}>
                <div style={{
                    display: 'inline-block',
                    width: '40px',
                    height: '40px',
                    border: '4px solid rgba(0, 0, 0, 0.1)',
                    borderLeftColor: '#3f51b5',
                    borderRadius: '50%',
                    animation: 'spin 1s linear infinite'
                }}></div>
                <p style={{ marginTop: '1rem' }}>Loading assessment...</p>
            </div>
        );
    }

    renderErrorMessage() {
        return (
            <div style={{
                backgroundColor: '#ffebee',
                border: '1px solid #ffcdd2',
                color: '#c62828',
                padding: '15px',
                borderRadius: '5px'
            }}>
                <h3 style={{ marginTop: 0, fontWeight: 'bold' }}>Error Loading Assessment</h3>
                <p>{this.state.error}</p>
                <button
                    onClick={() => this.renderHtmlContent()}
                    style={{
                        backgroundColor: '#c62828',
                        color: 'white',
                        border: 'none',
                        padding: '8px 16px',
                        borderRadius: '4px',
                        cursor: 'pointer',
                        marginTop: '10px'
                    }}
                >
                    Try Again
                </button>
            </div>
        );
    }

    // 4. Update studentChange method 
    studentChange = (value) => {
        if (value !== this.state.studentId) {
            // Reset form
            this.setState({
                studentId: value,
                isSubmitted: false,
                formData: {},
                validationErrors: {},
                checkboxStates: {},
                emojiRating: null,
                prefillCompleted: false // Reset prefill flag
            }, () => {
                // Render HTML content with new student ID
                this.renderHtmlContent();
            });
        } else {
            // Just set the student ID if it's the same
            this.setState({
                studentId: value
            });
        }
    }

    getSortedIds = () => {
        if (this.props.teacherBaseProps && this.props.teacherBaseProps.studentDict &&
            this.props.quiz && this.props.quiz.studentGrades) {
            return Object.keys(this.props.quiz.studentGrades).sort((a, b) => {
                const studentA = this.props.teacherBaseProps.studentDict[a]
                const studentB = this.props.teacherBaseProps.studentDict[b]
                return studentA.lastName.localeCompare(studentB.lastName)
            });
        }
        return [];
    }

    render() {
        const { isLoading, error, isSubmitted } = this.state;
        // console.log(this.props)

        return (
            <div className="html-form-handler" style={{
                fontFamily: 'Arial, sans-serif',
                maxWidth: this.props.maxWidth || '100%',
                margin: '0 auto'
            }}>
                {/* Form title if provided */}
                {this.props.title && (
                    <h2 style={{
                        fontSize: '1.5rem',
                        marginBottom: '1rem',
                        color: '#333'
                    }}>
                        {this.props.title}
                    </h2>
                )}

                <div className='mb-2'>
                    {!this.state.studentId ?
                        <div className='font-20 mb-1'>Select a student to get started:</div>
                        : ''}
                    <Select
                        showSearch
                        className="ant-select-very-large"
                        placeholder="Select student"
                        style={{ width: '300px' }}
                        value={this.state.studentId}
                        onChange={this.studentChange}
                    >
                        {this.props.teacherBaseProps && this.props.teacherBaseProps.studentDict &&
                            this.props.quiz && this.props.quiz.studentGrades &&
                            this.getSortedIds().map((studentId, index) => {
                                if (!this.props.teacherBaseProps.studentDict.hasOwnProperty(studentId)) {
                                    return null
                                }
                                const student = this.props.teacherBaseProps.studentDict[studentId]
                                return <Option value={student.id} key={"student-" + index}>
                                    <div className='flex flex-v-center w-100'>
                                        <PersonAvatar
                                            size={'default'}
                                            person={student}
                                        />
                                        {this.props.quiz.manualGrades &&
                                            this.props.quiz.manualGrades[studentId] &&
                                            this.props.quiz.manualGrades[studentId].graded ?
                                            <div className='ml-auto'>
                                                <Icon type="check" className='text-success'
                                                    title={studentId && this.props.quiz && this.props.quiz.manualGrades &&
                                                        this.props.quiz.manualGrades[studentId] &&
                                                        this.props.quiz.manualGrades[studentId].graded &&
                                                        this.props.quiz.manualGrades[studentId].manualGradeTimestamp &&
                                                        this.props.quiz.manualGrades[studentId].manualGradeTimestamp.seconds ?
                                                        'Completed on: ' + moment(this.props.quiz.manualGrades[studentId].manualGradeTimestamp.toDate()).format('MM/DD/YYYY')
                                                        :
                                                        'Completed'
                                                    } />
                                            </div>
                                            : ''}
                                    </div>
                                </Option>
                            })
                        }
                    </Select>
                </div>

                {!isSubmitted &&
                    this.state.studentId && this.props.quiz && this.props.quiz.manualGrades &&
                    this.props.quiz.manualGrades[this.state.studentId] &&
                    this.props.quiz.manualGrades[this.state.studentId].graded &&
                    this.props.quiz.manualGrades[this.state.studentId].manualGradeTimestamp &&
                    this.props.quiz.manualGrades[this.state.studentId].manualGradeTimestamp.seconds ?
                    <div className="completed-timestamp font-16 mb-2">
                        Completed on: {moment(this.props.quiz.manualGrades[this.state.studentId].manualGradeTimestamp.toDate()).format('MM/DD/YYYY')}
                    </div>
                    : ''}

                {/* Loading spinner */}
                {isLoading && this.renderLoadingSpinner()}

                {/* Error message */}
                {!isLoading && error && this.renderErrorMessage()}

                {/* Success message */}
                {!isLoading && !error && isSubmitted && this.renderSuccessMessage()}

                {/* Form container */}
                {!isLoading && !error && !isSubmitted && (
                    <div
                        ref={this.formContainerRef}
                        className="form-container"
                    ></div>
                )}

                {/* Debug panel - uncomment for debugging */}
                {/*
                <div style={{ 
                  marginTop: '2rem', 
                  padding: '1rem', 
                  backgroundColor: '#f5f5f5', 
                  borderRadius: '4px',
                  fontSize: '0.8rem'
                }}>
                  <h4 style={{ marginTop: 0 }}>Debug Information:</h4>
                  <pre style={{ 
                    overflowX: 'auto', 
                    backgroundColor: '#eee', 
                    padding: '0.5rem',
                    maxHeight: '200px' 
                  }}>
                    {JSON.stringify({
                      checkboxStates: this.state.checkboxStates,
                      formData: this.state.formData,
                      errors: this.state.validationErrors
                    }, null, 2)}
                  </pre>
                </div>
                */}
            </div>
        );
    }
}

export default HTMLAssessment;