import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../../styles/GlobalStyle.css'
import { db } from '../../firebase/Firebase'
import ColType from '../.././Types'
import { Button, Form, Radio, Icon, Spin } from 'antd'
import { chosenAnswersCorrectData, getQueryStringParam } from '../../Util'
import DocumentDelegator from '../../edviz/EdvizDelegator'
import AssessmentSurveyForm from './AssessmentSurveyForm'
//import PersonAvatar from '../../customcomponents/PersonAvatar'
//var moment = require('moment')
const FormItem = Form.Item

class PacingGuideAssessment extends Component {
    state = {
        quizId: '',
        quiz: null,
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        let quizId = getQueryStringParam('quiz');

        this.setState({
            quizId: quizId
        });

        // Add event listeners
        document.addEventListener('copy', this.preventCopyPaste);
        document.addEventListener('cut', this.preventCopyPaste);
        document.addEventListener('paste', this.preventCopyPaste);
        document.addEventListener('contextmenu', this.preventCopyPaste);
        document.addEventListener('keydown', this.preventCopyPasteKeyDown);

        this.setQuiz();
    }

    componentWillReceiveProps(nextProps) {
        this.setQuiz()
    }

    componentWillUnmount() {
        document.removeEventListener('copy', this.preventCopyPaste);
        document.removeEventListener('cut', this.preventCopyPaste);
        document.removeEventListener('paste', this.preventCopyPaste);
        document.removeEventListener('contextmenu', this.preventCopyPaste);
        document.removeEventListener('keydown', this.preventCopyPasteKeyDown);
    }

    setQuiz = () => {
        if (!this.props.quiz) {
            return
        }
        // already set, return
        if (this.state.quiz) {
            return
        }

        // clone quiz, so setState can be used
        let quiz = JSON.parse(JSON.stringify(this.props.quiz))
        /*quiz.questions.sort((a, b) => {
            if (!(a.hasOwnProperty('dokLevel') && b.hasOwnProperty('dokLevel'))) {
                return 0
            }
            return a.dokLevel - b.dokLevel
        })*/
        /*quiz.assessment.questionIdx = quiz.assessment.questions.length
        for (let question of quiz.assessment.questions) {
            question.chosenAnswers = ['A']
        }*/
        this.setState({
            quiz: quiz
        }, () => {
            //this.calculateGrade()
            this.prefillForm()
        })
    }

    prefillForm = () => {
        if (this.props.hasOwnProperty('viewOnly') && this.props.viewOnly) {
            return
        }

        let quiz = this.state.quiz
        if (!(quiz.quiz.questionIdx >= 0 && quiz.quiz.questionIdx <= quiz.quiz.docs.length - 1)) {
            return
        }
        if (!quiz.quiz.chosenAnswers.hasOwnProperty(quiz.quiz.questionIdx)) {
            return
        }

        this.props.form.setFieldsValue({
            // [0] now, if multiple answers later, then don't [0]
            question: quiz.quiz.chosenAnswers[quiz.quiz.questionIdx]
        })
    }

    calculateGrade = () => {
        let totalCorrectData = { correct: 0, total: 0 }
        for (let question of this.state.quiz.assessment.questions) {
            let correctData = chosenAnswersCorrectData(question)
            totalCorrectData.correct += correctData.correct
            totalCorrectData.total += correctData.total
        }
        let grade = 0
        if (totalCorrectData.total > 0) {
            grade = Math.round((totalCorrectData.correct / totalCorrectData.total) * 100)
        }
        return { correct: totalCorrectData.correct, total: totalCorrectData.total, grade: grade }
    }

    saveToDBAndNavigate = (quiz, navigationDirection) => {
        // reset form
        this.props.form.resetFields()

        if (navigationDirection === 'next') {
            quiz.quiz.questionIdx += 1
        } else if (navigationDirection === 'previous') {
            if (quiz.quiz.questionIdx > 0) {
                quiz.quiz.questionIdx -= 1
            }
        }

        // update in db
        db.collection(ColType.cleverStudentAssessments)
            .doc(quiz.id)
            .update({
                'quiz.chosenAnswers': quiz.quiz.chosenAnswers,
                'quiz.questionIdx': quiz.quiz.questionIdx,
            })
            .then(() => {
                //console.log('quiz submitted successfully')
            })
            .catch((error) => {
                console.log('error submitting quiz', error)
            })

        this.setState({
            quiz: quiz,
            submitting: false,
        }, () => {
            if (navigationDirection !== 'previous') {
                window.scrollTo(0, 0)
            }
            this.prefillForm()
        })
    }

    handleSubmit = (e, navigationDirection) => {
        e.preventDefault()
        this.props.form.validateFields((err, values) => {
            //console.log('form values', values)
            // no multiple choice answer selected and navigation direction is previous
            // then save and navigate. If navigation direction is 'next' then we must
            // always validate. If the navgiation direction is 'previous', we only
            // validate if there is an answer selected. Check for 0, cant just do !values.question
            // as 0 is falsy.
            if (!(values.question || values.question === 0)) {
                if (navigationDirection === 'previous') {
                    //console.log('no multiple choice answer selected, and navigation direction is previous, skipping form validation.')
                    this.saveToDBAndNavigate(this.state.quiz, navigationDirection)
                    return
                }
            }
            if (!err) {
                this.setState({
                    submitting: true,
                }, () => {
                    //console.log('received values of form', values)
                    let quiz = this.state.quiz
                    // add as a list right now, if multiple answers later
                    // then don't wrap in a list
                    // store as an object as nested lists are not supported
                    quiz.quiz.chosenAnswers[quiz.quiz.questionIdx] = values.question
                    this.saveToDBAndNavigate(quiz, navigationDirection)
                })
            }
        })
    }

    preventCopyPaste = (e) => {
        e.preventDefault();
        return false;
    }

    // Handle keyboard shortcuts (Ctrl+A, Ctrl+C, Ctrl+X)
    preventCopyPasteKeyDown = (e) => {
        // Check for Ctrl key or Command key (Mac)
        if (e.ctrlKey || e.metaKey) {
            // Prevent Ctrl+A (Select All)
            if (e.key === 'a' || e.keyCode === 65) {
                e.preventDefault();
                return false;
            }

            // Prevent Ctrl+C (Copy)
            if (e.key === 'c' || e.keyCode === 67) {
                e.preventDefault();
                return false;
            }

            // Prevent Ctrl+X (Cut)
            if (e.key === 'x' || e.keyCode === 88) {
                e.preventDefault();
                return false;
            }
        }
    }

    render() {
        const { getFieldDecorator } = this.props.form
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        }

        let currentQuestion = null
        if (this.state.quiz &&
            this.state.quiz.quiz &&
            this.state.quiz.quiz.docs &&
            this.state.quiz.quiz.questionIdx >= 0 &&
            this.state.quiz.quiz.questionIdx <= this.state.quiz.quiz.docs.length - 1) {
            currentQuestion = this.state.quiz.quiz.docs[this.state.quiz.quiz.questionIdx]
        }

        let canViewResults = true
        if (this.props.hasOwnProperty('canViewResults')) {
            canViewResults = this.props.canViewResults
        }

        return (
            <div className='no-select'>
                {this.state.quiz ?
                    <div>
                        {this.state.quiz.quiz.questionIdx === this.state.quiz.quiz.docs.length ?
                            <div>
                                {/* use this.props.quiz instead of this.state.quiz, as finalGrade
                                    is added to the quiz on the server, this.state.quiz is a local
                                    copy. */}
                                {/* has survey, and survey is not completed */}
                                {this.props.quiz.hasOwnProperty('survey') &&
                                    this.props.quiz.survey &&
                                    this.props.quiz.survey.hasOwnProperty('completed') &&
                                    !this.props.quiz.survey.completed ?
                                    <div>
                                        <AssessmentSurveyForm
                                            quiz={this.props.quiz}
                                        />
                                    </div>
                                    :
                                    this.props.quiz.hasOwnProperty('finalGrade') &&
                                        this.props.quiz.finalGrade &&
                                        this.props.quiz.finalGrade.hasOwnProperty('finalGrade') &&
                                        this.props.quiz.finalGrade.finalGrade &&
                                        this.props.quiz.finalGrade.hasOwnProperty('graded') &&
                                        this.props.quiz.finalGrade.graded ?
                                        <div className='font-20'>
                                            <div className='font-30 font-bold'>
                                                Summary
                                            </div>
                                            <div className='mb-2'>{this.props.quiz.finalGrade.finalGrade.correct + ' out of ' + this.props.quiz.finalGrade.finalGrade.total + ' correct: ' + Math.round(this.props.quiz.finalGrade.finalGrade.grade * 100) + '%'}</div>
                                            {!canViewResults ?
                                                <div className='p-4 br-4 text-center mb-2' style={{ background: '#e6f4ff' }}>
                                                    <img src='/no-assessments.png' alt='no assessments' width={250} />
                                                    <div className='font-30'>
                                                        <Icon type='check' className='mr-1 text-success' />
                                                        This assessment is completed!
                                                    </div>
                                                    <div className='font-24'>The questions and answers will be available once testing for this assessment has closed.</div>
                                                </div>
                                                :
                                                this.state.quiz.quiz.docs.map((doc, idx) => {
                                                    const answerIdx = this.state.quiz.quiz.chosenAnswers[idx]
                                                    const correct = doc.components[0].answers[answerIdx].correct
                                                    return <div className='font-black font-20 background-fff ant-shadow br-4 p-4 mb-50'>
                                                        <div className='flex flex-v-center w-100 mb-2'>
                                                            <div className='font-24 font-bold'>Question {idx + 1}</div>
                                                            <div className='ml-auto'>
                                                                {correct ?
                                                                    <div className='flex flex-v-center border border-success bg-light-green p-1 br-4'>
                                                                        <Icon type='check' className='text-success mr-1' />
                                                                        <div className='text-success'>Correct</div>
                                                                    </div>
                                                                    :
                                                                    <div className='flex flex-v-center border border-error bg-light-red p-1 br-4'>
                                                                        <Icon type='close' className='text-danger mr-1' />
                                                                        <div className='text-danger'>Missed</div>
                                                                    </div>}
                                                            </div>
                                                        </div>
                                                        <div className='mb-1 font-20'>
                                                            <DocumentDelegator
                                                                document={{
                                                                    components: doc.components,
                                                                    assessment: this.state.quiz.quiz,
                                                                    metadata: doc.metadata
                                                                }}
                                                                additionalData={
                                                                    {
                                                                        quiz: this.state.quiz,
                                                                        questionIdx: idx,
                                                                        chosenAnswerIdx: answerIdx,
                                                                        chosenAnswerCorrect: correct
                                                                    }
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                })}
                                            <div className='flex w-100 mt-1'>
                                                <Link to='/student/quizzes'
                                                    className='ant-btn ant-btn-primary lg-btn text-align-center font-bold ml-auto'>
                                                    <div className='font-24'>All done</div>
                                                </Link>
                                            </div>
                                        </div>
                                        :
                                        <div className='text-center'>
                                            <div className='font-24 mb-2'>Grading, please wait...</div>
                                            <Spin size="large" className="ant-spin-vlg" />
                                        </div>}
                                <div>

                                </div>
                            </div>
                            :
                            <div>
                                {currentQuestion ?
                                    <div>
                                        <div className='font-20 mb-2 text-muted'>
                                            {'Question ' + (this.state.quiz.quiz.questionIdx + 1) + ' of ' + (this.state.quiz.quiz.docs.length)}
                                        </div>
                                        <div>
                                            <Form className="block-label-form p-4 font-fff br-20 ant-shadow">
                                                <div className='font-black font-20'>
                                                    <div className='mb-1 font-20'>
                                                        <DocumentDelegator
                                                            document={{
                                                                components: currentQuestion.components[0].question,
                                                                assessment: this.state.quiz.quiz,
                                                                metadata: currentQuestion.metadata
                                                            }}
                                                        />
                                                    </div>
                                                    <FormItem {...formItemLayout} label={null}>
                                                        {getFieldDecorator('question', {
                                                            rules: [{ required: true, message: 'Select an option from the choices above.' }],
                                                        })(
                                                            <Radio.Group className='w-100'>
                                                                {currentQuestion.components[0].answers.map((choice, choiceIndex) => {
                                                                    let choiceLetter = 'A'
                                                                    const choiceIndexPlusOne = choiceIndex + 1
                                                                    if (choiceIndexPlusOne === 2) {
                                                                        choiceLetter = 'B'
                                                                    } else if (choiceIndexPlusOne === 3) {
                                                                        choiceLetter = 'C'
                                                                    } else if (choiceIndexPlusOne === 4) {
                                                                        choiceLetter = 'D'
                                                                    }
                                                                    return <Radio
                                                                        className='flex assessment-radio mt-1 p-2 border br-4 w-100'
                                                                        value={choiceIndex}
                                                                        size='large'
                                                                        key={this.state.quiz.quiz.questionIdx + '-' + choiceIndex}>
                                                                        <div className='flex ml-05 p-mb-0 white-space-normal'>
                                                                            <span className='mr-05'>{choiceLetter}.</span>
                                                                            <DocumentDelegator
                                                                                document={{
                                                                                    components: choice.choice,
                                                                                    assessment: this.state.quiz.quiz,
                                                                                    metadata: currentQuestion.metadata
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </Radio>
                                                                })}
                                                            </Radio.Group>
                                                        )}
                                                    </FormItem>
                                                </div>
                                                <div className='flex w-100 flex-v-center mt-4'>
                                                    {this.state.quiz.quiz.questionIdx > 0 ?
                                                        <Button
                                                            className='ant-btn lg-btn text-align-center font-bold'
                                                            onClick={(e) => {
                                                                e.currentTarget.blur()
                                                                this.handleSubmit(e, 'previous')
                                                            }}
                                                        >
                                                            <Icon type="arrow-left" />
                                                            <span>Previous question</span>
                                                        </Button>
                                                        : ''}
                                                    <FormItem className="mb-0 ml-auto">
                                                        <Button
                                                            type="primary"
                                                            size={'large'}
                                                            className="ant-btn ant-btn-primary lg-btn text-align-center font-bold"
                                                            onClick={(e) => {
                                                                e.currentTarget.blur()
                                                                this.handleSubmit(e, 'next')
                                                            }}
                                                        >
                                                            <span>{this.state.quiz.quiz.questionIdx === this.state.quiz.quiz.docs.length - 1 ?
                                                                'Complete test' : 'Next question'}</span>
                                                            <Icon type="arrow-right" />
                                                        </Button>
                                                    </FormItem>
                                                </div>
                                            </Form>
                                        </div>
                                    </div> : ''}
                            </div>}
                    </div> : ''}
            </div>
        )
    }
}

export default Form.create()(PacingGuideAssessment)
