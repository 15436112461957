export const KAssessment = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>South Carolina Kindergarten ELA Oral Assessment</title>
    <style>
        body {
            font-family: 'Comic Sans MS', cursive, sans-serif;
            background-color: #f5f5f5;
            margin: 0;
            padding: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .container {
            max-width: 850px;
            width: 100%;
            background-color: white;
            padding: 25px;
            border-radius: 15px;
            box-shadow: 0 4px 8px rgba(0,0,0,0.1);
        }
        h1 {
            color: #233142;
            text-align: center;
            margin-bottom: 5px;
        }
        h2 {
            color: #233142;
            border-bottom: 1px solid #233142;
            padding-bottom: 5px;
            margin-top: 25px;
        }
        .subtitle {
            color: #666;
            text-align: center;
            font-style: italic;
            margin-bottom: 20px;
        }
        .exam-section {
            margin-bottom: 30px;
            padding: 15px;
            border: 1px solid #e0e0e0;
            border-radius: 8px;
            background-color: #fff8dc;
        }
        .question {
            margin-bottom: 10px;
            padding-left: 5px;
        }
        .context {
            font-style: italic;
            color: #666;
            margin-bottom: 10px;
        }
        .checkbox-item {
            display: flex;
            align-items: flex-start;
            margin-bottom: 8px;
        }
        .checkbox-label {
            margin-left: 10px;
            flex: 1;
        }
        input[type="checkbox"] {
            width: 18px;
            height: 18px;
            margin-top: 2px;
        }
        label {
            display: block;
            font-weight: bold;
            margin-bottom: 5px;
            color: #233142;
        }
        .section-title {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .section-letter {
            font-weight: normal;
            color: #666;
            font-size: 0.9em;
            font-style: italic;
        }
        .instructions {
            background-color: #d9eeff;
            padding: 15px;
            border-radius: 8px;
            margin-bottom: 20px;
        }
        .resource {
            background-color: #e8f7ee;
            padding: 15px;
            border-radius: 8px;
            margin-bottom: 20px;
            border: 1px dashed #3fa672;
        }
        @media print {
            body {
                background-color: white;
                padding: 0;
                margin: 0;
            }
            .container {
                box-shadow: none;
                max-width: 100%;
            }
            .exam-section {
                break-inside: avoid;
            }
            @page {
                margin: 1cm;
            }
        }
    </style>
</head>
<body>
    <div class="container">
        <form id="assessment-form">
            <h1>South Carolina Kindergarten ELA Oral Assessment</h1>
            <div class="subtitle">Reading Resource: Little Cat's Big Day</div>
            
            <div class="instructions">
                <p><strong>Instructions for Examiner:</strong> This assessment evaluates student understanding of specific standards. Ask each question below verbally and check the corresponding box if the student gives an appropriate response.</p>
            </div>
            
            <div class="resource">
                <h3>Resource:</h3>
                <p><strong>Little Cat's Big Day</strong></p>
                <p>Little Cat naps on a red rug.<br>  
                She wakes up and runs outside.<br>  
                She hops and plays in the sun.<br>  
                Little Cat is happy!</p>
            </div>
            
            <!-- Section 1 -->
            <div class="exam-section">
                <div class="section-title">
                    <h2>Section 1: Letter Sound Identification</h2>
                    <span class="section-letter">(ELA.K.F.3.3)</span>
                </div>
                
                <div class="question">
                    <p><strong>Teacher Instructions:</strong> Say to the student: "Listen carefully to the first sound in the word 'Cat.' What sound do you hear?"</p>
                    
                    <div class="checkbox-group">
                        <div class="checkbox-item">
                            <input type="checkbox" id="s1q1c1" name="s1q1c1">
                            <div class="checkbox-label">
                                <label for="s1q1c1">Student correctly identifies the initial consonant sound /k/.</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <!-- Section 2 -->
            <div class="exam-section">
                <div class="section-title">
                    <h2>Section 2: Manipulating Sounds</h2>
                    <span class="section-letter">(ELA.K.F.3.6)</span>
                </div>
                
                <div class="question">
                    <p><strong>Teacher Instructions:</strong> Say to the student: "Change the 'r' in 'rug' to a 'b'. What new word did you make?"</p>
                    
                    <div class="checkbox-group">
                        <div class="checkbox-item">
                            <input type="checkbox" id="s2q1c1" name="s2q1c1">
                            <div class="checkbox-label">
                                <label for="s2q1c1">Student correctly says "bug."</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <!-- Section 3 -->
            <div class="exam-section">
                <div class="section-title">
                    <h2>Section 3: Counting Syllables</h2>
                    <span class="section-letter">(ELA.K.F.3.7)</span>
                </div>
                
                <div class="question">
                    <p><strong>Teacher Instructions:</strong> Say to the student: "Tell me how many syllables are in the word 'happy'?"</p>
                    
                    <div class="checkbox-group">
                        <div class="checkbox-item">
                            <input type="checkbox" id="s3q1c1" name="s3q1c1">
                            <div class="checkbox-label">
                                <label for="s3q1c1">Student correctly says "two."</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <!-- Section 4 -->
            <div class="exam-section">
                <div class="section-title">
                    <h2>Section 4: Story Retelling and Elements</h2>
                    <span class="section-letter">(ELA.K.AOR.1.1, ELA.K.AOR.2.1, ELA.K.AOR.2.2)</span>
                </div>
                
                <div class="question">
                    <p><strong>Teacher Instructions:</strong> Say to the student: "Who is the main character in 'Little Cat's Big Day'?"</p>
                    
                    <div class="checkbox-group">
                        <div class="checkbox-item">
                            <input type="checkbox" id="s4q1c1" name="s4q1c1">
                            <div class="checkbox-label">
                                <label for="s4q1c1">Student correctly identifies "Little Cat."</label>
                            </div>
                        </div>
                    </div>
                    
                    <p><strong>Teacher Instructions:</strong> Say to the student: "Tell me one thing Little Cat does in the story."</p>
                    <div class="checkbox-group">
                        <div class="checkbox-item">
                            <input type="checkbox" id="s4q2c1" name="s4q2c1">
                            <div class="checkbox-label">
                                <label for="s4q2c1">Student provides at least one correct event from the story (e.g., naps, wakes, runs outside, hops, plays in the sun).</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div style="text-align: right; margin-top: 30px; margin-bottom: 20px;">
                <button type="submit" id="assessment-submit" style="background-color: #233142; color: white; padding: 10px 20px; font-size: 16px; border: none; border-radius: 5px; cursor: pointer; font-family: 'Comic Sans MS', cursive, sans-serif;">Submit Assessment</button>
            </div>
        </form>
    </div>
</body>
</html>`;