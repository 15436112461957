import React, { Component } from 'react';
import Empty from '../customcomponents/Empty';
import CustomFooter from '../login/CustomFooter'
import ColType from '../Types'
import ReactToPrint from "react-to-print"
import { db, firebase } from '../firebase/Firebase'
import { FOLDER_CURRENT, shuffleArray, flattenDoc, getAssessmentDisplayName, getColorGradient, getQuestionStatusId, getStateStandardNamesDict, setQuestionStatus, getCurrentYear } from '../Util'
import { Layout, Select, Icon, Menu, message, Progress, Radio, Button, Popconfirm, notification, Tooltip, Collapse, Tabs, Modal } from 'antd'
import { Document } from '../teacher/edvizv2/Edviz';
import DocumentDelegator from '../edviz/EdvizDelegator'
import ReviewQuestionForm from './ReviewQuestionForm';
import PersonAvatar from '../customcomponents/PersonAvatar';
import DOKStandardBreakdown from './DOKStandardBreakdown';
import DOKTeacherBreakdown from './DOKTeacherBreakdown';
import DOKTeacherBreakdownPrint from './DOKTeacherBreakdownPrint';
import DOKStandardBreakdownPrint from './DOKStandardBreakdownPrint';
import moment from 'moment';
import StudentSurveyResults from './StudentSurveyResults';
import { KAssessment } from '../teacher/html/assessment/K.js';
import { FirstGradeAssessment } from '../teacher/html/assessment/1.js';
import { SecondGradeAssessment } from '../teacher/html/assessment/2.js';
import HTMLAssessmentViewer from '../teacher/html/components/HTMLAssessmentViewer.js';
const Sider = Layout.Sider;
const Content = Layout.Content;

const successMessage = (messageContent) => {
    notification.destroy()
    notification.success({
        message: messageContent,
        placement: 'bottomRight',
    })
};

class AssessmentAnalytics extends Component {
    state = {
        assessments: [],
        assessmentsDict: {},
        selectedAssessment: null,
        assessmentStatReport: null,
        entireAssessmentStatReports: null,
        districtSchoolIdStatsSelected: 'District',
        standardCategories: ['ELA', 'Math', 'Social Studies', 'Science'],
        topLevelMenuKey: [],
        menuKey: ['All'],
        standardNamesDict: {},
        standardName: '2nd Grade',
        standardCategory: 'ELA',
        standardSort: 'asc',
        queryId: '',
        teachers: [],
        teachersDict: {},
        students: [],
        studentsDict: {},
        mounted: false,
        assessmentsInitialized: false,
        reviewsMounted: false,
        teacherAssessments: null,
        teacherAssessmentRetakes: null,
        teacherAssessmentCountDict: null,
        individualTeacherRetakeDict: null,
        activeTabKey: 'district-school-tab',
        viewingTeacherId: '',
        endWeek: 2,
        endWeeks: [2, 3, 6, 9, 12, 15, 18, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39],
        gradeDictAcrossCategories: {
            'Kindergarten': 'K',
            '1st Grade': '1',
            '2nd Grade': '2',
            '3rd Grade': '3',
            '4th Grade': '4',
            '5th Grade': '5',
            '6th Grade': '6',
            '7th Grade': '7',
            '8th Grade': '8',
        },
        questionReviews: [],
        questionUUIDReviews: {},
        questionReviewListener: null,
        standardDocsDict: {},
        questionStatusDict: {},
        replacingQuestion: false,
        loadingQuestionBank: false,
        loadingStats: false,
    }

    componentDidMount() {
        if (this.props.analyticsFor === 'school' && this.props.schools && this.props.schools.length > 0) {
            this.setState({
                queryId: this.props.schools[0].id,
            }, () => {
                this.init()
            })
        } else if (this.props.analyticsFor === 'district' && this.props.districtId) {
            this.setState({
                queryId: this.props.districtId,
            }, () => {
                this.init()
            })
        }
        else {
            console.error('no school or district id')
            this.init()
        }
    }

    componentWillUnmount() {
        if (this.state.questionReviewListener) {
            this.state.questionReviewListener()
        }
    }

    init = () => {
        if (this.props.districtSettings &&
            this.props.districtSettings.hasOwnProperty('canReview') &&
            this.props.districtSettings.canReview) {
            let furthestReviewWeek = 3
            Object.keys(this.props.districtSettings.canReview).forEach((week) => {
                let weekAsInt = parseInt(week, 10)
                if (this.props.districtSettings.canReview[weekAsInt]) {
                    furthestReviewWeek = weekAsInt
                }
            })
            this.setState({
                endWeek: furthestReviewWeek,
            }, () => {
                this.getData(true, true)
            })
        } else {
            this.getData(true, true)
        }
    }

    getData = (reloadAssessments, reinitialize) => {
        if (!(this.props.district && this.props.districtId && this.props.districtSettings && this.state.queryId)) {
            return
        }

        if (reloadAssessments) {
            this.setState({
                loadingData: true,
            })
        }

        if (Object.keys(this.state.standardNamesDict).length === 0) {
            this.setState({
                standardNamesDict: getStateStandardNamesDict(this.props.district)
            })
        }

        /*
        this.state.standardCategories.map((subject) => {
            let docSubject = subject
            if (docSubject === 'Social Studies') {
                docSubject = 'SocialStudies'
            }
            let queryDocId = `${this.state.queryId}-${docSubject}`

            db.collection(ColType.entireAssessmentStatReports)
                .doc(queryDocId)
                .get()
                .then((doc) => {
                    let entireAssessmentStatReport = null
                    if (doc.exists) {
                        entireAssessmentStatReport = flattenDoc(doc)
                    }
                    console.log('entireAssessmentStatReports', subject, entireAssessmentStatReport)
                    let entireAssessmentStatReports = this.state.entireAssessmentStatReports
                    if (!entireAssessmentStatReports) {
                        entireAssessmentStatReports = {}
                    }
                    entireAssessmentStatReports[subject] = entireAssessmentStatReport

                    this.setState({
                        mounted: true,
                        entireAssessmentStatReports: entireAssessmentStatReports,
                    })
                })
                .catch((error) => {
                    console.log('error', error)
                    let entireAssessmentStatReports = this.state.entireAssessmentStatReports
                    if (!entireAssessmentStatReports) {
                        entireAssessmentStatReports = {}
                    }
                    entireAssessmentStatReports[subject] = null
                    this.setState({
                        mounted: true,
                        entireAssessmentStatReports: null,
                        entireAssessmentStatReports: entireAssessmentStatReports,
                    })
                })
        })
        */

        if (reloadAssessments) {
            db.collection(ColType.assessmentSC)
                .where('districtId', '==', this.props.districtId)
                .where('assessmentCategory', '!=', 'quiz')
                .where('endWeek', '==', this.state.endWeek)
                .get()
                .then(async (querySnapshot) => {
                    let assessments = []
                    let assessmentsDict = {}
                    let assessmentsWeekDict = {}
                    /*
                    querySnapshot.forEach((doc) => {
                        let assessment = flattenDoc(doc)
                        assessment.docs = JSON.parse(assessment.docs)
                        assessments.push(assessment)
                        assessmentsDict[assessment.id] = assessment
                        if (!assessmentsWeekDict.hasOwnProperty(assessment.endWeek)) {
                            assessmentsWeekDict[assessment.endWeek] = {
                                assessments: [],
                            }
                        }
                        assessmentsWeekDict[assessment.endWeek].assessments.push(assessment)
                    })
                    */

                    try {
                        const quizzesSnapshot = await db.collection(ColType.quizzes)
                            .where('districtId', '==', this.props.districtId)
                            .where('endWeek', '==', this.state.endWeek)
                            .where('year', '==', getCurrentYear())
                            //.where('live', '==', true)
                            .get()

                        quizzesSnapshot.forEach((doc) => {
                            let assessment = flattenDoc(doc)
                            const canView = assessment.hasOwnProperty('canView') && assessment.canView
                            if (!canView) {
                                return false
                            }
                            assessment.docs = JSON.parse(assessment.docs)
                            assessments.push(assessment)
                            assessmentsDict[assessment.id] = assessment
                            if (!assessmentsWeekDict.hasOwnProperty(assessment.endWeek)) {
                                assessmentsWeekDict[assessment.endWeek] = {
                                    assessments: [],
                                }
                            }
                            assessmentsWeekDict[assessment.endWeek].assessments.push(assessment)
                        })
                    } catch (e) {
                        console.log('quizzes error', e)
                    }

                    // test district
                    if (this.props.districtId === '64bec570267c8bfeea81f9ea') {
                        // K
                        const kAssessment = {
                            id: 'kAssessment',
                            uuid: 'kAssessment',
                            htmlQuiz: KAssessment,
                            assessmentCategory: 'common_assessment',
                            assessmentTitle: 'Kindergarten Common Assessment (Form A)',
                            createdAt: "2024-08-11 22:36:52",
                            live: true,
                            canEdit: false,
                            canView: true,
                            startWeek: 4,
                            endWeek: 6,
                            districtId: this.props.districtId,
                            standardName: 'Kindergarten',
                            standardCategory: 'ELA',
                            standards: ['ELA.K.AOR.1.1', 'ELA.K.AOR.2.1', 'ELA.K.AOR.7.1.a', 'ELA.K.AOR.8.1.b'],
                            state: 'SC',
                            docs: [
                                { components: [{ question: [], answers: [{ id: 0, correct: true }] }], metadata: { uuid: 0 } },
                                { components: [{ question: [], answers: [{ id: 0, correct: true }] }], metadata: { uuid: 1 } },
                                { components: [{ question: [], answers: [{ id: 0, correct: true }] }], metadata: { uuid: 2 } },
                                { components: [{ question: [], answers: [{ id: 0, correct: true }] }], metadata: { uuid: 3 } },
                                { components: [{ question: [], answers: [{ id: 0, correct: true }] }], metadata: { uuid: 4 } }
                            ],
                            chosenAnswers: {},
                            chosenAnswersBaseQuiz: {},
                            numQuestions: 5,
                            questionIdx: 0,
                            year: '2024',
                        };

                        assessments.push(kAssessment);
                        assessmentsDict[kAssessment.id] = kAssessment;

                        const firstAssessment = {
                            id: 'firstAssessment',
                            uuid: 'firstAssessment',
                            htmlQuiz: FirstGradeAssessment,
                            assessmentCategory: 'common_assessment',
                            assessmentTitle: '1st Grade Common Assessment (Form A)',
                            createdAt: "2024-08-11 22:36:52",
                            approved: 'approved',
                            live: true,
                            canEdit: false,
                            canView: true,
                            startWeek: 4,
                            endWeek: 6,
                            districtId: this.props.districtId,
                            standardName: '1st Grade',
                            standardCategory: 'ELA',
                            standards: ['ELA.1.AOR.2.2', 'ELA.1.AOR.6.1.b', 'ELA.1.AOR.5.3', 'ELA.1.AOR.7.1.d', 'ELA.1.AOR.8.1.c', 'ELA.1.AOR.5.1'],
                            state: 'SC',
                            docs: [
                                { components: [{ question: [], answers: [{ id: 0, correct: true }] }], metadata: { uuid: 0 } },
                                { components: [{ question: [], answers: [{ id: 0, correct: true }] }], metadata: { uuid: 1 } },
                                { components: [{ question: [], answers: [{ id: 0, correct: true }] }], metadata: { uuid: 2 } },
                                { components: [{ question: [], answers: [{ id: 0, correct: true }] }], metadata: { uuid: 3 } },
                                { components: [{ question: [], answers: [{ id: 0, correct: true }] }], metadata: { uuid: 4 } }
                            ],
                            chosenAnswers: {},
                            chosenAnswersBaseQuiz: {},
                            numQuestions: 5,
                            questionIdx: 0,
                            year: '2024',
                        };
                        assessments.push(firstAssessment);
                        assessmentsDict[firstAssessment.id] = firstAssessment;


                        const secondAssessment = {
                            id: 'secondAssessment',
                            uuid: 'secondAssessment',
                            htmlQuiz: SecondGradeAssessment,
                            assessmentCategory: 'common_assessment',
                            assessmentTitle: '2nd Grade Common Assessment (Form A)',
                            createdAt: "2024-08-11 22:36:52",
                            approved: 'approved',
                            live: true,
                            canEdit: false,
                            canView: true,
                            startWeek: 4,
                            endWeek: 6,
                            districtId: this.props.districtId,
                            standardName: '2nd Grade',
                            standardCategory: 'ELA',
                            standards: ['ELA.2.AOR.2.2', 'ELA.2.AOR.6.1.b', 'ELA.2.AOR.5.3', 'ELA.2.AOR.7.1.d', 'ELA.2.AOR.8.1.c', 'ELA.2.AOR.5.1'],
                            state: 'SC',
                            docs: [
                                { components: [{ question: [], answers: [{ id: 0, correct: true }] }], metadata: { uuid: 0 } },
                                { components: [{ question: [], answers: [{ id: 0, correct: true }] }], metadata: { uuid: 1 } },
                                { components: [{ question: [], answers: [{ id: 0, correct: true }] }], metadata: { uuid: 2 } },
                                { components: [{ question: [], answers: [{ id: 0, correct: true }] }], metadata: { uuid: 3 } },
                                { components: [{ question: [], answers: [{ id: 0, correct: true }] }], metadata: { uuid: 4 } }
                            ],
                            chosenAnswers: {},
                            chosenAnswersBaseQuiz: {},
                            numQuestions: 5,
                            questionIdx: 0,
                            year: '2024',
                        };

                        assessments.push(secondAssessment);
                        assessmentsDict[secondAssessment.id] = secondAssessment;
                    }

                    assessments.sort((a, b) => {
                        if (a.hasOwnProperty('assessmentTitle') &&
                            b.hasOwnProperty('assessmentTitle')) {
                            const isAKindergarten = a.assessmentTitle.includes('Kindergarten');
                            const isBKindergarten = b.assessmentTitle.includes('Kindergarten');
                            if (isAKindergarten && isBKindergarten) {
                                if (a.assessmentTitle < b.assessmentTitle) {
                                    return -1
                                }
                                if (a.assessmentTitle > b.assessmentTitle) {
                                    return 1
                                }
                                return 0
                            }
                            if (isAKindergarten && !isBKindergarten) {
                                return -1
                            }
                            if (!isAKindergarten && isBKindergarten) {
                                return 1
                            }

                            // Check if string includes 'Grade'
                            const isAGrade = a.assessmentTitle.includes('Grade');
                            const isBGrade = b.assessmentTitle.includes('Grade');

                            // If both are grade strings, sort numerically
                            if (isAGrade && isBGrade) {
                                const matchA = a.assessmentTitle.match(/\d+/);
                                const matchB = b.assessmentTitle.match(/\d+/);
                                if (matchA && matchB) {
                                    const gradeA = parseInt(matchA, 10);
                                    const gradeB = parseInt(matchB, 10);

                                    if (gradeA < gradeB) {
                                        return -1;
                                    } else if (gradeA > gradeB) {
                                        return 1;
                                    } else if (a.assessmentTitle < b.assessmentTitle) {
                                        return -1;
                                    } else if (a.assessmentTitle > b.assessmentTitle) {
                                        return 1;
                                    }
                                }
                            }

                            // If one is a grade and the other is not, the grade comes first
                            if (isAGrade) return -1;
                            if (isBGrade) return 1;

                            if (a.assessmentTitle < b.assessmentTitle) {
                                return -1
                            }
                            if (a.assessmentTitle > b.assessmentTitle) {
                                return 1
                            }
                            return 0
                        }
                        if (a.standardName < b.standardName) {
                            return -1
                        }
                        if (a.standardName > b.standardName) {
                            return 1
                        }
                        if (a.standardCategory < b.standardCategory) {
                            return -1
                        }
                        if (a.standardCategory > b.standardCategory) {
                            return 1
                        }
                        if (a.endWeek < b.endWeek) {
                            return -1
                        }
                        if (a.endWeek > b.endWeek) {
                            return 1
                        }
                        return 0
                    })
                    let menuKey = []
                    let selectedAssessment = null
                    let assessmentsInitialized = this.state.assessmentsInitialized
                    if ((reinitialize || !this.state.assessmentsInitialized) && assessments.length > 0) {
                        for (let i = 0; i < assessments.length; i++) {
                            // skip kindergarten and 1st grade
                            if ((assessments[i].standardName.indexOf('Kindergarten') !== -1 ||
                                assessments[i].standardName.indexOf('1st Grade') !== -1) &&
                                !assessments[i].hasOwnProperty('htmlQuiz')) {
                                continue
                            }
                            selectedAssessment = assessments[i]
                            menuKey = ['' + i]
                            break
                        }
                    }

                    this.setState({
                        assessments: assessments,
                        assessmentsDict: assessmentsDict,
                        assessmentsWeekDict: assessmentsWeekDict,
                        selectedAssessment: selectedAssessment,
                        menuKey: menuKey,
                        loadingData: false,
                        assessmentsInitialized: true,
                        mounted: true,
                    }, () => {
                        if (reinitialize || !assessmentsInitialized) {
                            this.getAssessmentStatReport()
                            this.getAssessmentQuestionReviews()
                            this.getTeacherAssessments()
                            this.getQuestionDataForAssessment(selectedAssessment)
                        }
                    })
                    //console.log('assessments', assessments)
                })
        }
    }

    getAssessmentStatReport = () => {
        if (!(this.state.queryId && this.state.selectedAssessment)) {
            return
        }

        if (this.props.districtId === '64bec570267c8bfeea81f9ea' &&
            this.state.selectedAssessment.hasOwnProperty('htmlQuiz')) {
            if (this.state.selectedAssessment.assessmentTitle.indexOf('Kindergarten') !== -1) {
                this.setState({
                    loadingStats: false,
                    assessmentStatReport: {
                        "questions": {
                            "0": {
                                "incorrect": 53,
                                "correct": 147,
                                "avg": 0.7350,
                                "chosen_answers": {
                                    "0": 147,
                                    "1": 53
                                },
                                "total": 200
                            },
                            "1": {
                                "total": 200,
                                "chosen_answers": {
                                    "0": 96,
                                    "1": 104
                                },
                                "incorrect": 104,
                                "correct": 96,
                                "avg": 0.4800
                            },
                            "2": {
                                "correct": 108,
                                "avg": 0.5400,
                                "total": 200,
                                "incorrect": 92,
                                "chosen_answers": {
                                    "0": 108,
                                    "1": 92
                                }
                            },
                            "3": {
                                "avg": 0.8250,
                                "incorrect": 35,
                                "total": 200,
                                "chosen_answers": {
                                    "0": 165,
                                    "1": 35
                                },
                                "correct": 165
                            },
                            "4": {
                                "correct": 113,
                                "chosen_answers": {
                                    "0": 113,
                                    "1": 87
                                },
                                "total": 200,
                                "incorrect": 87,
                                "avg": 0.5650
                            }
                        },
                        "schools": {
                            "64c9bd7fee3bd703dc3f25b6": {
                                "questions": {
                                    "0": {
                                        "avg": 0.7350,
                                        "chosen_answers": {
                                            "0": 147,
                                            "1": 53
                                        },
                                        "total": 200,
                                        "correct": 147,
                                        "incorrect": 53
                                    },
                                    "1": {
                                        "incorrect": 104,
                                        "correct": 96,
                                        "avg": 0.4800,
                                        "chosen_answers": {
                                            "0": 96,
                                            "1": 104
                                        },
                                        "total": 200
                                    },
                                    "2": {
                                        "total": 200,
                                        "avg": 0.5400,
                                        "incorrect": 92,
                                        "correct": 108,
                                        "chosen_answers": {
                                            "0": 108,
                                            "1": 92
                                        }
                                    },
                                    "3": {
                                        "chosen_answers": {
                                            "0": 165,
                                            "1": 35
                                        },
                                        "avg": 0.8250,
                                        "total": 200,
                                        "incorrect": 35,
                                        "correct": 165
                                    },
                                    "4": {
                                        "total": 200,
                                        "incorrect": 87,
                                        "chosen_answers": {
                                            "0": 113,
                                            "1": 87
                                        },
                                        "correct": 113,
                                        "avg": 0.5650
                                    }
                                },
                                "studentSurveys": {
                                    "good": 110,
                                    "notGood": 30,
                                    "total": 200,
                                    "somewhatGood": 60,
                                    "somewhatGoodPct": 0.30,
                                    "hasSurvey": true,
                                    "notGoodPct": 0.15,
                                    "goodPct": 0.55
                                },
                                "districtId": "64bec570267c8bfeea81f9ea",
                                "schoolId": "64c9bd7fee3bd703dc3f25b6"
                            }
                        },
                        "studentSurveys": {
                            "somewhatGood": 60,
                            "good": 110,
                            "goodPct": 0.55,
                            "notGoodPct": 0.15,
                            "somewhatGoodPct": 0.30,
                            "total": 200,
                            "hasSurvey": true,
                            "notGood": 30
                        },
                        "districtId": "64bec570267c8bfeea81f9ea",
                        "id": "64bec570267c8bfeea81f9ea-firstAssessment"
                    }
                });
            } else if (this.state.selectedAssessment.assessmentTitle.indexOf('1st Grade') !== -1) {
                this.setState({
                    loadingStats: false,
                    assessmentStatReport: {
                        "questions": {
                            "0": {
                                "incorrect": 54,
                                "correct": 146,
                                "avg": 0.7300,
                                "chosen_answers": {
                                    "0": 146,
                                    "1": 54
                                },
                                "total": 200
                            },
                            "1": {
                                "total": 200,
                                "chosen_answers": {
                                    "0": 158,
                                    "1": 42
                                },
                                "incorrect": 42,
                                "correct": 158,
                                "avg": 0.7900
                            },
                            "2": {
                                "correct": 152,
                                "avg": 0.7600,
                                "total": 200,
                                "incorrect": 48,
                                "chosen_answers": {
                                    "0": 152,
                                    "1": 48
                                }
                            },
                            "3": {
                                "avg": 0.6850,
                                "incorrect": 63,
                                "total": 200,
                                "chosen_answers": {
                                    "0": 137,
                                    "1": 63
                                },
                                "correct": 137
                            },
                            "4": {
                                "correct": 142,
                                "chosen_answers": {
                                    "0": 142,
                                    "1": 58
                                },
                                "total": 200,
                                "incorrect": 58,
                                "avg": 0.7100
                            }
                        },
                        "schools": {
                            "64c9bd7fee3bd703dc3f25b6": {
                                "questions": {
                                    "0": {
                                        "avg": 0.7300,
                                        "chosen_answers": {
                                            "0": 146,
                                            "1": 54
                                        },
                                        "total": 200,
                                        "correct": 146,
                                        "incorrect": 54
                                    },
                                    "1": {
                                        "incorrect": 42,
                                        "correct": 158,
                                        "avg": 0.7900,
                                        "chosen_answers": {
                                            "0": 158,
                                            "1": 42
                                        },
                                        "total": 200
                                    },
                                    "2": {
                                        "total": 200,
                                        "avg": 0.7600,
                                        "incorrect": 48,
                                        "correct": 152,
                                        "chosen_answers": {
                                            "0": 152,
                                            "1": 48
                                        }
                                    },
                                    "3": {
                                        "chosen_answers": {
                                            "0": 137,
                                            "1": 63
                                        },
                                        "avg": 0.6850,
                                        "total": 200,
                                        "incorrect": 63,
                                        "correct": 137
                                    },
                                    "4": {
                                        "total": 200,
                                        "incorrect": 58,
                                        "chosen_answers": {
                                            "0": 142,
                                            "1": 58
                                        },
                                        "correct": 142,
                                        "avg": 0.7100
                                    }
                                },
                                "studentSurveys": {
                                    "good": 120,
                                    "notGood": 20,
                                    "total": 200,
                                    "somewhatGood": 60,
                                    "somewhatGoodPct": 0.30,
                                    "hasSurvey": true,
                                    "notGoodPct": 0.10,
                                    "goodPct": 0.60
                                },
                                "districtId": "64bec570267c8bfeea81f9ea",
                                "schoolId": "64c9bd7fee3bd703dc3f25b6"
                            }
                        },
                        "studentSurveys": {
                            "somewhatGood": 60,
                            "good": 120,
                            "goodPct": 0.60,
                            "notGoodPct": 0.10,
                            "somewhatGoodPct": 0.30,
                            "total": 200,
                            "hasSurvey": true,
                            "notGood": 20
                        },
                        "districtId": "64bec570267c8bfeea81f9ea",
                        "id": "64bec570267c8bfeea81f9ea-firstAssessment"
                    }
                });
            } else if (this.state.selectedAssessment.assessmentTitle.indexOf('2nd Grade') !== -1) {
                this.setState({
                    loadingStats: false,
                    assessmentStatReport: {
                        "questions": {
                            "0": {
                                "incorrect": 52,
                                "correct": 148,
                                "avg": 0.7400,
                                "chosen_answers": {
                                    "0": 148,
                                    "1": 52
                                },
                                "total": 200
                            },
                            "1": {
                                "total": 200,
                                "chosen_answers": {
                                    "0": 134,
                                    "1": 66
                                },
                                "incorrect": 66,
                                "correct": 134,
                                "avg": 0.6700
                            },
                            "2": {
                                "correct": 94,
                                "avg": 0.4700,
                                "total": 200,
                                "incorrect": 106,
                                "chosen_answers": {
                                    "0": 94,
                                    "1": 106
                                }
                            },
                            "3": {
                                "avg": 0.8150,
                                "incorrect": 37,
                                "total": 200,
                                "chosen_answers": {
                                    "0": 163,
                                    "1": 37
                                },
                                "correct": 163
                            },
                            "4": {
                                "correct": 112,
                                "chosen_answers": {
                                    "0": 112,
                                    "1": 88
                                },
                                "total": 200,
                                "incorrect": 88,
                                "avg": 0.5600
                            },
                            "5": {
                                "correct": 178,
                                "chosen_answers": {
                                    "0": 178,
                                    "1": 22
                                },
                                "total": 200,
                                "incorrect": 22,
                                "avg": 0.8900
                            }
                        },
                        "schools": {
                            "64c9bd7fee3bd703dc3f25b6": {
                                "questions": {
                                    "0": {
                                        "avg": 0.7400,
                                        "chosen_answers": {
                                            "0": 148,
                                            "1": 52
                                        },
                                        "total": 200,
                                        "correct": 148,
                                        "incorrect": 52
                                    },
                                    "1": {
                                        "incorrect": 66,
                                        "correct": 134,
                                        "avg": 0.6700,
                                        "chosen_answers": {
                                            "0": 134,
                                            "1": 66
                                        },
                                        "total": 200
                                    },
                                    "2": {
                                        "total": 200,
                                        "avg": 0.4700,
                                        "incorrect": 106,
                                        "correct": 94,
                                        "chosen_answers": {
                                            "0": 94,
                                            "1": 106
                                        }
                                    },
                                    "3": {
                                        "chosen_answers": {
                                            "0": 163,
                                            "1": 37
                                        },
                                        "avg": 0.8150,
                                        "total": 200,
                                        "incorrect": 37,
                                        "correct": 163
                                    },
                                    "4": {
                                        "total": 200,
                                        "incorrect": 88,
                                        "chosen_answers": {
                                            "0": 112,
                                            "1": 88
                                        },
                                        "correct": 112,
                                        "avg": 0.5600
                                    },
                                    "5": {
                                        "total": 200,
                                        "incorrect": 22,
                                        "chosen_answers": {
                                            "0": 178,
                                            "1": 22
                                        },
                                        "correct": 178,
                                        "avg": 0.8900
                                    }
                                },
                                "studentSurveys": {
                                    "good": 134,
                                    "notGood": 21,
                                    "total": 200,
                                    "somewhatGood": 45,
                                    "somewhatGoodPct": 0.225,
                                    "hasSurvey": true,
                                    "notGoodPct": 0.105,
                                    "goodPct": 0.67
                                },
                                "districtId": "64bec570267c8bfeea81f9ea",
                                "schoolId": "64c9bd7fee3bd703dc3f25b6"
                            }
                        },
                        "studentSurveys": {
                            "somewhatGood": 45,
                            "good": 134,
                            "goodPct": 0.67,
                            "notGoodPct": 0.105,
                            "somewhatGoodPct": 0.225,
                            "total": 200,
                            "hasSurvey": true,
                            "notGood": 21
                        },
                        "districtId": "64bec570267c8bfeea81f9ea",
                        "id": "64bec570267c8bfeea81f9ea-firstAssessment"
                    }
                });
            }
            return;
        }

        this.setState({
            loadingStats: true,
            assessmentStatReport: null,
        })

        let queryDocId = `${this.state.queryId}-${this.state.selectedAssessment.uuid}`

        db.collection(ColType.assessmentStatReports)
            .doc(queryDocId)
            .get()
            .then((doc) => {
                let assessmentStatReport = null
                if (doc.exists) {
                    assessmentStatReport = flattenDoc(doc)
                }
                //console.log('assessmentStatReport', this.state.selectedAssessment, queryDocId, assessmentStatReport)
                this.setState({
                    assessmentStatReport: assessmentStatReport,
                    loadingStats: false,
                })
            })
            .catch((error) => {
                console.log('error', error)
                this.setState({
                    assessmentStatReport: null,
                    loadingStats: false,
                })
            })
    }

    getAssessmentQuestionReviews = () => {
        return;
        // reviews are deprecated
        /*
        if (!(this.state.queryId && this.state.selectedAssessment)) {
            return
        }

        if (this.state.questionReviewListener) {
            this.state.questionReviewListener()
        }

        // get reviews
        let questionReviewListener = db.collection(ColType.questionReview)
            .where('districtId', '==', this.props.districtId)
            .where('assessmentUUID', '==', this.state.selectedAssessment.uuid)
            .orderBy('timeStamp', 'asc')
            .onSnapshot((querySnapshot) => {
                let questionReviews = []
                let questionUUIDReviews = {}
                querySnapshot.forEach((doc) => {
                    let questionReview = flattenDoc(doc)
                    questionReviews.push(questionReview)
                    if (!questionUUIDReviews.hasOwnProperty(questionReview.questionUUID)) {
                        questionUUIDReviews[questionReview.questionUUID] = []
                    }
                    questionUUIDReviews[questionReview.questionUUID].push(questionReview)
                })
                //console.log('questionReviews', questionReviews)
                this.setState({
                    questionReviews: questionReviews,
                    questionUUIDReviews: questionUUIDReviews,
                    reviewsMounted: true,
                })
            })

        this.setState({
            questionReviewListener: questionReviewListener,
        })*/
    }

    getTeacherAssessmentStats = (teacherAssessment) => {
        let stats = {
            totalAssessments: 0,
            correct: 0,
            total: 0,
            totalGrade: 0,
            avg: 0,
        }

        Object.keys(teacherAssessment.studentGrades).map((studentId, idx) => {
            const studentGrade = teacherAssessment.studentGrades[studentId]
            if (studentGrade.graded) {
                stats.totalAssessments += 1
                stats.correct += studentGrade.finalGrade.correct
                stats.total += studentGrade.finalGrade.total
                stats.totalGrade += studentGrade.finalGrade.grade
            }
        })

        if (stats.totalAssessments > 0) {
            stats.avg = stats.totalGrade / stats.totalAssessments
        }

        return stats
    }

    handleTeacherAssessmentQuerySnapshot = (querySnapshot) => {
        let teacherAssessments = []
        let teacherAssessmentDict = {}
        let teacherAssessmentCountDict = {}
        let teacherAssessmentRetakes = {}
        let teachers = []
        let teachersDict = {}
        let individualTeacherRetakeDict = {}
        querySnapshot.forEach((doc) => {
            let teacherAssessment = flattenDoc(doc)
            teacherAssessment.quiz.docs = JSON.parse(teacherAssessment.quiz.docs)
            teacherAssessment.totalStats = this.getTeacherAssessmentStats(teacherAssessment)
            if (teacherAssessment.totalStats.totalAssessments > 0) {
                teacherAssessment.title = teacherAssessment.teacher.firstName + ' ' + teacherAssessment.teacher.lastName
                teacherAssessment.value = (teacherAssessment.totalStats.avg * 100).toFixed(2)
                if (!teacherAssessmentCountDict.hasOwnProperty(teacherAssessment.teacher.id)) {
                    teacherAssessmentCountDict[teacherAssessment.teacher.id] = 0
                }
                const count = teacherAssessmentCountDict[teacherAssessment.teacher.id]
                if (!teacherAssessmentRetakes.hasOwnProperty(count)) {
                    teacherAssessmentRetakes[count] = []
                }
                if (!individualTeacherRetakeDict.hasOwnProperty(teacherAssessment.teacher.id)) {
                    individualTeacherRetakeDict[teacherAssessment.teacher.id] = []
                }
                teacherAssessmentRetakes[count].push(teacherAssessment)
                individualTeacherRetakeDict[teacherAssessment.teacher.id].push(teacherAssessment)
                //console.log(teacherAssessment.teacher.id, count)
                teacherAssessmentCountDict[teacherAssessment.teacher.id] += 1
            }
            teacherAssessments.push(teacherAssessment)
            teacherAssessmentDict[teacherAssessment.id] = teacherAssessment
            if (!teachersDict.hasOwnProperty(teacherAssessment.teacher.id)) {
                teachersDict[teacherAssessment.teacher.id] = teacherAssessment.teacher
                teachers.push(teacherAssessment.teacher)
            }
        })
        teachers.sort((a, b) => {
            return a.lastName > b.lastName ? 1 : -1
        })
        teacherAssessments.sort((a, b) => {
            return a.teacherId > b.teacherId ? 1 : -1
        })
        //console.log('teacherAssessments', teacherAssessments, teacherAssessmentCountDict, teacherAssessmentRetakes)
        this.setState({
            teacherAssessments: teacherAssessments,
            teacherAssessmentDict: teacherAssessmentDict,
            teacherAssessmentCountDict: teacherAssessmentCountDict,
            teacherAssessmentRetakes: teacherAssessmentRetakes,
            teachers: teachers,
            teachersDict: teachersDict,
            individualTeacherRetakeDict: individualTeacherRetakeDict,
            loadingTeachers: false,
        })
    }

    getTeacherAssessments = () => {
        if (!(this.state.queryId && this.props.districtId && this.state.selectedAssessment)) {
            return
        }

        this.setState({
            loadingTeachers: true,
        }, () => {
            if (this.props.analyticsFor === 'district') {
                if (this.state.queryId && this.state.queryId === this.props.districtId) {
                    db.collection(ColType.cleverAssessments)
                        .where('districtId', '==', this.props.districtId)
                        .where('quiz.uuid', '==', this.state.selectedAssessment.uuid)
                        .where('status', '==', 'current')
                        .orderBy('timeStamp', 'asc')
                        .get()
                        .then((querySnapshot) => {
                            this.handleTeacherAssessmentQuerySnapshot(querySnapshot)
                        })
                } else if (this.state.queryId && this.state.queryId !== this.props.districtId) {
                    db.collection(ColType.cleverAssessments)
                        .where('districtId', '==', this.props.districtId)
                        .where('schoolId', '==', this.state.queryId)
                        .where('quiz.uuid', '==', this.state.selectedAssessment.uuid)
                        .where('status', '==', 'current')
                        .orderBy('timeStamp', 'asc')
                        .get()
                        .then((querySnapshot) => {
                            this.handleTeacherAssessmentQuerySnapshot(querySnapshot)
                        })
                } else {
                    db.collection(ColType.cleverAssessments)
                        .where('districtId', '==', this.props.districtId)
                        .where('quiz.uuid', '==', this.state.selectedAssessment.uuid)
                        .where('status', '==', 'current')
                        .orderBy('timeStamp', 'asc')
                        .get()
                        .then((querySnapshot) => {
                            this.handleTeacherAssessmentQuerySnapshot(querySnapshot)
                        })
                }
            } else if (this.props.analyticsFor === 'school') {
                db.collection(ColType.cleverAssessments)
                    .where('districtId', '==', this.props.districtId)
                    .where('schoolId', '==', this.state.queryId)
                    .where('quiz.uuid', '==', this.state.selectedAssessment.uuid)
                    .where('status', '==', 'current')
                    .orderBy('timeStamp', 'asc')
                    .get()
                    .then((querySnapshot) => {
                        this.handleTeacherAssessmentQuerySnapshot(querySnapshot)
                    })
            } else {
                console.log('getTeacherAssessments - unknown analyticsFor', this.props.analyticsFor)
            }
        })
    }

    endWeekOnChange = (endWeek) => {
        this.setState({
            endWeek: endWeek,
            students: [],
            studentsDict: {},
            teachers: [],
            teachersDict: {},
            viewingTeacherId: '',
        }, () => {
            this.getData(true, true)
            window.scrollTo(0, 0)
        })
    }

    onMenuChange = async (selectedAssessment) => {
        //console.log('selectedAssessment', selectedAssessment)
        this.setState({
            selectedAssessment: selectedAssessment,
            students: [],
            studentsDict: {},
            teachers: [],
            teachersDict: {},
            viewingTeacherId: '',
        }, () => {
            this.getAssessmentStatReport()
            this.getAssessmentQuestionReviews()
            this.getTeacherAssessments()
            this.getQuestionDataForAssessment(selectedAssessment)
        })

        //const docText = getTextFromDocument(selectedAssessment.docs[0].components[0].question)
        //console.log('question text', docText)
    }

    onStandardCategoryChange = (standardCategory) => {
        let stateObj = {
            standardCategory: standardCategory,
        }
        if (!this.state.gradeDictAcrossCategories.hasOwnProperty(this.state.standardName)) {
            stateObj.standardName = '2nd Grade'
        }
        this.setState(stateObj)
    }

    onStandardNameChange = (standardName) => {
        this.setState({
            standardName: standardName,
        })
    }

    onSchoolChange = (schoolId) => {
        this.setState({
            queryId: schoolId,
            students: [],
            studentsDict: {},
            teachers: [],
            teachersDict: {},
            viewingTeacherId: '',
        }, () => {
            this.getAssessmentStatReport()
            this.getAssessmentQuestionReviews()
            this.getTeacherAssessments()
            this.getData(false, false)
        })
    }

    canActivelyReviewWeek = (week) => {
        return this.props.districtSettings &&
            this.props.districtSettings.hasOwnProperty('canActivelyReview') &&
            this.props.districtSettings.canActivelyReview &&
            this.props.districtSettings.canActivelyReview.hasOwnProperty(week) &&
            this.props.districtSettings.canActivelyReview[week]
    }

    chunkArray = (array, size) => {
        const chunkedArr = [];
        for (let i = 0; i < array.length; i += size) {
            chunkedArr.push(array.slice(i, i + size));
        }
        return chunkedArr;
    }

    viewTeacherAssessment = (teacherId) => {
        this.setState({
            activeTabKey: 'student-tab',
            viewingTeacherId: teacherId,
            loadingStudents: true,
        }, () => {
            let studentIds = {}

            this.state.teacherAssessments.map((teacherAssessment) => {
                if (teacherAssessment.teacherId !== teacherId) {
                    return false
                }

                Object.keys(teacherAssessment.studentGrades).map((studentId, idx) => {
                    const studentGrade = teacherAssessment.studentGrades[studentId]
                    if (studentGrade.graded) {
                        if (!studentIds.hasOwnProperty(studentId)) {
                            studentIds[studentId] = true
                        }
                    }
                })
            })

            let promises = []
            let studentIdsToFech = Object.keys(studentIds).filter((studentId) => {
                return !this.state.studentsDict.hasOwnProperty(studentId)
            })
            // 'in' query has a max len of 10, so split into chunks
            let studentIdsChunks = this.chunkArray(studentIdsToFech, 10)
            studentIdsChunks.map((studentIdsChunk) => {
                promises.push(new Promise((resolve, reject) => {
                    db.collection(ColType.student)
                        .where(firebase.firestore.FieldPath.documentId(), 'in', studentIdsChunk)
                        .get()
                        .then((querySnapshot) => {
                            let students = this.state.students
                            let studentsDict = this.state.studentsDict
                            if (!students) {
                                students = []
                            }
                            if (!studentsDict) {
                                studentsDict = {}
                            }
                            querySnapshot.forEach((doc) => {
                                let student = flattenDoc(doc)
                                students.push(student)
                                studentsDict[student.id] = student
                            })
                            this.setState({
                                students: students,
                                studentsDict: studentsDict,
                            })
                            resolve()
                        })
                        .catch((error) => {
                            console.log('error', error)
                            resolve()
                        })
                }))
            })

            Promise.all(promises).then(() => {
                this.setState({
                    loadingStudents: false,
                })
            }).catch((error) => {
                console.log('error', error)
                this.setState({
                    loadingStudents: false,
                })
            })
        })
    }

    async getStandardsInAssessment(assessment) {
        if (!assessment) {
            return []
        }
        let standardsDict = {}
        assessment.docs.map((doc) => {
            if (doc.hasOwnProperty('metadata') &&
                doc.metadata.hasOwnProperty('standard') &&
                doc.metadata.standard.hasOwnProperty('standard')) {
                standardsDict[doc.metadata.standard.standard] = true
            }
        })

        return Object.keys(standardsDict)
    }

    async getQuestionDataForAssessment(assessment) {
        if (!assessment) {
            return
        }
        this.setState({
            loadingQuestionBank: true
        })
        const standardsInAssessment = await this.getStandardsInAssessment(assessment)
        let questionPromises = []
        standardsInAssessment.map((standard) => {
            questionPromises.push(this.getQuestionDataForStandard(standard))
        })

        Promise.all(questionPromises).then(() => {
            this.setState({
                loadingQuestionBank: false
            })
        })
            .catch((e) => {
                console.log('error', e)
                notification.error({
                    message: 'Could not load the question bank.',
                    description: 'Please re-select this assessment or refresh the page.',
                    placement: 'bottomRight',
                })
                this.setState({
                    loadingQuestionBank: false
                })
            })
    }

    async getQuestionDataForStandard(standard) {
        // if we already have the question data for this standard, return
        // add this back later
        /*if (this.state.standardDocsDict.hasOwnProperty(standard)) {
            return new Promise((resolve, reject) => {
                resolve()
            })
        }*/
        return new Promise((resolve, reject) => {
            db.collection(ColType.questionBank)
                .where('metadata.status', '==', 'reviewed')
                .where('metadata.standard.standard', '==', standard)
                .where('metadata.standard.standardCategory', '==', '' + this.state.selectedAssessment.standardCategory)
                .where('metadata.standard.standardName', '==', '' + this.state.selectedAssessment.standardName)
                .where('metadata.standard.state', '==', 'SC') //this.props.district.state)
                .get()
                .then(async (querySnapshot) => {
                    let questionDocs = [];
                    querySnapshot.forEach((doc) => {
                        const questionDoc = flattenDoc(doc);
                        questionDoc.components = JSON.parse(questionDoc.components);
                        questionDocs.push(questionDoc);
                    });

                    // get question status for each question
                    let questionStatusPromises = [];
                    questionDocs.forEach((questionDoc) => {
                        // if we keep the above commented out early resolve() code,
                        // do this fetch in there everytime for those questions.
                        questionStatusPromises.push(new Promise((resolve, reject) => {
                            db.collection(ColType.questionStatus)
                                .doc(getQuestionStatusId(this.props.districtId, questionDoc.metadata.uuid))
                                .get()
                                .then((doc) => {
                                    let questionStatusDict = this.state.questionStatusDict;
                                    // doc doesn't exist, remove from questionStatusDict if it exists there
                                    if (!doc.exists) {
                                        if (questionStatusDict.hasOwnProperty(questionDoc.metadata.uuid)) {
                                            delete questionStatusDict[questionDoc.metadata.uuid];
                                        }
                                    } else {
                                        const questionStatus = flattenDoc(doc);
                                        // inactive, remove from questionStatusDict if it exists there
                                        if (questionStatus.status === 'inactive') {
                                            if (questionStatusDict.hasOwnProperty(questionDoc.metadata.uuid)) {
                                                delete questionStatusDict[questionDoc.metadata.uuid];
                                            }
                                        }
                                        // active or replaced, add to questionStatusDict. If it is
                                        // in the questionStatusDict, it cannot be used 
                                        else {
                                            questionStatusDict[questionDoc.metadata.uuid] = questionStatus;
                                        }
                                    }
                                    this.setState({
                                        questionStatusDict: questionStatusDict,
                                    }, () => {
                                        resolve();
                                    })
                                })
                                .catch((e) => {
                                    //console.log('error', e)
                                    // can happen if the questionStatus doc doesn't exist
                                    resolve()
                                })
                        }))
                    })

                    try {
                        await Promise.all(questionStatusPromises)
                    } catch (e) {
                        // fall through if questionStatusPromises fails,
                        // failure can happen if the questionStatus doc doesn't exist
                    }

                    let standardNames = [];
                    questionDocs.forEach((questionDoc) => {
                        standardNames.push(questionDoc.metadata.standard.standard);
                    });

                    let standardDocsDict = this.state.standardDocsDict;
                    standardDocsDict[standard] = questionDocs;
                    this.setState({
                        standardDocsDict: standardDocsDict,
                    }, () => {
                        resolve()
                    })
                })
                .catch((e) => {
                    console.log('error', e)
                    reject(e)
                })
        })
    }

    onTutorialModalCancel = () => {
        if (this.mathTutorialVideoRef) {
            this.mathTutorialVideoRef.pause()
        }
        this.setState({
            showTutorialModal: false,
        })
    }

    /*
    createTestStats = async () => {
        // districtId
        if (this.state.queryId === this.props.districtId) {
            return
        }
        const school = this.props.schoolsDict[this.state.queryId]
        let testBreakdowns = [70, 40, 50, 60, 80, 30, 90, 70, 35, 80]
        if (Math.random() < 0.5) {
            testBreakdowns = [70, 50, 20, 60, 80, 30, 90, 60, 35, 70]
        }
        testBreakdowns = shuffleArray(testBreakdowns)
        for (let i = 0; i < testBreakdowns.length; i++) {
            let testBreakdown = testBreakdowns[i]
            // add random -8 to +8 to the testBreakdown
            testBreakdown += Math.floor(Math.random() * 9) - 8
            if (testBreakdown < 0) {
                testBreakdown = 0
            }
            if (testBreakdown > 100) {
                testBreakdown = 100
            }
            testBreakdowns[i] = testBreakdown
        }
        try {
            let teachersSnapshot = await db.collection(ColType.teacher)
                .where('districtId', '==', this.props.districtId)
                .where('schoolId', '==', this.state.queryId)
                .get()

            let teachers = []
            let teachersDict = {}
            teachersSnapshot.forEach((doc) => {
                let teacher = flattenDoc(doc)
                teachers.push(teacher)
                teachersDict[teacher.id] = teacher
            })

            console.log('teachers', teachers)

            let processedCount = 0
            for (let i = 0; i < teachers.length; i++) {
                const teacher = teachers[i]

                let studentsSnapshot = await db.collection(ColType.student)
                    .where('districtId', '==', this.props.districtId)
                    .where('teacherIds', 'array-contains', teacher.id)
                    .where('folder', '==', FOLDER_CURRENT)
                    .get()

                let students = []
                let studentsDict = {}
                studentsSnapshot.forEach((doc) => {
                    let student = flattenDoc(doc)
                    students.push(student)
                    studentsDict[student.id] = student
                })

                console.log('students', teacher, students)

                let gradesDict = {
                    '3rd Grade': '3',
                    '4th Grade': '4',
                    '5th Grade': '5',
                    '6th Grade': '6',
                    '7th Grade': '7',
                    '8th Grade': '8',
                    '9th Grade': '9',
                    '10th Grade': '10',
                    '11th Grade': '11',
                    '12th Grade': '12',
                    'Algebra': '9',
                    'Civics': '10',
                    'Economics': '12',
                    'United States History': '11',
                    'World History': '12',
                    'Geography': '9',
                    'Algebra 1': '9',
                    'Biology': '10',
                    'United States History and the Constitution': '11',
                }
                let studentsInGrade = 0
                for (let i = 0; i < students.length; i++) {
                    const student = students[i]
                    if (!gradesDict.hasOwnProperty(this.state.selectedAssessment.standardName)) {
                        console.log('no grade', this.state.selectedAssessment.standardName)
                    }
                    const grade = gradesDict[this.state.selectedAssessment.standardName]
                    if (student.grade !== grade) {
                        continue
                    }
                    studentsInGrade += 1
                }

                if (studentsInGrade === 0) {
                    continue
                }
                processedCount += 1

                this.createTestStatsTeacher(teacher, students, testBreakdowns)

                if (processedCount > 15) {
                    break
                }
            }
        } catch (e) {
            console.log('error', e)
        }
    }

    createTestStatsTeacher = async (teacher, students, testBreakdowns) => {
        console.log('here', this.state.selectedAssessment, teacher, students)
        let quizObj = {}
        let quiz = JSON.parse(JSON.stringify(this.state.selectedAssessment))
        quizObj.teacher = teacher
        quizObj.timeStamp = firebase.firestore.Timestamp.now()
        quizObj.teacherId = teacher.id
        quizObj.districtId = this.props.districtId

        quizObj.quiz = {}
        quizObj.quiz.chosenAnswers = {}
        quizObj.quiz.questionIdx = 0
        quizObj.quiz.teacherId = teacher.id
        quizObj.quiz.uuid = quiz.id
        quizObj.quiz.standards = []
        quizObj.quiz.assessmentTitle = quiz.assessmentTitle
        quizObj.quiz.schoolId = teacher.schoolId
        quizObj.quiz.docs = JSON.stringify(quiz.docs)
        quizObj.quiz.id = quiz.id
        quizObj.quiz.createdAt = quiz.createdAt
        quizObj.quiz.chosenAnswersBaseQuiz = {}
        quizObj.quiz.numQuestions = quiz.numQuestions
        quizObj.quiz.assessmentCategory = quiz.assessmentCategory
        quizObj.quiz.live = true
        quizObj.quiz.queryId = this.props.districtId
        quizObj.quiz.standardName = quiz.standardName
        quizObj.quiz.startWeek = quiz.startWeek
        quizObj.quiz.standardCategory = quiz.standardCategory
        quizObj.quiz.districtId = this.props.districtId
        quizObj.quiz.endWeek = quiz.endWeek

        quizObj.studentGrades = {}
        quizObj.studentSurveys = {}
        quizObj.sectionIdsDict = {}
        quizObj.schoolId = teacher.schoolId
        quizObj.status = 'current'
        quizObj.mode = 'students'

        let gradesDict = {
            '3rd Grade': '3',
            '4th Grade': '4',
            '5th Grade': '5',
            '6th Grade': '6',
            '7th Grade': '7',
            '8th Grade': '8',
            '9th Grade': '9',
            '10th Grade': '10',
            '11th Grade': '11',
            '12th Grade': '12',
            'Algebra': '9',
            'Civics': '10',
            'Economics': '12',
            'United States History': '11',
            'World History': '12',
            'Geography': '9',
            'Algebra 1': '9',
            'Biology': '10',
            'United States History and the Constitution': '11',
        }
        let studentsInGrade = 0
        for (let i = 0; i < students.length; i++) {
            const student = students[i]
            if (!gradesDict.hasOwnProperty(quiz.standardName)) {
                console.log('no grade', quiz.standardName)
                return
            }
            const grade = gradesDict[quiz.standardName]
            if (student.grade !== grade) {
                continue
            }
            studentsInGrade += 1

            let studentGrade = {
                chosenAnswersBaseQuiz: {},
                chosenAnswers: {},
                finalGrade: {
                    total: quiz.docs.length,
                    // if not divisible by 100 then do toFixed
                    grade: 0.0,
                    correct: 0.0,
                },
                graded: true,
            }
            let studentSurvey = {
                completed: true,
                qualitative: '',
            }
            if (Math.random() < 0.6) {
                if (Math.random() < 0.6) {
                    studentSurvey.qualitative = 'good';
                } else if (Math.random() < 0.5) {
                    studentSurvey.qualitative = 'somewhatGood';
                } else {
                    studentSurvey.qualitative = 'notGood';
                }
            } else {
                if (Math.random() < 0.2) {
                    studentSurvey.qualitative = 'good';
                } else if (Math.random() < 0.5) {
                    studentSurvey.qualitative = 'somewhatGood';
                } else {
                    studentSurvey.qualitative = 'notGood';
                }
            }
            quizObj.studentSurveys[student.id] = studentSurvey;

            for (let j = 0; j < quiz.docs.length; j++) {
                const doc = quiz.docs[j]
                let testBreakdown = testBreakdowns[j]
                let rndValue0To100 = Math.floor(Math.random() * 101)
                let correct = rndValue0To100 <= testBreakdown

                let correctAnswerIdx = -1
                let incorrectAnswerIdxs = []
                for (let k = 0; k < doc.components[0].answers.length; k++) {
                    const component = doc.components[0].answers[k]
                    if (component.correct) {
                        correctAnswerIdx = k
                    } else {
                        incorrectAnswerIdxs.push(k)
                    }
                }

                if (correct) {
                    studentGrade.finalGrade.correct += 1
                    studentGrade.chosenAnswers[j] = correctAnswerIdx
                    studentGrade.chosenAnswersBaseQuiz[doc.metadata.uuid] = correctAnswerIdx
                } else {
                    const incorrectAnswerIdx = incorrectAnswerIdxs[Math.floor(Math.random() * incorrectAnswerIdxs.length)]
                    studentGrade.chosenAnswers[j] = incorrectAnswerIdx
                    studentGrade.chosenAnswersBaseQuiz[doc.metadata.uuid] = incorrectAnswerIdx
                }
            }

            studentGrade.finalGrade.grade = studentGrade.finalGrade.correct / studentGrade.finalGrade.total

            quizObj.studentGrades[student.id] = studentGrade
        }

        // check if this quiz already exists
        let quizSnapshot = await db.collection(ColType.cleverAssessments)
            .where('districtId', '==', this.props.districtId)
            .where('teacherId', '==', teacher.id)
            .where('quiz.uuid', '==', quizObj.quiz.uuid)
            .where('status', '==', 'current')
            .get()

        if (quizSnapshot.size > 0) {
            const quizDoc = quizSnapshot.docs[0]
            const quizDocData = flattenDoc(quizDoc)
            if (studentsInGrade === 0) {
                db.collection(ColType.cleverAssessments)
                    .doc(quizDocData.id)
                    .delete()
                    .then(() => {
                        console.log('Test stats deleted')
                    })
                    .catch((error) => {
                        console.log('error', error)
                    })
            } else if (studentsInGrade > 0) {
                db.collection(ColType.cleverAssessments)
                    .doc(quizDocData.id)
                    .update(quizObj)
                    .then(() => {
                        console.log('Test stats updated')
                    })
                    .catch((error) => {
                        console.log('error', error)
                    })
            }
        } else {
            db.collection(ColType.cleverAssessments)
                .add(quizObj)
                .then((docRef) => {
                    console.log('docRef', docRef)
                    successMessage('Test stats created')
                })
                .catch((error) => {
                    console.log('error', error)
                })
        }
    }*/

    render() {
        return (
            <div>
                <Layout>
                    <Layout>
                        <Content className='layout-header-mt overflow-visible-important'>
                            <Sider
                                width={270}
                                style={{
                                    overflow: 'auto', overflowX: 'hidden', height: 'calc(100% - 83px)',
                                    position: 'fixed',
                                    left: 0, borderRight: '1px solid #f1f1f1',
                                    background: '#fff',
                                    zIndex: 10,
                                }}>
                                <div style={{ marginTop: 16 }}>
                                    {this.props.schools && this.props.schools.length > 1 ?
                                        <div className='pl-2 pr-2 mb-1'>
                                            <Select
                                                style={{ width: '100%' }}
                                                size='large'
                                                value={this.state.queryId}
                                                onChange={this.onSchoolChange}
                                            >
                                                {this.props.analyticsFor === 'district' ?
                                                    <Select.Option key='district' value={this.props.districtId}>District</Select.Option>
                                                    : ''}
                                                {this.props.schools.map((school, schoolIdx) => {
                                                    return <Select.Option key={school.id} value={school.id}>{school.schoolName}</Select.Option>
                                                })}
                                            </Select>
                                        </div>
                                        : ''}
                                    {/*
                                    <Menu selectedKeys={this.state.topLevelMenuKey} onSelect={(e) => {
                                        console.log('menu change', e)
                                        this.setState({
                                            topLevelMenuKey: [e.key],
                                            menuKey: [],
                                        })
                                        window.scrollTo(0, 0)
                                    }}>
                                        <Menu.Item key='All'>
                                            <div>
                                                {this.state.queryId === this.props.districtId ?
                                                    <div>District</div> :
                                                    this.props.schoolsDict &&
                                                        this.props.schoolsDict.hasOwnProperty(this.state.queryId) ?
                                                        <div>{this.props.schoolsDict[this.state.queryId].schoolName}</div>
                                                        : ''}
                                            </div>
                                        </Menu.Item>
                                                </Menu>*/}
                                    <div className='pl-2 pr-2'>
                                        <Select
                                            value={this.state.endWeek}
                                            size='large'
                                            className='w-100 mb-2'
                                            placeholder={'Select week...'}
                                            onChange={this.endWeekOnChange}
                                        >
                                            {this.state.endWeeks.map((endWeek, endWeekIdx) => {
                                                if (!(this.props.districtSettings &&
                                                    this.props.districtSettings.hasOwnProperty('canReview') &&
                                                    this.props.districtSettings.canReview &&
                                                    this.props.districtSettings.canReview.hasOwnProperty(endWeek) &&
                                                    this.props.districtSettings.canReview[endWeek])) {
                                                    return false
                                                }
                                                let canActivelyReview = this.canActivelyReviewWeek(endWeek)
                                                let weekDescription = 'W' + endWeek + ' - ' + (endWeek % 9 === 0 ? 'Benchmark' : 'Common Assessment')
                                                if (endWeek === 34) {
                                                    weekDescription = 'W' + endWeek + ' - Benchmark'
                                                }

                                                return <Select.Option key={endWeekIdx} value={endWeek}>
                                                    {canActivelyReview ?
                                                        <div className='flex flex-v-center w-100'>
                                                            <div className='font-16 font-bold mr-1 font-fff br-4' style={{ color: '#FF5F34' }}>
                                                                Review
                                                            </div>
                                                            <div>
                                                                {weekDescription}
                                                            </div>
                                                        </div>
                                                        :
                                                        <div>
                                                            {weekDescription}
                                                        </div>
                                                    }
                                                </Select.Option>
                                            })}
                                        </Select>
                                    </div>

                                    {this.state.loadingData ?
                                        <div className='font-16 pl-2 pr-2 mt-1 flex flex-v-center w-100'>
                                            <Icon type='loading' className='mr-1' />
                                            <div>Loading...</div></div>
                                        : ''}

                                    {!this.state.loadingData && this.state.assessments && this.state.assessments.length === 0 ?
                                        <div className='font-16 pl-2 pr-2 mt-2'>No assessments found.</div>
                                        : ''}
                                    <Menu selectedKeys={this.state.menuKey} onSelect={(e) => {
                                        //console.log('menu change', e)
                                        //console.log(this.state.assessments, this.state.assessments[parseInt(e.key, 10)])
                                        let keyAsInt = parseInt(e.key, 10)
                                        this.setState({
                                            menuKey: [e.key],
                                            topLevelMenuKey: [],
                                        })
                                        window.scrollTo(0, 0)
                                        this.onMenuChange(this.state.assessments[keyAsInt])
                                    }}>
                                        {!this.state.loadingData && this.state.assessments.map((assessment, assessmentIdx) => {
                                            if ((assessment.standardName.indexOf('Kindergarten') !== -1 ||
                                                assessment.standardName.indexOf('1st Grade') !== -1) && !assessment.hasOwnProperty('htmlQuiz')) {
                                                return false
                                            }
                                            let isBenchmark = this.state.endWeek % 9 === 0
                                            let isFormB = false
                                            if (assessmentIdx > 0 &&
                                                assessment.standardName === this.state.assessments[assessmentIdx - 1].standardName &&
                                                assessment.standardCategory === this.state.assessments[assessmentIdx - 1].standardCategory) {
                                                isFormB = true
                                            }
                                            return (
                                                <Menu.Item key={'' + assessmentIdx}
                                                    title={assessment.hasOwnProperty('assessmentTitle') ?
                                                        assessment.assessmentTitle : undefined}>
                                                    <div>
                                                        <div>
                                                            {assessment.hasOwnProperty('assessmentTitle') ?
                                                                assessment.assessmentTitle :
                                                                assessment.standardCategory + ': ' + assessment.standardName +
                                                                (!isBenchmark ? (isFormB ? ' - Form B' : ' - Form A') : '')
                                                            }
                                                        </div>
                                                    </div>
                                                </Menu.Item>
                                            )
                                        })}
                                    </Menu>
                                </div>
                            </Sider>
                            <Content style={{ marginLeft: 270, marginTop: "24px", overflowX: 'initial' }}>
                                <div className='pl-4 pr-4'>
                                    {!this.state.mounted ?
                                        <div></div> :
                                        this.state.topLevelMenuKey.length > 0 ?
                                            <div className='mt-4'>
                                                <div className='font-30 font-bold mb-4'>
                                                    {this.state.queryId === this.props.districtId ?
                                                        'District' :
                                                        this.props.schoolsDict &&
                                                            this.props.schoolsDict.hasOwnProperty(this.state.queryId) ?
                                                            this.props.schoolsDict[this.state.queryId].schoolName
                                                            : ''} Assessment Analytics
                                                </div>
                                                <Select onChange={this.onStandardCategoryChange} value={this.state.standardCategory} size='large' className='mr-2' style={{ width: '150px' }}>
                                                    {this.state.standardCategories && this.state.standardCategories.map((category, ind) => {
                                                        return <Select.Option key={'category-' + ind} value={category}>{category}</Select.Option>
                                                    })}
                                                </Select>
                                                <Select onChange={this.onStandardNameChange} value={this.state.standardName} size='large' style={{ width: '240px' }}>
                                                    {this.state.standardNamesDict &&
                                                        this.state.standardNamesDict.hasOwnProperty(this.state.standardCategory) &&
                                                        this.state.standardNamesDict[this.state.standardCategory] &&
                                                        this.state.standardNamesDict[this.state.standardCategory].map((standardName, ind) => {
                                                            return <Select.Option key={'grade-' + ind} value={standardName}>{standardName}</Select.Option>
                                                        })}
                                                </Select>

                                                {this.state.entireAssessmentStatReports &&
                                                    this.state.entireAssessmentStatReports.hasOwnProperty(this.state.standardCategory) &&
                                                    this.state.entireAssessmentStatReports[this.state.standardCategory] &&
                                                    ((this.state.standardSort === 'asc' &&
                                                        this.state.entireAssessmentStatReports[this.state.standardCategory].asc &&
                                                        this.state.entireAssessmentStatReports[this.state.standardCategory].asc.hasOwnProperty(this.state.standardName) &&
                                                        this.state.entireAssessmentStatReports[this.state.standardCategory].asc[this.state.standardName]) ||
                                                        (this.state.standardSort === 'desc' &&
                                                            this.state.entireAssessmentStatReports[this.state.standardCategory].desc &&
                                                            this.state.entireAssessmentStatReports[this.state.standardCategory].desc.hasOwnProperty(this.state.standardName) &&
                                                            this.state.entireAssessmentStatReports[this.state.standardCategory].desc[this.state.standardName])) ?
                                                    <div className='mb-50'>
                                                        <div className='font-24 font-bold mt-4 flex w-100 flex-v-center'>
                                                            <div>{this.state.standardCategory} - {this.state.standardName}</div>
                                                            <Radio.Group className='ml-auto' size='large' value={this.state.standardSort} onChange={(e) => {
                                                                this.setState({
                                                                    standardSort: e.target.value,
                                                                })
                                                            }}>
                                                                <Radio.Button size='large' value='asc'>Most challenging</Radio.Button>
                                                                <Radio.Button size='large' value='desc'>Least challenging</Radio.Button>
                                                            </Radio.Group>
                                                        </div>

                                                        <div>
                                                            <div className='font-20'>
                                                                <div>These are the standards that students had the {this.state.standardSort === 'asc' ? 'most' : 'least'} trouble with:</div>
                                                                <div className='font-16 mb-3'>*These standards are sorted using a weighted sort that factors in average and total. This is to prioritize the total number as well as the average score.</div>

                                                            </div>
                                                            <div>
                                                                {(this.state.standardSort === 'asc' ?
                                                                    this.state.entireAssessmentStatReports[this.state.standardCategory].asc[this.state.standardName] :
                                                                    this.state.entireAssessmentStatReports[this.state.standardCategory].desc[this.state.standardName]).map((standard, idx) => {
                                                                        let avgDisplay = (standard.avg * 100).toFixed(2)
                                                                        let correctPctNumber = standard.avg * 100
                                                                        let progressBarClassName = 'progress-light-green'
                                                                        if (correctPctNumber < 30) {
                                                                            progressBarClassName = 'progress-light-red'
                                                                        } else if (correctPctNumber < 60) {
                                                                            progressBarClassName = 'progress-light-yellow'
                                                                        } else if (correctPctNumber) {
                                                                            progressBarClassName = 'progress-light-green'
                                                                        }
                                                                        return <div className='flex flex-v-center w-100 border ant-shadow br-4 p-3 mb-2 font-20'>
                                                                            <div className='w-50 pr-2'>
                                                                                <div className='font-bold'>{standard.standard.standard}</div>
                                                                                {standard.standard.hasOwnProperty('isPriority') &&
                                                                                    standard.standard.isPriority ?
                                                                                    <div className='font-16'>*Priority Standard</div>
                                                                                    : ''}
                                                                                <div className='font-16'>{standard.standard.title}</div>
                                                                            </div>
                                                                            <div className='w-50 pl-2'>
                                                                                <div className='mb-2'>{standard.correct} / {standard.total} students answered this question correctly</div>
                                                                                <div className='font-bold'>{avgDisplay}%</div>
                                                                                <Progress size='large'
                                                                                    percent={avgDisplay}
                                                                                    className={progressBarClassName} />
                                                                            </div>
                                                                        </div>
                                                                    })}
                                                            </div>
                                                        </div>

                                                    </div>
                                                    :
                                                    <div>
                                                        <Empty
                                                            containerClassName="mt-50 mb-50 text-center"
                                                            width={55}
                                                            height={55}
                                                            image={'/empty/goals.png'}
                                                            description={'There are currently no analytics for this subject and grade.'}
                                                        />
                                                    </div>
                                                }
                                            </div>
                                            :
                                            <div>
                                                {/*<Select
                                        value={this.state.districtSchoolIdStatsSelected}
                                        onChange={(e) => {
                                            this.setState({
                                                districtSchoolIdStatsSelected: e,
                                            })
                                        }}>
                                        {this.props.adminBaseProps.schools &&
                                            this.props.adminBaseProps.schools.map((school, schoolIdx) => {
                                                return <Select.Option key={school.id} value={school.id}>{school.schoolName}</Select.Option>
                                            })}
                                        </Select>*/}

                                                <Modal
                                                    visible={this.state.showTutorialModal}
                                                    onCancel={this.onTutorialModalCancel}
                                                    footer={null}
                                                    width={800}>
                                                    <video
                                                        ref={el => (this.mathTutorialVideoRef = el)}
                                                        src='https://firebasestorage.googleapis.com/v0/b/education-9d7f3.appspot.com/o/Math-Assessment-Tutorial.mp4?alt=media&token=a89a5dca-046c-4def-97c1-6d6234367ac8'
                                                        controls
                                                        className='w-100'
                                                        style={{ height: 'auto' }}
                                                    />
                                                </Modal>

                                                <div>
                                                    <Tabs
                                                        className='tabs-no-overflow-hidden sticky-left sticky-left-more tabs-w-100'
                                                        defaultActiveKey='district-school-tab'
                                                        activeKey={this.state.activeTabKey}
                                                        size='large'
                                                        animated={false}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                activeTabKey: e,
                                                            })
                                                        }}
                                                    >
                                                        <Tabs.TabPane tab={'District / School Analytics'} key='district-school-tab'>

                                                            {this.state.selectedAssessment && (!this.canActivelyReviewWeek(this.state.endWeek) ||
                                                                (this.state.selectedAssessment &&
                                                                    this.state.selectedAssessment.hasOwnProperty('live') &&
                                                                    this.state.selectedAssessment.live)) ?
                                                                <ReactToPrint
                                                                    trigger={() =>
                                                                        <Button
                                                                            size={'large'}
                                                                            type='primary'
                                                                            className="lg-btn mt-3"
                                                                        >
                                                                            <Icon type="printer" />
                                                                            Print
                                                                        </Button>
                                                                    }
                                                                    content={() => this.districtAnalyticsComponentRef}
                                                                /> : ''}

                                                            <div ref={el => (this.districtAnalyticsComponentRef = el)}>
                                                                <div className={'font-24 font-bold mt-3 text-center print-text-left print-mt-0 print-font-30' +
                                                                    (this.state.selectedAssessment && this.state.selectedAssessment.hasOwnProperty('htmlQuiz') ?
                                                                        ' print-hide' : '')
                                                                }>
                                                                    {this.state.queryId === this.props.districtId ?
                                                                        'District Assessment Analytics' :
                                                                        this.props.schoolsDict &&
                                                                            this.props.schoolsDict.hasOwnProperty(this.state.queryId) ?
                                                                            this.props.schoolsDict[this.state.queryId].schoolName + ' Assessment Analytics'
                                                                            : ''}
                                                                </div>
                                                                <div className={'font-20 mb-2 text-center print-text-left print-font-24' +
                                                                    (this.state.selectedAssessment && this.state.selectedAssessment.hasOwnProperty('htmlQuiz') ?
                                                                        ' print-hide' : '')}>
                                                                    {this.state.selectedAssessment ?
                                                                        this.state.selectedAssessment.hasOwnProperty('assessmentTitle') ?
                                                                            this.state.selectedAssessment.assessmentTitle :
                                                                            this.state.selectedAssessment.standardName + ' ' +
                                                                            (this.state.gradeDictAcrossCategories.hasOwnProperty(this.state.selectedAssessment.standardName) ?
                                                                                this.state.selectedAssessment.standardCategory + ' ' : '') +
                                                                            getAssessmentDisplayName(this.state.selectedAssessment)
                                                                        : ''}
                                                                </div>

                                                                {(this.state.selectedAssessment &&
                                                                    this.state.assessmentStatReport &&
                                                                    this.state.assessmentStatReport.hasOwnProperty('studentSurveys') &&
                                                                    this.state.assessmentStatReport.studentSurveys &&
                                                                    this.state.assessmentStatReport.studentSurveys.hasSurvey) ||
                                                                    (this.state.selectedAssessment &&
                                                                        this.state.assessmentStatReport &&
                                                                        this.state.assessmentStatReport.hasOwnProperty('questions') &&
                                                                        this.state.assessmentStatReport.questions &&
                                                                        Object.keys(this.state.assessmentStatReport.questions).length > 0) ?
                                                                    <div className='question-container background-fff p-6 br-4 mb-50 ant-shadow print-hide'>
                                                                        {this.state.selectedAssessment &&
                                                                            this.state.assessmentStatReport &&
                                                                            this.state.assessmentStatReport.hasOwnProperty('studentSurveys') &&
                                                                            this.state.assessmentStatReport.studentSurveys &&
                                                                            this.state.assessmentStatReport.studentSurveys.hasSurvey ?
                                                                            <div className='w-100 print-hide'>
                                                                                <div className=''>
                                                                                    <StudentSurveyResults
                                                                                        calculatedStudentSurveys={this.state.assessmentStatReport.studentSurveys}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            : ''}
                                                                        {this.state.selectedAssessment &&
                                                                            this.state.assessmentStatReport &&
                                                                            this.state.assessmentStatReport.hasOwnProperty('questions') &&
                                                                            this.state.assessmentStatReport.questions &&
                                                                            Object.keys(this.state.assessmentStatReport.questions).length > 0 ?
                                                                            <div className='w-100 print-hide mt-3'>
                                                                                <div className='font-24 font-bold mb-2'>Results by Question</div>
                                                                                {this.chunkArray(this.state.selectedAssessment.docs, 5).map((chunk, chunkIndex) => {
                                                                                    return <div className='flex flex-v-center w-100 pt-1'>
                                                                                        {chunk.map((lessonPlanItem, questionIndex) => {
                                                                                            let borderClasses = ' border-right border-top border-bottom'
                                                                                            if (questionIndex === 0) {
                                                                                                borderClasses += ' border-left br-tl-4 br-bl-4'
                                                                                            } else if (questionIndex === chunk.length - 1) {
                                                                                                borderClasses += ' br-tr-4 br-br-4'
                                                                                            }
                                                                                            if (!this.state.assessmentStatReport.questions.hasOwnProperty(lessonPlanItem.metadata.uuid)) {
                                                                                                return false
                                                                                            }
                                                                                            const questionData = this.state.assessmentStatReport.questions[lessonPlanItem.metadata.uuid]
                                                                                            const correct = questionData.correct
                                                                                            const incorrect = questionData.incorrect
                                                                                            const total = questionData.total
                                                                                            const correctPctNumber = questionData.avg * 100
                                                                                            const correctPct = correctPctNumber.toFixed(2)
                                                                                            let background = '#fff'
                                                                                            if (correctPctNumber < 30) {
                                                                                                background = '#ffccc7'
                                                                                            } else if (correctPctNumber < 60) {
                                                                                                background = '#ffe7ba'
                                                                                            } else if (correctPctNumber) {
                                                                                                background = '#d9f7be'
                                                                                            }
                                                                                            return <div key={'assessment-total-' + questionIndex} style={{ flex: 1 }}>
                                                                                                <div className='font-16 text-center mb-05'>{'Q' + ((chunkIndex * 5) + questionIndex + 1)}</div>
                                                                                                <div className={'p-1' + borderClasses} style={{ background: getColorGradient(correctPctNumber) }}>
                                                                                                    <div className='text-center font-16'>
                                                                                                        {total > 0 ? correctPct + '%' : '-'}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        })}
                                                                                    </div>
                                                                                })}
                                                                            </div>
                                                                            :
                                                                            ''
                                                                        }
                                                                    </div> : ''}

                                                                <div>

                                                                    {(!this.canActivelyReviewWeek(this.state.endWeek) &&
                                                                        this.state.loadingStats) ?
                                                                        <div className='font-20 mb-3 mt-2 question-container print-hide flex flex-v-center flex-h-center'>
                                                                            <Icon type='loading' className='mr-1' />
                                                                            <div>Loading analytics...</div>
                                                                        </div>
                                                                        :
                                                                        (!this.canActivelyReviewWeek(this.state.endWeek) ||
                                                                            (this.state.selectedAssessment &&
                                                                                this.state.selectedAssessment.hasOwnProperty('live') &&
                                                                                this.state.selectedAssessment.live)) &&
                                                                            this.state.selectedAssessment &&
                                                                            (!this.state.assessmentStatReport ||
                                                                                (this.state.assessmentStatReport &&
                                                                                    this.state.assessmentStatReport.hasOwnProperty('questions') &&
                                                                                    this.state.assessmentStatReport.questions &&
                                                                                    Object.keys(this.state.assessmentStatReport.questions).length === 0)) ?
                                                                            <div className='font-20 mb-3 mt-1 pt-1 question-container print-hide'>There are currently no analytics for this assessment. Analytics will be added as students complete the assessment.</div>
                                                                            :
                                                                            ''
                                                                    }

                                                                    {this.state.selectedAssessment && this.state.selectedAssessment.hasOwnProperty('htmlQuiz') ?
                                                                        <HTMLAssessmentViewer
                                                                            htmlContent={this.state.selectedAssessment.htmlQuiz}
                                                                            quiz={this.state.selectedAssessment}
                                                                            teacherBaseProps={null}
                                                                            title={this.state.selectedAssessment.assessmentTitle}
                                                                            maxWidth="850px"
                                                                            showPrint={false}
                                                                        />
                                                                        : ''}

                                                                    {this.state.selectedAssessment && !this.state.selectedAssessment.hasOwnProperty('htmlQuiz') ?
                                                                        this.state.selectedAssessment.docs &&
                                                                        this.state.selectedAssessment.docs.map((lessonPlanItem, idx) => {
                                                                            let stats = null
                                                                            let progressBarClassName = ''
                                                                            let correctPctNumber = 0
                                                                            let correctPct = '0'
                                                                            if (this.state.assessmentStatReport &&
                                                                                this.state.assessmentStatReport.questions &&
                                                                                this.state.assessmentStatReport.questions.hasOwnProperty(lessonPlanItem.metadata.uuid)) {
                                                                                stats = this.state.assessmentStatReport.questions[lessonPlanItem.metadata.uuid]
                                                                                correctPctNumber = stats.avg * 100
                                                                                correctPct = correctPctNumber.toFixed(2)
                                                                                if (correctPctNumber < 30) {
                                                                                    progressBarClassName = 'progress-light-red'
                                                                                } else if (correctPctNumber < 60) {
                                                                                    progressBarClassName = 'progress-light-yellow'
                                                                                } else if (correctPctNumber) {
                                                                                    progressBarClassName = 'progress-light-green'
                                                                                }
                                                                            }
                                                                            return <div className={'flex flex-h-center page-break-after-always'}>
                                                                                <div className='flex'>
                                                                                    <div className='question-container mt-2 font-black font-20 border ant-shadow br-4 p-6 mb-50 print-remove-padding print-remove-border print-remove-shadow' key={'assessment-idx-' + idx}>
                                                                                        <div className='font-30 flex flex-v-center space-between w-100 mb-2'>
                                                                                            <div className='inline-flex'>Question {idx + 1}</div>
                                                                                        </div>
                                                                                        {stats ?
                                                                                            <div className='mb-4'>
                                                                                                <div className='font-20'>{stats.correct} / {stats.total} ({correctPct}%) students answered this question correctly</div>
                                                                                                <div className='pr-12px'>
                                                                                                    <Progress size='large'
                                                                                                        percent={correctPct}
                                                                                                        className={progressBarClassName} />
                                                                                                </div>
                                                                                            </div>
                                                                                            : ''}
                                                                                        <div>
                                                                                            <DocumentDelegator
                                                                                                assessment={this.state.selectedAssessment}
                                                                                                document={lessonPlanItem}
                                                                                                additionalData={{ questionStats: stats }}
                                                                                            />
                                                                                        </div>
                                                                                        {lessonPlanItem ?
                                                                                            <div className='mt-4'>
                                                                                                <div className='font-16 font-bold'>
                                                                                                    This question is for the standard: {lessonPlanItem.metadata.standard.standard}
                                                                                                </div>
                                                                                                {lessonPlanItem.metadata.standard.hasOwnProperty('isPriority') &&
                                                                                                    lessonPlanItem.metadata.standard.isPriority ?
                                                                                                    <div className='font-16'>*Priority Standard</div>
                                                                                                    : ''}
                                                                                                <div className='mt-1 font-16'>{lessonPlanItem.metadata.standard.title}</div>
                                                                                            </div>
                                                                                            : ''}
                                                                                    </div>
                                                                                </div>
                                                                                {this.canActivelyReviewWeek(this.state.endWeek) &&
                                                                                    this.state.selectedAssessment &&
                                                                                    this.state.selectedAssessment.hasOwnProperty('live') &&
                                                                                    !this.state.selectedAssessment.live &&
                                                                                    this.state.selectedAssessment.docs.length > 1 ?
                                                                                    <div className='ml-3' style={{ maxWidth: '270px' }}>
                                                                                        <div className='font-24 mb-2'>
                                                                                            <div className='mt-2'>Question {idx + 1} reviews</div>
                                                                                            <div className='font-16 mb-2'>
                                                                                                Add a review if you think this question needs to be reworked.
                                                                                            </div>
                                                                                            <ReviewQuestionForm
                                                                                                assessmentUUID={this.state.selectedAssessment.uuid}
                                                                                                questionUUID={lessonPlanItem.metadata.uuid}
                                                                                                reviewerId={this.props.reviewerId}
                                                                                                person={this.props.person}
                                                                                                schools={this.props.schools}
                                                                                                districtId={this.props.districtId}
                                                                                            />
                                                                                        </div>
                                                                                        {this.state.questionUUIDReviews &&
                                                                                            this.state.questionUUIDReviews.hasOwnProperty(lessonPlanItem.metadata.uuid) &&
                                                                                            this.state.questionUUIDReviews[lessonPlanItem.metadata.uuid] &&
                                                                                            this.state.questionUUIDReviews[lessonPlanItem.metadata.uuid].length > 0 ?
                                                                                            <Collapse bordered={false} className='pl-0 pr-0' key={'question-' + idx}>
                                                                                                <Collapse.Panel
                                                                                                    className='pl-0 pr-0'
                                                                                                    header={<div className='font-16'>
                                                                                                        {this.state.questionUUIDReviews[lessonPlanItem.metadata.uuid].length + ' reviews'}
                                                                                                    </div>}>

                                                                                                    {this.state.questionUUIDReviews[lessonPlanItem.metadata.uuid].map((questionReview, questionReviewIdx) => {
                                                                                                        return <div className='mb-1 font-black font-20 border ant-shadow br-4 p-2'>
                                                                                                            {this.props.reviewerId === questionReview.reviewerId ?
                                                                                                                <Popconfirm title={'Are you sure you want to delete your review?'}
                                                                                                                    cancelText={'No'} okText={'Yes'}
                                                                                                                    placement={'topLeft'}
                                                                                                                    onConfirm={() => {
                                                                                                                        db.collection(ColType.questionReview)
                                                                                                                            .doc(questionReview.id)
                                                                                                                            .delete()
                                                                                                                            .then(() => {
                                                                                                                                notification.success({
                                                                                                                                    message: 'Review deleted',
                                                                                                                                    description: 'Your review has been deleted.',
                                                                                                                                    placement: 'bottomRight',
                                                                                                                                })
                                                                                                                            })
                                                                                                                            .catch((error) => {
                                                                                                                                notification.error({
                                                                                                                                    message: 'Review added',
                                                                                                                                    description: 'Your review could not be deleted.',
                                                                                                                                    placement: 'bottomRight',
                                                                                                                                })
                                                                                                                            })
                                                                                                                    }}>
                                                                                                                    <Button icon='delete' className='float-right' />
                                                                                                                </Popconfirm>
                                                                                                                : ''}
                                                                                                            {questionReview.hasOwnProperty('anonymous') &&
                                                                                                                !questionReview.anonymous &&
                                                                                                                questionReview.person ?
                                                                                                                <Tooltip
                                                                                                                    title={questionReview.person.firstName + ' ' + questionReview.person.lastName}
                                                                                                                    placement='topLeft'
                                                                                                                >
                                                                                                                    <div>
                                                                                                                        <PersonAvatar
                                                                                                                            person={questionReview.person}
                                                                                                                            avatarColor={'#b3e9ff'}
                                                                                                                            avatarTextColor={'#000'}
                                                                                                                            containerClassName="font-16 mb-1"
                                                                                                                            avatarClassName="font-16 font-bold mr-1"
                                                                                                                            personClassName="font-black font-16 ellipsis max-w-110"
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                </Tooltip>
                                                                                                                : ''}
                                                                                                            <div className='font-16 font-bold'>Question issues</div>
                                                                                                            <ul className='pl-3'>
                                                                                                                {questionReview.reviewChoices.map((reviewChoice, reviewChocieIdx) => {
                                                                                                                    return <li className='font-16' key={lessonPlanItem.metadata.uuid + '-' + reviewChocieIdx}>{reviewChoice}</li>
                                                                                                                })}
                                                                                                            </ul>
                                                                                                            {questionReview.otherReviewMessage !== '' ?
                                                                                                                <div>
                                                                                                                    <div className='font-16 font-bold'>Other review information</div>
                                                                                                                    <div className='font-16'>{questionReview.otherReviewMessage}</div>
                                                                                                                </div>
                                                                                                                : ''}
                                                                                                        </div>

                                                                                                    })}
                                                                                                </Collapse.Panel>
                                                                                            </Collapse>

                                                                                            : ''
                                                                                        }
                                                                                    </div> : ''}
                                                                            </div>
                                                                        })
                                                                        :
                                                                        ''
                                                                    }

                                                                    {!this.state.selectedAssessment ?
                                                                        <div className='font-20 mt-4'>
                                                                            <Empty
                                                                                containerClassName="mt-50 mb-50 text-center"
                                                                                width={55}
                                                                                height={55}
                                                                                image={'/empty/goals.png'}
                                                                                description={<div>
                                                                                    <div className='mb-05'>There is currently no assessment selected.</div>
                                                                                    <div>Use the left side menu to select an assessment.</div>
                                                                                </div>}
                                                                            />
                                                                        </div> : ''}
                                                                </div>
                                                            </div>
                                                        </Tabs.TabPane>
                                                        <Tabs.TabPane tab={<div className='font-16'>Teacher Analytics</div>} key='teacher-tab'>
                                                            {!this.state.selectedAssessment || (this.state.selectedAssessment && (!this.state.assessmentStatReport ||
                                                                (this.state.assessmentStatReport &&
                                                                    this.state.assessmentStatReport.hasOwnProperty('questions') &&
                                                                    this.state.assessmentStatReport.questions &&
                                                                    Object.keys(this.state.assessmentStatReport.questions).length === 0))) ?
                                                                ''
                                                                :
                                                                <ReactToPrint
                                                                    trigger={() =>
                                                                        <Button
                                                                            size={'large'}
                                                                            type='primary'
                                                                            className="lg-btn mt-3"
                                                                        >
                                                                            <Icon type="printer" />
                                                                            Print
                                                                        </Button>
                                                                    }
                                                                    content={() => this.teacherAnalyticsComponentRef}
                                                                />
                                                            }

                                                            <div className={'mt-3 mb-3' + (this.state.activeTabKey !== 'teacher-tab' ? ' display-none' : '')}
                                                            >
                                                                <div className='font-24 font-bold mb-05 sticky-left sticky-left-more display-table'>
                                                                    Teacher Analytics
                                                                </div>
                                                                {this.state.selectedAssessment && this.state.selectedAssessment.hasOwnProperty('htmlQuiz') ?
                                                                    <div className='font-20 mb-3 mt-1 pt-1 question-container'>Teacher analytics are currently not available for this assessment.</div>
                                                                    : ''}
                                                                {(this.state.loadingStats || this.state.loadingTeachers) ?
                                                                    <div className='font-20 mb-3 mt-2 question-container print-hide flex flex-v-center flex-h-center'>
                                                                        <Icon type='loading' className='mr-1' />
                                                                        <div>Loading analytics...</div>
                                                                    </div>
                                                                    :
                                                                    !this.state.selectedAssessment || (this.state.selectedAssessment && (!this.state.assessmentStatReport ||
                                                                        (this.state.assessmentStatReport &&
                                                                            this.state.assessmentStatReport.hasOwnProperty('questions') &&
                                                                            this.state.assessmentStatReport.questions &&
                                                                            Object.keys(this.state.assessmentStatReport.questions).length === 0))) ?
                                                                        <div className='font-20 mb-3 mt-1 pt-1 question-container'>There are currently no analytics for this assessment. Analytics will be added as students complete the assessment.</div>
                                                                        :
                                                                        <div>
                                                                            {this.state.individualTeacherRetakeDict &&
                                                                                this.state.teachers &&
                                                                                this.state.teacherAssessments &&
                                                                                this.state.teacherAssessments.length > 0 ?
                                                                                <div>
                                                                                    <div ref={el => (this.teacherAnalyticsComponentRef = el)}>
                                                                                        <div className='print-show'>
                                                                                            <DOKTeacherBreakdownPrint
                                                                                                quiz={this.state.teacherAssessments[0]}
                                                                                                teacherRetakes={this.state.individualTeacherRetakeDict}
                                                                                                teachers={this.state.teachers}
                                                                                                students={this.state.students}
                                                                                                studentDict={this.state.studentsDict}
                                                                                            />
                                                                                        </div>
                                                                                    </div>

                                                                                    <DOKTeacherBreakdown
                                                                                        quiz={this.state.teacherAssessments[0]}
                                                                                        teacherRetakes={this.state.individualTeacherRetakeDict}
                                                                                        teachers={this.state.teachers}
                                                                                        students={this.state.students}
                                                                                        studentDict={this.state.studentsDict}
                                                                                    />
                                                                                </div>
                                                                                : ''}
                                                                        </div>}
                                                            </div>
                                                        </Tabs.TabPane>
                                                        <Tabs.TabPane tab={<div className='font-16'>Student Analytics</div>} key='student-tab'>
                                                            <div className={'mt-3 mb-3' + (this.state.activeTabKey !== 'student-tab' ? ' display-none' : '')}>
                                                                <div className='font-24 font-bold mb-05 sticky-left sticky-left-more display-table'>
                                                                    Student Analytics
                                                                </div>
                                                                {(this.state.loadingStats) ?
                                                                    <div className='font-20 mb-3 mt-2 question-container print-hide flex flex-v-center flex-h-center'>
                                                                        <Icon type='loading' className='mr-1' />
                                                                        <div>Loading analytics...</div>
                                                                    </div>
                                                                    :
                                                                    !this.canActivelyReviewWeek(this.state.endWeek) &&
                                                                        this.state.selectedAssessment && (!this.state.assessmentStatReport ||
                                                                            (this.state.assessmentStatReport &&
                                                                                this.state.assessmentStatReport.hasOwnProperty('questions') &&
                                                                                this.state.assessmentStatReport.questions &&
                                                                                Object.keys(this.state.assessmentStatReport.questions).length === 0)) ?
                                                                        <div className='font-20 mb-3 mt-1 pt-1 question-container'>There are currently no analytics for this assessment. Analytics will be added as students complete the assessment.</div>
                                                                        :
                                                                        <div>
                                                                            <div className='mb-4 sticky-left sticky-left-more display-table'>
                                                                                <div className='flex flex-v-center'>
                                                                                    <div className='font-16 mr-1'>Select teacher:</div>
                                                                                    <Select value={this.state.viewingTeacherId ? this.state.viewingTeacherId : undefined}
                                                                                        placeholder='Select teacher...'
                                                                                        size='large'
                                                                                        style={{ width: '250px' }}
                                                                                        onChange={(e) => {
                                                                                            this.viewTeacherAssessment(e)
                                                                                        }}
                                                                                    >
                                                                                        {this.state.teachers && this.state.teachers.map((teacher, teacherIdx) => {
                                                                                            return <Select.Option key={'teacher-' + teacherIdx} value={teacher.id}>
                                                                                                {teacher.firstName} {teacher.lastName}
                                                                                            </Select.Option>
                                                                                        })}
                                                                                    </Select>
                                                                                </div>
                                                                            </div>
                                                                            {!this.state.viewingTeacherId ?
                                                                                <div className='font-20'>
                                                                                    <Empty
                                                                                        containerClassName="mt-4 mb-50 text-center"
                                                                                        width={55}
                                                                                        height={55}
                                                                                        image={'/empty/goals.png'}
                                                                                        description={<div>There is currently no teacher selected.<br />Select a teacher by using the dropdown above.</div>}
                                                                                    />
                                                                                </div>
                                                                                :
                                                                                this.state.loadingStudents ?
                                                                                    <div className='font-24 mt-50 flex flex-v-center flex-h-center w-100'>
                                                                                        <Icon type='loading' className='mr-1' />
                                                                                        <div>Loading...</div>
                                                                                    </div> :
                                                                                    this.state.teacherAssessmentRetakes &&
                                                                                        Object.keys(this.state.teacherAssessmentRetakes).length === 0 ?
                                                                                        <div className='font-20'>
                                                                                            <Empty
                                                                                                containerClassName="mt-4 mb-50 text-center"
                                                                                                width={55}
                                                                                                height={55}
                                                                                                image={'/empty/goals.png'}
                                                                                                description={<div>There is currently no assessment information to display.</div>}
                                                                                            />
                                                                                        </div> :
                                                                                        <div>
                                                                                            <div className='flex w-100 mt-2'>
                                                                                                <ReactToPrint
                                                                                                    trigger={() =>
                                                                                                        <Button
                                                                                                            size={'large'}
                                                                                                            type='primary'
                                                                                                            className="lg-btn"
                                                                                                        >
                                                                                                            <Icon type="printer" />
                                                                                                            Print
                                                                                                        </Button>
                                                                                                    }
                                                                                                    content={() => this.studentAnalyticsComponentRef}
                                                                                                />
                                                                                            </div>
                                                                                            <div ref={el => (this.studentAnalyticsComponentRef = el)}>
                                                                                                <div className='print-show'>
                                                                                                    {this.state.teacherAssessmentRetakes &&
                                                                                                        Object.keys(this.state.teacherAssessmentRetakes).length > 0 &&
                                                                                                        Object.keys(this.state.teacherAssessmentRetakes).map((count, countIdx) => {
                                                                                                            const teacherAssessmentRetakes = this.state.teacherAssessmentRetakes[count]
                                                                                                            return teacherAssessmentRetakes.map((teacherAssessmentRetake, retakeIdx) => {
                                                                                                                if (teacherAssessmentRetake.teacherId !== this.state.viewingTeacherId) {
                                                                                                                    return false
                                                                                                                }
                                                                                                                return <div className={'mb-4' + (countIdx > 0 ? ' mt-50' : '')} key={'assessment-retake-' + countIdx}>
                                                                                                                    {countIdx === 0 ?
                                                                                                                        <div className='font-30 font-bold mb-3'>
                                                                                                                            {teacherAssessmentRetake.quiz.hasOwnProperty('assessmentTitle') ?
                                                                                                                                teacherAssessmentRetake.quiz.assessmentTitle :
                                                                                                                                teacherAssessmentRetake.quiz.standardName + ' ' +
                                                                                                                                (this.state.gradeDictAcrossCategories.hasOwnProperty(teacherAssessmentRetake.quiz.standardName) ?
                                                                                                                                    teacherAssessmentRetake.quiz.standardCategory + ' ' : '') +
                                                                                                                                getAssessmentDisplayName(teacherAssessmentRetake.quiz)}
                                                                                                                        </div>
                                                                                                                        : ''}
                                                                                                                    {this.state.teachersDict && this.state.teachersDict.hasOwnProperty(teacherAssessmentRetake.teacherId) ?
                                                                                                                        <PersonAvatar
                                                                                                                            person={this.state.teachersDict[teacherAssessmentRetake.teacherId]}
                                                                                                                            size={'large'}
                                                                                                                            containerClassName="font-20"
                                                                                                                            avatarClassName="font-20 font-bold mr-1"
                                                                                                                            personClassName="font-black font-24"
                                                                                                                        /> : ''}
                                                                                                                    <div className='font-24 mt-2 mb-05'>
                                                                                                                        <div className='font-bold'>{'Assessment: ' + (countIdx + 1)}</div>
                                                                                                                    </div>
                                                                                                                    <div className='font-20 text-muted mb-2'>
                                                                                                                        {teacherAssessmentRetake.hasOwnProperty('timeStamp') &&
                                                                                                                            teacherAssessmentRetake.timeStamp &&
                                                                                                                            teacherAssessmentRetake.timeStamp.hasOwnProperty('seconds') ?
                                                                                                                            moment.utc(teacherAssessmentRetake.timeStamp.seconds * 1000).format('MM/DD/YYYY')
                                                                                                                            : ''}
                                                                                                                    </div>
                                                                                                                    {teacherAssessmentRetake && this.state.students && this.state.studentsDict ?
                                                                                                                        <div>

                                                                                                                            <DOKStandardBreakdownPrint
                                                                                                                                quiz={teacherAssessmentRetake}
                                                                                                                                students={this.state.students}
                                                                                                                                studentDict={this.state.studentsDict}
                                                                                                                                onlyShowCompleted={true}
                                                                                                                                showProgress={false}
                                                                                                                            />
                                                                                                                        </div>
                                                                                                                        : ''}                                                                                            </div>
                                                                                                            })
                                                                                                        })}
                                                                                                </div>
                                                                                            </div>

                                                                                            <div>
                                                                                                {this.state.teacherAssessmentRetakes &&
                                                                                                    Object.keys(this.state.teacherAssessmentRetakes).length > 0 &&
                                                                                                    Object.keys(this.state.teacherAssessmentRetakes).map((count, countIdx) => {
                                                                                                        const teacherAssessmentRetakes = this.state.teacherAssessmentRetakes[count]
                                                                                                        return teacherAssessmentRetakes.map((teacherAssessmentRetake, retakeIdx) => {
                                                                                                            if (teacherAssessmentRetake.teacherId !== this.state.viewingTeacherId) {
                                                                                                                return false
                                                                                                            }
                                                                                                            return <div className='mt-4' key={'assessment-retake-' + countIdx}>
                                                                                                                <div className='sticky-left sticky-left-more display-table'>
                                                                                                                    {this.state.teacherAssessmentCountDict[teacherAssessmentRetake.teacherId] > 1 ?
                                                                                                                        <div className='font-20 mb-05 font-bold'>{'Assessment #' + (countIdx + 1)}</div>
                                                                                                                        :
                                                                                                                        <div className='font-20 mb-05 font-bold'>{'Assessment'}</div>
                                                                                                                    }
                                                                                                                </div>
                                                                                                                {teacherAssessmentRetake && this.state.students && this.state.studentsDict ?
                                                                                                                    <div>

                                                                                                                        <DOKStandardBreakdown
                                                                                                                            quiz={teacherAssessmentRetake}
                                                                                                                            students={this.state.students}
                                                                                                                            studentDict={this.state.studentsDict}
                                                                                                                            onlyShowCompleted={true}
                                                                                                                            showProgress={false}
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                    : ''}                                                                                            </div>
                                                                                                        })
                                                                                                    })}
                                                                                            </div>
                                                                                        </div>}
                                                                        </div>}
                                                            </div>
                                                        </Tabs.TabPane>
                                                    </Tabs>
                                                </div>
                                            </div>}
                                </div>
                                {this.state.mounted ?
                                    <div className='mt-4'>
                                        <CustomFooter />
                                    </div>
                                    : ''}

                            </Content>
                        </Content>
                    </Layout>
                </Layout>
            </div >

        );
    }
}

export default AssessmentAnalytics;
