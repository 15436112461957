import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../../styles/GlobalStyle.css'
import CustomFooter from '../../login/CustomFooter'
//import SubjectHistogram from '../../admin/SubjectHistogram'
//import SupportPieChartTiers from '../../admin/SupportPieChartTiers'
//import PersonAvatar from '../../customcomponents/PersonAvatar'
//import Empty from '../../customcomponents/Empty'
//import { getIDFromURL } from '../.././Util'
import { chunkArray, getAssessmentDisplayName, getQueryStringParam, getColorGradient } from '../.././Util'
//import { db } from '../../firebase/Firebase'
//import ColType from '../.././Types'
import { Layout, Icon, Menu, Progress, Select, Button } from 'antd'
//import PacingGuideAssessment from './PacingGuideAssessment'
import DocumentDelegator from '../../edviz/EdvizDelegator'
import PersonAvatar from '../../customcomponents/PersonAvatar'
import DOKStandardBreakdown from '../../admin/DOKStandardBreakdown'
import ReactToPrint from "react-to-print"
import moment from 'moment'
import StudentSurveyResults from '../../admin/StudentSurveyResults'
import PrintableTestAddGrade from './PrintableTestAddGrade'
import PrintableTestRemoveGrade from './PrintableTestRemoveGrade'
import HTMLAssessmentViewer from '../html/components/HTMLAssessmentViewer'
const { Content } = Layout
//var moment = require('moment')


class PrintableAssessment extends Component {
    state = {
    }

    render() {
        let assessment = this.props.assessment

        if (!assessment) {
            return ''
        }

        //console.log('assessment', assessment)

        return (
            <div>
                <div className={assessment.quiz.hasOwnProperty('htmlQuiz') ?
                    'w-850 m-lr-auto' : 'question-container background-fully-transparent'}>
                    <div>
                        {!assessment.quiz.hasOwnProperty('htmlQuiz') ?
                            <div className='flex w-100 mb-3'>
                                <ReactToPrint
                                    trigger={() =>
                                        <Button
                                            size={'large'}
                                            type='primary'
                                            className="lg-btn mr-1"
                                        >
                                            <Icon type="printer" />
                                            Print assessment
                                        </Button>
                                    }
                                    content={() => this.componentRef}
                                />
                                <PrintableTestAddGrade
                                    teacherBaseProps={this.props.teacherBaseProps}
                                    assessment={assessment}
                                />
                                <PrintableTestRemoveGrade
                                    teacherBaseProps={this.props.teacherBaseProps}
                                    assessment={assessment}
                                />
                            </div> : ''}
                    </div>

                    <div ref={el => (this.componentRef = el)}>
                        <div className='print-show'>
                            <div className='flex w-100 mb-3'>
                                <div className='flex font-20 font-black' style={{ width: '49%' }}>
                                    {assessment.quiz.assessmentTitle}
                                </div>
                                {!assessment.quiz.hasOwnProperty('htmlQuiz') ?
                                    <div className='flex' style={{ width: '49%' }}>
                                        <div className='mr-1 font-20'>Name:</div>
                                        <div className='border-bottom w-100 mb-1'></div>
                                    </div> : ''}
                            </div>
                        </div>
                        {assessment.quiz.hasOwnProperty('htmlQuiz') ?
                            <div>
                                <HTMLAssessmentViewer
                                    htmlContent={assessment.quiz.htmlQuiz}
                                    quiz={assessment}
                                    teacherBaseProps={this.props.teacherBaseProps}
                                    title={assessment.quiz.assessmentTitle}
                                    maxWidth="850px"
                                />
                            </div> : ''}
                        {assessment && !assessment.quiz.hasOwnProperty('htmlQuiz') && assessment.quiz.docs.map((lessonPlanItem, idx) => {
                            let currentQuestion = lessonPlanItem

                            return <div key={'assessment-idx-' + idx}>
                                <div className='print-hide'>
                                    <div className='font-black font-20 ant-shadow br-15 p-4 mb-50'>
                                        <div className='font-24 mb-2 font-bold'>Question {idx + 1}</div>
                                        <DocumentDelegator
                                            document={lessonPlanItem}
                                        />
                                    </div>
                                </div>

                                <div className='print-show'>
                                    <div className='font-black font-20 mb-50 mt-4 page-break-inside-avoid'>
                                        <div className='font-20 mb-2 font-bold'>Question {idx + 1}</div>
                                        <div className='mb-1 font-20'>
                                            <DocumentDelegator
                                                document={{
                                                    components: currentQuestion.components[0].question,
                                                    assessment: assessment.quiz,
                                                    metadata: currentQuestion.metadata
                                                }}
                                            />
                                        </div>

                                        <div className='w-100 mt-2 page-break-inside-avoid'>
                                            {currentQuestion.components[0].answers.map((choice, choiceIndex) => {
                                                let choiceLetter = 'A'
                                                const choiceIndexPlusOne = choiceIndex + 1
                                                if (choiceIndexPlusOne === 2) {
                                                    choiceLetter = 'B'
                                                } else if (choiceIndexPlusOne === 3) {
                                                    choiceLetter = 'C'
                                                } else if (choiceIndexPlusOne === 4) {
                                                    choiceLetter = 'D'
                                                }
                                                return <div className='flex w-100 mt-1'
                                                    key={assessment.quiz.questionIdx + '-' + choiceIndex}
                                                >
                                                    <div className='mt-1'>
                                                        <div className='flex flex-v-center'>
                                                            <span className='mr-1'>{choiceLetter}.</span>
                                                            <div style={{ width: '32px', height: '32px' }}
                                                                className='br-4 border mr-2'></div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className='flex assessment-radio p-2 border br-4 w-100'
                                                        value={choiceIndex}
                                                        size='large'
                                                    >
                                                        <div className='flex ml-05 p-mb-0 white-space-normal'>
                                                            <DocumentDelegator
                                                                document={{
                                                                    components: choice.choice,
                                                                    assessment: assessment.quiz,
                                                                    metadata: currentQuestion.metadata
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        })}
                    </div>
                </div>
            </div>
        )
    }
}

export default PrintableAssessment
