export const graphicOrganizerEmpty_ela_3_aor_6_1_a = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Summarize the Adventure - Story Summary Chart</title>
    <style>
        body {
            font-family: 'Comic Sans MS', cursive, sans-serif;
            background-color: #f5f5f5;
            margin: 0;
            padding: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .container {
            max-width: 850px;
            width: 100%;
            background-color: white;
            padding: 25px;
            border-radius: 15px;
            box-shadow: 0 4px 8px rgba(0,0,0,0.1);
        }
        h1 {
            color: #233142;
            text-align: center;
            margin-bottom: 5px;
        }
        .subtitle {
            color: #666;
            text-align: center;
            font-style: italic;
            margin-bottom: 20px;
        }
        .instructions {
            background-color: #d9eeff;
            padding: 15px;
            border-radius: 10px;
            margin: 15px 0;
            box-shadow: 0 2px 4px rgba(0,0,0,0.1);
        }
        .step {
            display: flex;
            margin-bottom: 5px;
        }
        .step-number {
            background-color: #233142;
            color: white;
            width: 25px;
            height: 25px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
            font-weight: bold;
        }
        .organizer-sheet {
            margin-top: 20px;
            padding: 15px;
            border: 1px solid #ddd;
            border-radius: 10px;
        }
        .organizer-row {
            margin-bottom: 40px;
            page-break-inside: avoid;
        }
        .field {
            margin-bottom: 20px;
        }
        .field-label {
            font-weight: bold;
            color: #233142;
            margin-bottom: 5px;
        }
        .input-field {
            width: 100%;
            padding: 8px;
            margin-bottom: 5px;
            border: 1px solid #aaa;
            border-radius: 5px;
            font-family: 'Comic Sans MS', cursive, sans-serif;
            font-size: 16px;
            background-color: #ffffff;
        }
        
        .input-field:focus {
            outline: none;
            border-color: #233142;
            box-shadow: 0 0 3px rgba(35, 49, 66, 0.5);
        }
        
        .text-area {
            width: 100%;
            padding: 8px;
            margin-bottom: 5px;
            border: 1px solid #aaa;
            border-radius: 5px;
            font-family: 'Comic Sans MS', cursive, sans-serif;
            font-size: 16px;
            min-height: 60px;
            resize: vertical;
        }
        
        .text-area:focus {
            outline: none;
            border-color: #233142;
            box-shadow: 0 0 3px rgba(35, 49, 66, 0.5);
        }
        .section-box {
            border: 2px solid #233142;
            border-radius: 10px;
            padding: 15px;
            margin-bottom: 20px;
            background-color: white;
            width: 100%;
            box-sizing: border-box;
        }
        .section-title {
            font-weight: bold;
            color: #233142;
            border-bottom: 1px solid #233142;
            padding-bottom: 5px;
            margin-bottom: 10px;
        }
        .boxed-content {
            padding: 10px;
            background-color: #f9f9f9;
            border-radius: 8px;
            border: 1px solid #ddd;
            margin-bottom: 15px;
            width: 100%;
            box-sizing: border-box;
        }
        .highlight-area {
            background-color: #fff8dc;
        }
        
        .highlight-input {
            background-color: #fff8dc;
        }
        
        .form-container {
            width: 100%;
        }
        .organizer-grid {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 15px;
            margin-top: 20px;
            width: 100%;
            box-sizing: border-box;
        }
        @media print {
            body {
                background-color: white;
                padding: 0;
                margin: 0;
            }
            .container {
                box-shadow: none;
                padding: 15px;
                max-width: 100%;
            }
            .instructions {
                border: 1px solid #ccc;
                box-shadow: none;
            }
            .organizer-row, .section-box {
                page-break-inside: avoid;
            }
            .organizer-sheet {
                border: none;
            }
            @page {
                margin: 1cm;
            }
        }
    </style>
</head>
<body>
    <div class="container">
        <h1>Summarize the Adventure</h1>
        <div class="subtitle">Story Summary Chart</div>
        
        <div class="instructions">
            <h3>Directions:</h3>
            <div class="step">
                <div class="step-number">1</div>
                <div>Read the story carefully, thinking about the main characters, setting, and events.</div>
            </div>
            <div class="step">
                <div class="step-number">2</div>
                <div>Fill in each section of the graphic organizer with information from the story.</div>
            </div>
            <div class="step">
                <div class="step-number">3</div>
                <div>Use the information you've collected to write a complete summary in the final section.</div>
            </div>
            <div class="step">
                <div class="step-number">4</div>
                <div>Remember that a good summary includes the most important elements but not every small detail.</div>
            </div>
        </div>
        
        <form class="form-container">
            <div class="organizer-sheet">
                <!-- Characters and Setting Section -->
                <div class="section-box">
                    <div class="section-title">Characters and Setting</div>
                    
                    <div class="field">
                        <div class="field-label">Who are the main characters?</div>
                        <input type="text" class="input-field" name="characters" placeholder="List the main characters in the story...">
                    </div>
                    
                    <div class="field">
                        <div class="field-label">Where and when does the story take place?</div>
                        <input type="text" class="input-field" name="setting" placeholder="Describe the setting (place and time)...">
                    </div>
                </div>
                
                <!-- Plot Section -->
                <div class="section-box">
                    <div class="section-title">Plot (Main Events)</div>
                    
                    <div class="field">
                        <div class="field-label">What happens at the beginning?</div>
                        <textarea class="text-area" name="beginning" placeholder="Describe how the story starts..."></textarea>
                    </div>
                    
                    <div class="field">
                        <div class="field-label">What are the key events in the middle?</div>
                        <textarea class="text-area" name="middle" placeholder="List the most important things that happen in the middle..."></textarea>
                    </div>
                    
                    <div class="field">
                        <div class="field-label">What happens at the end?</div>
                        <textarea class="text-area" name="end" placeholder="Describe how the story ends..."></textarea>
                    </div>
                </div>
                
                <!-- Key Details Section -->
                <div class="section-box">
                    <div class="section-title">Key Details</div>
                    
                    <div class="field">
                        <div class="field-label">List three important facts or details that help you understand the story better:</div>
                        <input type="text" class="input-field" name="detail1" placeholder="Important detail #1...">
                        <input type="text" class="input-field" name="detail2" placeholder="Important detail #2...">
                        <input type="text" class="input-field" name="detail3" placeholder="Important detail #3...">
                    </div>
                </div>
                
                <!-- Theme Section -->
                <div class="section-box">
                    <div class="section-title">Theme/Moral/Lesson</div>
                    
                    <div class="field">
                        <div class="field-label">What important idea, lesson, or theme did Mia and Jake's adventure teach you?</div>
                        <textarea class="text-area" name="theme" placeholder="Write the main lesson or message of the story..."></textarea>
                    </div>
                </div>
                
                <!-- Summary Section -->
                <div class="section-box">
                    <div class="section-title">Your Summary</div>
                    
                    <div class="field">
                        <div class="field-label">Using the information you filled in above, write one or two sentences in your own words summarizing the whole story:</div>
                        <textarea class="text-area highlight-input" name="final_summary" placeholder="Write your complete summary here..."></textarea>
                    </div>
                </div>
            </div>
        </form>
    </div>
</body>
</html>`;

export const graphicOrganizerFilled_ela_3_aor_6_1_a = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Summarize the Adventure - Story Summary Chart</title>
    <style>
        body {
            font-family: 'Comic Sans MS', cursive, sans-serif;
            background-color: #f5f5f5;
            margin: 0;
            padding: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .container {
            max-width: 850px;
            width: 100%;
            background-color: white;
            padding: 25px;
            border-radius: 15px;
            box-shadow: 0 4px 8px rgba(0,0,0,0.1);
        }
        h1 {
            color: #233142;
            text-align: center;
            margin-bottom: 5px;
        }
        .subtitle {
            color: #666;
            text-align: center;
            font-style: italic;
            margin-bottom: 20px;
        }
        .instructions {
            background-color: #d9eeff;
            padding: 15px;
            border-radius: 10px;
            margin: 15px 0;
            box-shadow: 0 2px 4px rgba(0,0,0,0.1);
        }
        .step {
            display: flex;
            margin-bottom: 5px;
        }
        .step-number {
            background-color: #233142;
            color: white;
            width: 25px;
            height: 25px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
            font-weight: bold;
        }
        .organizer-sheet {
            margin-top: 20px;
            padding: 15px;
            border: 1px solid #ddd;
            border-radius: 10px;
        }
        .organizer-row {
            margin-bottom: 40px;
            page-break-inside: avoid;
        }
        .field {
            margin-bottom: 20px;
        }
        .field-label {
            font-weight: bold;
            color: #233142;
            margin-bottom: 5px;
        }
        .input-field {
            width: 100%;
            padding: 8px;
            margin-bottom: 5px;
            border: 1px solid #aaa;
            border-radius: 5px;
            font-family: 'Comic Sans MS', cursive, sans-serif;
            font-size: 16px;
            background-color: #ffffff;
        }
        
        .input-field:focus {
            outline: none;
            border-color: #233142;
            box-shadow: 0 0 3px rgba(35, 49, 66, 0.5);
        }
        
        .text-area {
            width: 100%;
            padding: 8px;
            margin-bottom: 5px;
            border: 1px solid #aaa;
            border-radius: 5px;
            font-family: 'Comic Sans MS', cursive, sans-serif;
            font-size: 16px;
            min-height: 60px;
            resize: vertical;
        }
        
        .text-area:focus {
            outline: none;
            border-color: #233142;
            box-shadow: 0 0 3px rgba(35, 49, 66, 0.5);
        }
        .section-box {
            border: 2px solid #233142;
            border-radius: 10px;
            padding: 15px;
            margin-bottom: 20px;
            background-color: white;
            width: 100%;
            box-sizing: border-box;
        }
        .section-title {
            font-weight: bold;
            color: #233142;
            border-bottom: 1px solid #233142;
            padding-bottom: 5px;
            margin-bottom: 10px;
        }
        .boxed-content {
            padding: 10px;
            background-color: #f9f9f9;
            border-radius: 8px;
            border: 1px solid #ddd;
            margin-bottom: 15px;
            width: 100%;
            box-sizing: border-box;
        }
        .highlight-area {
            background-color: #fff8dc;
        }
        
        .highlight-input {
            background-color: #fff8dc;
        }
        
        .form-container {
            width: 100%;
        }
        .organizer-grid {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 15px;
            margin-top: 20px;
            width: 100%;
            box-sizing: border-box;
        }
        @media print {
            body {
                background-color: white;
                padding: 0;
                margin: 0;
            }
            .container {
                box-shadow: none;
                padding: 15px;
                max-width: 100%;
            }
            .instructions {
                border: 1px solid #ccc;
                box-shadow: none;
            }
            .organizer-row, .section-box {
                page-break-inside: avoid;
            }
            .organizer-sheet {
                border: none;
            }
            @page {
                margin: 1cm;
            }
        }
    </style>
</head>
<body>
    <div class="container">
        <h1>Summarize the Adventure</h1>
        <div class="subtitle">Story Summary Chart</div>
        
        <div class="instructions">
            <h3>Directions:</h3>
            <div class="step">
                <div class="step-number">1</div>
                <div>Read the story carefully, thinking about the main characters, setting, and events.</div>
            </div>
            <div class="step">
                <div class="step-number">2</div>
                <div>Fill in each section of the graphic organizer with information from the story.</div>
            </div>
            <div class="step">
                <div class="step-number">3</div>
                <div>Use the information you've collected to write a complete summary in the final section.</div>
            </div>
            <div class="step">
                <div class="step-number">4</div>
                <div>Remember that a good summary includes the most important elements but not every small detail.</div>
            </div>
        </div>
        
        <form class="form-container">
            <div class="organizer-sheet">
                <!-- Characters and Setting Section -->
                <div class="section-box">
                    <div class="section-title">Characters and Setting</div>
                    
                    <div class="field">
                        <div class="field-label">Who are the main characters?</div>
                        <input type="text" class="input-field" name="characters" value="Mia and her little brother Jake">
                    </div>
                    
                    <div class="field">
                        <div class="field-label">Where and when does the story take place?</div>
                        <input type="text" class="input-field" name="setting" value="Sunshine Pond in Willow Woods on a bright Saturday afternoon in spring">
                    </div>
                </div>
                
                <!-- Plot Section -->
                <div class="section-box">
                    <div class="section-title">Plot (Main Events)</div>
                    
                    <div class="field">
                        <div class="field-label">What happens at the beginning?</div>
                        <textarea class="text-area" name="beginning">Mia and Jake set out on an adventure to Sunshine Pond because they heard animals were waking up from winter sleep.</textarea>
                    </div>
                    
                    <div class="field">
                        <div class="field-label">What are the key events in the middle?</div>
                        <textarea class="text-area" name="middle">They see animals like ducklings, turtles, frogs, and butterflies at the pond. Jake accidentally drops his sandwich, and a hungry duckling eats the crumbs. Mia makes sketches of the animals they observed.</textarea>
                    </div>
                    
                    <div class="field">
                        <div class="field-label">What happens at the end?</div>
                        <textarea class="text-area" name="end">As evening approaches, they pack up their belongings. On the way home, Jake asks if they can return soon, and Mia says they will visit often. They laugh and plan their next adventure.</textarea>
                    </div>
                </div>
                
                <!-- Key Details Section -->
                <div class="section-box">
                    <div class="section-title">Key Details</div>
                    
                    <div class="field">
                        <div class="field-label">List three important facts or details that help you understand the story better:</div>
                        <input type="text" class="input-field" name="detail1" value="The animals had recently awakened from their winter sleep, showing it was springtime.">
                        <input type="text" class="input-field" name="detail2" value="Jake shared his sandwich with a duckling, showing he was kind to animals.">
                        <input type="text" class="input-field" name="detail3" value="Mia made sketches of the animals, showing she appreciates nature through art.">
                    </div>
                </div>
                
                <!-- Theme Section -->
                <div class="section-box">
                    <div class="section-title">Theme/Moral/Lesson</div>
                    
                    <div class="field">
                        <div class="field-label">What important idea, lesson, or theme did Mia and Jake's adventure teach you?</div>
                        <textarea class="text-area" name="theme">The story teaches us to appreciate and enjoy nature and its creatures. It also shows the importance of spending time with family and sharing experiences together outdoors.</textarea>
                    </div>
                </div>
                
                <!-- Summary Section -->
                <div class="section-box">
                    <div class="section-title">Your Summary</div>
                    
                    <div class="field">
                        <div class="field-label">Using the information you filled in above, write one or two sentences in your own words summarizing the whole story:</div>
                        <textarea class="text-area highlight-input" name="final_summary">Mia and Jake visit Sunshine Pond on a spring afternoon where they observe animals, share a sandwich with a duckling, and Mia sketches the wildlife. After enjoying their day in nature together, they head home while planning to return soon for another adventure.</textarea>
                    </div>
                </div>
            </div>
        </form>
    </div>
</body>
</html>`;

export const graphicOrganizerPrintable_ela_3_aor_6_1_a = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Summarize the Adventure - Story Summary Chart</title>
    <style>
        body {
            font-family: 'Comic Sans MS', cursive, sans-serif;
            background-color: #f5f5f5;
            margin: 0;
            padding: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .container {
            max-width: 850px;
            width: 100%;
            background-color: white;
            padding: 25px;
            border-radius: 15px;
            box-shadow: 0 4px 8px rgba(0,0,0,0.1);
        }
        h1 {
            color: #233142;
            text-align: center;
            margin-bottom: 5px;
        }
        .subtitle {
            color: #666;
            text-align: center;
            font-style: italic;
            margin-bottom: 20px;
        }
        .instructions {
            background-color: #d9eeff;
            padding: 15px;
            border-radius: 10px;
            margin: 15px 0;
            box-shadow: 0 2px 4px rgba(0,0,0,0.1);
        }
        .step {
            display: flex;
            margin-bottom: 5px;
        }
        .step-number {
            background-color: #233142;
            color: white;
            width: 25px;
            height: 25px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
            font-weight: bold;
        }
        .organizer-sheet {
            margin-top: 20px;
            padding: 15px;
            border: 1px solid #ddd;
            border-radius: 10px;
        }
        .organizer-row {
            margin-bottom: 40px;
            page-break-inside: avoid;
        }
        .field {
            margin-bottom: 20px;
        }
        .field-label {
            font-weight: bold;
            color: #233142;
            margin-bottom: 5px;
        }
        .writing-area {
            width: 100%;
            min-height: 35px;
            border: 1px solid #aaa;
            border-radius: 5px;
            margin-bottom: 5px;
            background-color: #ffffff;
            position: relative;
            box-sizing: border-box;
        }
        .writing-lines {
            width: 100%;
            min-height: 80px;
            border: 1px solid #aaa;
            border-radius: 5px;
            margin-bottom: 5px;
            background-color: #ffffff;
            background-image: linear-gradient(#e5e5e5 1px, transparent 1px);
            background-size: 100% 25px;
            position: relative;
            box-sizing: border-box;
        }
        .section-box {
            border: 2px solid #233142;
            border-radius: 10px;
            padding: 15px;
            margin-bottom: 20px;
            background-color: white;
            width: 100%;
            box-sizing: border-box;
        }
        .section-title {
            font-weight: bold;
            color: #233142;
            border-bottom: 1px solid #233142;
            padding-bottom: 5px;
            margin-bottom: 10px;
        }
        .highlight-area {
            background-color: #fff8dc;
        }
        
        .highlight-writing-area {
            background-color: #fff8dc;
            min-height: 120px;
        }
        
        /* Print styles */
        @media print {
            body {
                background-color: white;
                padding: 0;
                margin: 0;
            }
            .container {
                box-shadow: none;
                padding: 15px;
                max-width: 100%;
            }
            .instructions {
                border: 1px solid #ccc;
                box-shadow: none;
            }
            .organizer-row, .section-box {
                page-break-inside: avoid;
            }
            .organizer-sheet {
                border: none;
            }
            @page {
                margin: 1cm;
            }
        }
    </style>
</head>
<body>
    <div class="container">
        <h1>Summarize the Adventure</h1>
        <div class="subtitle">Story Summary Chart</div>
        
        <div class="instructions">
            <h3>Directions:</h3>
            <div class="step">
                <div class="step-number">1</div>
                <div>Read the story carefully, thinking about the main characters, setting, and events.</div>
            </div>
            <div class="step">
                <div class="step-number">2</div>
                <div>Fill in each section of the graphic organizer with information from the story.</div>
            </div>
            <div class="step">
                <div class="step-number">3</div>
                <div>Use the information you've collected to write a complete summary in the final section.</div>
            </div>
            <div class="step">
                <div class="step-number">4</div>
                <div>Remember that a good summary includes the most important elements but not every small detail.</div>
            </div>
        </div>
        
        <div class="organizer-sheet">
            <!-- Characters and Setting Section -->
            <div class="section-box">
                <div class="section-title">Characters and Setting</div>
                
                <div class="field">
                    <div class="field-label">Who are the main characters?</div>
                    <div class="writing-area"></div>
                </div>
                
                <div class="field">
                    <div class="field-label">Where and when does the story take place?</div>
                    <div class="writing-area"></div>
                </div>
            </div>
            
            <!-- Plot Section -->
            <div class="section-box">
                <div class="section-title">Plot (Main Events)</div>
                
                <div class="field">
                    <div class="field-label">What happens at the beginning?</div>
                    <div class="writing-lines"></div>
                </div>
                
                <div class="field">
                    <div class="field-label">What are the key events in the middle?</div>
                    <div class="writing-lines"></div>
                </div>
                
                <div class="field">
                    <div class="field-label">What happens at the end?</div>
                    <div class="writing-lines"></div>
                </div>
            </div>
            
            <!-- Key Details Section -->
            <div class="section-box">
                <div class="section-title">Key Details</div>
                
                <div class="field">
                    <div class="field-label">List three important facts or details that help you understand the story better:</div>
                    <div class="writing-area" style="margin-bottom: 10px;"></div>
                    <div class="writing-area" style="margin-bottom: 10px;"></div>
                    <div class="writing-area"></div>
                </div>
            </div>
            
            <!-- Theme Section -->
            <div class="section-box">
                <div class="section-title">Theme/Moral/Lesson</div>
                
                <div class="field">
                    <div class="field-label">What important idea, lesson, or theme did Mia and Jake's adventure teach you?</div>
                    <div class="writing-lines"></div>
                </div>
            </div>
            
            <!-- Summary Section -->
            <div class="section-box">
                <div class="section-title">Your Summary</div>
                
                <div class="field">
                    <div class="field-label">Using the information you filled in above, write one or two sentences in your own words summarizing the whole story:</div>
                    <div class="writing-lines highlight-area"></div>
                </div>
            </div>
        </div>
    </div>
</body>
</html>`;

export const studentResource_ela_3_aor_6_1_a = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>An Adventure at Sunshine Pond - Story Summarizing</title>
    <style>
        body {
            font-family: 'Comic Sans MS', cursive, sans-serif;
            background-color: #f5f5f5;
            margin: 0;
            padding: 20px;
            display: flex;
            justify-content: center;
        }
        .container {
            max-width: 800px;
            width: 100%;
            background-color: white;
            padding: 25px;
            border-radius: 15px;
            box-shadow: 0 4px 8px rgba(0,0,0,0.1);
        }
        h1 {
            color: #233142;
            text-align: center;
            border-bottom: 2px solid #233142;
            padding-bottom: 10px;
        }
        .subtitle {
            color: #666;
            text-align: center;
            font-style: italic;
            margin-top: -10px;
            margin-bottom: 20px;
        }
        p {
            line-height: 1.6;
            font-size: 16px;
            margin-bottom: 15px;
        }
        .highlight-word {
            font-weight: bold;
            background-color: #fff8dc;
            padding: 0 3px;
            border-radius: 3px;
        }
        .vocabulary {
            background-color: #e8f7ee;
            padding: 15px;
            border-radius: 8px;
            margin-bottom: 20px;
            border: 1px dashed #3fa672;
        }
        .vocabulary-title {
            font-weight: bold;
            color: #233142;
            margin-bottom: 5px;
        }
        .instructions {
            background-color: #d9eeff;
            padding: 15px;
            border-radius: 8px;
            margin: 20px 0;
            box-shadow: 0 2px 4px rgba(0,0,0,0.05);
        }
        .fun-fact {
            background-color: #fff8dc;
            padding: 15px;
            margin: 15px 0;
            border-radius: 8px;
            border: 1px solid #ddd;
        }
        .fun-fact-title {
            font-weight: bold;
            color: #233142;
            margin-bottom: 5px;
        }
        .story-image {
            text-align: center;
            margin: 15px 0;
        }
        .story-image img {
            max-width: 100%;
            border-radius: 8px;
            box-shadow: 0 2px 4px rgba(0,0,0,0.1);
        }
        @media print {
            body {
                background-color: white;
                padding: 0;
                margin: 0;
            }
            .container {
                box-shadow: none;
                padding: 0;
                max-width: 100%;
            }
            .instructions, .vocabulary, .fun-fact {
                border: 1px solid #ccc;
                box-shadow: none;
            }
            @page {
                margin: 1cm;
            }
        }
    </style>
</head>
<body>
    <div class="container">
        <h1>An Adventure at Sunshine Pond</h1>
        <div class="subtitle">A Spring Story to Summarize</div>
        
        <div class="instructions">
            <h3>Reading Instructions:</h3>
            <p>As you read this story, think about the <span class="highlight-word">main events</span>, <span class="highlight-word">characters</span>, and <span class="highlight-word">setting</span>. After reading, you'll practice summarizing what happened.</p>
        </div>
        
        <p>One bright Saturday afternoon, Mia and her little brother Jake set out on an adventure through Willow Woods. They decided to visit Sunshine Pond, their favorite spot, because Mia heard it was home to many creatures that had recently awakened from their winter sleep.</p>
        
        <div class="fun-fact">
            <div class="fun-fact-title">Spring Awakening!</div>
            <p>Many animals hibernate or become less active during winter. When spring arrives with warmer weather, these animals become active again!</p>
        </div>
        
        <p>At the pond, they saw ducklings splashing in the water, turtles sunbathing on logs, and butterflies fluttering nearby. Jake said, "Look, Mia! There are frogs hopping everywhere. They must love spring!"</p>
        
        <p>As the children carefully watched the frogs, suddenly Jake slipped and dropped his sandwich on the grass. Quickly, a curious, hungry duckling waddled over and began nibbling Jake's sandwich crusts.</p>
        
        <p>Jake laughed, "Now we've both had a snack, little duckling!" He patted his belly, giggling as the duckling gobbled up the crumbs.</p>
        
        <p>Afterwards, Mia sketched <span class="highlight-word">drawings</span> of the animals they had seen. When the afternoon faded into evening and the sky turned shades of gold and pink, the siblings packed up their belongings.</p>
        
        <p>Walking home, Jake asked, "Will we come back soon, Mia?"</p>
        
        <p>Mia smiled and replied, "Of course, Jake. We'll visit often, just like the ducklings!"</p>
        
        <p>Together they laughed, already planning their next adventure.</p>
        
        <div class="fun-fact">
            <div class="fun-fact-title">Summarizing Tip!</div>
            <p>When you summarize a story, you tell the most important parts in a shorter way. You don't need to include every single detail!</p>
        </div>
        
        <div class="vocabulary">
            <div class="vocabulary-title">Story Elements to Think About:</div>
            <strong>Characters</strong> - Who is in the story?<br>
            <strong>Setting</strong> - Where and when does the story take place?<br>
            <strong>Plot</strong> - What are the main events that happen?<br>
            <strong>Theme</strong> - What lesson or message does the story teach us?
        </div>
    </div>
</body>
</html>`;

export const teacherResource_ela_3_aor_6_1_a = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>An Adventure at Sunshine Pond - Story Summarizing</title>
    <style>
        body {
            font-family: 'Comic Sans MS', cursive, sans-serif;
            background-color: #f5f5f5;
            margin: 0;
            padding: 20px;
            display: flex;
            justify-content: center;
        }
        .container {
            max-width: 800px;
            width: 100%;
            background-color: white;
            padding: 25px;
            border-radius: 15px;
            box-shadow: 0 4px 8px rgba(0,0,0,0.1);
        }
        h1 {
            color: #233142;
            text-align: center;
            border-bottom: 2px solid #233142;
            padding-bottom: 10px;
        }
        .subtitle {
            color: #666;
            text-align: center;
            font-style: italic;
            margin-top: -10px;
            margin-bottom: 20px;
        }
        p {
            line-height: 1.6;
            font-size: 16px;
            margin-bottom: 15px;
        }
        .highlight-word {
            font-weight: bold;
            background-color: #fff8dc;
            padding: 0 3px;
            border-radius: 3px;
        }
        .vocabulary {
            background-color: #e8f7ee;
            padding: 15px;
            border-radius: 8px;
            margin-bottom: 20px;
            border: 1px dashed #3fa672;
        }
        .vocabulary-title {
            font-weight: bold;
            color: #233142;
            margin-bottom: 5px;
        }
        .instructions {
            background-color: #d9eeff;
            padding: 15px;
            border-radius: 8px;
            margin: 20px 0;
            box-shadow: 0 2px 4px rgba(0,0,0,0.05);
        }
        .fun-fact {
            background-color: #fff8dc;
            padding: 15px;
            margin: 15px 0;
            border-radius: 8px;
            border: 1px solid #ddd;
        }
        .fun-fact-title {
            font-weight: bold;
            color: #233142;
            margin-bottom: 5px;
        }
        .story-image {
            text-align: center;
            margin: 15px 0;
        }
        .story-image img {
            max-width: 100%;
            border-radius: 8px;
            box-shadow: 0 2px 4px rgba(0,0,0,0.1);
        }
        @media print {
            body {
                background-color: white;
                padding: 0;
                margin: 0;
            }
            .container {
                box-shadow: none;
                padding: 0;
                max-width: 100%;
            }
            .instructions, .vocabulary, .fun-fact {
                border: 1px solid #ccc;
                box-shadow: none;
            }
            @page {
                margin: 1cm;
            }
        }
    </style>
</head>
<body>
    <div class="container">
        <h1>An Adventure at Sunshine Pond</h1>
        <div class="subtitle">A Spring Story to Summarize</div>
        
        <div class="instructions">
            <h3>Reading Instructions:</h3>
            <p>As you read this story, think about the <span class="highlight-word">main events</span>, <span class="highlight-word">characters</span>, and <span class="highlight-word">setting</span>. After reading, you'll practice summarizing what happened.</p>
        </div>
        
        <p>One bright Saturday afternoon, Mia and her little brother Jake set out on an adventure through Willow Woods. They decided to visit Sunshine Pond, their favorite spot, because Mia heard it was home to many creatures that had recently awakened from their winter sleep.</p>
        
        <div class="fun-fact">
            <div class="fun-fact-title">Spring Awakening!</div>
            <p>Many animals hibernate or become less active during winter. When spring arrives with warmer weather, these animals become active again!</p>
        </div>
        
        <p>At the pond, they saw ducklings splashing in the water, turtles sunbathing on logs, and butterflies fluttering nearby. Jake said, "Look, Mia! There are frogs hopping everywhere. They must love spring!"</p>
        
        <p>As the children carefully watched the frogs, suddenly Jake slipped and dropped his sandwich on the grass. Quickly, a curious, hungry duckling waddled over and began nibbling Jake's sandwich crusts.</p>
        
        <p>Jake laughed, "Now we've both had a snack, little duckling!" He patted his belly, giggling as the duckling gobbled up the crumbs.</p>
        
        <p>Afterwards, Mia sketched <span class="highlight-word">drawings</span> of the animals they had seen. When the afternoon faded into evening and the sky turned shades of gold and pink, the siblings packed up their belongings.</p>
        
        <p>Walking home, Jake asked, "Will we come back soon, Mia?"</p>
        
        <p>Mia smiled and replied, "Of course, Jake. We'll visit often, just like the ducklings!"</p>
        
        <p>Together they laughed, already planning their next adventure.</p>
        
        <div class="fun-fact">
            <div class="fun-fact-title">Summarizing Tip!</div>
            <p>When you summarize a story, you tell the most important parts in a shorter way. You don't need to include every single detail!</p>
        </div>
        
        <div class="vocabulary">
            <div class="vocabulary-title">Story Elements to Think About:</div>
            <strong>Characters</strong> - Who is in the story?<br>
            <strong>Setting</strong> - Where and when does the story take place?<br>
            <strong>Plot</strong> - What are the main events that happen?<br>
            <strong>Theme</strong> - What lesson or message does the story teach us?
        </div>
        
        <!-- Teacher's Notes Section -->
        <div style="margin-top: 40px; border-top: 2px dashed #233142; padding-top: 20px;">
            <h3 style="color: #233142;">Teacher's Notes</h3>
            <p>This section is intended for teacher reference only. Consider printing student copies without this section.</p>
            
            <div style="background-color: #e8f7ee; padding: 15px; border-radius: 8px; margin: 15px 0; border: 1px dashed #3fa672;">
                <h4 style="color: #2e7d32; margin-top: 0;">Lesson Objectives:</h4>
                <ul>
                    <li>Students will practice summarizing a literary text to enhance their comprehension.</li>
                    <li>Students will identify the plot, theme, and key details of the story.</li>
                </ul>
            </div>
            
            <div style="background-color: #d9eeff; padding: 15px; border-radius: 8px; margin: 15px 0;">
                <h4 style="color: #233142; margin-top: 0;">Discussion Questions:</h4>
                <ol>
                    <li>Who are the main characters in the story? Where do they go?</li>
                    <li>Can you summarize what happens at Sunshine Pond?</li>
                    <li>What important details help you understand the story better?</li>
                    <li>What lesson or theme can we learn from Mia and Jake's adventure?</li>
                </ol>
            </div>
            
            <div style="background-color: #fff8dc; padding: 15px; border-radius: 8px; margin: 15px 0; border: 1px solid #ddd;">
                <h4 style="color: #233142; margin-top: 0;">Teacher Guidance:</h4>
                <ul>
                    <li>After reading, students should practice summarizing the story in their own words, including main events, characters, and setting.</li>
                    <li>Guide students in identifying the clear theme: enjoying nature, exploring thoughtfully, friendship, or caring for animals.</li>
                    <li>Encourage students to grasp the difference between retelling every detail and summarizing what is most important (key details, main characters, plot, and theme).</li>
                </ul>
            </div>
        </div>
    </div>
</body>
</html>`;