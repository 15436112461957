export const graphicOrganizerEmpty_ela_3_aor_5_1 = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Sunny Pond Step-by-Step Analysis Map</title>
    <style>
        body {
            font-family: 'Comic Sans MS', cursive, sans-serif;
            background-color: #f5f5f5;
            margin: 0;
            padding: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .container {
            max-width: 850px;
            width: 100%;
            background-color: white;
            padding: 25px;
            border-radius: 15px;
            box-shadow: 0 4px 8px rgba(0,0,0,0.1);
        }
        h1 {
            color: #233142;
            text-align: center;
            margin-bottom: 5px;
        }
        .subtitle {
            color: #666;
            text-align: center;
            font-style: italic;
            margin-bottom: 20px;
        }
        .instructions {
            background-color: #d9eeff;
            padding: 15px;
            border-radius: 10px;
            margin: 15px 0;
            box-shadow: 0 2px 4px rgba(0,0,0,0.1);
        }
        .step {
            display: flex;
            margin-bottom: 5px;
        }
        .step-number {
            background-color: #233142;
            color: white;
            width: 25px;
            height: 25px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
            font-weight: bold;
        }
        .organizer-sheet {
            margin-top: 20px;
            padding: 15px;
            border: 1px solid #ddd;
            border-radius: 10px;
        }
        .organizer-row {
            margin-bottom: 40px;
            page-break-inside: avoid;
        }
        .field {
            margin-bottom: 20px;
        }
        .field-label {
            font-weight: bold;
            color: #233142;
            margin-bottom: 5px;
        }
        .input-field {
            width: 100%;
            padding: 8px;
            margin-bottom: 5px;
            border: 1px solid #aaa;
            border-radius: 5px;
            font-family: 'Comic Sans MS', cursive, sans-serif;
            font-size: 16px;
            background-color: #ffffff;
        }
        
        .input-field:focus {
            outline: none;
            border-color: #233142;
            box-shadow: 0 0 3px rgba(35, 49, 66, 0.5);
        }
        
        .text-area {
            width: 100%;
            padding: 8px;
            margin-bottom: 5px;
            border: 1px solid #aaa;
            border-radius: 5px;
            font-family: 'Comic Sans MS', cursive, sans-serif;
            font-size: 16px;
            min-height: 60px;
            resize: vertical;
        }
        
        .text-area:focus {
            outline: none;
            border-color: #233142;
            box-shadow: 0 0 3px rgba(35, 49, 66, 0.5);
        }
        .section-box {
            border: 2px solid #233142;
            border-radius: 10px;
            padding: 15px;
            margin-bottom: 20px;
            background-color: white;
            width: 100%;
            box-sizing: border-box;
        }
        .section-title {
            font-weight: bold;
            color: #233142;
            border-bottom: 1px solid #233142;
            padding-bottom: 5px;
            margin-bottom: 10px;
        }
        .boxed-content {
            padding: 10px;
            background-color: #f9f9f9;
            border-radius: 8px;
            border: 1px solid #ddd;
            margin-bottom: 15px;
            width: 100%;
            box-sizing: border-box;
        }
        .highlight-area {
            background-color: #fff8dc;
        }
        
        .highlight-input {
            background-color: #fff8dc;
        }
        
        .form-container {
            width: 100%;
        }
        .paragraph-icon {
            display: inline-block;
            margin-right: 5px;
            font-size: 20px;
        }
        .paragraph-number {
            font-weight: bold;
            color: #233142;
            font-size: 18px;
            margin-bottom: 5px;
        }
        @media print {
            body {
                background-color: white;
                padding: 0;
                margin: 0;
            }
            .container {
                box-shadow: none;
                padding: 15px;
                max-width: 100%;
            }
            .instructions {
                border: 1px solid #ccc;
                box-shadow: none;
            }
            .organizer-row, .section-box {
                page-break-inside: avoid;
            }
            .organizer-sheet {
                border: none;
            }
            @page {
                margin: 1cm;
            }
        }
    </style>
</head>
<body>
    <div class="container">
        <h1>Sunny Pond Step-by-Step Analysis Map</h1>
        <div class="subtitle">Analyzing how paragraphs build on each other in "The Secret of Sunny Pond"</div>
        
        <div class="instructions">
            <h3>Directions:</h3>
            <div class="step">
                <div class="step-number">1</div>
                <div>Read each paragraph of "The Secret of Sunny Pond" carefully.</div>
            </div>
            <div class="step">
                <div class="step-number">2</div>
                <div>For each paragraph, write a brief summary of what happens.</div>
            </div>
            <div class="step">
                <div class="step-number">3</div>
                <div>Identify 2-3 powerful words or phrases the author uses in each paragraph.</div>
            </div>
            <div class="step">
                <div class="step-number">4</div>
                <div>Explain how each paragraph builds upon or adds something new to the previous paragraph(s).</div>
            </div>
            <div class="step">
                <div class="step-number">5</div>
                <div>In the final section, explain how all the paragraphs work together to create the overall meaning of the story.</div>
            </div>
        </div>
        
        <form class="form-container">
            <div class="organizer-sheet">
                <!-- Paragraph 1 Section -->
                <div class="section-box">
                    <div class="section-title"><span class="paragraph-icon">🌿</span> Paragraph 1</div>
                    
                    <div class="field">
                        <div class="field-label">Paragraph Summary:</div>
                        <textarea class="text-area" name="paragraph1_summary" placeholder="Write a brief summary of what happens in Paragraph 1..."></textarea>
                    </div>
                    
                    <div class="field">
                        <div class="field-label">Author's Word Choices (2-3 powerful words or phrases):</div>
                        <input type="text" class="input-field" name="paragraph1_words1" placeholder="Word or phrase 1">
                        <input type="text" class="input-field" name="paragraph1_words2" placeholder="Word or phrase 2">
                        <input type="text" class="input-field" name="paragraph1_words3" placeholder="Word or phrase 3 (optional)">
                    </div>
                    
                    <div class="field">
                        <div class="field-label">What's Different or New?</div>
                        <textarea class="text-area" name="paragraph1_different" placeholder="This is the first paragraph, so describe what it introduces or establishes..."></textarea>
                    </div>
                </div>
                
                <!-- Paragraph 2 Section -->
                <div class="section-box">
                    <div class="section-title"><span class="paragraph-icon">🦋</span> Paragraph 2</div>
                    
                    <div class="field">
                        <div class="field-label">Paragraph Summary:</div>
                        <textarea class="text-area" name="paragraph2_summary" placeholder="Write a brief summary of what happens in Paragraph 2..."></textarea>
                    </div>
                    
                    <div class="field">
                        <div class="field-label">Author's Word Choices (2-3 powerful words or phrases):</div>
                        <input type="text" class="input-field" name="paragraph2_words1" placeholder="Word or phrase 1">
                        <input type="text" class="input-field" name="paragraph2_words2" placeholder="Word or phrase 2">
                        <input type="text" class="input-field" name="paragraph2_words3" placeholder="Word or phrase 3 (optional)">
                    </div>
                    
                    <div class="field">
                        <div class="field-label">What's Different or New?</div>
                        <textarea class="text-area" name="paragraph2_different" placeholder="How does this paragraph build upon or add something new to Paragraph 1?"></textarea>
                    </div>
                </div>
                
                <!-- Paragraph 3 Section -->
                <div class="section-box">
                    <div class="section-title"><span class="paragraph-icon">🌧️</span> Paragraph 3</div>
                    
                    <div class="field">
                        <div class="field-label">Paragraph Summary:</div>
                        <textarea class="text-area" name="paragraph3_summary" placeholder="Write a brief summary of what happens in Paragraph 3..."></textarea>
                    </div>
                    
                    <div class="field">
                        <div class="field-label">Author's Word Choices (2-3 powerful words or phrases):</div>
                        <input type="text" class="input-field" name="paragraph3_words1" placeholder="Word or phrase 1">
                        <input type="text" class="input-field" name="paragraph3_words2" placeholder="Word or phrase 2">
                        <input type="text" class="input-field" name="paragraph3_words3" placeholder="Word or phrase 3 (optional)">
                    </div>
                    
                    <div class="field">
                        <div class="field-label">What's Different or New?</div>
                        <textarea class="text-area" name="paragraph3_different" placeholder="How does this paragraph build upon or add something new to Paragraphs 1 and 2?"></textarea>
                    </div>
                </div>
                
                <!-- Paragraph 4 Section -->
                <div class="section-box">
                    <div class="section-title"><span class="paragraph-icon">☀️</span> Paragraph 4</div>
                    
                    <div class="field">
                        <div class="field-label">Paragraph Summary:</div>
                        <textarea class="text-area" name="paragraph4_summary" placeholder="Write a brief summary of what happens in Paragraph 4..."></textarea>
                    </div>
                    
                    <div class="field">
                        <div class="field-label">Author's Word Choices (2-3 powerful words or phrases):</div>
                        <input type="text" class="input-field" name="paragraph4_words1" placeholder="Word or phrase 1">
                        <input type="text" class="input-field" name="paragraph4_words2" placeholder="Word or phrase 2">
                        <input type="text" class="input-field" name="paragraph4_words3" placeholder="Word or phrase 3 (optional)">
                    </div>
                    
                    <div class="field">
                        <div class="field-label">What's Different or New?</div>
                        <textarea class="text-area" name="paragraph4_different" placeholder="How does this paragraph build upon or add something new to the previous paragraphs?"></textarea>
                    </div>
                </div>
                
                <!-- Putting it All Together Section -->
                <div class="section-box">
                    <div class="section-title"><span class="paragraph-icon">🔍</span> Putting it All Together</div>
                    
                    <div class="field">
                        <div class="field-label">How do all the paragraphs work together to create the overall meaning or message of the story?</div>
                        <textarea class="text-area highlight-input" name="overall_meaning" placeholder="Explain how each paragraph builds upon the others to create the complete story. What is the author's message?"></textarea>
                    </div>
                </div>
            </div>
        </form>
    </div>
</body>
</html>`;

export const graphicOrganizerFilled_ela_3_aor_5_1 = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Sunny Pond Step-by-Step Analysis Map</title>
    <style>
        body {
            font-family: 'Comic Sans MS', cursive, sans-serif;
            background-color: #f5f5f5;
            margin: 0;
            padding: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .container {
            max-width: 850px;
            width: 100%;
            background-color: white;
            padding: 25px;
            border-radius: 15px;
            box-shadow: 0 4px 8px rgba(0,0,0,0.1);
        }
        h1 {
            color: #233142;
            text-align: center;
            margin-bottom: 5px;
        }
        .subtitle {
            color: #666;
            text-align: center;
            font-style: italic;
            margin-bottom: 20px;
        }
        .instructions {
            background-color: #d9eeff;
            padding: 15px;
            border-radius: 10px;
            margin: 15px 0;
            box-shadow: 0 2px 4px rgba(0,0,0,0.1);
        }
        .step {
            display: flex;
            margin-bottom: 5px;
        }
        .step-number {
            background-color: #233142;
            color: white;
            width: 25px;
            height: 25px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
            font-weight: bold;
        }
        .organizer-sheet {
            margin-top: 20px;
            padding: 15px;
            border: 1px solid #ddd;
            border-radius: 10px;
        }
        .organizer-row {
            margin-bottom: 40px;
            page-break-inside: avoid;
        }
        .field {
            margin-bottom: 20px;
        }
        .field-label {
            font-weight: bold;
            color: #233142;
            margin-bottom: 5px;
        }
        .input-field {
            width: 100%;
            padding: 8px;
            margin-bottom: 5px;
            border: 1px solid #aaa;
            border-radius: 5px;
            font-family: 'Comic Sans MS', cursive, sans-serif;
            font-size: 16px;
            background-color: #ffffff;
        }
        
        .input-field:focus {
            outline: none;
            border-color: #233142;
            box-shadow: 0 0 3px rgba(35, 49, 66, 0.5);
        }
        
        .text-area {
            width: 100%;
            padding: 8px;
            margin-bottom: 5px;
            border: 1px solid #aaa;
            border-radius: 5px;
            font-family: 'Comic Sans MS', cursive, sans-serif;
            font-size: 16px;
            min-height: 60px;
            resize: vertical;
        }
        
        .text-area:focus {
            outline: none;
            border-color: #233142;
            box-shadow: 0 0 3px rgba(35, 49, 66, 0.5);
        }
        .section-box {
            border: 2px solid #233142;
            border-radius: 10px;
            padding: 15px;
            margin-bottom: 20px;
            background-color: white;
            width: 100%;
            box-sizing: border-box;
        }
        .section-title {
            font-weight: bold;
            color: #233142;
            border-bottom: 1px solid #233142;
            padding-bottom: 5px;
            margin-bottom: 10px;
        }
        .boxed-content {
            padding: 10px;
            background-color: #f9f9f9;
            border-radius: 8px;
            border: 1px solid #ddd;
            margin-bottom: 15px;
            width: 100%;
            box-sizing: border-box;
        }
        .highlight-area {
            background-color: #fff8dc;
        }
        
        .highlight-input {
            background-color: #fff8dc;
        }
        
        .form-container {
            width: 100%;
        }
        .paragraph-icon {
            display: inline-block;
            margin-right: 5px;
            font-size: 20px;
        }
        .paragraph-number {
            font-weight: bold;
            color: #233142;
            font-size: 18px;
            margin-bottom: 5px;
        }
        @media print {
            body {
                background-color: white;
                padding: 0;
                margin: 0;
            }
            .container {
                box-shadow: none;
                padding: 15px;
                max-width: 100%;
            }
            .instructions {
                border: 1px solid #ccc;
                box-shadow: none;
            }
            .organizer-row, .section-box {
                page-break-inside: avoid;
            }
            .organizer-sheet {
                border: none;
            }
            @page {
                margin: 1cm;
            }
        }
    </style>
</head>
<body>
    <div class="container">
        <h1>Sunny Pond Step-by-Step Analysis Map</h1>
        <div class="subtitle">Analyzing how paragraphs build on each other in "The Secret of Sunny Pond"</div>
        
        <div class="instructions">
            <h3>Directions:</h3>
            <div class="step">
                <div class="step-number">1</div>
                <div>Read each paragraph of "The Secret of Sunny Pond" carefully.</div>
            </div>
            <div class="step">
                <div class="step-number">2</div>
                <div>For each paragraph, write a brief summary of what happens.</div>
            </div>
            <div class="step">
                <div class="step-number">3</div>
                <div>Identify 2-3 powerful words or phrases the author uses in each paragraph.</div>
            </div>
            <div class="step">
                <div class="step-number">4</div>
                <div>Explain how each paragraph builds upon or adds something new to the previous paragraph(s).</div>
            </div>
            <div class="step">
                <div class="step-number">5</div>
                <div>In the final section, explain how all the paragraphs work together to create the overall meaning of the story.</div>
            </div>
        </div>
        
        <form class="form-container">
            <div class="organizer-sheet">
                <!-- Paragraph 1 Section -->
                <div class="section-box">
                    <div class="section-title"><span class="paragraph-icon">🌿</span> Paragraph 1</div>
                    
                    <div class="field">
                        <div class="field-label">Paragraph Summary:</div>
                        <textarea class="text-area" name="paragraph1_summary">Mia asks Carlos why flowers grow near Sunny Pond. Carlos jokes that maybe flowers like to drink water and swim like frogs. They both laugh while watching the flowers sway by the pond.</textarea>
                    </div>
                    
                    <div class="field">
                        <div class="field-label">Author's Word Choices (2-3 powerful words or phrases):</div>
                        <input type="text" class="input-field" name="paragraph1_words1" value="whispered excitedly">
                        <input type="text" class="input-field" name="paragraph1_words2" value="flowers gently swaying">
                        <input type="text" class="input-field" name="paragraph1_words3" value="beneath the old oak tree">
                    </div>
                    
                    <div class="field">
                        <div class="field-label">What's Different or New?</div>
                        <textarea class="text-area" name="paragraph1_different">This paragraph introduces the characters Carlos and Mia at Sunny Pond. It establishes the setting with the oak tree, pond, frogs, and flowers. It also shows the children are curious about nature and have a playful relationship.</textarea>
                    </div>
                </div>
                
                <!-- Paragraph 2 Section -->
                <div class="section-box">
                    <div class="section-title"><span class="paragraph-icon">🦋</span> Paragraph 2</div>
                    
                    <div class="field">
                        <div class="field-label">Paragraph Summary:</div>
                        <textarea class="text-area" name="paragraph2_summary">Mia notices that butterflies appear when flowers bloom. Carlos observes butterflies flying between flowers and drinking nectar. Carlos realizes that in nature, "one beautiful thing leads to another."</textarea>
                    </div>
                    
                    <div class="field">
                        <div class="field-label">Author's Word Choices (2-3 powerful words or phrases):</div>
                        <input type="text" class="input-field" name="paragraph2_words1" value="sweet fragrance">
                        <input type="text" class="input-field" name="paragraph2_words2" value="butterflies flutter around">
                        <input type="text" class="input-field" name="paragraph2_words3" value="sipping nectar">
                    </div>
                    
                    <div class="field">
                        <div class="field-label">What's Different or New?</div>
                        <textarea class="text-area" name="paragraph2_different">This paragraph builds on the first by adding butterflies to the pond scene. It shows the first connection in nature - between flowers and butterflies. Carlos's observation that "one beautiful thing leads to another" introduces the main theme of interconnection in nature.</textarea>
                    </div>
                </div>
                
                <!-- Paragraph 3 Section -->
                <div class="section-box">
                    <div class="section-title"><span class="paragraph-icon">🌧️</span> Paragraph 3</div>
                    
                    <div class="field">
                        <div class="field-label">Paragraph Summary:</div>
                        <textarea class="text-area" name="paragraph3_summary">Dark clouds appear and it begins to rain. Carlos and Mia run home but notice plants soaking up the raindrops. Carlos suggests they return after the rain to see if anything has changed at the pond.</textarea>
                    </div>
                    
                    <div class="field">
                        <div class="field-label">Author's Word Choices (2-3 powerful words or phrases):</div>
                        <input type="text" class="input-field" name="paragraph3_words1" value="dark clouds gathered">
                        <input type="text" class="input-field" name="paragraph3_words2" value="plants soaking up">
                        <input type="text" class="input-field" name="paragraph3_words3" value="see what's different">
                    </div>
                    
                    <div class="field">
                        <div class="field-label">What's Different or New?</div>
                        <textarea class="text-area" name="paragraph3_different">This paragraph introduces a change in weather - rain comes to Sunny Pond. This builds on the previous paragraphs by adding another element of nature (rain) that will affect the pond ecosystem. It creates anticipation about how the rain might change what they've already observed.</textarea>
                    </div>
                </div>
                
                <!-- Paragraph 4 Section -->
                <div class="section-box">
                    <div class="section-title"><span class="paragraph-icon">☀️</span> Paragraph 4</div>
                    
                    <div class="field">
                        <div class="field-label">Paragraph Summary:</div>
                        <textarea class="text-area" name="paragraph4_summary">Carlos and Mia return to Sunny Pond after the rain and notice everything looks better - flowers are taller, frogs jump farther, and the pond sparkles. They realize that everything has changed slightly but builds on what was already there.</textarea>
                    </div>
                    
                    <div class="field">
                        <div class="field-label">Author's Word Choices (2-3 powerful words or phrases):</div>
                        <input type="text" class="input-field" name="paragraph4_words1" value="everything seemed brighter">
                        <input type="text" class="input-field" name="paragraph4_words2" value="gentle ripples of the pond sparkled">
                        <input type="text" class="input-field" name="paragraph4_words3" value="whispered in awe">
                    </div>
                    
                    <div class="field">
                        <div class="field-label">What's Different or New?</div>
                        <textarea class="text-area" name="paragraph4_different">This final paragraph shows the results of the rain on the pond ecosystem. It completes the cycle by showing how the rain affected all the elements introduced earlier (flowers, frogs, the pond). Most importantly, it reveals the story's message when Mia says "it built upon what was already here" - showing how each part of nature connects to and enhances the others.</textarea>
                    </div>
                </div>
                
                <!-- Putting it All Together Section -->
                <div class="section-box">
                    <div class="section-title"><span class="paragraph-icon">🔍</span> Putting it All Together</div>
                    
                    <div class="field">
                        <div class="field-label">How do all the paragraphs work together to create the overall meaning or message of the story?</div>
                        <textarea class="text-area highlight-input" name="overall_meaning">The story of Sunny Pond shows how everything in nature is connected and builds upon itself. In Paragraph 1, we meet the characters and setting. Paragraph 2 introduces the first connection (flowers and butterflies). Paragraph 3 adds rain as a new element that will affect everything. Finally, Paragraph 4 shows how the rain improved the entire pond ecosystem. The author carefully structured these paragraphs to show that in nature, just like in good stories, each part connects to and enhances the others. The message is that small changes can make beautiful differences, and everything in nature (and life) is interconnected - "one beautiful thing leads to another."</textarea>
                    </div>
                </div>
            </div>
        </form>
    </div>
</body>
</html>`;

export const graphicOrganizerPrintable_ela_3_aor_5_1 = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Sunny Pond Step-by-Step Analysis Map</title>
    <style>
        body {
            font-family: 'Comic Sans MS', cursive, sans-serif;
            background-color: #f5f5f5;
            margin: 0;
            padding: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .container {
            max-width: 850px;
            width: 100%;
            background-color: white;
            padding: 25px;
            border-radius: 15px;
            box-shadow: 0 4px 8px rgba(0,0,0,0.1);
        }
        h1 {
            color: #233142;
            text-align: center;
            margin-bottom: 5px;
        }
        .subtitle {
            color: #666;
            text-align: center;
            font-style: italic;
            margin-bottom: 20px;
        }
        .instructions {
            background-color: #d9eeff;
            padding: 15px;
            border-radius: 10px;
            margin: 15px 0;
            box-shadow: 0 2px 4px rgba(0,0,0,0.1);
        }
        .step {
            display: flex;
            margin-bottom: 5px;
        }
        .step-number {
            background-color: #233142;
            color: white;
            width: 25px;
            height: 25px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
            font-weight: bold;
        }
        .organizer-sheet {
            margin-top: 20px;
            padding: 15px;
            border: 1px solid #ddd;
            border-radius: 10px;
        }
        .organizer-row {
            margin-bottom: 40px;
            page-break-inside: avoid;
        }
        .field {
            margin-bottom: 20px;
        }
        .field-label {
            font-weight: bold;
            color: #233142;
            margin-bottom: 5px;
        }
        .writing-area {
            width: 100%;
            min-height: 60px;
            border: 1px solid #aaa;
            border-radius: 5px;
            margin-bottom: 5px;
            background-color: #ffffff;
            position: relative;
            box-sizing: border-box;
        }
        .writing-lines {
            width: 100%;
            min-height: 90px;
            border: 1px solid #aaa;
            border-radius: 5px;
            margin-bottom: 5px;
            background-color: #ffffff;
            background-image: linear-gradient(#e5e5e5 1px, transparent 1px);
            background-size: 100% 25px;
            position: relative;
            box-sizing: border-box;
        }
        .word-writing-area {
            width: 100%;
            height: 25px;
            border: 1px solid #aaa;
            border-radius: 5px;
            margin-bottom: 10px;
            background-color: #ffffff;
            position: relative;
            box-sizing: border-box;
        }
        .section-box {
            border: 2px solid #233142;
            border-radius: 10px;
            padding: 15px;
            margin-bottom: 20px;
            background-color: white;
            width: 100%;
            box-sizing: border-box;
        }
        .section-title {
            font-weight: bold;
            color: #233142;
            border-bottom: 1px solid #233142;
            padding-bottom: 5px;
            margin-bottom: 10px;
        }
        .highlight-area {
            background-color: #fff8dc;
        }
        
        .highlight-writing-area {
            background-color: #fff8dc;
            min-height: 120px;
        }
        
        .paragraph-icon {
            display: inline-block;
            margin-right: 5px;
            font-size: 20px;
        }
        
        /* Print styles */
        @media print {
            body {
                background-color: white;
                padding: 0;
                margin: 0;
            }
            .container {
                box-shadow: none;
                padding: 15px;
                max-width: 100%;
            }
            .instructions {
                border: 1px solid #ccc;
                box-shadow: none;
            }
            .organizer-row, .section-box {
                page-break-inside: avoid;
            }
            .organizer-sheet {
                border: none;
            }
            @page {
                margin: 1cm;
            }
        }
    </style>
</head>
<body>
    <div class="container">
        <h1>Sunny Pond Step-by-Step Analysis Map</h1>
        <div class="subtitle">Analyzing how paragraphs build on each other in "The Secret of Sunny Pond"</div>
        
        <div class="instructions">
            <h3>Directions:</h3>
            <div class="step">
                <div class="step-number">1</div>
                <div>Read each paragraph of "The Secret of Sunny Pond" carefully.</div>
            </div>
            <div class="step">
                <div class="step-number">2</div>
                <div>For each paragraph, write a brief summary of what happens.</div>
            </div>
            <div class="step">
                <div class="step-number">3</div>
                <div>Identify 2-3 powerful words or phrases the author uses in each paragraph.</div>
            </div>
            <div class="step">
                <div class="step-number">4</div>
                <div>Explain how each paragraph builds upon or adds something new to the previous paragraph(s).</div>
            </div>
            <div class="step">
                <div class="step-number">5</div>
                <div>In the final section, explain how all the paragraphs work together to create the overall meaning of the story.</div>
            </div>
        </div>
        
        <div class="organizer-sheet">
            <!-- Paragraph 1 Section -->
            <div class="section-box">
                <div class="section-title"><span class="paragraph-icon">🌿</span> Paragraph 1</div>
                
                <div class="field">
                    <div class="field-label">Paragraph Summary:</div>
                    <div class="writing-lines"></div>
                </div>
                
                <div class="field">
                    <div class="field-label">Author's Word Choices (2-3 powerful words or phrases):</div>
                    <div class="word-writing-area"></div>
                    <div class="word-writing-area"></div>
                    <div class="word-writing-area"></div>
                </div>
                
                <div class="field">
                    <div class="field-label">What's Different or New?</div>
                    <div class="writing-lines"></div>
                </div>
            </div>
            
            <!-- Paragraph 2 Section -->
            <div class="section-box">
                <div class="section-title"><span class="paragraph-icon">🦋</span> Paragraph 2</div>
                
                <div class="field">
                    <div class="field-label">Paragraph Summary:</div>
                    <div class="writing-lines"></div>
                </div>
                
                <div class="field">
                    <div class="field-label">Author's Word Choices (2-3 powerful words or phrases):</div>
                    <div class="word-writing-area"></div>
                    <div class="word-writing-area"></div>
                    <div class="word-writing-area"></div>
                </div>
                
                <div class="field">
                    <div class="field-label">What's Different or New?</div>
                    <div class="writing-lines"></div>
                </div>
            </div>
            
            <!-- Paragraph 3 Section -->
            <div class="section-box">
                <div class="section-title"><span class="paragraph-icon">🌧️</span> Paragraph 3</div>
                
                <div class="field">
                    <div class="field-label">Paragraph Summary:</div>
                    <div class="writing-lines"></div>
                </div>
                
                <div class="field">
                    <div class="field-label">Author's Word Choices (2-3 powerful words or phrases):</div>
                    <div class="word-writing-area"></div>
                    <div class="word-writing-area"></div>
                    <div class="word-writing-area"></div>
                </div>
                
                <div class="field">
                    <div class="field-label">What's Different or New?</div>
                    <div class="writing-lines"></div>
                </div>
            </div>
            
            <!-- Paragraph 4 Section -->
            <div class="section-box">
                <div class="section-title"><span class="paragraph-icon">☀️</span> Paragraph 4</div>
                
                <div class="field">
                    <div class="field-label">Paragraph Summary:</div>
                    <div class="writing-lines"></div>
                </div>
                
                <div class="field">
                    <div class="field-label">Author's Word Choices (2-3 powerful words or phrases):</div>
                    <div class="word-writing-area"></div>
                    <div class="word-writing-area"></div>
                    <div class="word-writing-area"></div>
                </div>
                
                <div class="field">
                    <div class="field-label">What's Different or New?</div>
                    <div class="writing-lines"></div>
                </div>
            </div>
            
            <!-- Putting it All Together Section -->
            <div class="section-box">
                <div class="section-title"><span class="paragraph-icon">🔍</span> Putting it All Together</div>
                
                <div class="field">
                    <div class="field-label">How do all the paragraphs work together to create the overall meaning or message of the story?</div>
                    <div class="writing-lines highlight-area"></div>
                </div>
            </div>
        </div>
    </div>
</body>
</html>`;

export const studentResource_ela_3_aor_5_1 = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>The Secret of Sunny Pond - Understanding Story Structure</title>
    <style>
        body {
            font-family: 'Comic Sans MS', cursive, sans-serif;
            background-color: #f5f5f5;
            margin: 0;
            padding: 20px;
            display: flex;
            justify-content: center;
        }
        .container {
            max-width: 800px;
            width: 100%;
            background-color: white;
            padding: 25px;
            border-radius: 15px;
            box-shadow: 0 4px 8px rgba(0,0,0,0.1);
        }
        h1 {
            color: #233142;
            text-align: center;
            border-bottom: 2px solid #233142;
            padding-bottom: 10px;
        }
        .subtitle {
            color: #666;
            text-align: center;
            font-style: italic;
            margin-top: -10px;
            margin-bottom: 20px;
        }
        p {
            line-height: 1.6;
            font-size: 16px;
            margin-bottom: 15px;
        }
        .highlight-word {
            font-weight: bold;
            background-color: #fff8dc;
            padding: 0 3px;
            border-radius: 3px;
        }
        .vocabulary {
            background-color: #e8f7ee;
            padding: 15px;
            border-radius: 8px;
            margin-bottom: 20px;
            border: 1px dashed #3fa672;
        }
        .vocabulary-title {
            font-weight: bold;
            color: #233142;
            margin-bottom: 5px;
        }
        .instructions {
            background-color: #d9eeff;
            padding: 15px;
            border-radius: 8px;
            margin: 20px 0;
            box-shadow: 0 2px 4px rgba(0,0,0,0.05);
        }
        .fun-fact {
            background-color: #fff8dc;
            padding: 15px;
            margin: 15px 0;
            border-radius: 8px;
            border: 1px solid #ddd;
        }
        .fun-fact-title {
            font-weight: bold;
            color: #233142;
            margin-bottom: 5px;
        }
        .paragraph-section {
            background-color: #f9f9f9;
            padding: 15px;
            border-radius: 8px;
            margin: 15px 0;
            border-left: 4px solid #6a9eda;
        }
        .paragraph-title {
            font-weight: bold;
            color: #233142;
            margin-bottom: 10px;
        }
        @media print {
            body {
                background-color: white;
                padding: 0;
                margin: 0;
            }
            .container {
                box-shadow: none;
                padding: 0;
                max-width: 100%;
            }
            .instructions, .vocabulary, .fun-fact, .paragraph-section {
                border: 1px solid #ccc;
                box-shadow: none;
            }
            @page {
                margin: 1cm;
            }
        }
    </style>
</head>
<body>
    <div class="container">
        <h1>The Secret of Sunny Pond</h1>
        <div class="subtitle">A Story About Connections in Nature</div>
        
        <div class="instructions">
            <h3>Reading Instructions:</h3>
            <p>As you read this story, notice how each paragraph builds on the one before it. Pay attention to the <strong>descriptive words</strong> that help you imagine Sunny Pond and how it changes throughout the story.</p>
        </div>
        
        <p>It was a bright afternoon at Sunny Pond. Carlos and his sister Mia loved to sit beneath the old oak tree, watching frogs leap from lily pad to lily pad.</p>
        
        <div class="paragraph-section">
            <div class="paragraph-title">Paragraph 1:</div>
            <p>"Carlos," Mia whispered excitedly, pointing toward the water. "Why do you think all these flowers grow near the edge of Sunny Pond?"</p>
            <p>Carlos smiled, "Maybe because they love to drink and swim like frogs?"</p>
            <p>They laughed, watching the flowers gently <span class="highlight-word">swaying</span>.</p>
        </div>
        
        <div class="paragraph-section">
            <div class="paragraph-title">Paragraph 2:</div>
            <p>Suddenly, the breeze carried a sweet <span class="highlight-word">fragrance</span> from purple flowers blooming by the water. "Have you noticed," Mia said, sniffing the air, "that when the flowers bloom, more butterflies flutter around?"</p>
            <p>Carlos looked closely, noticing butterflies flying from flower to flower sipping <span class="highlight-word">nectar</span>.</p>
            <p>"That's true! It's like one beautiful thing leads to another," Carlos exclaimed.</p>
        </div>
        
        <div class="fun-fact">
            <div class="fun-fact-title">Nature Connection!</div>
            <p>Butterflies are attracted to the sweet nectar in flowers. While they drink the nectar, they also help spread pollen from flower to flower, helping more plants grow!</p>
        </div>
        
        <div class="paragraph-section">
            <div class="paragraph-title">Paragraph 3:</div>
            <p>As they talked, dark clouds gathered overhead. Soon, a soft rain began. Carlos grabbed Mia's hand as they hurried home, seeing the plants <span class="highlight-word">soaking up</span> the cool drops.</p>
            <p>"When the rain stops, Mia, we should come back and see what's different," Carlos said.</p>
            <p>"Good idea," Mia agreed.</p>
        </div>
        
        <div class="paragraph-section">
            <div class="paragraph-title">Paragraph 4:</div>
            <p>Later, when the rain had ended, they returned to Sunny Pond. Everything seemed brighter. Flowers stood taller, frogs jumped farther, and the gentle <span class="highlight-word">ripples</span> of the pond sparkled with sunlight and life.</p>
            <p>"Everything has changed just a little bit," Mia whispered in awe, "but it built upon what was already here."</p>
            <p>Carlos nodded thoughtfully, "Yes! Every part, every change makes Sunny Pond even more special and beautiful."</p>
        </div>
        
        <div class="fun-fact">
            <div class="fun-fact-title">Think About It!</div>
            <p>Just like each paragraph in this story builds on the one before it, nature works the same way! Rain helps plants grow, plants help butterflies live, and all these connections create a beautiful pond ecosystem.</p>
        </div>
        
        <p style="text-align: center; font-style: italic; margin-top: 30px;">~ The End ~</p>
    </div>
</body>
</html>`;

export const teacherResource_ela_3_aor_5_1 = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>The Secret of Sunny Pond - Understanding Story Structure</title>
    <style>
        body {
            font-family: 'Comic Sans MS', cursive, sans-serif;
            background-color: #f5f5f5;
            margin: 0;
            padding: 20px;
            display: flex;
            justify-content: center;
        }
        .container {
            max-width: 800px;
            width: 100%;
            background-color: white;
            padding: 25px;
            border-radius: 15px;
            box-shadow: 0 4px 8px rgba(0,0,0,0.1);
        }
        h1 {
            color: #233142;
            text-align: center;
            border-bottom: 2px solid #233142;
            padding-bottom: 10px;
        }
        .subtitle {
            color: #666;
            text-align: center;
            font-style: italic;
            margin-top: -10px;
            margin-bottom: 20px;
        }
        p {
            line-height: 1.6;
            font-size: 16px;
            margin-bottom: 15px;
        }
        .highlight-word {
            font-weight: bold;
            background-color: #fff8dc;
            padding: 0 3px;
            border-radius: 3px;
        }
        .vocabulary {
            background-color: #e8f7ee;
            padding: 15px;
            border-radius: 8px;
            margin-bottom: 20px;
            border: 1px dashed #3fa672;
        }
        .vocabulary-title {
            font-weight: bold;
            color: #233142;
            margin-bottom: 5px;
        }
        .instructions {
            background-color: #d9eeff;
            padding: 15px;
            border-radius: 8px;
            margin: 20px 0;
            box-shadow: 0 2px 4px rgba(0,0,0,0.05);
        }
        .fun-fact {
            background-color: #fff8dc;
            padding: 15px;
            margin: 15px 0;
            border-radius: 8px;
            border: 1px solid #ddd;
        }
        .fun-fact-title {
            font-weight: bold;
            color: #233142;
            margin-bottom: 5px;
        }
        .paragraph-section {
            background-color: #f9f9f9;
            padding: 15px;
            border-radius: 8px;
            margin: 15px 0;
            border-left: 4px solid #6a9eda;
        }
        .paragraph-title {
            font-weight: bold;
            color: #233142;
            margin-bottom: 10px;
        }
        @media print {
            body {
                background-color: white;
                padding: 0;
                margin: 0;
            }
            .container {
                box-shadow: none;
                padding: 0;
                max-width: 100%;
            }
            .instructions, .vocabulary, .fun-fact, .paragraph-section {
                border: 1px solid #ccc;
                box-shadow: none;
            }
            @page {
                margin: 1cm;
            }
        }
    </style>
</head>
<body>
    <div class="container">
        <h1>The Secret of Sunny Pond</h1>
        <div class="subtitle">A Story About Connections in Nature</div>
        
        <div class="instructions">
            <h3>Reading Instructions:</h3>
            <p>As you read this story, notice how each paragraph builds on the one before it. Pay attention to the <strong>descriptive words</strong> that help you imagine Sunny Pond and how it changes throughout the story.</p>
        </div>
        
        <p>It was a bright afternoon at Sunny Pond. Carlos and his sister Mia loved to sit beneath the old oak tree, watching frogs leap from lily pad to lily pad.</p>
        
        <div class="paragraph-section">
            <div class="paragraph-title">Paragraph 1:</div>
            <p>"Carlos," Mia whispered excitedly, pointing toward the water. "Why do you think all these flowers grow near the edge of Sunny Pond?"</p>
            <p>Carlos smiled, "Maybe because they love to drink and swim like frogs?"</p>
            <p>They laughed, watching the flowers gently <span class="highlight-word">swaying</span>.</p>
        </div>
        
        <div class="paragraph-section">
            <div class="paragraph-title">Paragraph 2:</div>
            <p>Suddenly, the breeze carried a sweet <span class="highlight-word">fragrance</span> from purple flowers blooming by the water. "Have you noticed," Mia said, sniffing the air, "that when the flowers bloom, more butterflies flutter around?"</p>
            <p>Carlos looked closely, noticing butterflies flying from flower to flower sipping <span class="highlight-word">nectar</span>.</p>
            <p>"That's true! It's like one beautiful thing leads to another," Carlos exclaimed.</p>
        </div>
        
        <div class="fun-fact">
            <div class="fun-fact-title">Nature Connection!</div>
            <p>Butterflies are attracted to the sweet nectar in flowers. While they drink the nectar, they also help spread pollen from flower to flower, helping more plants grow!</p>
        </div>
        
        <div class="paragraph-section">
            <div class="paragraph-title">Paragraph 3:</div>
            <p>As they talked, dark clouds gathered overhead. Soon, a soft rain began. Carlos grabbed Mia's hand as they hurried home, seeing the plants <span class="highlight-word">soaking up</span> the cool drops.</p>
            <p>"When the rain stops, Mia, we should come back and see what's different," Carlos said.</p>
            <p>"Good idea," Mia agreed.</p>
        </div>
        
        <div class="paragraph-section">
            <div class="paragraph-title">Paragraph 4:</div>
            <p>Later, when the rain had ended, they returned to Sunny Pond. Everything seemed brighter. Flowers stood taller, frogs jumped farther, and the gentle <span class="highlight-word">ripples</span> of the pond sparkled with sunlight and life.</p>
            <p>"Everything has changed just a little bit," Mia whispered in awe, "but it built upon what was already here."</p>
            <p>Carlos nodded thoughtfully, "Yes! Every part, every change makes Sunny Pond even more special and beautiful."</p>
        </div>
        
        <div class="fun-fact">
            <div class="fun-fact-title">Think About It!</div>
            <p>Just like each paragraph in this story builds on the one before it, nature works the same way! Rain helps plants grow, plants help butterflies live, and all these connections create a beautiful pond ecosystem.</p>
        </div>
        
        <p style="text-align: center; font-style: italic; margin-top: 30px;">~ The End ~</p>
        
        <!-- Teacher's Notes Section -->
        <div style="margin-top: 40px; border-top: 2px dashed #233142; padding-top: 20px;">
            <h3 style="color: #233142;">Teacher's Notes</h3>
            <p>This section is intended for teacher reference only. Consider printing student copies without this section.</p>
            
            <div style="background-color: #e8f7ee; padding: 15px; border-radius: 8px; margin: 15px 0; border: 1px dashed #3fa672;">
                <h4 style="color: #2e7d32; margin-top: 0;">Lesson Objectives:</h4>
                <ul>
                    <li>Help students identify how the author uses words and phrases to craft meaning and mood in a literary text.</li>
                    <li>Encourage students to analyze how each paragraph builds on the previous paragraphs, creating a structured, cohesive meaning in the text.</li>
                </ul>
            </div>
            
            <div style="background-color: #d9eeff; padding: 15px; border-radius: 8px; margin: 15px 0;">
                <h4 style="color: #233142; margin-top: 0;">Discussion Questions:</h4>
                <ol>
                    <li>Why do you think the author described Sunny Pond as changing after the rain? How does this show that parts of the story connect to one another?</li>
                    <li>Which words or phrases helped you imagine Sunny Pond clearly in your mind? What made those words powerful?</li>
                    <li>How would the story be different if the author didn't include the rain scene? How does each event lead to another?</li>
                </ol>
            </div>
            
            <div style="background-color: #fff8dc; padding: 15px; border-radius: 8px; margin: 15px 0; border: 1px solid #ddd;">
                <h4 style="color: #233142; margin-top: 0;">Guidance on Using the Resource:</h4>
                <ul>
                    <li>Begin by reading the story aloud and asking students to visualize each section.</li>
                    <li>Next, guide students to identify key words or phrases used in each paragraph and discuss how they shape their understanding.</li>
                    <li>Finally, have students collaborate to explain how each paragraph builds upon the last, adding something new to the setting and experience.</li>
                    <li>Optional: Have students create drawings to represent each stage of Sunny Pond's development, highlighting how the story structure contributes to the meaning.</li>
                </ul>
            </div>
        </div>
    </div>
</body>
</html>`;