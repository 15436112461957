import React, { Component } from 'react'
//import { Link } from 'react-router-dom'
import '../styles/GlobalStyle.css'
import { Layout, Icon } from 'antd'
import { isReadOnlyTeacherId } from '../Util'
const Footer = Layout.Footer

class CustomFooter extends Component {
  state = {

  }

  componentDidMount() {

  }

  render() {
    const isDarkTheme = this.props.hasOwnProperty('theme') && this.props.theme === 'dark'
    return (
      <Footer className={"footer text-center" + (isDarkTheme ? ' transparent' : ' transparent')}>
        <div>
          <Icon type="copyright" className="mr-1" />
          <span className="mr-3">2025 Smart Learning Systems, LLC.</span>
          <a href={"https://firebasestorage.googleapis.com/v0/b/" +
            "education-9d7f3.appspot.com/o/Terms%20of%20Service%20" +
            "-%20dot%20it.pdf?alt=media&token=f949f25a-2c24-4bae-a" +
            "59f-f5c71e48b743"} className="text-fff mr-3"
            target="_blank">
            <span style={{ color: "grey" }}>Terms</span>
          </a>
          <a href={'https://firebasestorage.googleapis.com/v0/b/education-9d7f3.appspot.com/o/Dot-It-Privacy-Policy.pdf?alt=media&token=cf923d03-b8a8-4f25-b7ca-be37272306c1'}
            className="text-fff" target="_blank">
            <span style={{ color: "grey" }}>Privacy</span>
          </a>
          {/*this.props.hasOwnProperty("teacherId") && !isReadOnlyTeacherId(this.props.teacherId) ?
            <Link to={"/teacher/help/" + this.props.teacherId} className="mr-3 font-fff">
              Need help?
            </Link>
          : ''*/}
          {this.props.hasOwnProperty("teacherId") && isReadOnlyTeacherId(this.props.teacherId) ?
            <span className="mr-3" style={{ color: "grey" }}>
              Displaying hypothetical students. No real student information is being used.
            </span>
            : ''}
        </div>
      </Footer>
    )
  }
}

export default CustomFooter