import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../../styles/GlobalStyle.css'
import { db } from '../../firebase/Firebase'
//import PersonAvatar from '../../customcomponents/PersonAvatar'
import StudentNavigation from './StudentNavigation'
import CustomFooter from '../../login/CustomFooter'
//import StudentUpload from './StudentUpload'
//import Empty from '../.././customcomponents/Empty'
import {
  getIDFromURL, getQueryStringParam, capitalizeFirstChar,
  isSandboxNoSub, FOLDER_CURRENT, FOLDER_ARCHIVED, deleteIEPSFromCalendarEvents, getIconFromMainSubject, isReadOnlyTeacherId
} from '../../Util'
import ColType from '../../Types'
import { Layout, Row, Col, Icon, Button, message, Modal, Spin } from 'antd'
import StandardNumber from '../../customcomponents/StandardNumber'
import PersonAvatar from '../../customcomponents/PersonAvatar'
const { Content } = Layout
const Promise = require("bluebird")

const errorMessage = (description) => {
  message.destroy()
  message.error(description)
}

const successMessage = (description) => {
  message.destroy()
  message.success(description)
}

class TeacherStudent extends Component {
  state = {
    teacherId: this.props.teacherBaseProps.teacherId,
    studentId: '',
    student: null,
    studentIdx: -1,
    deletingIEPS: {},
    deletePlanModalVisible: false,
    deletePlan: null,
    visible: false,
    deleteDropdownVisible: {},
    currentTab: "Tab-1",
    selectedRows: [],
    moveInProcess: false,
    measurementTableColumns: [{
      title: "Plan",
      dataIndex: "plan",
      key: "plan",
    },
    {
      title: "Content Area",
      dataIndex: "contentArea",
      key: "contentArea",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "On Track",
      dataIndex: "goalOnTrack",
      key: "goalOnTrack",
    },
    {
      title: "Progress Monitoring",
      dataIndex: "progressMonitoringDue",
      key: "progressMonitoringDue",
    }],
  }

  showModal = () => {
    this.setState({
      visible: true,
    })
  }

  hideModal = () => {
    this.setState({
      visible: false,
    })
  }

  handleOk = (e) => {
    this.setState({
      visible: false,
    })
  }

  handleCancel = (e) => {
    this.setState({
      visible: false,
    })
  }

  componentWillReceiveProps(nextProps) {
    var studentId = getQueryStringParam('student')

    if (nextProps.teacherBaseProps.studentDict && nextProps.teacherBaseProps.students) {
      var studentIdx = -1
      for (var i = 0; i < nextProps.teacherBaseProps.students.length; i++) {
        var student = nextProps.teacherBaseProps.students[i]
        if (student.id === studentId) {
          studentIdx = i
          break
        }
      }
      this.setState({
        studentId: studentId,
        student: nextProps.teacherBaseProps.studentDict[studentId],
        studentIdx: studentIdx,
      })
    }
  }

  // Do fetch here
  componentDidMount() {
    document.title = "Student - Dot It"
    var teacherId = getIDFromURL(window.location)
    var studentId = getQueryStringParam('student')
    var archived = getQueryStringParam('pl')
    if (archived !== null && archived !== undefined && archived !== '') {
      var archivedTab = 'Tab-1'
      if (archived === 'false') {
        archivedTab = 'Tab-2'
      }
      this.setState({
        currentTab: archivedTab,
      })
    }
    this.setState({
      teacherId: teacherId,
      studentId: studentId,
    })

    if (this.props.teacherBaseProps.studentDict && this.props.teacherBaseProps.students) {
      var studentIdx = -1
      for (var i = 0; i < this.props.teacherBaseProps.students.length; i++) {
        var student = this.props.teacherBaseProps.students[i]
        if (student.id === studentId) {
          studentIdx = i
          break
        }
      }
      this.setState({
        studentId: studentId,
        student: this.props.teacherBaseProps.studentDict[studentId],
        studentIdx: studentIdx,
      })
    }
  }

  linkToEdit = (e) => {
    e.preventDefault()

    this.props.history.push(
      {
        pathname: '/teacher/update-student/' +
          this.state.teacherId + '?student=' +
          this.state.studentId,
      }
    )
  }

  onDeleteDropdownVisibleChange = (IEPId, visible) => {
    var deleteDropdownVisible = this.state.deleteDropdownVisible
    deleteDropdownVisible[IEPId] = visible
    this.setState({
      deleteDropdownVisible: deleteDropdownVisible,
    })
  }

  deleteSelectedRows = () => {
    this.state.selectedRows.map((IEPId, idx) => {
      this.deleteIEP(IEPId)
      return false
    })
  }

  archiveSelectedRows = () => {
    var promises = []
    this.state.selectedRows.map((IEPId, idx) => {
      return promises.push(db.collection(ColType.iep)
        .doc(IEPId)
        .update({
          isArchived: true
        }))
    })
    Promise.all(promises)
      .then(() => {
        this.setState({
          selectedRows: []
        })
        successMessage("The selected plans have been archived successfully.")
      })
      .catch((e) => {
        errorMessage("An error occurred, try again.")
      })
  }

  unArchiveSelectedRows = () => {
    var promises = []
    this.state.selectedRows.map((IEPId, idx) => {
      return promises.push(db.collection(ColType.iep)
        .doc(IEPId)
        .update({
          isArchived: false
        }))
    })
    Promise.all(promises)
      .then(() => {
        this.setState({
          selectedRows: []
        })
        successMessage("The selected plans have been unarchived successfully.")
      })
      .catch((e) => {
        errorMessage("An error occurred, try again.")
      })
  }

  archiveGoal = (e, IEPId) => {
    e.preventDefault()
    e.stopPropagation()

    db.collection(ColType.iep)
      .doc(IEPId)
      .update({
        isArchived: true,
      })
      .then(() => {
        successMessage("The plan has been archived successfully.")
      })
      .catch((e) => {
        errorMessage("An error occurred, try again.")
      })
  }

  unArchiveGoal = (e, IEPId) => {
    e.preventDefault()
    e.stopPropagation()

    db.collection(ColType.iep)
      .doc(IEPId)
      .update({
        isArchived: false,
      })
      .then(() => {
        successMessage("The plan has been unarchived successfully.")
      })
      .catch((e) => {
        errorMessage("An error occurred, try again.")
      })
  }

  menuClick = ({ item, key, keyPath }) => {
    console.log("menu click", item, key, keyPath)
  }

  render() {
    let prevStudentIdx = 0
    let nextStudentIdx = 0
    let numStudentGoals = 0
    if (this.props.teacherBaseProps.students && this.state.studentIdx !== -1) {
      prevStudentIdx = this.state.studentIdx - 1
      nextStudentIdx = this.state.studentIdx + 1
      if (prevStudentIdx < 0) {
        prevStudentIdx = 0
      }
      if (nextStudentIdx > this.props.teacherBaseProps.students.length - 1) {
        nextStudentIdx = this.props.teacherBaseProps.students.length - 1
      }
    }
    if (this.props.teacherBaseProps.IEPGoals) {
      for (var i = 0; i < this.props.teacherBaseProps.IEPGoals.length; i++) {
        var IEPGoal = this.props.teacherBaseProps.IEPGoals[i]
        if (IEPGoal.studentId === this.state.studentId) {
          numStudentGoals += 1
        }
      }
    }

    return (
      <div>
        <Layout className="content layout-header-mt">
          <Layout>
            <Content className="layout-content">
              <div className="m-lr-auto sub-menu-width">
                {this.state.moveInProcess ?
                  <div className={"flex flex-h-center" +
                    " flex-center font-30 font-bold text-cyan mt-4 pt-4"}>
                    <div>
                      <Spin size="large" className="ant-spin-vlg cyan-spin" />
                    </div>
                  </div>
                  : ''}

                {!this.state.moveInProcess && this.props.teacherBaseProps.teacher && this.state.student ?
                  <Row gutter={50}>
                    <Col xs={24} sm={24} md={18} lg={18} xl={18}>
                      <Row gutter={32}>
                        <Col span={24}>
                          <StudentNavigation
                            history={this.props.history}
                            teacherBaseProps={this.props.teacherBaseProps}
                            onMoveFromCurrent={(studentId, ieps) => {
                              // ieps will always be of length 1 here
                              deleteIEPSFromCalendarEvents(this.props.teacherBaseProps.events, ieps)
                            }}
                            onBeforeMove={(studentId) => {
                              this.setState({
                                moveInProcess: true,
                              })
                            }}
                            onAllGoalsMoved={(studentId) => {
                              this.setState({
                                moveInProcess: false,
                              }, () => {
                                this.props.history.push(
                                  {
                                    pathname: '/teacher/home/' +
                                      this.state.teacherId
                                  }
                                )
                              })
                            }}
                            studentId={this.state.studentId}
                            student={this.props.teacherBaseProps.studentDict[this.state.studentId]}
                            folderFrom={FOLDER_CURRENT}
                            folderTo={FOLDER_ARCHIVED}
                          />
                        </Col>
                        <Col span={24} className="">
                          <div>
                            <Modal
                              title="Upgrade Account"
                              visible={this.state.visible}
                              width={500}
                              footer={null}
                              onOk={this.handleOk}
                              onCancel={this.handleCancel}
                            >
                              <div className="font-20">
                                <div className="mb-2">
                                  This feature is available with an upgraded account.
                                </div>
                                {isSandboxNoSub(this.props.teacherBaseProps) ?
                                  <Link to={"/teacher/subscribe/" +
                                    this.props.teacherBaseProps.teacher.id}
                                    onClick={this.hideModal}
                                    className={"ant-btn ant-btn-primary ant-btn-lg " +
                                      "font-bold-important font-18 w-100"}
                                  >
                                    Upgrade My Account
                                  </Link>
                                  : ''}
                              </div>
                            </Modal>

                            <Modal
                              title={null}
                              visible={this.state.deletePlanModalVisible}
                              footer={null}
                              onCancel={(e) => {
                                this.setState({
                                  deletePlanModalVisible: false,
                                })
                              }}
                            >
                              <div className="font-20 mb-2 mt-4 font-bold">Are you sure you want to permanently delete this goal?</div>
                              <div className="flex flex-v-center">
                                <Button className="ant-btn lg-btn ml-auto mr-2"
                                  disabled={this.state.deletePlan &&
                                    this.state.deletingIEPS.hasOwnProperty(this.state.deletePlan.id)}
                                  onClick={(e) => {
                                    this.setState({
                                      deletePlanModalVisible: false,
                                    })
                                  }}
                                >
                                  No
                                </Button>
                                <Button className="ant-btn ant-btn-primary lg-btn"
                                  disabled={this.state.deletePlan &&
                                    this.state.deletingIEPS.hasOwnProperty(this.state.deletePlan.id)}
                                  onClick={(e) => {
                                    if (this.state.deletePlan) {
                                      this.deleteIEP(e, this.state.deletePlan.id)
                                    }
                                  }}
                                >
                                  {this.state.deletePlan &&
                                    this.state.deletingIEPS.hasOwnProperty(this.state.deletePlan.id) ?
                                    "Deleting..." :
                                    "Yes"
                                  }
                                </Button>
                              </div>
                            </Modal>


                            <Row gutter={32} type="flex">
                              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <div className='font-30 mb-1 mt-3 font-bold'>
                                  Student Goals
                                </div>
                              </Col>

                              {this.props.teacherBaseProps.IEPGoals &&
                                this.props.teacherBaseProps.IEPGoals.map((iep, idx) => {
                                  let colorClass = "#13c2c2"
                                  let colorTextClass = "#08979c"
                                  if (iep.hasOwnProperty("isMTSS") && iep.isMTSS) {
                                    if (iep.iep.tier === "Tier 2") {
                                      colorClass = "#faad14"
                                      colorTextClass = "#d48806"
                                    } else {
                                      colorClass = "#ff4d4f"
                                      colorTextClass = "#cf1322"
                                    }
                                  }
                                  if (iep.studentId !== this.state.studentId) {
                                    return false
                                  }
                                  let goalType = iep.hasOwnProperty("isMTSS") && iep.isMTSS ?
                                    iep.iep.tier === "Tier 2" ?
                                      "Tier 2" :
                                      "Tier 3" :
                                    "IEP"
                                  return <Col xs={24} sm={24} md={24} lg={24} xl={24} key={iep.id}>
                                    <Link to={"/teacher/student-plan/" + this.props.teacherBaseProps.teacher.id + "?student=" + iep.studentId + "&plan=" + iep.id}>
                                      <div className={"br-20 mb-3 background-fff overflow-hidden font-black font-16 ant-shadow ant-shadow-more-hover parent-hover relative"}
                                      >

                                        <div className="p-3">
                                          <div className='flex flex-v-center mb-2'>
                                            <div className="br-50 flex flex-h-center flex-v-center mr-1" style={{ background: colorClass, minWidth: '64px', width: '64px', height: '64px' }}>
                                              <img
                                                src={getIconFromMainSubject(iep.iep.mainSubject, iep.iep.hasOwnProperty('tier') ? iep.iep.tier : '')}
                                                alt="icon"
                                                width={48}
                                              />
                                            </div>
                                            <div className='font-20 font-bold' style={{ color: colorTextClass }}>{goalType}</div>
                                          </div>

                                          <div>
                                            <div className="mb-1 font-24 font-bold">{iep.iep.mainSubject}</div>
                                            <div className="font-20">
                                              <StandardNumber
                                                district={this.props.teacherBaseProps.district}
                                                iep={iep.iep}
                                                className='mr-0'
                                                containerClassName={'pl-2 pr-2 pt-1 pb-1 mr-1 br-100 font-20'}
                                                containerStyle={{ backgroundColor: '#e8e8e8' }}
                                              />
                                              <span>
                                                {capitalizeFirstChar(iep.iep.standardDescription)}
                                              </span>
                                            </div>
                                          </div>

                                        </div>

                                      </div>
                                    </Link>
                                  </Col>
                                })}
                            </Row>

                          </div>
                        </Col>
                      </Row>

                      {numStudentGoals === 0 ?
                        <div className="w-700 m-lr-auto">
                          <h2 className="font-bold mb-2 break-word">{
                            this.props.teacherBaseProps.studentDict[this.state.studentId].firstName +
                            ' has no plans. Would you like to add one?'}</h2>
                          <Row gutter={32}>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                              <Link
                                to={"/teacher/add-goal-student/" + this.state.teacherId + "?student=" + this.state.studentId}
                                onClick={(e) => {
                                  if ((this.props.teacherBaseProps.teacher &&
                                    isReadOnlyTeacherId(this.props.teacherBaseProps.teacher.id)) ||
                                    (this.props.teacherBaseProps.students &&
                                      this.props.teacherBaseProps.students.length === 0)) {
                                    e.preventDefault()
                                  }
                                }}
                              >
                                <div
                                  className={"p-2 br-20 background-fff mb-3 ant-shadow ant-shadow-more-hover parent-hover relative flex flex-v-center font-20 font-black"}
                                >
                                  <div className="br-4 overflow-hidden">
                                    <img src="/add-buttons/add-iep.png" alt="home icon" height="100" />
                                  </div>
                                  <h2 className="ml-2 font-24 font-bold">
                                    Draft IEP
                                  </h2>
                                </div>
                              </Link>

                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                              <Link
                                to={"/teacher/mtss-add-goal-student/" +
                                  this.state.teacherId + "?student=" + this.state.studentId}
                                onClick={(e) => {
                                  if ((this.props.teacherBaseProps.teacher &&
                                    isReadOnlyTeacherId(this.props.teacherBaseProps.teacher.id)) ||
                                    (this.props.teacherBaseProps.students &&
                                      this.props.teacherBaseProps.students.length === 0)) {
                                    e.preventDefault()
                                  }
                                }}
                              >
                                <div
                                  className={"p-2 br-20 background-fff mb-3 ant-shadow ant-shadow-more-hover parent-hover relative flex flex-v-center font-20 font-black"}
                                >
                                  <div className="br-4 overflow-hidden">
                                    <img src="/add-buttons/add-tier.png" alt="home icon" height="100" />
                                  </div>
                                  <h2 className="ml-2 font-24 font-bold">
                                    Draft Tier Plan
                                  </h2>
                                </div>
                              </Link>
                            </Col>
                          </Row>
                        </div>
                        : ''}
                    </Col>
                    <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                      <h1 className='font-24 font-bold mb-1 mt-4'>Students</h1>
                      {this.props.teacherBaseProps.students.map((student, idx) => {
                        return <Link to={"/teacher/student/" + this.props.teacherBaseProps.teacher.id + "?student=" + student.id}
                          id={'nav-' + student.id}>
                          <div className={"p-1 br-20 background-fff mb-1 ant-shadow ant-shadow-more-hover parent-hover relative flex flex-v-center" +
                            (this.state.studentId === student.id ? ' side-menu-item-active' : '')}>
                            <PersonAvatar
                              person={student}
                              size={'large'}
                              containerClassName="font-20"
                              avatarClassName="font-20 font-bold mr-2"
                              personClassName="font-black font-20 ellipsis"
                              personStyle={{ maxWidth: '139px' }}
                            />

                          </div>
                        </Link>
                      })}

                      <Link
                        to={"/teacher/add-student/" + this.state.teacherId + "?r=1"}
                        onClick={(e) => {
                          if (!this.props.teacherBaseProps.teacher) {
                            e.preventDefault()
                          } else if (isReadOnlyTeacherId(this.props.teacherBaseProps.teacher.id)) {
                            e.preventDefault()
                          }
                        }}
                      >
                        <div
                          className={"p-1 br-20 background-fff mb-1 ant-shadow ant-shadow-more-hover parent-hover relative flex flex-v-center font-20 font-black"}
                        >
                          <Icon type="plus" style={{ marginLeft: '11px' }} />
                          <h2 className="mb-0 font-20" style={{ marginLeft: '25px' }}>
                            Add Student
                          </h2>
                        </div>
                      </Link>
                    </Col>
                  </Row>

                  : ''}
              </div>
            </Content>

          </Layout>
        </Layout>
        <CustomFooter teacherId={this.state.teacherId} />
      </div >
    )
  }
}

export default TeacherStudent