import React, { Component } from 'react'
//import { Link } from 'react-router-dom'
import '../styles/GlobalStyle.css'
import { db, firebase } from '../firebase/Firebase'
import { getQueryStringParam, flattenDoc, linkAfterLogin } from '.././Util'
import ColType from '.././Types'
import { message, Spin } from 'antd'
import CustomFooter from './CustomFooter'

/*
const successMessage = (description) => {
    message.success(description)
}
*/

const errorMessage = (description) => {
    message.error(description)
}

class SignInClasslink extends Component {
    state = {
        classLinkCode: '',
        userRole: '',
        user: null,
        signingIn: false,
        hideEmail: false,
        classLinkCodeError: false,
        userNotFoundError: false,
        userArchivedError: false,
    }

    componentDidMount() {
        document.title = "Sign in with Classlink - Dot It"
        let classLinkCode = getQueryStringParam('code')

        this.setState({
            classLinkCode: classLinkCode,
        }, () => {
            this.handleClasslinkSignIn()
        })
    }

    handleClasslinkSignIn = async () => {
        if (!this.state.classLinkCode) {
            return
        }

        const classLinkCode = this.state.classLinkCode

        let headers = new Headers()
        headers.append('Origin', 'https://my.dotit.app')
        headers.append('Content-Type', 'application/json')
        headers.append('Access-Control-Allow-Origin', 'https://my.dotit.app')

        fetch("https://us-central1-education-9d7f3.cloudfunctions.net/app/dotit/classlink-sign-in", {
            method: "POST",
            body: JSON.stringify({
                classLinkCode: classLinkCode,
            }),
            mode: 'cors',
            headers: headers,
        })
            .then(res => res.json())
            .then(data => {
                //console.log('data', data)

                if (data.hasOwnProperty('message') &&
                    data.message === 'Error during Classlink OAuth process') {
                    this.setState({
                        classLinkCodeError: true,
                    })
                } else if (data.hasOwnProperty('message') &&
                    data.message === 'Error account not found') {
                    this.setState({
                        userNotFoundError: true,
                    })
                } else if (data.hasOwnProperty('message') &&
                    data.message === 'Error account archived') {
                    this.setState({
                        userArchivedError: true,
                    })
                } else {
                    firebase.auth().signInWithCustomToken(data.token)
                        .then((userObj) => {
                            const user = userObj.user
                            //console.log('user sign in', user)
                            db.collection(ColType.users)
                                .doc(user.uid)
                                .get()
                                .then((doc) => {
                                    var userInfo = flattenDoc(doc)
                                    if (userInfo.accessLevel === 'superAdmin') {
                                        window.location.href = '/super-admin/add-initial-admin'
                                        return
                                    }
                                    else {
                                        db.collection(userInfo.accessLevel)
                                            .doc(userInfo.refId)
                                            .get()
                                            .then((doc) => {
                                                var userJobInfo = flattenDoc(doc)
                                                linkAfterLogin(userInfo, userJobInfo)
                                            })
                                    }
                                }).catch((error) => {
                                    //console.log('get user info error', error)
                                    this.setState({
                                        submitting: false,
                                    })
                                })
                        })
                        .catch((error) => {
                            //console.log('user sign in error', error)
                        })
                }
            })
            .catch(e => {
                errorMessage('An error occurred, please try again.')
                this.setState({
                    submitting: false,
                })
            })
    }

    render() {
        return (
            <div className="pt-4 pb-70 relative" style={{ minHeight: '100%' }}>
                <div className='absolute-tl w-100 h-100'>
                </div>

                <div className='relative'>
                    <div className="text-center">
                        <a href="https://dotit.app/">
                            <img src='/logo-icon.png' alt='logo' height='120' />
                        </a>
                    </div>
                    <div className="p-4 w-500 m-lr-auto br-20 ant-shadow background-fff">
                        <div className="block w-100 m-lr-auto mb-1 text-center">
                            <img src="/dot-it-sign-in.png" alt="sign-in" height="300" />
                        </div>
                        {!this.state.classLinkCodeError && !this.state.userNotFoundError && !this.state.userArchivedError ?
                            <div className='text-center'>
                                <div className="mb-3 text-center pt-3 font-30 font-bold">Welcome to Dot It</div>
                                <Spin size="large" className="ant-spin-vlg" />
                            </div>
                            : ''}
                        {this.state.classLinkCodeError ?
                            <div className='text-center'>
                                <div className="mb-1 text-center pt-3 font-30 font-bold">Welcome to Dot It</div>
                                <div className='pl-1 pr-1'>
                                    <div className='text-left font-20 p-3 br-4 bg-light-red mb-2'>
                                        Oops, something went wrong. Please try logging in again.
                                    </div>
                                </div>
                                {/*<a href={`https://classlink.com/oauth/authorize?response_type=code&client_id=${DEV_CLASSLINK_CLIENT_ID}&redirect_uri=${DEV_CLASSLINK_REDIRECT_URI}`}>
                                    <img src="https://files.readme.io/efb3f5d-LogInWithclasslink2x.png" alt="classlink log in button" class="" align="" caption="" height="auto" width="auto" loading="lazy" />
                                </a>*/}
                            </div> : ''}
                        {this.state.userNotFoundError ?
                            <div className='text-center'>
                                <div className="mb-1 text-center pt-3 font-30 font-bold">Welcome to Dot It</div>
                                <div className='pl-1 pr-1'>
                                    <div className='text-left font-20 p-3 br-4 bg-light-red mb-2'>
                                        Your account was not found. Please email support@dotit.app for assistance.
                                    </div>
                                </div>
                            </div> : ''}
                        {this.state.userArchivedError ?
                            <div className='text-center'>
                                <div className="mb-1 text-center pt-3 font-30 font-bold">Welcome to Dot It</div>
                                <div className='pl-1 pr-1'>
                                    <div className='text-left font-20 p-3 br-4 bg-light-red mb-2'>
                                        Your account has been archived. If you believe this is an error or if you have any questions, please email support@dotit.app for assistance.
                                    </div>
                                </div>
                            </div> : ''}
                    </div>
                    <CustomFooter
                        theme='dark'
                    />

                </div>
            </div>
        )
    }
}

export default SignInClasslink