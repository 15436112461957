export const graphicOrganizerEmpty_ela_3_aor_8_1_a = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Literal vs. Nonliteral Meaning Chart</title>
    <style>
        body {
            font-family: 'Comic Sans MS', cursive, sans-serif;
            background-color: #f5f5f5;
            margin: 0;
            padding: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .container {
            max-width: 850px;
            width: 100%;
            background-color: white;
            padding: 25px;
            border-radius: 15px;
            box-shadow: 0 4px 8px rgba(0,0,0,0.1);
        }
        h1 {
            color: #233142;
            text-align: center;
            margin-bottom: 5px;
        }
        .subtitle {
            color: #666;
            text-align: center;
            font-style: italic;
            margin-bottom: 20px;
        }
        .instructions {
            background-color: #d9eeff;
            padding: 15px;
            border-radius: 10px;
            margin: 15px 0;
            box-shadow: 0 2px 4px rgba(0,0,0,0.1);
        }
        .step {
            display: flex;
            margin-bottom: 5px;
        }
        .step-number {
            background-color: #233142;
            color: white;
            width: 25px;
            height: 25px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
            font-weight: bold;
        }
        .organizer-sheet {
            margin-top: 20px;
            padding: 15px;
            border: 1px solid #ddd;
            border-radius: 10px;
        }
        .phrase-row {
            margin-bottom: 40px;
            page-break-inside: avoid;
            border: 2px solid #233142;
            border-radius: 10px;
            padding: 15px;
            background-color: white;
        }
        .phrase-header {
            font-weight: bold;
            color: #233142;
            font-size: 18px;
            margin-bottom: 10px;
            padding-bottom: 5px;
            border-bottom: 1px solid #233142;
        }
        .meaning-grid {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 15px;
            margin-bottom: 15px;
        }
        .meaning-box {
            padding: 10px;
            background-color: #f9f9f9;
            border-radius: 8px;
            border: 1px solid #ddd;
        }
        .box-label {
            font-weight: bold;
            color: #233142;
            margin-bottom: 5px;
        }
        .illustration-section {
            border: 1px dashed #233142;
            padding: 10px;
            border-radius: 8px;
            min-height: 150px;
            background-color: #fff8dc;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
        .illustration-label {
            font-weight: bold;
            color: #233142;
            margin-bottom: 10px;
            text-align: center;
        }
        .illustration-instruction {
            font-style: italic;
            color: #666;
            text-align: center;
            font-size: 14px;
        }
        .text-area {
            width: 100%;
            padding: 8px;
            margin-bottom: 5px;
            border: 1px solid #aaa;
            border-radius: 5px;
            font-family: 'Comic Sans MS', cursive, sans-serif;
            font-size: 16px;
            min-height: 60px;
            resize: vertical;
        }
        .text-area:focus {
            outline: none;
            border-color: #233142;
            box-shadow: 0 0 3px rgba(35, 49, 66, 0.5);
        }
        @media print {
            body {
                background-color: white;
                padding: 0;
                margin: 0;
            }
            .container {
                box-shadow: none;
                padding: 15px;
                max-width: 100%;
            }
            .instructions {
                border: 1px solid #ccc;
                box-shadow: none;
            }
            .phrase-row {
                page-break-inside: avoid;
            }
            .organizer-sheet {
                border: none;
            }
            @page {
                margin: 1cm;
            }
        }
    </style>
</head>
<body>
    <div class="container">
        <h1>Literal vs. Nonliteral Meaning Chart</h1>
        <div class="subtitle">Exploring idioms and expressions from "A Trip to Grandma's House"</div>
        
        <div class="instructions">
            <h3>Directions:</h3>
            <div class="step">
                <div class="step-number">1</div>
                <div>Read each phrase from the story "A Trip to Grandma's House."</div>
            </div>
            <div class="step">
                <div class="step-number">2</div>
                <div>In the "Literal Meaning" box, explain what the phrase would mean if taken word-for-word.</div>
            </div>
            <div class="step">
                <div class="step-number">3</div>
                <div>In the "Nonliteral Meaning" box, explain what the phrase actually means in the story.</div>
            </div>
            <div class="step">
                <div class="step-number">4</div>
                <div>Draw a picture that shows either the literal meaning, nonliteral meaning, or both!</div>
            </div>
        </div>
        
        <form class="form-container">
            <div class="organizer-sheet">
                <!-- First Phrase -->
                <div class="phrase-row">
                    <div class="phrase-header">"Ants in your pants"</div>
                    
                    <div class="meaning-grid">
                        <div class="meaning-box">
                            <div class="box-label">Literal Meaning:</div>
                            <div class="box-description">What it sounds like it means:</div>
                            <textarea class="text-area" placeholder="Explain what this would mean if taken literally..."></textarea>
                        </div>
                        
                        <div class="meaning-box">
                            <div class="box-label">Nonliteral Meaning:</div>
                            <div class="box-description">What it really means:</div>
                            <textarea class="text-area" placeholder="Explain what this expression actually means..."></textarea>
                        </div>
                    </div>
                    
                    <div class="illustration-section">
                        <div class="illustration-label">Illustration</div>
                        <div class="illustration-instruction">Draw a picture showing the literal meaning, nonliteral meaning, or both!</div>
                    </div>
                </div>
                
                <!-- Second Phrase -->
                <div class="phrase-row">
                    <div class="phrase-header">"Hit the road"</div>
                    
                    <div class="meaning-grid">
                        <div class="meaning-box">
                            <div class="box-label">Literal Meaning:</div>
                            <div class="box-description">What it sounds like it means:</div>
                            <textarea class="text-area" placeholder="Explain what this would mean if taken literally..."></textarea>
                        </div>
                        
                        <div class="meaning-box">
                            <div class="box-label">Nonliteral Meaning:</div>
                            <div class="box-description">What it really means:</div>
                            <textarea class="text-area" placeholder="Explain what this expression actually means..."></textarea>
                        </div>
                    </div>
                    
                    <div class="illustration-section">
                        <div class="illustration-label">Illustration</div>
                        <div class="illustration-instruction">Draw a picture showing the literal meaning, nonliteral meaning, or both!</div>
                    </div>
                </div>
                
                <!-- Third Phrase -->
                <div class="phrase-row">
                    <div class="phrase-header">"Raining cats and dogs"</div>
                    
                    <div class="meaning-grid">
                        <div class="meaning-box">
                            <div class="box-label">Literal Meaning:</div>
                            <div class="box-description">What it sounds like it means:</div>
                            <textarea class="text-area" placeholder="Explain what this would mean if taken literally..."></textarea>
                        </div>
                        
                        <div class="meaning-box">
                            <div class="box-label">Nonliteral Meaning:</div>
                            <div class="box-description">What it really means:</div>
                            <textarea class="text-area" placeholder="Explain what this expression actually means..."></textarea>
                        </div>
                    </div>
                    
                    <div class="illustration-section">
                        <div class="illustration-label">Illustration</div>
                        <div class="illustration-instruction">Draw a picture showing the literal meaning, nonliteral meaning, or both!</div>
                    </div>
                </div>
                
                <!-- Fourth Phrase -->
                <div class="phrase-row">
                    <div class="phrase-header">"Grab a bite"</div>
                    
                    <div class="meaning-grid">
                        <div class="meaning-box">
                            <div class="box-label">Literal Meaning:</div>
                            <div class="box-description">What it sounds like it means:</div>
                            <textarea class="text-area" placeholder="Explain what this would mean if taken literally..."></textarea>
                        </div>
                        
                        <div class="meaning-box">
                            <div class="box-label">Nonliteral Meaning:</div>
                            <div class="box-description">What it really means:</div>
                            <textarea class="text-area" placeholder="Explain what this expression actually means..."></textarea>
                        </div>
                    </div>
                    
                    <div class="illustration-section">
                        <div class="illustration-label">Illustration</div>
                        <div class="illustration-instruction">Draw a picture showing the literal meaning, nonliteral meaning, or both!</div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</body>
</html>`;

export const graphicOrganizerFilled_ela_3_aor_8_1_a = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Literal vs. Nonliteral Meaning Chart</title>
    <style>
        body {
            font-family: 'Comic Sans MS', cursive, sans-serif;
            background-color: #f5f5f5;
            margin: 0;
            padding: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .container {
            max-width: 850px;
            width: 100%;
            background-color: white;
            padding: 25px;
            border-radius: 15px;
            box-shadow: 0 4px 8px rgba(0,0,0,0.1);
        }
        h1 {
            color: #233142;
            text-align: center;
            margin-bottom: 5px;
        }
        .subtitle {
            color: #666;
            text-align: center;
            font-style: italic;
            margin-bottom: 20px;
        }
        .instructions {
            background-color: #d9eeff;
            padding: 15px;
            border-radius: 10px;
            margin: 15px 0;
            box-shadow: 0 2px 4px rgba(0,0,0,0.1);
        }
        .step {
            display: flex;
            margin-bottom: 5px;
        }
        .step-number {
            background-color: #233142;
            color: white;
            width: 25px;
            height: 25px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
            font-weight: bold;
        }
        .organizer-sheet {
            margin-top: 20px;
            padding: 15px;
            border: 1px solid #ddd;
            border-radius: 10px;
        }
        .phrase-row {
            margin-bottom: 40px;
            page-break-inside: avoid;
            border: 2px solid #233142;
            border-radius: 10px;
            padding: 15px;
            background-color: white;
        }
        .phrase-header {
            font-weight: bold;
            color: #233142;
            font-size: 18px;
            margin-bottom: 10px;
            padding-bottom: 5px;
            border-bottom: 1px solid #233142;
        }
        .meaning-grid {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 15px;
            margin-bottom: 15px;
        }
        .meaning-box {
            padding: 10px;
            background-color: #f9f9f9;
            border-radius: 8px;
            border: 1px solid #ddd;
        }
        .box-label {
            font-weight: bold;
            color: #233142;
            margin-bottom: 5px;
        }
        .illustration-section {
            border: 1px dashed #233142;
            padding: 10px;
            border-radius: 8px;
            min-height: 150px;
            background-color: #fff8dc;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
        .illustration-label {
            font-weight: bold;
            color: #233142;
            margin-bottom: 10px;
            text-align: center;
        }
        .illustration-instruction {
            font-style: italic;
            color: #666;
            text-align: center;
            font-size: 14px;
        }
        .text-area {
            width: 100%;
            padding: 8px;
            margin-bottom: 5px;
            border: 1px solid #aaa;
            border-radius: 5px;
            font-family: 'Comic Sans MS', cursive, sans-serif;
            font-size: 16px;
            min-height: 60px;
            resize: vertical;
        }
        .text-area:focus {
            outline: none;
            border-color: #233142;
            box-shadow: 0 0 3px rgba(35, 49, 66, 0.5);
        }
        .filled-illustration {
            width: 100%;
            height: 150px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-style: italic;
            color: #555;
            text-align: center;
            border: 1px dashed #999;
            border-radius: 5px;
            background-color: #f9f9f9;
        }
        @media print {
            body {
                background-color: white;
                padding: 0;
                margin: 0;
            }
            .container {
                box-shadow: none;
                padding: 15px;
                max-width: 100%;
            }
            .instructions {
                border: 1px solid #ccc;
                box-shadow: none;
            }
            .phrase-row {
                page-break-inside: avoid;
            }
            .organizer-sheet {
                border: none;
            }
            @page {
                margin: 1cm;
            }
        }
    </style>
</head>
<body>
    <div class="container">
        <h1>Literal vs. Nonliteral Meaning Chart</h1>
        <div class="subtitle">Exploring idioms and expressions from "A Trip to Grandma's House"</div>
        
        <div class="instructions">
            <h3>Directions:</h3>
            <div class="step">
                <div class="step-number">1</div>
                <div>Read each phrase from the story "A Trip to Grandma's House."</div>
            </div>
            <div class="step">
                <div class="step-number">2</div>
                <div>In the "Literal Meaning" box, explain what the phrase would mean if taken word-for-word.</div>
            </div>
            <div class="step">
                <div class="step-number">3</div>
                <div>In the "Nonliteral Meaning" box, explain what the phrase actually means in the story.</div>
            </div>
            <div class="step">
                <div class="step-number">4</div>
                <div>Draw a picture that shows either the literal meaning, nonliteral meaning, or both!</div>
            </div>
        </div>
        
        <form class="form-container">
            <div class="organizer-sheet">
                <!-- First Phrase -->
                <div class="phrase-row">
                    <div class="phrase-header">"Ants in your pants"</div>
                    
                    <div class="meaning-grid">
                        <div class="meaning-box">
                            <div class="box-label">Literal Meaning:</div>
                            <div class="box-description">What it sounds like it means:</div>
                            <textarea class="text-area">It sounds like you actually have real ants crawling inside your pants, which would make you jump around because they're tickling you or biting you.</textarea>
                        </div>
                        
                        <div class="meaning-box">
                            <div class="box-label">Nonliteral Meaning:</div>
                            <div class="box-description">What it really means:</div>
                            <textarea class="text-area">It really means that someone is very excited or can't sit still. In the story, Lucy was jumping around because she was excited, not because she had real ants in her pants.</textarea>
                        </div>
                    </div>
                    
                    <div class="illustration-section">
                        <div class="illustration-label">Illustration</div>
                        <div class="filled-illustration">
                            [Drawing of a girl jumping up and down excitedly on one side, and on the other side, a funny picture of pants with tiny ants crawling inside them]
                        </div>
                    </div>
                </div>
                
                <!-- Second Phrase -->
                <div class="phrase-row">
                    <div class="phrase-header">"Hit the road"</div>
                    
                    <div class="meaning-grid">
                        <div class="meaning-box">
                            <div class="box-label">Literal Meaning:</div>
                            <div class="box-description">What it sounds like it means:</div>
                            <textarea class="text-area">It sounds like you would actually hit or punch the road with your hand or foot, which Lucy thought might hurt. It's like physically striking the pavement.</textarea>
                        </div>
                        
                        <div class="meaning-box">
                            <div class="box-label">Nonliteral Meaning:</div>
                            <div class="box-description">What it really means:</div>
                            <textarea class="text-area">It really means to start traveling or begin a journey. Grandma was telling Lucy it was time to start their trip, not that they should actually hit anything.</textarea>
                        </div>
                    </div>
                    
                    <div class="illustration-section">
                        <div class="illustration-label">Illustration</div>
                        <div class="filled-illustration">
                            [Drawing of a car driving down a road with "Let's go!" in a speech bubble, and beside it a silly drawing of a person hitting the road with their fist]
                        </div>
                    </div>
                </div>
                
                <!-- Third Phrase -->
                <div class="phrase-row">
                    <div class="phrase-header">"Raining cats and dogs"</div>
                    
                    <div class="meaning-grid">
                        <div class="meaning-box">
                            <div class="box-label">Literal Meaning:</div>
                            <div class="box-description">What it sounds like it means:</div>
                            <textarea class="text-area">It sounds like actual cats and dogs are falling from the sky instead of raindrops. Lucy was worried that real animals would fall from the clouds!</textarea>
                        </div>
                        
                        <div class="meaning-box">
                            <div class="box-label">Nonliteral Meaning:</div>
                            <div class="box-description">What it really means:</div>
                            <textarea class="text-area">It really means that it's raining very heavily or pouring down hard. Grandma was telling Lucy that there would be a lot of rain coming down, not animals.</textarea>
                        </div>
                    </div>
                    
                    <div class="illustration-section">
                        <div class="illustration-label">Illustration</div>
                        <div class="filled-illustration">
                            [Drawing of heavy rain falling from dark clouds on one side, and on the other side a funny picture of cats and dogs falling from the sky with surprised expressions]
                        </div>
                    </div>
                </div>
                
                <!-- Fourth Phrase -->
                <div class="phrase-row">
                    <div class="phrase-header">"Grab a bite"</div>
                    
                    <div class="meaning-grid">
                        <div class="meaning-box">
                            <div class="box-label">Literal Meaning:</div>
                            <div class="box-description">What it sounds like it means:</div>
                            <textarea class="text-area">It sounds like you would grab something that might bite you, or maybe that the food would bite you! Lucy was worried the food might bite them.</textarea>
                        </div>
                        
                        <div class="meaning-box">
                            <div class="box-label">Nonliteral Meaning:</div>
                            <div class="box-description">What it really means:</div>
                            <textarea class="text-area">It really means to get something to eat or have a meal. Grandma was telling Lucy it was time to eat lunch or dinner, not that anything would bite them.</textarea>
                        </div>
                    </div>
                    
                    <div class="illustration-section">
                        <div class="illustration-label">Illustration</div>
                        <div class="filled-illustration">
                            [Drawing of a grandmother and girl sitting at a table with sandwiches on one side, and on the other side a silly drawing of a sandwich with teeth trying to bite someone's hand]
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</body>
</html>`;

export const graphicOrganizerPrintable_ela_3_aor_8_1_a = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Literal vs. Nonliteral Meaning Chart</title>
    <style>
        body {
            font-family: 'Comic Sans MS', cursive, sans-serif;
            background-color: #f5f5f5;
            margin: 0;
            padding: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .container {
            max-width: 850px;
            width: 100%;
            background-color: white;
            padding: 25px;
            border-radius: 15px;
            box-shadow: 0 4px 8px rgba(0,0,0,0.1);
        }
        h1 {
            color: #233142;
            text-align: center;
            margin-bottom: 5px;
        }
        .subtitle {
            color: #666;
            text-align: center;
            font-style: italic;
            margin-bottom: 20px;
        }
        .instructions {
            background-color: #d9eeff;
            padding: 15px;
            border-radius: 10px;
            margin: 15px 0;
            box-shadow: 0 2px 4px rgba(0,0,0,0.1);
        }
        .step {
            display: flex;
            margin-bottom: 5px;
        }
        .step-number {
            background-color: #233142;
            color: white;
            width: 25px;
            height: 25px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
            font-weight: bold;
        }
        .organizer-sheet {
            margin-top: 20px;
            padding: 15px;
            border: 1px solid #ddd;
            border-radius: 10px;
        }
        .phrase-row {
            margin-bottom: 40px;
            page-break-inside: avoid;
            border: 2px solid #233142;
            border-radius: 10px;
            padding: 15px;
            background-color: white;
        }
        .phrase-header {
            font-weight: bold;
            color: #233142;
            font-size: 18px;
            margin-bottom: 10px;
            padding-bottom: 5px;
            border-bottom: 1px solid #233142;
        }
        .meaning-grid {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 15px;
            margin-bottom: 15px;
        }
        .meaning-box {
            padding: 10px;
            background-color: #f9f9f9;
            border-radius: 8px;
            border: 1px solid #ddd;
        }
        .box-label {
            font-weight: bold;
            color: #233142;
            margin-bottom: 5px;
        }
        .box-description {
            margin-bottom: 5px;
            font-style: italic;
            font-size: 14px;
        }
        .writing-lines {
            width: 100%;
            min-height: 80px;
            border: 1px solid #aaa;
            border-radius: 5px;
            margin-bottom: 5px;
            background-color: #ffffff;
            background-image: linear-gradient(#e5e5e5 1px, transparent 1px);
            background-size: 100% 20px;
            position: relative;
            box-sizing: border-box;
        }
        .illustration-section {
            border: 1px dashed #233142;
            padding: 10px;
            border-radius: 8px;
            min-height: 180px;
            background-color: #fff8dc;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
        .illustration-label {
            font-weight: bold;
            color: #233142;
            margin-bottom: 10px;
            text-align: center;
        }
        .illustration-instruction {
            font-style: italic;
            color: #666;
            text-align: center;
            font-size: 14px;
            margin-bottom: 10px;
        }
        .drawing-area {
            width: 90%;
            height: 130px;
            border: 1px solid #ccc;
            background-color: white;
            border-radius: 5px;
        }
        
        /* Print styles */
        @media print {
            body {
                background-color: white;
                padding: 0;
                margin: 0;
            }
            .container {
                box-shadow: none;
                padding: 15px;
                max-width: 100%;
            }
            .instructions {
                border: 1px solid #ccc;
                box-shadow: none;
            }
            .phrase-row {
                page-break-inside: avoid;
            }
            .organizer-sheet {
                border: none;
            }
            @page {
                margin: 1cm;
            }
        }
    </style>
</head>
<body>
    <div class="container">
        <h1>Literal vs. Nonliteral Meaning Chart</h1>
        <div class="subtitle">Exploring idioms and expressions from "A Trip to Grandma's House"</div>
        
        <div class="instructions">
            <h3>Directions:</h3>
            <div class="step">
                <div class="step-number">1</div>
                <div>Read each phrase from the story "A Trip to Grandma's House."</div>
            </div>
            <div class="step">
                <div class="step-number">2</div>
                <div>In the "Literal Meaning" box, explain what the phrase would mean if taken word-for-word.</div>
            </div>
            <div class="step">
                <div class="step-number">3</div>
                <div>In the "Nonliteral Meaning" box, explain what the phrase actually means in the story.</div>
            </div>
            <div class="step">
                <div class="step-number">4</div>
                <div>Draw a picture that shows either the literal meaning, nonliteral meaning, or both!</div>
            </div>
        </div>
        
        <div class="organizer-sheet">
            <!-- First Phrase -->
            <div class="phrase-row">
                <div class="phrase-header">"Ants in your pants"</div>
                
                <div class="meaning-grid">
                    <div class="meaning-box">
                        <div class="box-label">Literal Meaning:</div>
                        <div class="box-description">What it sounds like it means:</div>
                        <div class="writing-lines"></div>
                    </div>
                    
                    <div class="meaning-box">
                        <div class="box-label">Nonliteral Meaning:</div>
                        <div class="box-description">What it really means:</div>
                        <div class="writing-lines"></div>
                    </div>
                </div>
                
                <div class="illustration-section">
                    <div class="illustration-label">Illustration</div>
                    <div class="illustration-instruction">Draw a picture showing the literal meaning, nonliteral meaning, or both!</div>
                    <div class="drawing-area"></div>
                </div>
            </div>
            
            <!-- Second Phrase -->
            <div class="phrase-row">
                <div class="phrase-header">"Hit the road"</div>
                
                <div class="meaning-grid">
                    <div class="meaning-box">
                        <div class="box-label">Literal Meaning:</div>
                        <div class="box-description">What it sounds like it means:</div>
                        <div class="writing-lines"></div>
                    </div>
                    
                    <div class="meaning-box">
                        <div class="box-label">Nonliteral Meaning:</div>
                        <div class="box-description">What it really means:</div>
                        <div class="writing-lines"></div>
                    </div>
                </div>
                
                <div class="illustration-section">
                    <div class="illustration-label">Illustration</div>
                    <div class="illustration-instruction">Draw a picture showing the literal meaning, nonliteral meaning, or both!</div>
                    <div class="drawing-area"></div>
                </div>
            </div>
            
            <!-- Third Phrase -->
            <div class="phrase-row">
                <div class="phrase-header">"Raining cats and dogs"</div>
                
                <div class="meaning-grid">
                    <div class="meaning-box">
                        <div class="box-label">Literal Meaning:</div>
                        <div class="box-description">What it sounds like it means:</div>
                        <div class="writing-lines"></div>
                    </div>
                    
                    <div class="meaning-box">
                        <div class="box-label">Nonliteral Meaning:</div>
                        <div class="box-description">What it really means:</div>
                        <div class="writing-lines"></div>
                    </div>
                </div>
                
                <div class="illustration-section">
                    <div class="illustration-label">Illustration</div>
                    <div class="illustration-instruction">Draw a picture showing the literal meaning, nonliteral meaning, or both!</div>
                    <div class="drawing-area"></div>
                </div>
            </div>
            
            <!-- Fourth Phrase -->
            <div class="phrase-row">
                <div class="phrase-header">"Grab a bite"</div>
                
                <div class="meaning-grid">
                    <div class="meaning-box">
                        <div class="box-label">Literal Meaning:</div>
                        <div class="box-description">What it sounds like it means:</div>
                        <div class="writing-lines"></div>
                    </div>
                    
                    <div class="meaning-box">
                        <div class="box-label">Nonliteral Meaning:</div>
                        <div class="box-description">What it really means:</div>
                        <div class="writing-lines"></div>
                    </div>
                </div>
                
                <div class="illustration-section">
                    <div class="illustration-label">Illustration</div>
                    <div class="illustration-instruction">Draw a picture showing the literal meaning, nonliteral meaning, or both!</div>
                    <div class="drawing-area"></div>
                </div>
            </div>
        </div>
    </div>
</body>
</html>`;

export const studentResource_ela_3_aor_8_1_a = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Understanding Idioms: A Trip to Grandma's House</title>
    <style>
        body {
            font-family: 'Comic Sans MS', cursive, sans-serif;
            background-color: #f5f5f5;
            margin: 0;
            padding: 20px;
            display: flex;
            justify-content: center;
        }
        .container {
            max-width: 800px;
            width: 100%;
            background-color: white;
            padding: 25px;
            border-radius: 15px;
            box-shadow: 0 4px 8px rgba(0,0,0,0.1);
        }
        h1 {
            color: #233142;
            text-align: center;
            border-bottom: 2px solid #233142;
            padding-bottom: 10px;
        }
        .subtitle {
            color: #666;
            text-align: center;
            font-style: italic;
            margin-top: -10px;
            margin-bottom: 20px;
        }
        p {
            line-height: 1.6;
            font-size: 16px;
            margin-bottom: 15px;
        }
        .highlight-word {
            font-weight: bold;
            background-color: #fff8dc;
            padding: 0 3px;
            border-radius: 3px;
        }
        .vocabulary {
            background-color: #e8f7ee;
            padding: 15px;
            border-radius: 8px;
            margin-bottom: 20px;
            border: 1px dashed #3fa672;
        }
        .vocabulary-title {
            font-weight: bold;
            color: #233142;
            margin-bottom: 5px;
        }
        .instructions {
            background-color: #d9eeff;
            padding: 15px;
            border-radius: 8px;
            margin: 20px 0;
            box-shadow: 0 2px 4px rgba(0,0,0,0.05);
        }
        .fun-fact {
            background-color: #fff8dc;
            padding: 15px;
            margin: 15px 0;
            border-radius: 8px;
            border: 1px solid #ddd;
        }
        .fun-fact-title {
            font-weight: bold;
            color: #233142;
            margin-bottom: 5px;
        }
        .dialog {
            margin-left: 20px;
        }
        @media print {
            body {
                background-color: white;
                padding: 0;
                margin: 0;
            }
            .container {
                box-shadow: none;
                padding: 0;
                max-width: 100%;
            }
            .instructions, .vocabulary, .fun-fact {
                border: 1px solid #ccc;
                box-shadow: none;
            }
            @page {
                margin: 1cm;
            }
        }
    </style>
</head>
<body>
    <div class="container">
        <h1>A Trip to Grandma's House</h1>
        <div class="subtitle">Learning About Idioms and Expressions</div>
        
        <div class="vocabulary">
            <div class="vocabulary-title">Key Vocabulary:</div>
            <strong>idiom</strong> - a phrase that means something different from the actual words<br>
            <strong>literal</strong> - taking words in their exact meaning<br>
            <strong>nonliteral</strong> - when words mean something different than their exact meaning
        </div>
        
        <div class="instructions">
            <h3>Reading Instructions:</h3>
            <p>As you read, look for the <strong>highlighted expressions</strong> that Lucy misunderstands. These are called idioms - phrases that mean something different from their actual words!</p>
        </div>
        
        <p class="dialog">"Do you have <span class="highlight-word">ants in your pants</span>, Lucy?" Grandma laughed as I jumped around.</p>
        
        <p class="dialog">"No, Grandma, it just means I'm excited!" I giggled back.</p>
        
        <p class="dialog">"Well, let's <span class="highlight-word">hit the road</span>," she smiled.</p>
        
        <p class="dialog">I looked down at the road. "Hit the road? But Grandma, wouldn't that hurt?"</p>
        
        <p class="dialog">Grandma laughed again. "It doesn't really mean to hit anything, Lucy. It's just a fun way to say 'let's start our trip!'"</p>
        
        <p class="dialog">As we drove along, Grandma pointed to the sky. "Looks like it's about to <span class="highlight-word">rain cats and dogs</span>!"</p>
        
        <p class="dialog">I stared at the clouds nervously. "Oh no, Grandma! Cat and dogs will fall from the sky?"</p>
        
        <p class="dialog">Grandma smiled warmly. "No, no. Don't worry! It just means it's going to rain really hard."</p>
        
        <p class="dialog">When we finally reached Grandma's house, she said, "Time to <span class="highlight-word">grab a bite</span>."</p>
        
        <p class="dialog">I looked around. "Grab a bite? Will the food bite us?"</p>
        
        <p class="dialog">Grandma chuckled. "Don't worry, dear. It just means it's time to eat."</p>
        
        <p class="dialog">As we sat and enjoyed our sandwiches, I smiled. I liked learning Grandma's special kind of words. Language sure was funny, I thought.</p>
        
        <div class="fun-fact">
            <div class="fun-fact-title">Fun Fact!</div>
            <p>Idioms are used in all languages around the world! In English, we have thousands of idioms that we use every day. When someone says they're "feeling blue," they don't actually turn blue - they're just feeling sad!</p>
        </div>
        
        <div class="fun-fact">
            <div class="fun-fact-title">Think About It:</div>
            <p>Can you think of other idioms you've heard? What do they really mean? Why do you think we use idioms instead of just saying exactly what we mean?</p>
        </div>
    </div>
</body>
</html>`;

export const teacherResource_ela_3_aor_8_1_a = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Understanding Idioms: A Trip to Grandma's House</title>
    <style>
        body {
            font-family: 'Comic Sans MS', cursive, sans-serif;
            background-color: #f5f5f5;
            margin: 0;
            padding: 20px;
            display: flex;
            justify-content: center;
        }
        .container {
            max-width: 800px;
            width: 100%;
            background-color: white;
            padding: 25px;
            border-radius: 15px;
            box-shadow: 0 4px 8px rgba(0,0,0,0.1);
        }
        h1 {
            color: #233142;
            text-align: center;
            border-bottom: 2px solid #233142;
            padding-bottom: 10px;
        }
        .subtitle {
            color: #666;
            text-align: center;
            font-style: italic;
            margin-top: -10px;
            margin-bottom: 20px;
        }
        p {
            line-height: 1.6;
            font-size: 16px;
            margin-bottom: 15px;
        }
        .highlight-word {
            font-weight: bold;
            background-color: #fff8dc;
            padding: 0 3px;
            border-radius: 3px;
        }
        .vocabulary {
            background-color: #e8f7ee;
            padding: 15px;
            border-radius: 8px;
            margin-bottom: 20px;
            border: 1px dashed #3fa672;
        }
        .vocabulary-title {
            font-weight: bold;
            color: #233142;
            margin-bottom: 5px;
        }
        .instructions {
            background-color: #d9eeff;
            padding: 15px;
            border-radius: 8px;
            margin: 20px 0;
            box-shadow: 0 2px 4px rgba(0,0,0,0.05);
        }
        .fun-fact {
            background-color: #fff8dc;
            padding: 15px;
            margin: 15px 0;
            border-radius: 8px;
            border: 1px solid #ddd;
        }
        .fun-fact-title {
            font-weight: bold;
            color: #233142;
            margin-bottom: 5px;
        }
        .dialog {
            margin-left: 20px;
        }
        @media print {
            body {
                background-color: white;
                padding: 0;
                margin: 0;
            }
            .container {
                box-shadow: none;
                padding: 0;
                max-width: 100%;
            }
            .instructions, .vocabulary, .fun-fact {
                border: 1px solid #ccc;
                box-shadow: none;
            }
            @page {
                margin: 1cm;
            }
        }
    </style>
</head>
<body>
    <div class="container">
        <h1>A Trip to Grandma's House</h1>
        <div class="subtitle">Learning About Idioms and Expressions</div>
        
        <div class="vocabulary">
            <div class="vocabulary-title">Key Vocabulary:</div>
            <strong>idiom</strong> - a phrase that means something different from the actual words<br>
            <strong>literal</strong> - taking words in their exact meaning<br>
            <strong>nonliteral</strong> - when words mean something different than their exact meaning
        </div>
        
        <div class="instructions">
            <h3>Reading Instructions:</h3>
            <p>As you read, look for the <strong>highlighted expressions</strong> that Lucy misunderstands. These are called idioms - phrases that mean something different from their actual words!</p>
        </div>
        
        <p class="dialog">"Do you have <span class="highlight-word">ants in your pants</span>, Lucy?" Grandma laughed as I jumped around.</p>
        
        <p class="dialog">"No, Grandma, it just means I'm excited!" I giggled back.</p>
        
        <p class="dialog">"Well, let's <span class="highlight-word">hit the road</span>," she smiled.</p>
        
        <p class="dialog">I looked down at the road. "Hit the road? But Grandma, wouldn't that hurt?"</p>
        
        <p class="dialog">Grandma laughed again. "It doesn't really mean to hit anything, Lucy. It's just a fun way to say 'let's start our trip!'"</p>
        
        <p class="dialog">As we drove along, Grandma pointed to the sky. "Looks like it's about to <span class="highlight-word">rain cats and dogs</span>!"</p>
        
        <p class="dialog">I stared at the clouds nervously. "Oh no, Grandma! Cat and dogs will fall from the sky?"</p>
        
        <p class="dialog">Grandma smiled warmly. "No, no. Don't worry! It just means it's going to rain really hard."</p>
        
        <p class="dialog">When we finally reached Grandma's house, she said, "Time to <span class="highlight-word">grab a bite</span>."</p>
        
        <p class="dialog">I looked around. "Grab a bite? Will the food bite us?"</p>
        
        <p class="dialog">Grandma chuckled. "Don't worry, dear. It just means it's time to eat."</p>
        
        <p class="dialog">As we sat and enjoyed our sandwiches, I smiled. I liked learning Grandma's special kind of words. Language sure was funny, I thought.</p>
        
        <div class="fun-fact">
            <div class="fun-fact-title">Fun Fact!</div>
            <p>Idioms are used in all languages around the world! In English, we have thousands of idioms that we use every day. When someone says they're "feeling blue," they don't actually turn blue - they're just feeling sad!</p>
        </div>
        
        <div class="fun-fact">
            <div class="fun-fact-title">Think About It:</div>
            <p>Can you think of other idioms you've heard? What do they really mean? Why do you think we use idioms instead of just saying exactly what we mean?</p>
        </div>
        
        <!-- Teacher's Notes Section -->
        <div style="margin-top: 40px; border-top: 2px dashed #233142; padding-top: 20px;">
            <h3 style="color: #233142;">Teacher's Notes</h3>
            <p>This section is intended for teacher reference only. Consider printing student copies without this section.</p>
            
            <div style="background-color: #e8f7ee; padding: 15px; border-radius: 8px; margin: 15px 0; border: 1px dashed #3fa672;">
                <h4 style="color: #2e7d32; margin-top: 0;">Key Objectives:</h4>
                <ul>
                    <li>Help students distinguish the difference between literal and nonliteral meanings of words and phrases.</li>
                    <li>Provide practice identifying and interpreting common idioms and expressions.</li>
                </ul>
            </div>
            
            <div style="background-color: #d9eeff; padding: 15px; border-radius: 8px; margin: 15px 0;">
                <h4 style="color: #233142; margin-top: 0;">Discussion Questions:</h4>
                <ol>
                    <li>When Grandma said it was raining "cats and dogs," what did she really mean?</li>
                    <li>Why do you think the author included funny misunderstandings for Lucy?</li>
                    <li>Can you share another example of words used in a nonliteral way?</li>
                </ol>
            </div>
            
            <div style="background-color: #fff8dc; padding: 15px; border-radius: 8px; margin: 15px 0; border: 1px solid #ddd;">
                <h4 style="color: #233142; margin-top: 0;">Guidance:</h4>
                <ul>
                    <li>After reading aloud, discuss the idioms introduced within the story.</li>
                    <li>Have students match the phrase with the appropriate nonliteral meaning through small-group activities or partner discussion.</li>
                    <li>Encourage students to illustrate their favorite idiom from the passage, labeling literal and nonliteral meanings.</li>
                </ul>
            </div>
        </div>
    </div>
</body>
</html>`;
