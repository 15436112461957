import React, { Component } from 'react'
//import { Link } from 'react-router-dom'
import '../styles/GlobalStyle.css'
//import CustomFooter from '../login/CustomFooter'
//import DistrictStats from './DistrictStats'
import ColType from '../Types'
import { db, firebase } from '../firebase/Firebase'
//import { getIDFromURL, flattenDoc, getQueryStringParam } from '../Util'
import { Form, Input, Button, Icon, Modal, notification, Row, Col } from 'antd'
const FormItem = Form.Item
const TextArea = Input.TextArea

const successMessage = (description) => {
    notification.open({
        type: 'success',
        message: description,
        placement: 'bottomRight'
    })
}

const errorMessage = (description) => {
    notification.open({
        type: 'error',
        message: description,
        placement: 'bottomRight'
    })
}

class CoachingNotesForm extends Component {
    state = {
        modalVisible: false,
        submitting: false,
        formData: [
            {
                label: 'Title',
                fieldDecorator: 'reportTitle',
                placeholder: '',
            },
            {
                label: 'Description',
                fieldDecorator: 'reportDescription',
                placeholder: '',
            },
            {
                label: 'Goal',
                fieldDecorator: 'reportReflection',
                placeholder: '',
            },
            {
                label: "Pre-conference notes",
                fieldDecorator: 'adminIncentive',
                placeholder: '',
            },
            {
                label: "Co-teaching notes",
                fieldDecorator: 'adminChangeEnvironment',
                placeholder: '',
            },
            {
                label: "Post-conference notes",
                fieldDecorator: 'adminProgress',
                placeholder: '',
            },
            {
                label: 'Reflection',
                fieldDecorator: 'adminNextSteps',
                placeholder: '',
            },
            {
                label: "Decisions",
                fieldDecorator: 'coachesSupport',
                placeholder: '',
            }
        ]
    }

    showModal = () => {
        this.setState({
            modalVisible: true,
        })
    }

    handleOk = () => {
        this.setState({
            modalVisible: false,
        })
    }

    handleCancel = () => {
        this.setState({
            modalVisible: false,
        })
    }


    handleSubmit = (e) => {
        e.preventDefault()
        e.stopPropagation()

        if (!(this.props.districtId && this.props.author && this.props.authorId && this.props.teacherId)) {
            console.error('missing props in report form', this.props)
            return
        }

        this.props.form.validateFields((err, values) => {
            if (!err) {
                //console.log('Received values of form: ', values)

                this.setState({
                    submitting: true,
                })

                Object.keys(values).forEach((key) => {
                    if (!values[key]) {
                        values[key] = ''
                    }
                })

                values.districtId = this.props.districtId
                values.schoolId = this.props.teacher.schoolId
                values.author = this.props.author
                values.authorId = this.props.authorId
                values.teacherId = this.props.teacherId
                values.updatedAt = firebase.firestore.FieldValue.serverTimestamp()
                values.timeStamp = firebase.firestore.FieldValue.serverTimestamp()

                //console.log(values)

                db.collection(ColType.coachingReports)
                    .add(values)
                    .then((docRef) => {
                        console.log("Document written with ID: ", docRef.id)
                        successMessage('Report added successfully.')
                        this.setState({
                            submitting: false,
                        })
                        this.props.form.resetFields()
                        this.handleCancel()
                    })
                    .catch((error) => {
                        console.error("Error adding document: ", error)
                        errorMessage('Error adding report, please try again.')
                        this.setState({
                            submitting: false,
                        })
                    })
            }
        })
    }

    render() {
        const { getFieldDecorator } = this.props.form

        const formItemBlockLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        }

        return (
            <div className='ml-auto'>
                <Button type="primary" className="lg-btn font-16" onClick={this.showModal}>
                    <Icon type="plus" className='font-16' /> <span className='font-16'>Add coaching report</span>
                </Button>
                <Modal
                    title="Add coaching report"
                    visible={this.state.modalVisible}
                    width={700}
                    footer={null}
                    onCancel={this.handleCancel}
                >
                    <Form onSubmit={this.handleSubmit} className="block-label-form">
                        <Row gutter={32}>
                            {this.state.formData.map((item, index) => {
                                return <Col span={item['label'] === 'Title' ||
                                    item['label'] === 'Description' ? 24 : 12}>
                                    <FormItem {...formItemBlockLayout} label={item.label + '::'}>
                                        {getFieldDecorator(item.fieldDecorator, {
                                            // remove required, all optional
                                            rules: [{ required: false, message: 'Required.' }],
                                        })(
                                            item['label'] === 'Title' ?
                                                <Input size={"large"} placeholder={item.placeholder} /> :
                                                <TextArea rows={4} size={"large"} placeholder={item.placeholder} />
                                        )}
                                    </FormItem>
                                </Col>
                            })}
                        </Row>

                        <FormItem className="mb-0">
                            <Button
                                type="primary"
                                htmlType="submit"
                                className="lg-btn float-right"
                                disabled={this.state.submitting}
                            >
                                {this.state.submitting ?
                                    <span><Icon type="loading" className="mr-1" />Adding coaching report...</span> :
                                    <span><Icon type="plus" /> Add coaching report</span>}
                            </Button>
                        </FormItem>
                    </Form>
                </Modal>
            </div>
        )
    }
}

export default Form.create()(CoachingNotesForm)