import React, { Component } from 'react'
import { Link } from 'react-router-dom'
//import { Route } from 'react-router-dom'
//import StudentReport from './reports/StudentReport'
import '../styles/GlobalStyle.css'
import CustomFooter from '../login/CustomFooter'
//import { db } from '../firebase/Firebase'
//import { getURLSplitArray } from '../Util'
//import ColType from '../Types'
import { Layout } from 'antd'
import { getAssessmentDisplayName, getIconFromMainSubject, isTestingTimeRangeExpired } from '../Util'
import AssessmentSurveyForm from '../teacher/pacingguide/AssessmentSurveyForm'
const Content = Layout.Content
const moment = require('moment')


class StudentAssessments extends Component {
    state = {

    }

    componentDidMount() {
        document.title = 'My Quizzes - Dot It'
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <div>
                <Layout className="content layout-header-mt">
                    <Layout className="mt-0">
                        <Content className="mt-0">

                            <div className="sub-menu-width m-lr-auto mt-4 pl-2 pr-2">
                                <div className='font-30 font-bold'>My Quizzes</div>

                                <div className='font-24 mb-2 ellipsis' style={{ maxWidth: '500px' }}>
                                    {this.props.studentBaseProps.user &&
                                        this.props.studentBaseProps.user.hasOwnProperty('firstName') &&
                                        this.props.studentBaseProps.user.firstName &&
                                        this.props.studentBaseProps.user.hasOwnProperty('lastName') &&
                                        this.props.studentBaseProps.user.lastName ?
                                        this.props.studentBaseProps.user.firstName + ' ' + this.props.studentBaseProps.user.lastName
                                        : ''}
                                </div>

                                {!this.props.studentBaseProps.inTestingTimeRange &&
                                    this.props.studentBaseProps.serverMoment &&
                                    this.props.studentBaseProps.assessments &&
                                    this.props.studentBaseProps.assessments.length > 0 ?
                                    <div className='p-4 br-20 text-center' style={{ background: '#b3e9ff' }}>
                                        <img src='/no-assessments.png' alt='no assessments' width={250} />
                                        <div className='font-24'>The testing window is Monday-Friday from 7:30am to 4:00pm.<br />
                                            You can continue testing then!</div>
                                    </div>
                                    :
                                    (this.props.studentBaseProps.numAssessments === 0 ||
                                        (this.props.studentBaseProps.assessments &&
                                            this.props.studentBaseProps.assessments.length === 0)) ?
                                        <div className='p-4 br-20 text-center' style={{ background: '#b3e9ff' }}>
                                            <img src='/no-assessments.png' alt='no assessments' width={250} />
                                            <div className='font-30'>You are all caught up!</div>
                                        </div>
                                        : ''}

                                {this.props.studentBaseProps.inTestingTimeRange &&
                                    this.props.studentBaseProps.serverMoment &&
                                    this.props.studentBaseProps.assessments &&
                                    this.props.studentBaseProps.assessments.map((assessment, index) => {
                                        let mainSubject = ''
                                        let quizDisplayName = getAssessmentDisplayName(assessment.quiz)
                                        try {
                                            mainSubject = assessment.quiz.standardCategory
                                        } catch (e) {
                                            try {
                                                // fallback 2
                                                mainSubject = assessment.quiz.docs[0].metadata.standard.standardCategory
                                            } catch (e) {
                                                // fallback 3
                                                mainSubject = 'Math'
                                            }
                                        }

                                        let colorClass = "#4096ff"
                                        //let colorTextClass = "#08979c"
                                        const isMTSS = false
                                        const tier = 'Tier 2'
                                        if (isMTSS) {
                                            if (tier === "Tier 2") {
                                                colorClass = "#faad14"
                                                //colorTextClass = "#d48806"
                                            } else {
                                                colorClass = "#ff4d4f"
                                                //colorTextClass = "#cf1322"
                                            }
                                        }
                                        if (mainSubject === 'Math') {
                                            // colorClass = "#722ed1"
                                            //colorTextClass = "#531dab"
                                        }

                                        let testingTimeIsOver = isTestingTimeRangeExpired(assessment, this.props.studentBaseProps.serverMoment)
                                        let testingIsOpen = true

                                        // but an existing isOpen property overrides that default
                                        if (assessment.hasOwnProperty('isOpen')) {
                                            testingIsOpen = assessment.isOpen
                                        }

                                        if (testingTimeIsOver) {
                                            return false
                                        }

                                        return <Link to={"/student/quiz?quiz=" + assessment.id} key={assessment.id}
                                            disabled={!testingIsOpen}>
                                            <div className={"br-20 mb-3 background-fff overflow-hidden font-black font-16 ant-shadow ant-shadow-more-hover parent-hover relative"}
                                                style={!testingIsOpen ? {
                                                    opacity: 0.888,
                                                    color: '#ccc',
                                                    filter: 'grayscale(100%)',
                                                } : {}}
                                            >
                                                <div className="flex flex-v-center p-3">
                                                    <div className="br-50 flex flex-h-center flex-v-center mr-4" style={{ background: colorClass, minWidth: '80px', width: '80px', height: '80px' }}>
                                                        <img
                                                            src={getIconFromMainSubject(mainSubject, '')}
                                                            alt="icon"
                                                            width={48}
                                                        />
                                                    </div>

                                                    <div>
                                                        <div className="font-24 font-bold mb-1">
                                                            <div className='ellipsis' style={{ maxWidth: '400px' }}>
                                                                {'Teacher: ' + assessment.teacher.firstName + ' ' + assessment.teacher.lastName}
                                                            </div>
                                                        </div>
                                                        <div className="font-20 mr-3">
                                                            <span>
                                                                {quizDisplayName}
                                                            </span>
                                                            <span className='text-muted ml-2 font-16'>
                                                                {assessment.hasOwnProperty('timeStamp') &&
                                                                    assessment.timeStamp &&
                                                                    assessment.timeStamp.hasOwnProperty('seconds') &&
                                                                    assessment.timeStamp.seconds ?
                                                                    moment.utc(assessment.timeStamp.seconds * 1000).format('MM/DD/YYYY') : ''}
                                                            </span>
                                                            {!testingIsOpen ?
                                                                <span className='text-muted ml-2 font-16 font-black font-bold'>
                                                                    {'(Testing is currently closed)'}
                                                                </span>
                                                                : ''}
                                                        </div>

                                                    </div>

                                                    <div className='font-20 font-bold ml-auto'>
                                                        <div>{assessment.quiz.questionIdx === 0 ? 'Get started' : 'Continue'}</div>
                                                        {assessment.quiz.questionIdx > 0 ?
                                                            <div className='font-normal'>{'Question: (' + (assessment.quiz.questionIdx + 1) + ' of ' + assessment.quiz.docs.length + ')'}</div>
                                                            : ''}
                                                    </div>

                                                </div>

                                            </div>
                                        </Link>
                                    })}
                            </div>
                        </Content>
                    </Layout>
                </Layout>
                <CustomFooter />
            </div>
        )
    }
}

export default StudentAssessments