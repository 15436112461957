import React, { Component } from 'react'
//import { Link } from 'react-router-dom'
import '../../styles/GlobalStyle.css'
//import { db } from '../../firebase/Firebase'
//import ColType from '../.././Types'
import { Button, Checkbox, Icon, Modal, Radio, Select, notification } from 'antd'
import { cleverStartHTMLQuiz, getQueryStringParam } from '../../Util'
import PersonAvatar from '../../customcomponents/PersonAvatar'
//import { Document } from '../edvizv2/Edviz'
//import PersonAvatar from '../../customcomponents/PersonAvatar'
//var moment = require('moment')

class PacingGuideHTMLAssessmentModalForm extends Component {
    state = {
        quizId: '',
        quiz: null,
        visible: false,
        sections: [],
        allSectionsCheckboxSelected: false,
        sectionsSelected: [],
        studentIds: [],
        mode: 'sections',
        studentIdsSelectDropdownVisible: false,
        submitting: false,
    }

    componentDidMount() {
        // console.log(this.props)
    }

    clearAllSelections = (clearMode) => {
        let stateObj = {
            allSectionsCheckboxSelected: false,
            sectionsSelected: [],
            studentIds: [],
            studentIdsSelectDropdownVisible: false,
        }
        if (clearMode) {
            stateObj.mode = 'sections'
        }
        this.setState(stateObj)
    }

    handleCancel = () => {
        if (this.state.studentIdsSelectDropdownVisible) {
            return
        }
        this.setState({
            visible: false,
        })
        setTimeout(() => {
            this.clearAllSelections(true)
        }, 100)
    }

    showModal = () => {
        this.setState({ visible: true })
        this.clearAllSelections(true)
    }

    allSectionsCheckboxSelectedOnChange = (e, section) => {
        if (!(this.props.teacherBaseProps &&
            this.props.teacherBaseProps.sections &&
            this.props.teacherBaseProps.sections.length > 0)) {
            return
        }

        const checked = e.target.checked
        let allSectionsIds = []
        this.props.teacherBaseProps.sections.map(section => {
            allSectionsIds.push(section.id)
        })
        this.setState({
            allSectionsCheckboxSelected: checked,
            sectionsSelected: checked ? allSectionsIds : []
        })
    }

    startQuiz = () => {
        const mode = this.state.mode

        if (!(this.props.assessment &&
            this.props.teacherBaseProps &&
            this.props.teacherBaseProps.teacher &&
            this.props.teacherBaseProps.sections &&
            this.props.teacherBaseProps.sections.length > 0)) {
            console.log('missing props')
            return
        } else if (!(mode === 'sections' || mode === 'students')) {
            console.log('invalid mode')
            return
        }

        this.setState({
            submitting: true
        })

        let quizType = 'Quiz'
        if (this.props.assessment && this.props.assessment.assessmentCategory === "common_assessment") {
            quizType = 'Common assessment'
        } else if (this.props.assessment && this.props.assessment.assessmentCategory === "benchmark_assessment") {
            quizType = 'Benchmark assessment'
        }

        let sections = []
        let studentIds = []
        if (mode === 'sections') {
            this.state.sectionsSelected.map(sectionId => {
                if (this.props.teacherBaseProps.sectionsDict.hasOwnProperty(sectionId)) {
                    sections.push(this.props.teacherBaseProps.sectionsDict[sectionId])
                }
            })
        } else if (mode === 'students') {
            studentIds = JSON.parse(JSON.stringify(this.state.studentIds))
        }

        if (mode === 'sections' && sections.length === 0) {
            console.log('sections mode, sections length is 0')
            return
        } else if (mode === 'students' && studentIds.length === 0) {
            console.log('students mode, studentIds length is 0')
            return
        }

        //teacher, mode, sections, studentIds, fullQuiz
        cleverStartHTMLQuiz(this.props.teacherBaseProps.teacher,
            mode,
            sections, studentIds, this.props.assessment)
            .then((quiz) => {
                this.setState({
                    submitting: false
                })
                this.handleCancel()

                notification.open({
                    type: 'success',
                    duration: 12,
                    message: quizType + ' started successfully. You can give the assessment by click on "Assessments" at the top of the page and then clicking on this assessment.',
                    placement: 'bottomRight'
                })
            })
            .catch((error) => {
                this.setState({
                    submitting: false
                })
                console.log(error)

                notification.open({
                    type: 'error',
                    duration: 4,
                    message: 'An error occurred while starting the ' + quizType.toLowerCase() + '. Please try again.',
                    placement: 'bottomRight'
                })
            })
    }

    render() {
        let quizType = 'quiz'
        if (this.props.assessment && this.props.assessment.assessmentCategory === "common_assessment") {
            quizType = 'common assessment'
        } else if (this.props.assessment && this.props.assessment.assessmentCategory === "benchmark_assessment") {
            quizType = 'benchmark assessment'
        }

        return (
            <div>
                <Button
                    className="ant-btn ant-btn-primary lg-btn text-align-center font-bold"
                    disabled={this.props.hasOwnProperty('disableQuiz') &&
                        this.props.disableQuiz ? true : false}
                    onClick={this.showModal}>
                    Start {this.props.buttonText ? this.props.buttonText : quizType}
                </Button>
                <Modal
                    title={<div className='font-16'>
                        {'Start ' + (this.props.buttonText ? this.props.buttonText : quizType)}
                    </div>}
                    visible={this.state.visible}
                    onCancel={this.handleCancel}
                    width={550}
                    footer={null}
                >
                    {this.props.teacherBaseProps &&
                        this.props.teacherBaseProps.sections ?
                        <div>
                            <div>
                                <div className='font-20 mb-05'>
                                    Quiz for:
                                </div>

                                <Radio.Group
                                    value={this.state.mode}
                                    size='large'
                                    onChange={(e) => {
                                        this.setState({
                                            mode: e.target.value
                                        }, () => {
                                            this.clearAllSelections(false)
                                        })
                                    }}>
                                    <Radio.Button value="sections">Sections</Radio.Button>
                                    <Radio.Button value="students">Students</Radio.Button>
                                </Radio.Group>
                            </div>
                            <div className='mt-2'>
                                {this.state.mode === 'sections' ?
                                    <div>
                                        <div className='font-20 mb-1'>
                                            Choose section or sections that will take this {quizType}:
                                        </div>
                                        {this.props.teacherBaseProps.sections.length === 0 ?
                                            <div className='font-20'>No sections found.</div> :
                                            <div>
                                                <Checkbox
                                                    checked={this.state.allSectionsCheckboxSelected}
                                                    disabled={this.state.submitting}
                                                    onChange={(e) => {
                                                        this.allSectionsCheckboxSelectedOnChange(e)
                                                    }}
                                                    className='ml-0 flex flex-v-center font-black font-normal'>
                                                    <span className='pl-1 font-20'>All sections</span>
                                                </Checkbox>
                                                <Checkbox.Group value={this.state.sectionsSelected}
                                                    disabled={this.state.submitting}
                                                    onChange={(sectionsSelected) => {
                                                        this.setState({
                                                            sectionsSelected: sectionsSelected,
                                                            allSectionsCheckboxSelected: sectionsSelected.length === this.props.teacherBaseProps.sections.length
                                                        })
                                                    }}>
                                                    {this.props.teacherBaseProps &&
                                                        this.props.teacherBaseProps.sections &&
                                                        this.props.teacherBaseProps.sections.map((section, idx) => {
                                                            return <Checkbox
                                                                key={'section-checkbox-' + idx}
                                                                value={section.id}
                                                                className='ml-0 flex flex-v-center font-black font-normal'>
                                                                <div className='pl-1 font-20'>{section.name}</div>
                                                            </Checkbox>
                                                        })}
                                                </Checkbox.Group>

                                            </div>}
                                    </div> :
                                    <div>
                                        <div className='font-20 mb-1'>
                                            Choose students that will take this {quizType}:
                                        </div>
                                        <Select
                                            mode='multiple'
                                            className='select-option-height-auto'
                                            placeholder="Select students..."
                                            size='large'
                                            showSearch={true}
                                            filterOption={(input, option) => {
                                                const studentId = option.props.value
                                                if (this.props.teacherBaseProps &&
                                                    this.props.teacherBaseProps.studentDict &&
                                                    this.props.teacherBaseProps.studentDict.hasOwnProperty(studentId)) {
                                                    const student = this.props.teacherBaseProps.studentDict[studentId]
                                                    const studentName = student.firstName + ' ' + student.lastName
                                                    if (studentName.toLowerCase().includes(input.toLowerCase())) {
                                                        return true
                                                    }
                                                }

                                                return false
                                            }}
                                            value={this.state.studentIds}
                                            onChange={(studentIds) => {
                                                this.setState({
                                                    studentIds: studentIds
                                                })
                                            }}
                                            onDropdownVisibleChange={(open) => {
                                                setTimeout(() => {
                                                    this.setState({
                                                        studentIdsSelectDropdownVisible: open
                                                    })
                                                }, open ? 0 : 300)
                                            }}
                                            style={{ width: '100%' }}
                                        >
                                            {this.props.teacherBaseProps &&
                                                this.props.teacherBaseProps.students &&
                                                this.props.teacherBaseProps.students.map((student, idx) => {
                                                    return <Select.Option
                                                        key={'student-option-' + idx}
                                                        value={student.id}>
                                                        <PersonAvatar
                                                            person={student}
                                                            size={'large'}
                                                            containerClassName="font-20"
                                                            avatarClassName="font-20 font-bold mr-2"
                                                            personClassName="font-black font-20 ellipsis"
                                                        />
                                                    </Select.Option>
                                                })}
                                        </Select>
                                    </div>}
                            </div>
                            <div className='mt-2 flex flex-v-center w-100'>
                                <Button
                                    type="primary"
                                    size={'large'}
                                    disabled={this.props.disableQuiz || this.state.submitting ||
                                        (this.state.mode === 'sections' && this.state.sectionsSelected.length === 0) ||
                                        (this.state.mode === 'students' && this.state.studentIds.length === 0)}
                                    className="ant-btn ant-btn-primary lg-btn text-align-center font-bold ml-auto"
                                    onClick={this.startQuiz}
                                >
                                    <span>
                                        {this.state.submitting ?
                                            <Icon type='loading' className='mr-1' /> : ''}
                                        <span>{(this.state.submitting ? 'Starting ' : 'Start ') + quizType}</span>
                                    </span>
                                </Button>
                            </div>
                        </div> : ''}
                </Modal>
            </div>
        )
    }
}

export default PacingGuideHTMLAssessmentModalForm
