import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { firebase } from '../../firebase/Firebase'
import '../../styles/GlobalStyle.css'
import {
    getStudentMayHaveTroubleWith, getIDFromURL, cleverIsAuthed, cleverGetStudents,
    isReadOnlyTeacherId
} from '../.././Util'
import { Layout, Row, Col, Button, Alert } from 'antd'
import CustomFooter from '../../login/CustomFooter'
import TeacherAddStudentsClever from './TeacherAddStudentsClever'
//import AddStudentCSV from './AddStudentCSV'
const { Content } = Layout

class TeacherMeetings extends Component {
    state = {
        teacherId: '',
    }

    componentDidMount() {
        document.title = "Meetings - Dot It"

        var teacherId = getIDFromURL(window.location)

        this.setState({
            teacherId: teacherId,
        })
    }

    render() {
        return (
            <div>
                <Layout className="content layout-header-mt">
                    <Layout>
                        <Content className="layout-content">
                            <div className="sub-menu-width m-lr-auto">
                                <div className='br-20 overflow-hidden mb-3'>
                                    <div className='br-4 relative pl-3 pr-3 pt-3 relative'
                                        style={{ height: '220px', }}>

                                        <div className="absolute-tl w-100 h-100 background-darker-blue">
                                        </div>
                                        <div className="absolute-tl w-100 h-100"
                                        >
                                        </div>
                                        <div className='absolute-tl w-100 h-100'>
                                            <div className='h-100'>
                                                <div className='flex flex-v-center font-40 font-fff w-100 h-100 p-4'>
                                                    <div className='w-100'>
                                                        <div className='font-40 font-bold font-fff'>Meetings</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='font-30 font-bold mb-2'>Plan my meetings</div>
                                <Row gutter={32} className='pl-2 pr-2'>

                                    <Col xs={24} sm={24} md={8} lg={8} xl={8} className="pl-0">
                                        <Link
                                            to={"/teacher/mtss-add-goal-student/" +
                                                this.state.teacherId}
                                            onClick={(e) => {
                                                if ((this.props.teacherBaseProps.teacher &&
                                                    isReadOnlyTeacherId(this.props.teacherBaseProps.teacher.id)) ||
                                                    (this.props.teacherBaseProps.students &&
                                                        this.props.teacherBaseProps.students.length === 0)) {
                                                    e.preventDefault()
                                                }
                                            }}
                                        >
                                            <div
                                                className={"p-2 br-20 background-fff mb-3 ant-shadow ant-shadow-more-hover parent-hover relative flex flex-v-center font-20 font-black overflow-hidden"}
                                                style={{ minHeight: '118px' }}
                                            >
                                                <div className="br-4 overflow-hidden">
                                                    <img src="/tier-two-three-icon.png" alt="tier 2 and 3 icon" height="64" />
                                                </div>
                                                <h2 className="ml-2 font-24 font-bold">
                                                    MTSS
                                                </h2>

                                                {(this.props.teacherBaseProps.teacher &&
                                                    isReadOnlyTeacherId(this.props.teacherBaseProps.teacher.id)) ||
                                                    (this.props.teacherBaseProps.students &&
                                                        this.props.teacherBaseProps.students.length === 0) ?
                                                    <div>
                                                        <div className="disabled-overlay">

                                                        </div>
                                                        {(this.props.teacherBaseProps.students &&
                                                            this.props.teacherBaseProps.students.length === 0) ?
                                                            <div className="absolute-tl w-100 font-16 cursor-disabled">
                                                                <Alert
                                                                    className="alert-vlg"
                                                                    message="You can draft a tier plan after adding a student."
                                                                    type="info"
                                                                    showIcon
                                                                />
                                                            </div> : ''}
                                                    </div> :
                                                    ''}
                                            </div>
                                        </Link>
                                    </Col>

                                    <Col xs={24} sm={24} md={8} lg={8} xl={8} className="pl-0">
                                        <Link
                                            to={"/teacher/add-goal-student/" + this.state.teacherId}
                                            onClick={(e) => {
                                                if ((this.props.teacherBaseProps.teacher &&
                                                    isReadOnlyTeacherId(this.props.teacherBaseProps.teacher.id)) ||
                                                    (this.props.teacherBaseProps.students &&
                                                        this.props.teacherBaseProps.students.length === 0)) {
                                                    e.preventDefault()
                                                }
                                            }}
                                        >
                                            <div
                                                className={"p-2 br-20 background-fff mb-3 ant-shadow ant-shadow-more-hover parent-hover relative flex flex-v-center font-20 font-black overflow-hidden"}
                                                style={{ minHeight: '118px' }}
                                            >
                                                <div className="br-4 overflow-hidden">
                                                    <img src="/add-iep.png" alt="home icon" height="64" />
                                                </div>
                                                <h2 className="ml-2 font-24 font-bold">
                                                    Special education
                                                </h2>

                                                {(this.props.teacherBaseProps.teacher &&
                                                    isReadOnlyTeacherId(this.props.teacherBaseProps.teacher.id)) ||
                                                    (this.props.teacherBaseProps.students &&
                                                        this.props.teacherBaseProps.students.length === 0) ?
                                                    <div>
                                                        <div className="disabled-overlay">

                                                        </div>
                                                        {(this.props.teacherBaseProps.students &&
                                                            this.props.teacherBaseProps.students.length === 0) ?
                                                            <div className="absolute-tl w-100 font-16 cursor-disabled">
                                                                <Alert
                                                                    className="alert-vlg"
                                                                    message="You can draft an IEP after adding a student."
                                                                    type="info"
                                                                    showIcon
                                                                />
                                                            </div> : ''}
                                                    </div> :
                                                    ''}
                                            </div>
                                        </Link>
                                    </Col>

                                    <Link to={'/teacher/chat/' + this.state.teacherId}>
                                        <div
                                            className={"p-2 br-20 background-fff mb-3 ant-shadow ant-shadow-more-hover parent-hover relative flex flex-v-center font-20 font-black overflow-hidden"}
                                            style={{ minHeight: '118px' }}
                                        >
                                            <div className="br-4 overflow-hidden">
                                                <img src="/teams-icon.png" alt="home icon" height="64" />
                                            </div>
                                            <h2 className="ml-2 font-24 font-bold">
                                                Teams
                                            </h2>
                                        </div>
                                    </Link>


                                </Row>
                            </div>
                        </Content>
                    </Layout>
                </Layout>
                <CustomFooter />
            </div>
        )
    }
}

export default TeacherMeetings