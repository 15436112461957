import React, { Component } from 'react'
//import { Link } from 'react-router-dom'
import '../styles/GlobalStyle.css'
import { Icon, Tooltip } from 'antd'
import { getStudentSurveysResults } from '../Util';

class StudentSurveyResults extends Component {
    state = {
        surveyResults: null,
    }

    componentDidMount() {
        if (this.props.hasOwnProperty('calculatedStudentSurveys') &&
            this.props.calculatedStudentSurveys) {
            this.setState({ surveyResults: this.props.calculatedStudentSurveys });
        } else if (this.props.hasOwnProperty('studentSurveys') &&
            this.props.studentSurveys) {
            this.calculateSurveyResults();
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.hasOwnProperty('calculatedStudentSurveys') &&
            nextProps.calculatedStudentSurveys) {
            this.setState({ surveyResults: nextProps.calculatedStudentSurveys });
        }
    }

    calculateSurveyResults = () => {
        if (!this.props.studentSurveys) {
            return
        }

        let surveyResults = getStudentSurveysResults(this.props.studentSurveys);
        this.setState({ surveyResults: surveyResults });
    }

    render() {
        return (
            <div>
                {this.state.surveyResults ?
                    <div>
                        <div className='font-24 font-bold'>Student Voice</div>
                        <div className='font-20 mb-1'>Students' response to: How do you feel about this test?</div>
                        <div className='flex w-100'>
                            <div className='pt-1 br-4 mr-2'>
                                <div className='flex flex-v-center w-100'>
                                    <div style={{ fontSize: '50px' }}>😀</div>
                                    <div className='inline-flex ml-1'>
                                        <div>

                                            <div className='font-24'>
                                                {(this.state.surveyResults.goodPct * 100).toFixed(2) + '%'}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='br-4 overflow-hidden'
                                    style={{ width: '194px', height: '20px', background: '#a8a8a8' }}>
                                    <div className='background-green h-100' style={{ width: (this.state.surveyResults.goodPct * 100).toFixed(2) + '%' }}></div>

                                </div>
                                <div className='font-20 text-muted pt-05'>
                                    {this.state.surveyResults.good + ' of ' +
                                        this.state.surveyResults.total + ' students'}
                                </div>
                            </div>
                            <div className='pt-1 br-4 mr-2'>
                                <div className='flex flex-v-center w-100'>
                                    <div style={{ fontSize: '50px' }}>😐</div>
                                    <div className='inline-flex ml-1'>
                                        <div>

                                            <div className='font-24'>
                                                {(this.state.surveyResults.somewhatGoodPct * 100).toFixed(2) + '%'}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='br-4 overflow-hidden'
                                    style={{ width: '194px', height: '20px', background: '#a8a8a8' }}>
                                    <div className='background-orange h-100' style={{ width: (this.state.surveyResults.somewhatGoodPct * 100).toFixed(2) + '%' }}></div>
                                </div>
                                <div className='font-20 text-muted pt-05'>
                                    {this.state.surveyResults.somewhatGood + ' of ' +
                                        this.state.surveyResults.total + ' students'}
                                </div>
                            </div>
                            <div className='pt-1 br-4'>
                                <div className='flex flex-v-center w-100'>
                                    <div style={{ fontSize: '50px' }}>☹️</div>
                                    <div className='inline-flex ml-1'>
                                        <div>

                                            <div className='font-24'>
                                                {(this.state.surveyResults.notGoodPct * 100).toFixed(2) + '%'}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='br-4 overflow-hidden'
                                    style={{ width: '194px', height: '20px', background: '#a8a8a8' }}>
                                    <div className='background-red h-100' style={{ width: (this.state.surveyResults.notGoodPct * 100).toFixed(2) + '%' }}></div>
                                </div>
                                <div className='font-20 text-muted pt-05'>
                                    {this.state.surveyResults.notGood + ' of ' +
                                        this.state.surveyResults.total + ' students'}
                                </div>
                            </div>
                        </div>
                    </div>
                    : ''}
            </div>
        )
    }
}

export default StudentSurveyResults