import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../../styles/GlobalStyle.css'
import CustomFooter from '../../login/CustomFooter'
import MainPlanView from './MainPlanView'
import { getIDFromURL, getQueryStringParam, deleteIEPSFromCalendarEvents, FOLDER_CURRENT, FOLDER_ARCHIVED, capitalizeFirstChar, getIconFromMainSubjectColors } from '../.././Util'
//import ColType from '../../Types'
import { Layout, Spin, Row, Col } from 'antd'
import IEPNavigation from './IEPNavigation'
import StandardNumber from '../../customcomponents/StandardNumber'
const { Content } = Layout

// Using this to add students until the step form for adding students is done.
class StudentPlan extends Component {
  state = {
    teacherId: null,
    studentId: null,
    student: null,
    studentsIEPs: [],
    IEPId: null,
    IEPIdx: -1,
    archivedPlan: false,
    moveInProcess: false,
  }

  componentWillReceiveProps(nextProps) {
    var teacherId = getIDFromURL(window.location)
    var studentId = getQueryStringParam('student')
    var IEPId = getQueryStringParam('plan')
    var archivedPlan = getQueryStringParam('pl')
    var IEPIdx = -1
    var studentsIEPs = []
    var student = null

    if (nextProps.teacherBaseProps.studentDict && nextProps.teacherBaseProps.studentDict.hasOwnProperty(studentId)) {
      student = nextProps.teacherBaseProps.studentDict[studentId]
    }
    if (nextProps.teacherBaseProps.IEPGoals) {
      studentsIEPs = nextProps.teacherBaseProps.IEPGoals.filter(iep => iep.studentId === studentId)
      for (var i = 0; i < studentsIEPs.length; i++) {
        var IEPGoal = studentsIEPs[i]
        if (IEPGoal.id === IEPId) {
          IEPIdx = i
          break
        }
      }
    }
    this.setState({
      teacherId: teacherId,
      studentId: studentId,
      student: student,
      IEPId: IEPId,
      archivedPlan: archivedPlan,
      studentsIEPs: studentsIEPs,
      IEPIdx: IEPIdx,
    })
  }

  componentDidMount() {
    document.title = 'Goal - Dot It'

    var teacherId = getIDFromURL(window.location)
    var studentId = getQueryStringParam('student')
    var IEPId = getQueryStringParam('plan')
    var archivedPlan = getQueryStringParam('pl')
    var IEPIdx = -1
    var studentsIEPs = []
    var student = null
    if (this.props.teacherBaseProps.studentDict && this.props.teacherBaseProps.studentDict.hasOwnProperty(studentId)) {
      student = this.props.teacherBaseProps.studentDict[studentId]
    }
    if (this.props.teacherBaseProps.IEPGoals) {
      studentsIEPs = this.props.teacherBaseProps.IEPGoals.filter(iep => iep.studentId === studentId)
      for (var i = 0; i < studentsIEPs.length; i++) {
        var IEPGoal = studentsIEPs[i]
        if (IEPGoal.id === IEPId) {
          IEPIdx = i
          break
        }
      }
    }
    this.setState({
      teacherId: teacherId,
      studentId: studentId,
      student: student,
      IEPId: IEPId,
      archivedPlan: archivedPlan,
      IEPIdx: IEPIdx,
      studentsIEPs: studentsIEPs,
    })
  }

  render() {
    let IEP = null

    if (this.state.teacherId &&
      this.state.studentId &&
      this.props.teacherBaseProps.IEPDict &&
      this.props.teacherBaseProps.IEPDict.hasOwnProperty(this.state.IEPId) &&
      this.props.teacherBaseProps.IEPDict[this.state.IEPId]) {
      IEP = this.props.teacherBaseProps.IEPDict[this.state.IEPId]
    }

    return (
      <div>
        <Layout className="content layout-header-mt">
          <Layout>
            <Content className="layout-content">
              <div className="sub-menu-width m-lr-auto mb-4">
                {this.state.moveInProcess ?
                  <div className={"flex flex-h-center" +
                    " flex-center font-30 font-bold text-cyan mt-4 pt-4"}>
                    <div>
                      <Spin size="large" className="ant-spin-vlg cyan-spin" />
                    </div>
                  </div>
                  : ''}
                {!this.state.moveInProcess &&
                  (IEP || this.state.IEPId === 'tier1') &&
                  this.state.teacherId &&
                  this.state.studentId &&
                  this.props.teacherBaseProps.studentDict &&
                  this.props.teacherBaseProps.studentDict.hasOwnProperty(this.state.studentId)
                  ?
                  <Row gutter={50}>
                    <Col span={18}>
                      <div className="br-4">
                        <div>
                          <IEPNavigation
                            history={this.props.history}
                            teacherBaseProps={this.props.teacherBaseProps}
                            onMoveFromCurrent={(studentId, ieps) => {
                              console.log('called')
                              // ieps will always be of length 1 here
                              deleteIEPSFromCalendarEvents(this.props.teacherBaseProps.events, ieps)
                            }}
                            onBeforeMove={(studentId) => {
                              this.setState({
                                moveInProcess: true,
                              })
                            }}
                            onSingleGoalMoved={(studentId, didError) => {
                              this.setState({
                                moveInProcess: false,
                              }, () => {
                                this.props.history.push(
                                  {
                                    pathname: '/teacher/student/' +
                                      this.state.teacherId + '?student=' + studentId,
                                  }
                                )
                              })
                            }}
                            onAllGoalsMoved={(studentId, didError) => {
                              this.setState({
                                moveInProcess: false,
                              }, () => {
                                this.props.history.push(
                                  {
                                    pathname: '/teacher/student/' +
                                      this.state.teacherId + '?student=' + studentId,
                                  }
                                )
                              })
                            }}
                            iep={IEP}
                            studentsIEPs={this.state.studentsIEPs}
                            studentId={this.state.studentId}
                            folderFrom={FOLDER_CURRENT}
                            folderTo={FOLDER_ARCHIVED}
                          />

                          <MainPlanView
                            studentId={this.state.studentId}
                            IEPId={this.state.IEPId}
                            student={this.state.student}
                            iep={IEP}
                            teacherBaseProps={this.props.teacherBaseProps}
                            showCopyButtons={true}
                          />
                        </div>

                      </div>
                    </Col>
                    <Col span={6}>
                      <h1 className='font-24 font-bold mb-1 mt-4'>Goals</h1>
                      {this.props.teacherBaseProps.IEPGoals &&
                        this.props.teacherBaseProps.IEPGoals.map((iep, idx) => {
                          if (iep.studentId !== this.state.studentId) {
                            return false
                          }
                          return <Link to={"/teacher/student-plan/" + this.props.teacherBaseProps.teacher.id + "?student=" + this.state.studentId + "&plan=" + iep.id}
                            key={'nav-' + iep.id}
                            className='font-black'>
                            <div className={"p-2 br-20 background-fff ant-shadow mb-1 ant-shadow-more-hover parent-hover relative" +
                              (this.state.IEPId === iep.id ? ' side-menu-item-active' : '')}>
                              <div className='absolute-tr' style={{ top: '16px', right: '16px' }}>

                                <img
                                  src={getIconFromMainSubjectColors(iep.iep.mainSubject, iep.iep.hasOwnProperty('tier') ? iep.iep.tier : '')}
                                  alt="icon"
                                  width={32}
                                />
                              </div>
                              <h2 className="font-bold mb-0 font-14 text-muted">
                                {(iep.hasOwnProperty("isMTSS") && iep.isMTSS) ?
                                  iep.iep.tier + " Goal" : "IEP Goal"}
                              </h2>
                              <div className="mb-1 font-16 font-bold">{iep.iep.mainSubject}</div>

                              <span className="w-400 ellipsis">
                                <StandardNumber
                                  district={this.props.teacherBaseProps.district}
                                  iep={iep.iep}
                                  className='mr-0'
                                  containerClassName={'pl-1 pr-1 pt-05 pb-05 mr-05 br-100'}
                                  containerStyle={{ backgroundColor: '#e8e8e8' }}
                                />
                              </span>
                              <span>
                                {capitalizeFirstChar(iep.iep.standardDescription)}
                              </span>
                            </div>
                          </Link>
                        })}
                    </Col>
                  </Row>
                  : ''}
              </div>
            </Content>

          </Layout>
        </Layout>
        <CustomFooter teacherId={this.state.teacherId} />
      </div>
    )
  }
}

export default StudentPlan