import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../../styles/GlobalStyle.css'
//import CustomFooter from '../../login/CustomFooter'
//import SubjectHistogram from '../../admin/SubjectHistogram'
//import SupportPieChartTiers from '../../admin/SupportPieChartTiers'
//import PersonAvatar from '../../customcomponents/PersonAvatar'
//import Empty from '../../customcomponents/Empty'
//import { getMathStandardsKindergarten, getMathUnits, getMathPrebuiltPacingGuideWeeks, getELAK } from './SCMath'
import { Layout, Select, Icon, Checkbox, Input, Spin, Button, Alert, Menu, Radio, Row, Col, Modal } from 'antd'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import PacingGuideDraggableStandard from './PacingGuideDraggableStandard'
import PacingGuideAssessmentModalForm from './PacingGuideAssessmentModalForm'
import Empty from '../../customcomponents/Empty'
import ModalVideo from '../../customcomponents/ModalVideo'
import PacingGuideHTMLAssessmentModalForm from './PacingGuideHTMLAssessmentModalForm'
import { KAssessment } from '../html/assessment/K.js'
import { FirstGradeAssessment } from '../html/assessment/1.js'
//import { db } from '../../firebase/Firebase'
//import ColType from '../.././Types'
//import { flattenDoc, getStandardsColType, getstandardNamesDict } from '../../Util'
const Sider = Layout.Sider
const Content = Layout.Content
const RadioGroup = Radio.Group


class PacingGuideSC extends Component {
    state = {
        mathStandardsDict: {},
        mathIndividualStandardsDict: {},
        standards: [],
        prevStandardName: '',
        prevStandardCategory: '',
        standardName: 'Kindergarten',
        standardCategory: 'ELA',
        standardCategories: ['ELA', 'Math', 'Social Studies', 'Science'],
        gradeDictAcrossCategories: {
            'Kindergarten': 'K',
            '1st Grade': '1',
            '2nd Grade': '2',
            '3rd Grade': '3',
            '4th Grade': '4',
            '5th Grade': '5',
            '6th Grade': '6',
            '7th Grade': '7',
            '8th Grade': '8',
        },
        visible: false,
        assessmentVisible: false,
        lessonPlanStandardNames: ['ELA.1.F.3.6', 'ELA.3.AOR.6.1.a',
            'ELA.6.AOR.1.2', 'ELA.C.1', 'ELA.K.F.1.4', '2.ATO.1', '3.ATO.1',
            '4.ATO.4', '7.RP.2.d', 'A1.AAPR.1', 'K.NS.1', '3-ESS2-1',
            'B-LS1-5', 'USG.1.IP'],
        subjects: ['ELA', 'Math', 'Social Studies', 'Science'],
        lessonPlanStandardNamesSubjectDict: {
            'ELA': ['ELA.K.F.1.4', 'ELA.1.F.3.6', 'ELA.3.AOR.6.1.a',
                'ELA.6.AOR.1.2', 'ELA.C.1'],
            'Math': ['K.NS.1', '2.ATO.1', '3.ATO.1',
                '4.ATO.4', '7.RP.2.d', 'A1.AAPR.1'],
            'Social Studies': ['USG.1.IP'],
            'Science': ['3-ESS2-1', 'B-LS1-5'],
        },
        selectedStandardName: 'ELA.1.F.3.6',
        notScheduledFilter: false,
        searchFilter: '',
        expandedWeeks: {},
        expandedItems: {},
        futureStandardsResourcesModalVisible: false,
        commonAssessmentTutorialModalVisible: false,
        quizTutorialModalVisible: false,
    }

    componentDidMount() {

    }

    componentWillReceiveProps(nextProps) {
    }

    showLessonPlanModal = (standard) => {
        this.setState({
            visible: true,
        })
    }

    getListStyleSider = (isDraggingOver) => {
        return {
            background: isDraggingOver ? 'lightblue' : 'white',
            padding: 8,
            borderRadius: '4px',
        }
    }

    getListStyle = (isDraggingOver, numItems) => {
        return {
            background: isDraggingOver ? 'lightblue' : 'transparent',
            border: (numItems === 0 ? '1px dashed #d9d9d9' : 'none'),
            display: 'flex',
            padding: 8,
            overflow: 'auto',
            minHeight: '100px',
            marginBottom: '16px',
            borderRadius: '4px',
        }
    }

    getItemStyleSider = (isDragging, draggableStyle) => {
        return Object.assign({
            userSelect: 'none',
            borderRadius: '15px',
            padding: '16px',
            marginRight: '8px',
            marginBottom: '8px',
            boxShadow: 'rgba(100, 100, 111, 0.15) 0px 6px 18px 0px',
            background: isDragging ? 'lightgreen' : 'white',
        }, draggableStyle)
    }

    getItemStyle = (isDragging, draggableStyle, pacingGuideMode) => {
        let paddingBottom = '74px'
        if (pacingGuideMode && pacingGuideMode === 'future') {
            paddingBottom = '16px'
        }
        return Object.assign({
            position: 'relative',
            userSelect: 'none',
            borderRadius: '15px',
            padding: '16px',
            paddingBottom: paddingBottom,
            marginRight: '16px',
            maxWidth: '240px',
            minWidth: '240px',
            boxShadow: 'rgba(100, 100, 111, 0.15) 0px 6px 18px 0px',
            background: isDragging ? 'lightgreen' : 'white',
            // ...draggableStyle,
        }, draggableStyle)
    }

    reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list)
        const [removed] = result.splice(startIndex, 1)
        result.splice(endIndex, 0, removed)

        return result
    }

    onDragEnd = (result) => {
        const { source, destination } = result
        //console.log(result, source, destination)

        if (!(this.props.pacingGuideBaseProps &&
            this.props.pacingGuideBaseProps.pacingGuide &&
            this.props.pacingGuideBaseProps.pacingGuide.weeks)) {
            return
        }

        // dropped outside the list
        if (!destination) {
            return
        }
        const sInd = +source.droppableId
        const dInd = +destination.droppableId

        //console.log(sInd, dInd)

        // index 0 is the side menu list (not a week)
        if (sInd > 0 && sInd === dInd) {
            let weeks = this.props.pacingGuideBaseProps.pacingGuide.weeks
            const items = this.reorder(weeks[sInd], source.index, destination.index)
            weeks[sInd] = items

            if (this.props.pacingGuideBaseProps &&
                this.props.pacingGuideBaseProps.setWeeksFunc) {
                this.props.pacingGuideBaseProps.setWeeksFunc(weeks)
            }
        } else {
            let weeks = this.props.pacingGuideBaseProps.pacingGuide.weeks
            const result = this.move(sInd, dInd, weeks[sInd], weeks[dInd], source, destination)
            weeks[sInd] = result[sInd]
            weeks[dInd] = result[dInd]

            if (this.props.pacingGuideBaseProps &&
                this.props.pacingGuideBaseProps.setWeeksFunc) {
                this.props.pacingGuideBaseProps.setWeeksFunc(weeks)
            }
        }
    }

    /**
     * Moves an item from one list to another list.
     */
    move = (sInd, dInd, source, destination, droppableSource, droppableDestination) => {
        const sourceClone = Array.from(source)
        const destClone = Array.from(destination)
        let removedValue = sourceClone[droppableSource.index]
        // don't remove from side menu
        if (sInd > 0) {
            sourceClone.splice(droppableSource.index, 1)
        }
        if (dInd > 0) {
            destClone.splice(droppableDestination.index, 0, removedValue)
        }

        const result = {}
        result[droppableSource.droppableId] = sourceClone
        result[droppableDestination.droppableId] = destClone

        return result
    }

    onStandardNameChange = (value) => {
        if (this.props.pacingGuideBaseProps &&
            this.props.pacingGuideBaseProps.onStandardNameChangeFunc) {
            this.props.pacingGuideBaseProps.onStandardNameChangeFunc(value)
        }
    }

    onStandardCategoryChange = (value) => {
        if (this.props.pacingGuideBaseProps &&
            this.props.pacingGuideBaseProps.onStandardCategoryChangeFunc) {
            this.props.pacingGuideBaseProps.onStandardCategoryChangeFunc(value)
        }
    }

    onNotScheduledFilterChange = (e) => {
        this.setState({ notScheduledFilter: e.target.checked })
    }

    displayPacingGuideBeingCreatedNotificationIfNeeded = () => {
        if (this.props.teacherBaseProps &&
            this.props.teacherBaseProps.hasOwnProperty('districtSettings') &&
            this.props.teacherBaseProps.districtSettings &&
            this.props.teacherBaseProps.districtSettings.hasOwnProperty('pacingGuideBeingCreated') &&
            this.props.teacherBaseProps.districtSettings.pacingGuideBeingCreated) {
            return <div className='mb-3'>
                <Alert
                    type='info'
                    className='p-3 br-4'
                    message={<div className='font-20 font-bold'>Pacing Guide</div>}
                    description={<div className='font-20'>
                        Your pacing guide is currently being created.
                    </div>
                    }
                />
            </div>
        }

        return ''
    }

    getWeeks = (pacingGuide, futurePacingGuide) => {
        if (futurePacingGuide) {
            return futurePacingGuide.weeks
        } else if (pacingGuide) {
            return pacingGuide.weeks
        }

        return {}
    }

    pacingGuideIsEmpty = (pacingGuide) => {
        if (!pacingGuide) {
            return true
        }

        if (pacingGuide &&
            pacingGuide.weeks) {
            for (let week in pacingGuide.weeks) {
                // skip week 0
                if (week === '0') {
                    continue
                }
                if (pacingGuide.weeks[week].length > 0) {
                    return false
                }
            }
        }

        return true
    }

    render() {
        let isLocked = false
        let standardDisplayName = ''
        let isCurrentYear = true
        if (this.props.pacingGuideBaseProps &&
            this.props.pacingGuideBaseProps.standardNamesDict &&
            this.props.pacingGuideBaseProps.standardCategory &&
            this.props.pacingGuideBaseProps.standardName &&
            this.props.pacingGuideBaseProps.standardNamesDict.hasOwnProperty(this.props.pacingGuideBaseProps.standardCategory + 'DisplayNames') &&
            this.props.pacingGuideBaseProps.standardNamesDict[this.props.pacingGuideBaseProps.standardCategory + 'DisplayNames'].hasOwnProperty(this.props.pacingGuideBaseProps.standardName)) {
            standardDisplayName = this.props.pacingGuideBaseProps.standardNamesDict[this.props.pacingGuideBaseProps.standardCategory + 'DisplayNames'][this.props.pacingGuideBaseProps.standardName]
        }
        if (this.props.pacingGuideBaseProps &&
            this.props.pacingGuideBaseProps.activeYear &&
            this.props.pacingGuideBaseProps.standardYears &&
            this.props.pacingGuideBaseProps.standardYears.currentYear) {
            // viewing a previous pacing guide, pacing guide is locked
            isCurrentYear = this.props.pacingGuideBaseProps.activeYear === this.props.pacingGuideBaseProps.standardYears.currentYear
        }
        // teachers can't edit pacing guide
        if (this.props.teacherBaseProps &&
            this.props.teacherBaseProps.hasOwnProperty('teacher')) {
            if (!(this.props.teacherBaseProps.districtSettings &&
                this.props.teacherBaseProps.districtSettings.hasOwnProperty('teachersCanEditPacingGuide') &&
                this.props.teacherBaseProps.districtSettings.teachersCanEditPacingGuide)) {
                isLocked = true
            }
        }
        // admins can't edit pacing guide if the pacing guide is locked
        else if (this.props.teacherBaseProps.district &&
            this.props.teacherBaseProps.district.hasOwnProperty('pacingGuideLocked') &&
            this.props.teacherBaseProps.district.pacingGuideLocked) {
            isLocked = true
        } else if (!isCurrentYear) {
            isLocked = true
        }

        if (!(this.props.pacingGuideBaseProps &&
            this.props.pacingGuideBaseProps.pacingGuide &&
            this.props.pacingGuideBaseProps.pacingGuide.weeks)) {
            isLocked = true
        }
        if (this.props.pacingGuideBaseProps &&
            this.props.pacingGuideBaseProps.pacingGuideMode === 'future') {
            isLocked = true
        }

        return (
            <div>
                {this.props.pacingGuideBaseProps ?
                    <div>
                        {isLocked ?
                            <Layout className="layout-header-mt mt-0 min-w-1024">
                                <Sider
                                    width={270}
                                    style={{
                                        overflow: 'auto', overflowX: 'hidden', height: 'calc(100% - 82px)',
                                        position: 'fixed',
                                        left: 0, marginTop: '82px', borderRight: '1px solid #f1f1f1',
                                        background: '#fff', borderTop: '1px solid #f1f1f1'
                                    }}>
                                    <div className='pl-2 pr-2 mb-2 mt-3'>
                                        <Select onChange={this.onStandardCategoryChange} value={this.props.pacingGuideBaseProps.standardCategory} size='large' className='w-100 mb-1' >
                                            {this.props.pacingGuideBaseProps.standardCategories.map((category, ind) => {
                                                return <Select.Option key={'category-' + ind} value={category}>{category}</Select.Option>
                                            })}
                                        </Select>
                                        <Select onChange={this.onStandardNameChange} value={this.props.pacingGuideBaseProps.standardName} size='large' className='w-100'>
                                            {this.props.pacingGuideBaseProps.pacingGuideMode === 'current' &&
                                                this.props.pacingGuideBaseProps.standardNamesDict &&
                                                this.props.pacingGuideBaseProps.standardNamesDict.hasOwnProperty(this.props.pacingGuideBaseProps.standardCategory) &&
                                                this.props.pacingGuideBaseProps.standardNamesDict[this.props.pacingGuideBaseProps.standardCategory].map((standardName, ind) => {
                                                    return <Select.Option key={'grade-' + ind} value={standardName}>{standardName}</Select.Option>
                                                })}
                                            {this.props.pacingGuideBaseProps.pacingGuideMode === 'future' &&
                                                this.props.pacingGuideBaseProps.standardNamesDict &&
                                                this.props.pacingGuideBaseProps.standardNamesDict.hasOwnProperty('Future' + this.props.pacingGuideBaseProps.standardCategory) &&
                                                this.props.pacingGuideBaseProps.standardNamesDict['Future' + this.props.pacingGuideBaseProps.standardCategory] &&
                                                this.props.pacingGuideBaseProps.standardNamesDict['Future' + this.props.pacingGuideBaseProps.standardCategory].map((standardName, ind) => {
                                                    return <Select.Option key={'grade-' + ind} value={standardName}>
                                                        Next year: {standardName}
                                                    </Select.Option>
                                                })}
                                        </Select>
                                        {this.props.teacherBaseProps &&
                                            !this.props.teacherBaseProps.hasOwnProperty('teacher') &&
                                            this.props.teacherBaseProps.hasOwnProperty('districtSettings') &&
                                            this.props.teacherBaseProps.districtSettings &&
                                            this.props.teacherBaseProps.districtSettings.hasOwnProperty('pacingGuideYears') &&
                                            this.props.teacherBaseProps.districtSettings.pacingGuideYears &&
                                            this.props.teacherBaseProps.districtSettings.pacingGuideYears.length > 1 ?
                                            <Select onChange={(e) => {
                                                if (this.props.pacingGuideBaseProps &&
                                                    this.props.pacingGuideBaseProps.onSetActiveYearFunc) {
                                                    this.props.pacingGuideBaseProps.onSetActiveYearFunc(e)
                                                }
                                            }}
                                                value={this.props.pacingGuideBaseProps.activeYear}
                                                size='large' className='w-100 mt-1'>
                                                {this.props.teacherBaseProps.districtSettings.pacingGuideYears.sort((a, b) => {
                                                    return b - a
                                                }).map((year, ind) => {
                                                    let yearAsInt = parseInt(year, 10)
                                                    return <Select.Option key={'year-' + ind} value={year}>{year + '-' + (yearAsInt + 1)}</Select.Option>
                                                })}
                                            </Select> : ''}

                                        {this.props.pacingGuideBaseProps.futurePacingGuide ?
                                            <div className='w-100 mt-2'>
                                                <RadioGroup size='large' onChange={(e) => {
                                                    if (this.props.pacingGuideBaseProps &&
                                                        this.props.pacingGuideBaseProps.onSetPacingGuideMode) {
                                                        this.props.pacingGuideBaseProps.onSetPacingGuideMode(e.target.value)
                                                    }
                                                }}
                                                    value={this.props.pacingGuideBaseProps.pacingGuideMode} className='mt-1'>
                                                    <Radio.Button value={'current'} className='font-16 w-100'>Current Pacing Guide</Radio.Button>
                                                    <Radio.Button value={'future'} className='font-16 w-100'>Next Year's Standards</Radio.Button>
                                                </RadioGroup>

                                                {this.props.pacingGuideBaseProps.pacingGuideMode === 'future' ?
                                                    <a href={`https://ed.sc.gov/instruction/standards/mathematics/instructional-resources/content-shifts-potential-gaps-for-math-standards-implementation/#${encodeURIComponent(this.props.pacingGuideBaseProps.standardName)}`}
                                                        target='_blank' rel='noopener noreferrer'
                                                        className='ant-btn ant-btn-primary ant-btn-lg w-100 mb-2 mt-3'>
                                                        View Content Shifts
                                                        <Icon type='arrow-right' className='ml-1' />
                                                    </a> : ''}
                                            </div>
                                            : ''}
                                    </div>

                                    <Menu
                                        onSelect={(e) => {
                                            const element = document.getElementById('Quarter-' + e.key)
                                            if (element) {
                                                const offset = 100;
                                                const elementPosition = element.getBoundingClientRect().top + window.scrollY - offset;
                                                window.scrollTo({
                                                    top: elementPosition,
                                                })
                                            }
                                        }}
                                        defaultSelectedKeys={['1']}
                                    >
                                        <Menu.Item key="1">
                                            <div className='font-16'>Quarter 1</div>
                                        </Menu.Item>
                                        <Menu.Item key="2">
                                            <div className='font-16'>Quarter 2</div>
                                        </Menu.Item>
                                        <Menu.Item key="3">
                                            <div className='font-16'>Quarter 3</div>
                                        </Menu.Item>
                                        <Menu.Item key="4">
                                            <div className='font-16'>Quarter 4</div>
                                        </Menu.Item>
                                    </Menu>
                                </Sider>
                                <Layout className="content">
                                    <Content
                                        style={{
                                            marginLeft: 270, marginTop: "98px", overflowX: 'initial'
                                        }}
                                        className='p-2 pl-4 pr-4'
                                    >
                                        <div
                                            className='pb-2 pl-4 pr-4'>
                                            {this.displayPacingGuideBeingCreatedNotificationIfNeeded()}
                                            <div className='flex flex-v-center w-100 mb-2'>
                                                {/*this.props.teacherBaseProps &&
                                                    this.props.teacherBaseProps.teacher ?
                                                    <Link
                                                        to={'/teacher/pacing-guide/weekly-planner/' + this.props.teacherBaseProps.teacher.id}
                                                        className={'ant-btn ant-btn-primary lg-btn inline-flex ml-2'}>
                                                        <Icon type="schedule" className={"va-middle " +
                                                            "mr-0 br-50 p-1 font-24"} />
                                                        <span>Weekly Planner</span>
                                    </Link> : ''*/}
                                                {this.props.pacingGuideBaseProps &&
                                                    this.props.pacingGuideBaseProps.pacingGuidePDFURL !== '' ?
                                                    <div className='ml-auto'>
                                                        <a
                                                            href={this.props.pacingGuideBaseProps.pacingGuidePDFURL}
                                                            target='_blank'
                                                            rel='noopener noreferrer'
                                                            className={'ant-btn ant-btn-primary lg-btn inline-flex'}
                                                        >
                                                            <Icon type='file-pdf' className='font-20 mr-1' />
                                                            {this.props.pacingGuideBaseProps &&
                                                                this.props.pacingGuideBaseProps.pacingGuidePDFURLLoading ?
                                                                <div style={{ width: '216px' }}>
                                                                    <Spin className='fff-spin' />
                                                                </div> :
                                                                <div>{'View Pacing Guide PDF'}</div>}
                                                        </a>
                                                    </div> : ''}
                                            </div>


                                            <div>
                                                <div className='font-24 mb-2 p-2 background-lighter-blue br-4'>
                                                    <div className='mb-2'>
                                                        Quizzes for most standards and common assessments are now available. To learn how to access and use them, please view our tutorial videos below.
                                                    </div>
                                                    <ModalVideo
                                                        title='Common Assessments'
                                                        visible={this.state.commonAssessmentTutorialModalVisible}
                                                        onOk={() => {
                                                            this.setState({
                                                                commonAssessmentTutorialModalVisible: false,
                                                            })
                                                        }}
                                                        onCancel={() => {
                                                            this.setState({
                                                                commonAssessmentTutorialModalVisible: false,
                                                            })
                                                        }}
                                                        width={667}
                                                        src={'https://firebasestorage.googleapis.com/v0/b/education-9d7f3.appspot.com/o/common_assessments_v2.mp4?alt=media&token=d4fa6ab4-a976-4234-bc06-dec21613cee2'}
                                                        className='p-0'
                                                    />
                                                    <ModalVideo
                                                        title='Quizzes'
                                                        visible={this.state.quizTutorialModalVisible}
                                                        onOk={() => {
                                                            this.setState({
                                                                quizTutorialModalVisible: false,
                                                            })
                                                        }}
                                                        onCancel={() => {
                                                            this.setState({
                                                                quizTutorialModalVisible: false,
                                                            })
                                                        }}
                                                        width={667}
                                                        src={'https://firebasestorage.googleapis.com/v0/b/education-9d7f3.appspot.com/o/quizzes-2024.mp4?alt=media&token=31de3985-9f90-4ea4-9694-9e9c89b033b8'}
                                                        className='p-0'
                                                    />
                                                    <div className='flex'>
                                                        <Button className='lg-btn mr-1' type='primary'
                                                            onClick={(e) => {
                                                                this.setState({
                                                                    quizTutorialModalVisible: true,
                                                                })
                                                            }}
                                                        >
                                                            <div className='flex flex-v-center'>
                                                                <Icon type='play-circle' className='mr-1 font-24' />
                                                                View Quiz Tutorial Video
                                                            </div>
                                                        </Button>
                                                        <Button className='lg-btn' type='primary'
                                                            onClick={(e) => {
                                                                this.setState({
                                                                    commonAssessmentTutorialModalVisible: true,
                                                                })
                                                            }}
                                                        >
                                                            <div className='flex flex-v-center'>
                                                                <Icon type='play-circle' className='mr-1 font-24' />
                                                                View Common Assessments Tutorial Video
                                                            </div>
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>




                                            {this.props.pacingGuideBaseProps.pacingGuideMode === 'future' &&
                                                this.props.pacingGuideBaseProps.futurePacingGuide ?
                                                <div>
                                                    <div className='font-24 mb-2 p-2 background-lighter-blue br-4'>This is an example pacing guide to preview next year's standards.</div>
                                                </div>
                                                : ''}
                                            <div className='br-20 overflow-hidden' id={'Quarter-' + 1}>
                                                <div className='br-4 relative pl-3 pr-3 pt-3 relative'
                                                    style={{ height: '280px', }}>

                                                    <div className="absolute-tl w-100 h-100" style={{ backgroundColor: '#233142' }}>
                                                    </div>
                                                    <div className="absolute-tl w-100 h-100"
                                                    >
                                                    </div>
                                                    <div className='absolute-tl w-100 h-100'>
                                                        <div className='h-100'>
                                                            <div className='flex flex-v-center font-40 font-fff h-100 p-4'>
                                                                <div>
                                                                    <div className='font-40 font-bold font-fff'>Standards First Pacing Guide</div>
                                                                    <div className='font-24 font-fff font-bold'>{standardDisplayName ? standardDisplayName : ''}</div>
                                                                    <div>
                                                                        <div className='font-24 p-2 pt-1 pb-1 br-4 mt-1 inline-block'
                                                                            style={{ background: '#3fa672' }}
                                                                        ><span>{'Quarter 1'}</span></div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {this.props.pacingGuideBaseProps.weekNums &&
                                                this.props.pacingGuideBaseProps.weekNums.map((weekNumber, ind) => {
                                                    if (ind === 0) {
                                                        return false
                                                    }
                                                    let week = null
                                                    if (this.props.pacingGuideBaseProps.pacingGuide) {
                                                        if (!this.props.pacingGuideBaseProps.pacingGuide.weeks.hasOwnProperty(weekNumber)) {
                                                            return false
                                                        }
                                                        week = this.props.pacingGuideBaseProps.pacingGuide.weeks[weekNumber]
                                                    }
                                                    if (this.props.pacingGuideBaseProps.pacingGuideMode === 'future' &&
                                                        this.props.pacingGuideBaseProps.futurePacingGuide) {
                                                        if (!this.props.pacingGuideBaseProps.futurePacingGuide.weeks.hasOwnProperty(weekNumber)) {
                                                            return false
                                                        }
                                                        week = this.props.pacingGuideBaseProps.futurePacingGuide.weeks[weekNumber]
                                                    }
                                                    if (!week) {
                                                        return false
                                                    }

                                                    // every 9 weeks
                                                    let quarter = -1
                                                    if (ind === 1) {
                                                        quarter = 1
                                                    } else if (ind === 10) {
                                                        quarter = 2
                                                    } else if (ind === 19) {
                                                        quarter = 3
                                                    } else if (ind === 28) {
                                                        quarter = 4
                                                    }
                                                    let weekNumForQuarter = ind % 9
                                                    if (weekNumForQuarter === 0) {
                                                        weekNumForQuarter = 9
                                                    }
                                                    let commonAssessmentsForThisWeekAreLive = false
                                                    if (this.props.teacherBaseProps &&
                                                        this.props.teacherBaseProps.hasOwnProperty('districtSettings') &&
                                                        this.props.teacherBaseProps.districtSettings &&
                                                        this.props.teacherBaseProps.districtSettings.hasOwnProperty('commonAssessmentsLive') &&
                                                        this.props.teacherBaseProps.districtSettings.commonAssessmentsLive &&
                                                        this.props.teacherBaseProps.districtSettings.commonAssessmentsLive.hasOwnProperty(weekNumber)) {
                                                        commonAssessmentsForThisWeekAreLive = this.props.teacherBaseProps.districtSettings.commonAssessmentsLive[weekNumber]
                                                    }
                                                    let isBenchmark = false
                                                    if (this.props.pacingGuideBaseProps &&
                                                        this.props.pacingGuideBaseProps.assessmentsWeekDict &&
                                                        this.props.pacingGuideBaseProps.assessmentsWeekDict.hasOwnProperty(weekNumber) &&
                                                        this.props.pacingGuideBaseProps.assessmentsWeekDict[weekNumber].assessments &&
                                                        this.props.pacingGuideBaseProps.assessmentsWeekDict[weekNumber].assessments.length > 0) {
                                                        let firstAssessment = this.props.pacingGuideBaseProps.assessmentsWeekDict[weekNumber].assessments[0]
                                                        isBenchmark = firstAssessment.assessmentCategory === 'benchmark'
                                                    }

                                                    let backgroundColor = '#faa916'
                                                    if (quarter === 3) {
                                                        backgroundColor = '#722ed1'
                                                    } else if (quarter === 4) {
                                                        backgroundColor = '#1098f7'
                                                    }

                                                    return <div key={'weekNum-' + ind}>
                                                        {quarter !== -1 && quarter !== 1 ?
                                                            <div className='br-20 overflow-hidden' id={'Quarter-' + quarter}>
                                                                <div className='br-4 relative pl-3 pr-3 pt-3 relative'
                                                                    style={{ height: '280px', }}>

                                                                    <div className="absolute-tl w-100 h-100" style={{ backgroundColor: '#233142' }}>
                                                                    </div>
                                                                    <div className="absolute-tl w-100 h-100"
                                                                    >
                                                                    </div>
                                                                    <div className='absolute-tl w-100 h-100'>
                                                                        <div className='h-100'>
                                                                            <div className='flex flex-v-center font-40 font-fff h-100 p-4'>
                                                                                <div>
                                                                                    <div className='font-40 font-bold font-fff'>Standards First Pacing Guide</div>
                                                                                    <div className='font-24 font-fff font-bold'>{standardDisplayName ? standardDisplayName : ''}</div>
                                                                                    <div>
                                                                                        <div className='font-24 p-2 pt-1 pb-1 br-4 mt-1 inline-block'
                                                                                            style={{ background: backgroundColor }}
                                                                                        ><span>{'Quarter ' + quarter}</span></div>
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div> : ''}
                                                        <div className='flex flex-v-center mt-4 pt-3 mb-05'>
                                                            <div className='font-20 font-bold text-muted mr-3'>{'Week ' + weekNumForQuarter}</div>
                                                            <Button type='default' size='large'
                                                                onClick={(e) => {
                                                                    let expandedWeeks = this.state.expandedWeeks
                                                                    let expandedItems = this.state.expandedItems
                                                                    const weekBase = weekNumber + '-'
                                                                    if (expandedWeeks.hasOwnProperty(weekNumber)) {
                                                                        delete expandedWeeks[weekNumber]
                                                                        Object.keys(expandedItems).forEach((key) => {
                                                                            if (key.startsWith(weekBase)) {
                                                                                delete expandedItems[key]
                                                                            }
                                                                        })
                                                                    } else {
                                                                        expandedWeeks[weekNumber] = true
                                                                        for (let i = 0; i < week.length; i++) {
                                                                            let key = weekBase + i
                                                                            expandedItems[key] = true
                                                                        }
                                                                    }

                                                                    this.setState({
                                                                        expandedWeeks: expandedWeeks,
                                                                        expandedItems: expandedItems
                                                                    })
                                                                }}>
                                                                {this.state.expandedWeeks.hasOwnProperty(weekNumber) ?
                                                                    <div className='flex flex-v-center font-16'>
                                                                        <Icon type='minus' className='font-20 mr-1' />
                                                                        <div>Hide all descriptions</div>
                                                                    </div>
                                                                    :
                                                                    <div className='flex flex-v-center font-16'>
                                                                        <Icon type='plus' className='font-20 mr-1' />
                                                                        <div>Show all descriptions</div>
                                                                    </div>
                                                                }
                                                            </Button>
                                                        </div>
                                                        <div key={ind}>

                                                            <div
                                                                key={'droppable-div-' + ind}
                                                                style={this.getListStyle(false, 10)}
                                                            >
                                                                {week.map((item, index) => {
                                                                    const lessonPlanQueryString = '?standardName=' + item.standardName + '&standard=' + item.standard + '&grade=' + item.grade + '&category=' + item.standardCategory + '&week=' + weekNumForQuarter + '&index=' + index
                                                                    return <div key={ind + '-' + index}
                                                                        style={this.getItemStyle(false, {}, this.props.pacingGuideBaseProps.pacingGuideMode)}>
                                                                        <div>
                                                                            <PacingGuideDraggableStandard
                                                                                item={item}
                                                                                displayTitle={this.state.expandedItems.hasOwnProperty(weekNumber + '-' + index)}
                                                                            />
                                                                            <Button type='primary' size='large' className='transparent-btn text-cyan font-16'
                                                                                onClick={(e) => {
                                                                                    let key = weekNumber + '-' + index
                                                                                    let expandedItems = this.state.expandedItems
                                                                                    if (expandedItems.hasOwnProperty(key)) {
                                                                                        delete expandedItems[key]
                                                                                    }
                                                                                    else {
                                                                                        expandedItems[key] = true
                                                                                    }
                                                                                    this.setState({
                                                                                        expandedItems: expandedItems
                                                                                    })
                                                                                }}>
                                                                                {this.state.expandedItems.hasOwnProperty(weekNumber + '-' + index) ?
                                                                                    <div className='flex flex-v-center font-14'>
                                                                                        <Icon type='minus' className='font-16 mr-1' />
                                                                                        <div>Hide description</div>
                                                                                    </div>
                                                                                    :
                                                                                    <div className='flex flex-v-center font-14'>
                                                                                        <Icon type='plus' className='font-16 mr-1' />
                                                                                        <div>Show description</div>
                                                                                    </div>
                                                                                }
                                                                            </Button>
                                                                            {!(!isCurrentYear ||
                                                                                (this.props.pacingGuideBaseProps.pacingGuideMode === 'future' &&
                                                                                    this.props.pacingGuideBaseProps.futurePacingGuide)) ?
                                                                                <Link to={this.props.teacherBaseProps &&
                                                                                    this.props.teacherBaseProps.teacher ?
                                                                                    '/teacher/pacing-guide/lesson-plan/' + this.props.teacherBaseProps.teacher.id + lessonPlanQueryString
                                                                                    :
                                                                                    this.props.teacherBaseProps &&
                                                                                        this.props.teacherBaseProps.admin ?
                                                                                        '/admin/pacing-guide/lesson-plan/' + this.props.teacherBaseProps.admin.id + lessonPlanQueryString
                                                                                        :
                                                                                        this.props.teacherBaseProps &&
                                                                                            this.props.teacherBaseProps.instructionalFacilitator ?
                                                                                            '/instructional-facilitator/pacing-guide/lesson-plan/' + this.props.teacherBaseProps.instructionalFacilitator.id + lessonPlanQueryString
                                                                                            :
                                                                                            this.props.teacherBaseProps &&
                                                                                                this.props.teacherBaseProps.schoolAdmin ?
                                                                                                '/school-admin/pacing-guide/lesson-plan/' + this.props.teacherBaseProps.schoolAdmin.id + lessonPlanQueryString
                                                                                                :
                                                                                                ''}
                                                                                    size='large'
                                                                                    style={{ position: 'absolute', bottom: '16px', left: '16px', width: 'calc(100% - 32px)' }}
                                                                                    className='ant-btn ant-btn-lg ant-btn-default mt-2 flex flex-v-center flex-h-center font-16'>
                                                                                    <Icon type='solution' className='mr-05 font-20' />
                                                                                    <span>View Lesson</span>
                                                                                </Link> : ''}
                                                                        </div>
                                                                    </div>
                                                                })}
                                                            </div>

                                                            {weekNumber === 3 &&
                                                                this.props.teacherBaseProps &&
                                                                this.props.teacherBaseProps.hasOwnProperty('district') &&
                                                                this.props.teacherBaseProps.district &&
                                                                this.props.teacherBaseProps.district.hasOwnProperty('state') &&
                                                                (this.props.teacherBaseProps.district.id === '64bec570267c8bfeea81f9ea' ||
                                                                    this.props.teacherBaseProps.district.id === '521ce2f24da7037445000552') &&
                                                                this.props.pacingGuideBaseProps &&
                                                                this.props.pacingGuideBaseProps.standardCategory === 'ELA' &&
                                                                this.props.pacingGuideBaseProps.standardName === 'Kindergarten' ?
                                                                <div>
                                                                    <PacingGuideHTMLAssessmentModalForm
                                                                        assessment={{
                                                                            htmlQuiz: KAssessment,
                                                                            assessmentCategory: 'common_assessment',
                                                                            assessmentTitle: 'Kindergarten Common Assessment (Form A)',
                                                                            createdAt: "2024-08-11 22:36:52",
                                                                            live: true,
                                                                            canEdit: false,
                                                                            districtId: this.props.teacherBaseProps.district.id,
                                                                            standardName: 'Kindergarten',
                                                                            standardCategory: 'ELA',
                                                                            standards: ['ELA.K.AOR.1.1', 'ELA.K.AOR.2.1', 'ELA.K.AOR.7.1.a', 'ELA.K.AOR.8.1.b'],
                                                                            state: 'SC',
                                                                            docs: [
                                                                                { components: [{ question: [], answers: [{ id: 0, correct: true }] }], metadata: { uuid: 0 } },
                                                                                { components: [{ question: [], answers: [{ id: 0, correct: true }] }], metadata: { uuid: 1 } },
                                                                                { components: [{ question: [], answers: [{ id: 0, correct: true }] }], metadata: { uuid: 2 } },
                                                                                { components: [{ question: [], answers: [{ id: 0, correct: true }] }], metadata: { uuid: 3 } },
                                                                                { components: [{ question: [], answers: [{ id: 0, correct: true }] }], metadata: { uuid: 4 } }
                                                                            ],
                                                                            chosenAnswers: {},
                                                                            chosenAnswersBaseQuiz: {},
                                                                            numQuestions: 5,
                                                                            questionIdx: 0,
                                                                            year: '2024',
                                                                        }}
                                                                        teacherBaseProps={this.props.teacherBaseProps}
                                                                        buttonText={'Kindergarten Common Assessment (Form A)'}
                                                                        disableQuiz={this.props.teacherBaseProps && !this.props.teacherBaseProps.hasOwnProperty('teacher')}
                                                                    />
                                                                </div>
                                                                : ''}

                                                            {weekNumber === 3 &&
                                                                this.props.teacherBaseProps &&
                                                                this.props.teacherBaseProps.hasOwnProperty('district') &&
                                                                this.props.teacherBaseProps.district &&
                                                                this.props.teacherBaseProps.district.hasOwnProperty('state') &&
                                                                (this.props.teacherBaseProps.district.id === '64bec570267c8bfeea81f9ea' ||
                                                                    this.props.teacherBaseProps.district.id === '521ce2f24da7037445000552') &&
                                                                this.props.pacingGuideBaseProps &&
                                                                this.props.pacingGuideBaseProps.standardCategory === 'ELA' &&
                                                                this.props.pacingGuideBaseProps.standardName === '1st Grade' ?
                                                                <div>
                                                                    <PacingGuideHTMLAssessmentModalForm
                                                                        assessment={{
                                                                            htmlQuiz: FirstGradeAssessment,
                                                                            assessmentCategory: 'common_assessment',
                                                                            assessmentTitle: '1st Grade Common Assessment (Form A)',
                                                                            createdAt: "2024-08-11 22:36:52",
                                                                            live: true,
                                                                            canEdit: false,
                                                                            districtId: this.props.teacherBaseProps.district.id,
                                                                            standardName: '1st Grade',
                                                                            standardCategory: 'ELA',
                                                                            standards: ['ELA.1.AOR.2.2', 'ELA.1.AOR.6.1.b', 'ELA.1.AOR.5.3', 'ELA.1.AOR.7.1.d', 'ELA.1.AOR.8.1.c', 'ELA.1.AOR.5.1'],
                                                                            state: 'SC',
                                                                            docs: [
                                                                                { components: [{ question: [], answers: [{ id: 0, correct: true }] }], metadata: { uuid: 0 } },
                                                                                { components: [{ question: [], answers: [{ id: 0, correct: true }] }], metadata: { uuid: 1 } },
                                                                                { components: [{ question: [], answers: [{ id: 0, correct: true }] }], metadata: { uuid: 2 } },
                                                                                { components: [{ question: [], answers: [{ id: 0, correct: true }] }], metadata: { uuid: 3 } },
                                                                                { components: [{ question: [], answers: [{ id: 0, correct: true }] }], metadata: { uuid: 4 } }
                                                                            ],
                                                                            chosenAnswers: {},
                                                                            chosenAnswersBaseQuiz: {},
                                                                            numQuestions: 5,
                                                                            questionIdx: 0,
                                                                            year: '2024',
                                                                        }}
                                                                        teacherBaseProps={this.props.teacherBaseProps}
                                                                        buttonText={'1st Grade Common Assessment (Form A)'}
                                                                        disableQuiz={this.props.teacherBaseProps && !this.props.teacherBaseProps.hasOwnProperty('teacher')}
                                                                    />
                                                                </div>
                                                                : ''}

                                                            {(commonAssessmentsForThisWeekAreLive &&
                                                                this.props.pacingGuideBaseProps &&
                                                                this.props.pacingGuideBaseProps.assessmentsWeekDict &&
                                                                this.props.pacingGuideBaseProps.assessmentsWeekDict.hasOwnProperty(weekNumber) &&
                                                                this.props.pacingGuideBaseProps.assessmentsWeekDict[weekNumber].assessments)
                                                                ||
                                                                (commonAssessmentsForThisWeekAreLive &&
                                                                    this.props.pacingGuideBaseProps.hasOwnProperty('quizzesForEndWeek') &&
                                                                    this.props.pacingGuideBaseProps.quizzesForEndWeek &&
                                                                    this.props.pacingGuideBaseProps.quizzesForEndWeek.hasOwnProperty(weekNumber) &&
                                                                    this.props.pacingGuideBaseProps.quizzesForEndWeek[weekNumber] &&
                                                                    this.props.pacingGuideBaseProps.quizzesForEndWeek[weekNumber].length > 0) ?
                                                                <div className='mt-4'>
                                                                    <div className='ant-shadow p-2 br-4 mb-2 background-light-grey-blue'>
                                                                        {this.props.pacingGuideBaseProps &&
                                                                            this.props.pacingGuideBaseProps.assessmentsWeekDict &&
                                                                            this.props.pacingGuideBaseProps.assessmentsWeekDict.hasOwnProperty(weekNumber) &&
                                                                            this.props.pacingGuideBaseProps.assessmentsWeekDict[weekNumber].assessments ?
                                                                            <div>
                                                                                <div className='font-20 mb-05 font-bold'>
                                                                                    {this.props.pacingGuideBaseProps.standardName === 'Kindergarten' ||
                                                                                        this.props.pacingGuideBaseProps.standardName === '1st Grade' ?
                                                                                        'Kindergarten and 1st Grade common assessments are coming soon.' :
                                                                                        isBenchmark ? 'Benchmark' : 'Common assessments'}
                                                                                </div>
                                                                                {isBenchmark ?
                                                                                    <div className='font-20 mb-2' style={{ maxWidth: '1100px' }}>
                                                                                        The Benchmark questions are aligned with the standards of this quarter in the Pacing Guide.
                                                                                    </div>
                                                                                    :
                                                                                    <div className='font-20 mb-2' style={{ maxWidth: '1100px' }}>
                                                                                        The Common Assessment questions are aligned with the last three weeks of the Pacing Guide. Common Assessment Form A is given to the entire class. Common Assessment Form B can be given to individual students selected by the teacher as a retake.
                                                                                    </div>
                                                                                }
                                                                            </div> : ''}
                                                                        {this.props.pacingGuideBaseProps.hasOwnProperty('quizzesForEndWeek') &&
                                                                            this.props.pacingGuideBaseProps.quizzesForEndWeek &&
                                                                            this.props.pacingGuideBaseProps.quizzesForEndWeek.hasOwnProperty(weekNumber) &&
                                                                            this.props.pacingGuideBaseProps.quizzesForEndWeek[weekNumber] &&
                                                                            this.props.pacingGuideBaseProps.quizzesForEndWeek[weekNumber].length > 0 ?
                                                                            <div>
                                                                                <div className='font-20 mb-05 font-bold'>
                                                                                    Assessments
                                                                                </div>
                                                                            </div>
                                                                            : ''}
                                                                        <div className='flex flex-wrap w-100'>
                                                                            {this.props.pacingGuideBaseProps &&
                                                                                this.props.pacingGuideBaseProps.assessmentsWeekDict &&
                                                                                this.props.pacingGuideBaseProps.assessmentsWeekDict.hasOwnProperty(weekNumber) &&
                                                                                this.props.pacingGuideBaseProps.assessmentsWeekDict[weekNumber].assessments &&
                                                                                this.props.pacingGuideBaseProps.assessmentsWeekDict[weekNumber].assessments.map((assessment, index) => {
                                                                                    return <div className='mb-1 mr-1'>
                                                                                        <PacingGuideAssessmentModalForm
                                                                                            assessment={assessment}
                                                                                            teacherBaseProps={this.props.teacherBaseProps}
                                                                                            buttonText={isBenchmark ? 'Benchmark' :
                                                                                                (assessment.standardName + (index === 0 ? ' Common Assessment (Form A)' : ' Common Assessment (Form B)'))
                                                                                            }
                                                                                            disableQuiz={!commonAssessmentsForThisWeekAreLive ||
                                                                                                (this.props.teacherBaseProps && !this.props.teacherBaseProps.hasOwnProperty('teacher')) ||
                                                                                                this.props.pacingGuideBaseProps.standardName === 'Kindergarten' ||
                                                                                                this.props.pacingGuideBaseProps.standardName === '1st Grade' ||
                                                                                                assessment.standardName === 'Kindergarten' ||
                                                                                                assessment.standardName === '1st Grade'}
                                                                                        />
                                                                                    </div>
                                                                                })}

                                                                            {this.props.pacingGuideBaseProps.hasOwnProperty('quizzesForEndWeek') &&
                                                                                this.props.pacingGuideBaseProps.quizzesForEndWeek &&
                                                                                this.props.pacingGuideBaseProps.quizzesForEndWeek.hasOwnProperty(weekNumber) &&
                                                                                this.props.pacingGuideBaseProps.quizzesForEndWeek[weekNumber] &&
                                                                                this.props.pacingGuideBaseProps.quizzesForEndWeek[weekNumber].map((quiz, index) => {
                                                                                    return <div className='mb-1 mr-1'>
                                                                                        <PacingGuideAssessmentModalForm
                                                                                            assessment={quiz}
                                                                                            teacherBaseProps={this.props.teacherBaseProps}
                                                                                            buttonText={quiz.hasOwnProperty('assessmentTitle') ? quiz.assessmentTitle : 'Quiz'}
                                                                                            disableQuiz={!commonAssessmentsForThisWeekAreLive ||
                                                                                                (this.props.teacherBaseProps && !this.props.teacherBaseProps.hasOwnProperty('teacher')) ||
                                                                                                this.props.pacingGuideBaseProps.standardName === 'Kindergarten' ||
                                                                                                this.props.pacingGuideBaseProps.standardName === '1st Grade' ||
                                                                                                quiz.standardName === 'Kindergarten' ||
                                                                                                quiz.standardName === '1st Grade'}
                                                                                        />
                                                                                    </div>
                                                                                })}


                                                                        </div>
                                                                    </div>
                                                                </div> : ''}

                                                        </div>
                                                    </div>
                                                })}
                                        </div>
                                    </Content>
                                </Layout>
                            </Layout>
                            :
                            <DragDropContext onDragEnd={this.onDragEnd}>
                                {this.props.pacingGuideBaseProps ?
                                    <Layout className="layout-header-mt mt-0 min-w-1024">
                                        <Sider
                                            width={270}
                                            style={{
                                                overflow: 'auto', overflowX: 'hidden', height: 'calc(100% - 82px)',
                                                position: 'fixed',
                                                left: 0, marginTop: '82px', borderRight: '1px solid #f1f1f1',
                                                background: '#fff', borderTop: '1px solid #f1f1f1'
                                            }}>
                                            <div className='pl-2 pr-2 pb-3 mb-3 mt-3 border-bottom-lighter'>
                                                <Select onChange={this.onStandardCategoryChange} value={this.props.pacingGuideBaseProps.standardCategory} size='large' className='w-100 mb-1' >
                                                    {this.props.pacingGuideBaseProps.standardCategories.map((category, ind) => {
                                                        return <Select.Option key={'category-' + ind} value={category}>{category}</Select.Option>
                                                    })}
                                                </Select>
                                                <Select onChange={this.onStandardNameChange} value={this.props.pacingGuideBaseProps.standardName} size='large' className='w-100'>
                                                    {this.props.pacingGuideBaseProps.pacingGuideMode === 'current' &&
                                                        this.props.pacingGuideBaseProps.standardNamesDict &&
                                                        this.props.pacingGuideBaseProps.standardNamesDict.hasOwnProperty(this.props.pacingGuideBaseProps.standardCategory) &&
                                                        this.props.pacingGuideBaseProps.standardNamesDict[this.props.pacingGuideBaseProps.standardCategory].map((standardName, ind) => {
                                                            return <Select.Option key={'grade-' + ind} value={standardName}>{standardName}</Select.Option>
                                                        })}
                                                    {this.props.pacingGuideBaseProps.pacingGuideMode === 'future' &&
                                                        this.props.pacingGuideBaseProps.standardNamesDict &&
                                                        this.props.pacingGuideBaseProps.standardNamesDict.hasOwnProperty('Future' + this.props.pacingGuideBaseProps.standardCategory) &&
                                                        this.props.pacingGuideBaseProps.standardNamesDict['Future' + this.props.pacingGuideBaseProps.standardCategory] &&
                                                        this.props.pacingGuideBaseProps.standardNamesDict['Future' + this.props.pacingGuideBaseProps.standardCategory].map((standardName, ind) => {
                                                            return <Select.Option key={'grade-' + ind} value={standardName}>
                                                                Next year: {standardName}
                                                            </Select.Option>
                                                        })}
                                                </Select>
                                                {this.props.teacherBaseProps &&
                                                    !this.props.teacherBaseProps.hasOwnProperty('teacher') &&
                                                    this.props.teacherBaseProps.hasOwnProperty('districtSettings') &&
                                                    this.props.teacherBaseProps.districtSettings &&
                                                    this.props.teacherBaseProps.districtSettings.hasOwnProperty('pacingGuideYears') &&
                                                    this.props.teacherBaseProps.districtSettings.pacingGuideYears &&
                                                    this.props.teacherBaseProps.districtSettings.pacingGuideYears.length > 1 ?
                                                    <Select onChange={(e) => {
                                                        if (this.props.pacingGuideBaseProps &&
                                                            this.props.pacingGuideBaseProps.onSetActiveYearFunc) {
                                                            this.props.pacingGuideBaseProps.onSetActiveYearFunc(e)
                                                        }
                                                    }}
                                                        value={this.props.pacingGuideBaseProps.activeYear}
                                                        size='large' className='w-100 mt-1'>
                                                        {this.props.teacherBaseProps.districtSettings.pacingGuideYears.sort((a, b) => {
                                                            return b - a
                                                        }).map((year, ind) => {
                                                            let yearAsInt = parseInt(year, 10)
                                                            return <Select.Option key={'year-' + ind} value={year}>{year + '-' + (yearAsInt + 1)}</Select.Option>
                                                        })}
                                                    </Select> : ''}

                                                {this.props.pacingGuideBaseProps.futurePacingGuide ?
                                                    <div className='w-100 mt-2'>
                                                        <RadioGroup size='large' onChange={(e) => {
                                                            if (this.props.pacingGuideBaseProps &&
                                                                this.props.pacingGuideBaseProps.onSetPacingGuideMode) {
                                                                this.props.pacingGuideBaseProps.onSetPacingGuideMode(e.target.value)
                                                            }
                                                        }}
                                                            value={this.props.pacingGuideBaseProps.pacingGuideMode} className='mt-1'>
                                                            <Radio.Button value={'current'} className='font-16 w-100'>Current Pacing Guide</Radio.Button>
                                                            <Radio.Button value={'future'} className='font-16 w-100'>Next Year's Standards</Radio.Button>
                                                        </RadioGroup>

                                                        {this.props.pacingGuideBaseProps.pacingGuideMode === 'future' ?
                                                            <a href={`https://ed.sc.gov/instruction/standards/mathematics/instructional-resources/content-shifts-potential-gaps-for-math-standards-implementation/#${encodeURIComponent(this.props.pacingGuideBaseProps.standardName)}`}
                                                                target='_blank' rel='noopener noreferrer'
                                                                className='ant-btn ant-btn-primary ant-btn-lg w-100 mb-2 mt-3'>
                                                                View Content Shifts
                                                                <Icon type='arrow-right' className='ml-1' />
                                                            </a> : ''}
                                                    </div>
                                                    : ''}
                                            </div>
                                            <Menu
                                                className='mb-3 pb-3 border-bottom-lighter'
                                                onSelect={(e) => {
                                                    const element = document.getElementById('Quarter-' + e.key)
                                                    if (element) {
                                                        const offset = 100;
                                                        const elementPosition = element.getBoundingClientRect().top + window.scrollY - offset;
                                                        window.scrollTo({
                                                            top: elementPosition,
                                                        })
                                                    }
                                                }}
                                                defaultSelectedKeys={['1']}
                                            >
                                                <Menu.Item key="1">
                                                    <div className='font-16'>Quarter 1</div>
                                                </Menu.Item>
                                                <Menu.Item key="2">
                                                    <div className='font-16'>Quarter 2</div>
                                                </Menu.Item>
                                                <Menu.Item key="3">
                                                    <div className='font-16'>Quarter 3</div>
                                                </Menu.Item>
                                                <Menu.Item key="4">
                                                    <div className='font-16'>Quarter 4</div>
                                                </Menu.Item>
                                            </Menu>
                                            <div className='mb-1 ml-2 mt-1'>
                                                <Checkbox checked={this.state.notScheduledFilter} size='large'
                                                    onChange={this.onNotScheduledFilterChange}>
                                                    <span className='pl-1 font-16 va-minus-2 font-black'>Filter not scheduled</span>
                                                </Checkbox>
                                            </div>
                                            <div className='pl-2 pr-2 mt-1 mb-1'>
                                                <Input
                                                    placeholder="Filter by standard"
                                                    allowClear
                                                    size='large'
                                                    onChange={(e) => {
                                                        this.setState({
                                                            searchFilter: e.target.value
                                                        })
                                                    }}
                                                />
                                            </div>
                                            {this.state.notScheduledFilter &&
                                                this.props.pacingGuideBaseProps &&
                                                this.props.pacingGuideBaseProps.standards &&
                                                this.props.pacingGuideBaseProps.scheduledStandardsDict &&
                                                this.props.pacingGuideBaseProps.standards.length === Object.keys(this.props.pacingGuideBaseProps.scheduledStandardsDict).length ?
                                                <div className='p-2 font-20 text-success'>
                                                    All standards are scheduled!
                                                </div>
                                                : ''}
                                            {this.props.pacingGuideBaseProps.pacingGuide &&
                                                this.props.pacingGuideBaseProps.pacingGuide.weeks.hasOwnProperty(0) ?
                                                // weeks[0] is all standard for the side menu, weeks is a dictionary
                                                <Droppable key={'side-menu-item-' + 0} droppableId={`${0}`}>
                                                    {(provided, snapshot) => (
                                                        <div
                                                            key={'droppable-div-' + 0}
                                                            ref={provided.innerRef}
                                                            style={this.getListStyleSider(snapshot.isDraggingOver)}
                                                            {...provided.droppableProps}
                                                        >
                                                            {this.props.pacingGuideBaseProps.pacingGuide.weeks[0].map((item, index) => {
                                                                if (this.props.pacingGuideBaseProps &&
                                                                    this.props.pacingGuideBaseProps.scheduledStandardsDict &&
                                                                    this.props.pacingGuideBaseProps.scheduledStandardsDict.hasOwnProperty(item.standard) &&
                                                                    this.state.notScheduledFilter) {
                                                                    return false
                                                                }
                                                                if (this.state.searchFilter !== '' && !item.standard.toLowerCase().includes(this.state.searchFilter.toLowerCase())) {
                                                                    return false
                                                                }
                                                                return <Draggable
                                                                    key={0 + '-' + index}
                                                                    draggableId={0 + '-' + index}
                                                                    index={index}
                                                                >
                                                                    {(provided, snapshot) => (
                                                                        <div
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                            style={this.getItemStyleSider(
                                                                                snapshot.isDragging,
                                                                                provided.draggableProps.style
                                                                            )}
                                                                        >
                                                                            <PacingGuideDraggableStandard
                                                                                item={item}
                                                                                scheduledNum={this.props.pacingGuideBaseProps.scheduledStandardsDict &&
                                                                                    this.props.pacingGuideBaseProps.scheduledStandardsDict.hasOwnProperty(item.standard) ?
                                                                                    this.props.pacingGuideBaseProps.scheduledStandardsDict[item.standard] : 0}
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </Draggable>
                                                            })}
                                                            {provided.placeholder}
                                                        </div>
                                                    )}
                                                </Droppable> : ''}
                                        </Sider>
                                        <Layout className="content">
                                            <Content
                                                style={{
                                                    marginLeft: 270, marginTop: "98px", overflowX: 'initial'
                                                }}
                                                className='p-2 pl-4 pr-4'
                                            >
                                                <div>
                                                    {this.displayPacingGuideBeingCreatedNotificationIfNeeded()}
                                                    <div className='flex w-100 mb-2'>
                                                        {this.props.pacingGuideBaseProps &&
                                                            this.props.pacingGuideBaseProps.pacingGuidePDFURL !== '' ?
                                                            <div className='ml-auto'>
                                                                <a
                                                                    href={this.props.pacingGuideBaseProps.pacingGuidePDFURL}
                                                                    target='_blank'
                                                                    rel='noopener noreferrer'
                                                                    className={'ant-btn ant-btn-primary lg-btn inline-flex'}
                                                                >
                                                                    <Icon type='file-pdf' className='font-20 mr-1' />
                                                                    {this.props.pacingGuideBaseProps &&
                                                                        this.props.pacingGuideBaseProps.pacingGuidePDFURLLoading ?
                                                                        <div style={{ width: '216px' }}>
                                                                            <Spin className='fff-spin' />
                                                                        </div> :
                                                                        <div>{'View Pacing Guide PDF'}</div>}
                                                                </a>
                                                            </div> : ''}
                                                    </div>
                                                    <div className='br-20 overflow-hidden' id={'Quarter-' + 1}>
                                                        <div className='br-4 relative pl-3 pr-3 pt-3 relative'
                                                            style={{ height: '280px', }}>

                                                            <div className="absolute-tl w-100 h-100" style={{ backgroundColor: '#233142' }}>
                                                            </div>
                                                            <div className="absolute-tl w-100 h-100"
                                                            >
                                                            </div>
                                                            <div className='absolute-tl w-100 h-100'>
                                                                <div className='h-100'>
                                                                    <div className='flex flex-v-center font-40 font-fff h-100 p-4'>
                                                                        <div>
                                                                            <div className='font-40 font-bold font-fff'>Standards First Pacing Guide</div>
                                                                            <div className='font-24 font-fff font-bold'>{standardDisplayName ? standardDisplayName : ''}</div>
                                                                            <div>
                                                                                <div className='font-24 p-2 pt-1 pb-1 br-4 mt-1 inline-block'
                                                                                    style={{ background: '#3fa672' }}
                                                                                ><span>{'Quarter 1'}</span></div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {this.props.pacingGuideBaseProps.weekNums &&
                                                        this.props.pacingGuideBaseProps.weekNums.map((weekNumber, ind) => {
                                                            if (ind === 0) {
                                                                return false
                                                            }
                                                            if (!this.props.pacingGuideBaseProps.pacingGuide.weeks.hasOwnProperty(weekNumber)) {
                                                                return false
                                                            }
                                                            let week = null
                                                            if (this.props.pacingGuideBaseProps.pacingGuide) {
                                                                if (!this.props.pacingGuideBaseProps.pacingGuide.weeks.hasOwnProperty(weekNumber)) {
                                                                    return false
                                                                }
                                                                week = this.props.pacingGuideBaseProps.pacingGuide.weeks[weekNumber]
                                                            }
                                                            if (this.props.pacingGuideBaseProps.pacingGuideMode === 'future' &&
                                                                this.props.pacingGuideBaseProps.futurePacingGuide) {
                                                                if (!this.props.pacingGuideBaseProps.futurePacingGuide.weeks.hasOwnProperty(weekNumber)) {
                                                                    return false
                                                                }
                                                                week = this.props.pacingGuideBaseProps.futurePacingGuide.weeks[weekNumber]
                                                            }
                                                            if (!week) {
                                                                return false
                                                            }

                                                            // every 9 weeks
                                                            let quarter = -1
                                                            if (ind === 1) {
                                                                quarter = 1
                                                            } else if (ind === 10) {
                                                                quarter = 2
                                                            } else if (ind === 19) {
                                                                quarter = 3
                                                            } else if (ind === 28) {
                                                                quarter = 4
                                                            }
                                                            let weekNumForQuarter = ind % 9
                                                            if (weekNumForQuarter === 0) {
                                                                weekNumForQuarter = 9
                                                            }

                                                            let backgroundColor = '#faa916'
                                                            if (quarter === 3) {
                                                                backgroundColor = '#722ed1'
                                                            } else if (quarter === 4) {
                                                                backgroundColor = '#1098f7'
                                                            }

                                                            return <div key={'weekNum-' + ind}>
                                                                {quarter !== -1 && quarter !== 1 ?
                                                                    <div className='br-20 overflow-hidden' id={'Quarter-' + quarter}>
                                                                        <div className='br-4 relative pl-3 pr-3 pt-3 relative'
                                                                            style={{ height: '280px', }}>

                                                                            <div className="absolute-tl w-100 h-100" style={{ backgroundColor: '#233142' }}>
                                                                            </div>
                                                                            <div className="absolute-tl w-100 h-100"
                                                                            >
                                                                            </div>
                                                                            <div className='absolute-tl w-100 h-100'>
                                                                                <div className='h-100'>
                                                                                    <div className='flex flex-v-center font-40 font-fff h-100 p-4'>
                                                                                        <div>
                                                                                            <div className='font-40 font-bold font-fff'>Standards First Pacing Guide</div>
                                                                                            <div className='font-24 font-fff font-bold'>{standardDisplayName ? standardDisplayName : ''}</div>
                                                                                            <div>
                                                                                                <div className='font-24 p-2 pt-1 pb-1 br-4 mt-1 inline-block'
                                                                                                    style={{ background: backgroundColor }}
                                                                                                ><span>{'Quarter ' + quarter}</span></div>
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div> : ''}
                                                                <div className='flex flex-v-center mt-4 pt-3 mb-05'>
                                                                    <div className='font-20 font-bold text-muted mr-3'>{'Week ' + weekNumForQuarter}</div>
                                                                    <Button type='default' size='large'
                                                                        onClick={(e) => {
                                                                            let expandedWeeks = this.state.expandedWeeks
                                                                            let expandedItems = this.state.expandedItems
                                                                            const weekBase = weekNumber + '-'
                                                                            if (expandedWeeks.hasOwnProperty(weekNumber)) {
                                                                                delete expandedWeeks[weekNumber]
                                                                                Object.keys(expandedItems).forEach((key) => {
                                                                                    if (key.startsWith(weekBase)) {
                                                                                        delete expandedItems[key]
                                                                                    }
                                                                                })
                                                                            } else {
                                                                                expandedWeeks[weekNumber] = true
                                                                                for (let i = 0; i < week.length; i++) {
                                                                                    let key = weekBase + i
                                                                                    expandedItems[key] = true
                                                                                }
                                                                            }

                                                                            this.setState({
                                                                                expandedWeeks: expandedWeeks,
                                                                                expandedItems: expandedItems
                                                                            })
                                                                        }}>
                                                                        {this.state.expandedWeeks.hasOwnProperty(weekNumber) ?
                                                                            <div className='flex flex-v-center font-16'>
                                                                                <Icon type='minus' className='font-20 mr-1' />
                                                                                <div>Hide all descriptions</div>
                                                                            </div>
                                                                            :
                                                                            <div className='flex flex-v-center font-16'>
                                                                                <Icon type='plus' className='font-20 mr-1' />
                                                                                <div>Show all descriptions</div>
                                                                            </div>
                                                                        }
                                                                    </Button>
                                                                </div>
                                                                <Droppable key={ind} droppableId={`${ind}`} direction="horizontal">
                                                                    {(provided, snapshot) => (
                                                                        <div
                                                                            key={'droppable-div-' + ind}
                                                                            ref={provided.innerRef}
                                                                            style={this.getListStyle(snapshot.isDraggingOver, week.length)}
                                                                            {...provided.droppableProps}
                                                                        >
                                                                            {week.map((item, index) => {
                                                                                return <Draggable
                                                                                    key={ind + '-' + index}
                                                                                    draggableId={ind + '-' + index}
                                                                                    index={index}
                                                                                >
                                                                                    {(provided, snapshot) => (
                                                                                        <div
                                                                                            ref={provided.innerRef}
                                                                                            {...provided.draggableProps}
                                                                                            {...provided.dragHandleProps}
                                                                                            style={this.getItemStyle(
                                                                                                snapshot.isDragging,
                                                                                                provided.draggableProps.style,
                                                                                                this.props.pacingGuideBaseProps.pacingGuideMode
                                                                                            )}
                                                                                        >
                                                                                            <PacingGuideDraggableStandard item={item} displayTitle={this.state.expandedItems.hasOwnProperty(weekNumber + '-' + index)}
                                                                                            />
                                                                                            <Button type='primary' size='large' className='transparent-btn text-cyan font-16'
                                                                                                onClick={(e) => {
                                                                                                    let key = weekNumber + '-' + index
                                                                                                    let expandedItems = this.state.expandedItems
                                                                                                    if (expandedItems.hasOwnProperty(key)) {
                                                                                                        delete expandedItems[key]
                                                                                                    }
                                                                                                    else {
                                                                                                        expandedItems[key] = true
                                                                                                    }
                                                                                                    this.setState({
                                                                                                        expandedItems: expandedItems
                                                                                                    })
                                                                                                }}>
                                                                                                {this.state.expandedItems.hasOwnProperty(weekNumber + '-' + index) ?
                                                                                                    <div className='flex flex-v-center font-14'>
                                                                                                        <Icon type='minus' className='font-16 mr-1' />
                                                                                                        <div>Hide description</div>
                                                                                                    </div>
                                                                                                    :
                                                                                                    <div className='flex flex-v-center font-14'>
                                                                                                        <Icon type='plus' className='font-16 mr-1' />
                                                                                                        <div>Show description</div>
                                                                                                    </div>
                                                                                                }
                                                                                            </Button>
                                                                                            {!(!isCurrentYear ||
                                                                                                (this.props.pacingGuideBaseProps.pacingGuideMode === 'future' &&
                                                                                                    this.props.pacingGuideBaseProps.futurePacingGuide)) ?
                                                                                                <Link to={this.props.teacherBaseProps &&
                                                                                                    this.props.teacherBaseProps.teacher ?
                                                                                                    '/teacher/pacing-guide/lesson-plan/' + this.props.teacherBaseProps.teacher.id + '?standardName=' + item.standardName + '&standard=' + item.standard + '&grade=' + item.grade + '&category=' + item.standardCategory
                                                                                                    :
                                                                                                    this.props.teacherBaseProps &&
                                                                                                        this.props.teacherBaseProps.admin ?
                                                                                                        '/admin/pacing-guide/lesson-plan/' + this.props.teacherBaseProps.admin.id + '?standardName=' + item.standardName + '&standard=' + item.standard + '&grade=' + item.grade + '&category=' + item.standardCategory
                                                                                                        :
                                                                                                        this.props.teacherBaseProps &&
                                                                                                            this.props.teacherBaseProps.instructionalFacilitator ?
                                                                                                            '/instructional-facilitator/pacing-guide/lesson-plan/' + this.props.teacherBaseProps.instructionalFacilitator.id + '?standardName=' + item.standardName + '&standard=' + item.standard + '&grade=' + item.grade + '&category=' + item.standardCategory
                                                                                                            :
                                                                                                            this.props.teacherBaseProps &&
                                                                                                                this.props.teacherBaseProps.schoolAdmin ?
                                                                                                                '/school-admin/pacing-guide/lesson-plan/' + this.props.teacherBaseProps.schoolAdmin.id + '?standardName=' + item.standardName + '&standard=' + item.standard + '&grade=' + item.grade + '&category=' + item.standardCategory
                                                                                                                :
                                                                                                                ''}
                                                                                                    size='large'
                                                                                                    style={{ position: 'absolute', bottom: '16px', left: '16px', width: 'calc(100% - 32px)' }}
                                                                                                    className='ant-btn ant-btn-lg ant-btn-default mt-2 flex flex-v-center flex-h-center font-16'>
                                                                                                    <Icon type='solution' className='mr-05 font-20' />
                                                                                                    <span>View Lesson</span>
                                                                                                </Link>
                                                                                                : ''}
                                                                                        </div>
                                                                                    )}
                                                                                </Draggable>
                                                                            })}
                                                                            {provided.placeholder}
                                                                        </div>
                                                                    )}
                                                                </Droppable>

                                                                <div className='mb-2'>
                                                                    {this.props.pacingGuideBaseProps.hasOwnProperty('quizzesForEndWeek') &&
                                                                        this.props.pacingGuideBaseProps.quizzesForEndWeek &&
                                                                        this.props.pacingGuideBaseProps.quizzesForEndWeek.hasOwnProperty(weekNumber) &&
                                                                        this.props.pacingGuideBaseProps.quizzesForEndWeek[weekNumber] &&
                                                                        this.props.pacingGuideBaseProps.quizzesForEndWeek[weekNumber].map((quiz, index) => {
                                                                            // Always disableQuiz because the pacing guide is unlocked
                                                                            return <div className='mb-1 mr-1'>
                                                                                <PacingGuideAssessmentModalForm
                                                                                    assessment={quiz}
                                                                                    teacherBaseProps={this.props.teacherBaseProps}
                                                                                    buttonText={quiz.hasOwnProperty('assessmentTitle') ? quiz.assessmentTitle : 'Quiz'}
                                                                                    disableQuiz={true}
                                                                                />
                                                                            </div>
                                                                        })}
                                                                </div>
                                                            </div>
                                                        })}
                                                </div>
                                            </Content>
                                        </Layout>
                                    </Layout> : ''}
                            </DragDropContext>}
                    </div>
                    : ''}
            </div>
        )
    }
}

export default PacingGuideSC