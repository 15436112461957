export const SecondGradeAssessment = `<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>2nd Grade ELA Reading Assessment</title>
    <style>
        body {
            font-family: 'Comic Sans MS', cursive, sans-serif;
            /* Keeping the requested font */
            background-color: #f5f5f5;
            margin: 0;
            padding: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .container {
            max-width: 850px;
            width: 100%;
            background-color: white;
            padding: 25px;
            border-radius: 15px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        }

        h1 {
            color: #233142;
            text-align: center;
            margin-bottom: 5px;
        }

        h2 {
            color: #233142;
            border-bottom: 1px solid #233142;
            padding-bottom: 5px;
            margin-top: 25px;
        }

        .subtitle {
            color: #666;
            text-align: center;
            font-style: italic;
            margin-bottom: 20px;
        }

        .exam-section {
            margin-bottom: 30px;
            padding: 15px;
            border: 1px solid #e0e0e0;
            border-radius: 8px;
            background-color: #fff8dc;
            /* Keeping section background */
        }

        .question-block {
            /* Renamed from .question for clarity */
            margin-bottom: 20px;
            padding-left: 5px;
        }

        .question-text {
            /* Added class for specific styling */
            font-weight: bold;
            margin-bottom: 10px;
        }

        .options-group {
            margin-left: 20px;
            /* Indent options slightly */
        }

        .option-item {
            display: flex;
            align-items: center;
            margin-bottom: 8px;
        }

        .option-label {
            margin-left: 10px;
            flex: 1;
        }

        input[type="radio"] {
            width: 18px;
            /* Consistent size */
            height: 18px;
            margin-top: 1px;
            /* Align better with text */
            flex-shrink: 0;
            /* Prevent radio button from shrinking */
        }

        label {
            /* General label styling */
            display: inline;
            /* Allow label text to wrap naturally */
            font-weight: normal;
            /* Options are not bold */
            color: #333;
        }

        .section-title {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .section-letter {
            font-weight: normal;
            color: #666;
            font-size: 0.9em;
            font-style: italic;
        }

        .instructions {
            background-color: #d9eeff;
            /* Keeping instruction box style */
            padding: 15px;
            border-radius: 8px;
            margin-bottom: 20px;
        }

        .resource {
            background-color: #e8f7ee;
            /* Keeping resource box style */
            padding: 15px;
            border-radius: 8px;
            margin-bottom: 20px;
            border: 1px dashed #3fa672;
        }

        @media print {
            body {
                background-color: white;
                padding: 0;
                margin: 0;
            }

            .container {
                box-shadow: none;
                max-width: 100%;
            }

            .exam-section,
            .question-block {
                break-inside: avoid;
                /* Try to keep sections and questions together */
            }

            @page {
                margin: 1cm;
            }
        }
    </style>
</head>

<body>
    <div class="container">
        <form id="assessment-form">
            <h1>2nd Grade ELA Reading Check</h1>
            <div class="subtitle">Reading Passage: A New Friend at the Park</div>

            <div class="resource">
                <h3>Reading Passage:</h3>
                <p><strong>A New Friend at the Park</strong></p>
                <p>Emma went to the park on a sunny day. She saw a small, furry puppy under a tall tree. The puppy looked lost and scared. Emma softly called, and the puppy walked slowly toward her. She gently picked him up and saw a tag with his name, Toby. Emma smiled when Toby wagged his tail, knowing she had made a new friend.</p>
            </div>

            <!-- Section 1 -->
            <div class="exam-section">
                <div class="section-title">
                    <h2>Section 1: Questions about the Story</h2>
                    <span class="section-letter">(RL.2.1, RL.2.7)</span>
                </div>

                <div class="question-block">
                    <p class="question-text">1. Where did Emma first see the puppy?</p>
                    <div class="options-group">
                        <div class="option-item">
                            <input type="radio" id="s1q1_optA" name="s1q1" value="A">
                            <label for="s1q1_optA" class="option-label">A) Near the swings</label>
                        </div>
                        <div class="option-item">
                            <input type="radio" id="s1q1_optB" name="s1q1" value="B">
                            <label for="s1q1_optB" class="option-label">B) Under a tall tree</label>
                        </div>
                        <div class="option-item">
                            <input type="radio" id="s1q1_optC" name="s1q1" value="C">
                            <label for="s1q1_optC" class="option-label">C) By the water fountain</label>
                        </div>
                        <div class="option-item">
                            <input type="radio" id="s1q1_optD" name="s1q1" value="D">
                            <label for="s1q1_optD" class="option-label">D) On the playground slide</label>
                        </div>
                    </div>
                </div>

                <div class="question-block">
                    <p class="question-text">2. Why did Emma smile at the end of the story?</p>
                    <div class="options-group">
                        <div class="option-item">
                            <input type="radio" id="s1q2_optA" name="s1q2" value="A">
                            <label for="s1q2_optA" class="option-label">A) She saw her brother.</label>
                        </div>
                        <div class="option-item">
                            <input type="radio" id="s1q2_optB" name="s1q2" value="B">
                            <label for="s1q2_optB" class="option-label">B) The sun was shining.</label>
                        </div>
                        <div class="option-item">
                            <input type="radio" id="s1q2_optC" name="s1q2" value="C">
                            <label for="s1q2_optC" class="option-label">C) She made a new friend.</label>
                        </div>
                        <div class="option-item">
                            <input type="radio" id="s1q2_optD" name="s1q2" value="D">
                            <label for="s1q2_optD" class="option-label">D) She found her ball.</label>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Section 2 -->
            <div class="exam-section">
                <div class="section-title">
                    <h2>Section 2: Finding Word Meanings</h2>
                    <span class="section-letter">(RL.2.4)</span>
                </div>

                <div class="question-block">
                    <p class="question-text">3. Read this sentence from the story:<br>"The puppy looked lost and scared."<br>What does the word "scared" mean?</p>
                    <div class="options-group">
                        <div class="option-item">
                            <input type="radio" id="s2q1_optA" name="s2q1" value="A">
                            <label for="s2q1_optA" class="option-label">A) Happy</label>
                        </div>
                        <div class="option-item">
                            <input type="radio" id="s2q1_optB" name="s2q1" value="B">
                            <label for="s2q1_optB" class="option-label">B) Hungry</label>
                        </div>
                        <div class="option-item">
                            <input type="radio" id="s2q1_optC" name="s2q1" value="C">
                            <label for="s2q1_optC" class="option-label">C) Afraid</label>
                        </div>
                        <div class="option-item">
                            <input type="radio" id="s2q1_optD" name="s2q1" value="D">
                            <label for="s2q1_optD" class="option-label">D) Sleepy</label>
                        </div>
                    </div>
                </div>

                <div class="question-block">
                    <p class="question-text">4. What does the word "gently" mean in the story?</p>
                    <div class="options-group">
                        <div class="option-item">
                            <input type="radio" id="s2q2_optA" name="s2q2" value="A">
                            <label for="s2q2_optA" class="option-label">A) Loudly</label>
                        </div>
                        <div class="option-item">
                            <input type="radio" id="s2q2_optB" name="s2q2" value="B">
                            <label for="s2q2_optB" class="option-label">B) Quickly</label>
                        </div>
                        <div class="option-item">
                            <input type="radio" id="s2q2_optC" name="s2q2" value="C">
                            <label for="s2q2_optC" class="option-label">C) Softly</label>
                        </div>
                        <div class="option-item">
                            <input type="radio" id="s2q2_optD" name="s2q2" value="D">
                            <label for="s2q2_optD" class="option-label">D) Happily</label>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Section 3 -->
            <div class="exam-section">
                <div class="section-title">
                    <h2>Section 3: Word and Syllable Skills</h2>
                    <span class="section-letter">(RF.2.3)</span>
                </div>

                <div class="question-block">
                    <p class="question-text">5. Which word from the story has two syllables?</p>
                    <div class="options-group">
                        <div class="option-item">
                            <input type="radio" id="s3q1_optA" name="s3q1" value="A">
                            <label for="s3q1_optA" class="option-label">A) day</label>
                        </div>
                        <div class="option-item">
                            <input type="radio" id="s3q1_optB" name="s3q1" value="B">
                            <label for="s3q1_optB" class="option-label">B) puppy</label>
                        </div>
                        <div class="option-item">
                            <input type="radio" id="s3q1_optC" name="s3q1" value="C">
                            <label for="s3q1_optC" class="option-label">C) small</label>
                        </div>
                        <div class="option-item">
                            <input type="radio" id="s3q1_optD" name="s3q1" value="D">
                            <label for="s3q1_optD" class="option-label">D) tree</label>
                        </div>
                    </div>
                </div>

                <div class="question-block">
                    <p class="question-text">6. Look at the word "wagged." What vowel sound do you hear in the word "wagged"?</p>
                    <div class="options-group">
                        <div class="option-item">
                            <input type="radio" id="s3q2_optA" name="s3q2" value="A">
                            <label for="s3q2_optA" class="option-label">A) o, like in home</label>
                        </div>
                        <div class="option-item">
                            <input type="radio" id="s3q2_optB" name="s3q2" value="B">
                            <label for="s3q2_optB" class="option-label">B) e, like in feet</label>
                        </div>
                        <div class="option-item">
                            <input type="radio" id="s3q2_optC" name="s3q2" value="C">
                            <label for="s3q2_optC" class="option-label">C) a, like in cat</label>
                        </div>
                        <div class="option-item">
                            <input type="radio" id="s3q2_optD" name="s3q2" value="D">
                            <label for="s3q2_optD" class="option-label">D) i, like in kite</label>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</body>

</html>`;