import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../styles/GlobalStyle.css'
import { firebase, db } from '../firebase/Firebase'
import { flattenDoc, linkAfterLogin } from '.././Util'
import ColType from '.././Types'
//import { Helmet } from "react-helmet"
import { Icon, Form, Input, Button, message } from 'antd'
const FormItem = Form.Item

const errorMessage = (description) => {
    // stays permanently, I'm destroying when form is submitted.
    message.error(description, 0)
}

class SignInLegacy extends Component {
    state = {
        user: {},
        admin: {},
        signingIn: false,
        hideEmail: false,
    }

    componentDidMount() {
        document.title = "Sign in - Dot It"

        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                // User is signed in.
                console.log("signed in user", user)
                this.setState({
                    user: user,
                }, () => {
                    db.collection(ColType.users)
                        .doc(user.uid)
                        .get()
                        .then((doc) => {
                            var userInfo = flattenDoc(doc)
                            console.log("userinfo", userInfo)
                            if (userInfo.accessLevel === 'superAdmin') {
                                window.location.href = '/super-admin/add-initial-admin'
                                return
                            }
                            // sandbox users don't have a completedSetup flag.
                            // only non-sandbox users do.
                            else if (!userInfo.hasOwnProperty("isSandbox") &&
                                userInfo.hasOwnProperty("completedSetup") &&
                                !userInfo.completedSetup) {
                                /*this.props.history.push(
                                    {
                                        pathname: '/setup-account'
                                    }
                                )*/
                            }
                            else {
                                db.collection(userInfo.accessLevel)
                                    .doc(userInfo.refId)
                                    .get()
                                    .then((doc) => {
                                        var userJobInfo = flattenDoc(doc)
                                        console.log(userInfo, userJobInfo)
                                        linkAfterLogin(userInfo, userJobInfo)
                                    })
                            }
                        })
                })
            } else {
                console.log("signed out user", user)
                // User is signed out.
                this.setState({
                    user: user,
                })
            }
        })
    }

    // adding directly here, in the future it will send an email.
    handleSubmit = (e) => {
        e.preventDefault()
        this.props.form.validateFields((err, values) => {
            message.destroy() // destroy previous error messages on form submit.
            if (!err) {
                //console.log('Received values of form: ', values)

                this.setState({
                    signingIn: true,
                }, () => {
                    firebase.auth().signInWithEmailAndPassword(values.email, values.password)
                        .then(() => {
                            console.log("User was signed in successfully")
                        })
                        .catch((error) => {
                            // Handle Errors here.
                            var errorCode = error.code
                            var errorMessageString = error.message
                            if (errorMessageString === 'There ' +
                                'is no user record corresponding to this identifier. ' +
                                'The user may have been deleted.') {
                                errorMessageString = 'This account does not exist.'
                            }
                            else if (errorMessageString === 'The password is invalid ' +
                                'or the user does not have a password.') {
                                errorMessageString = 'Email address or password is incorrect.'
                            }
                            errorMessage(errorMessageString)
                            console.log("Could not sign in user", errorCode, errorMessageString)
                            // only need to set this to false on error, 
                            // make the button un-disabled again for another sign in.
                            this.setState({
                                signingIn: false,
                            })
                        })
                })
            }
        })
    }

    render() {
        const { getFieldDecorator } = this.props.form
        const formItemLayout = {
            labelCol: {
                xs: { span: 25 },
                sm: { span: 0 },
            },
            wrapperCol: {
                xs: { span: 25 },
                sm: { span: 24 },
            },
        }

        return (
            <div className="pt-4 pb-70 relative" style={{ minHeight: '100%' }}>
                <div className='absolute-tl w-100 h-100'>
                </div>

                <div className='relative'>
                    <div className="text-center">
                        <a href="https://dotit.app/">
                            <img src='/logo-icon.png' alt='logo' height='120' />
                        </a>
                    </div>
                    <div className="p-4 w-500 m-lr-auto br-20 ant-shadow background-fff">
                        <div className="block w-100 m-lr-auto mb-1 text-center">
                            <img src="/dot-it-sign-in.png" alt="sign-in" height="300" />
                        </div>
                        <div className="mb-3 text-center pt-3 font-30 font-bold">Sign in to Dot It</div>
                        <Form onSubmit={this.handleSubmit} className="login-form">
                            <FormItem {...formItemLayout} label="Email address">
                                {getFieldDecorator('email', {
                                    rules: [{ required: true, message: 'Please input your email address.' }],
                                })(
                                    <Input
                                        size={"large"}
                                        type={this.state.hideEmail ? 'password' : null}
                                        placeholder="Email Address..." />
                                )}
                            </FormItem>
                            <FormItem {...formItemLayout} label="Password">
                                {getFieldDecorator('password', {
                                    rules: [{ required: true, message: 'Please input a password.' }],
                                })(
                                    <Input
                                        size={"large"}
                                        type="password"
                                        placeholder="Password..." />
                                )}
                            </FormItem>
                            <FormItem className="mb-0">
                                <Button
                                    disabled={this.state.signingIn}
                                    size={"large"}
                                    type="primary"
                                    htmlType="submit"
                                    className="full-width-btn mt-05">
                                    {!this.state.signingIn ?
                                        <span className="font-bold">Sign in</span> :
                                        <span className="font-bold">
                                            <Icon type="loading" className="mr-8" />Signing in...</span>
                                    }
                                </Button>
                            </FormItem>
                        </Form>
                    </div>
                    <div className="text-center mt-2">
                        <Link to="/forgot-password"
                            className="text-primary mt-0 mb-3 font-16 font-bold">Forgot password?</Link>
                    </div>
                    {/*<div className="text-center mt-3 font-18 font-bold">
            <Link to="/free-trial" className="text-cyan">
              Don't have an account? Create one here.
            </Link>
        </div>*/}
                </div>
            </div>

        )
    }
}

export default Form.create()(SignInLegacy)