import React, { Component } from 'react'
import '../../styles/GlobalStyle.css'
import { db, firebase } from '../../firebase/Firebase'
import ColType from '../.././Types'
//import { getIDFromURL, flattenDoc, getInitials } from '../.././Util'
import { Input, Button, Form, Icon, Modal, Select, message } from 'antd'
import PersonAvatar from '../../customcomponents/PersonAvatar'
const FormItem = Form.Item
const Option = Select.Option

const successMessage = (description) => {
    message.success(description)
}

const errorMessage = (description) => {
    message.error(description)
}


class AddGroupForm extends Component {
    constructor(props) {
        super(props)
        this.selectRef = React.createRef()
        this.state = {
            addGroupModalVisible: false,
            addingGroup: false,
            refAdded: false,
        }
    }

    componentWillUnmount() {
        if (this.selectRef &&
            this.selectRef.hasOwnProperty('rcSelect') &&
            this.selectRef.rcSelect &&
            this.selectRef.rcSelect.hasOwnProperty('inputRef') &&
            this.selectRef.rcSelect.inputRef) {
            this.selectRef.rcSelect.inputRef.removeEventListener("keydown", this.inputRefPreventKeydown)
        }
    }

    inputRefPreventKeydown = (e) => {
        e.preventDefault()
        e.stopPropagation()
    }

    onGroupModalCancel = () => {
        this.setState({
            addGroupModalVisible: false,
        })
    }

    handleSubmit = (e) => {
        e.preventDefault()
        this.props.form.validateFields((err, values) => {
            if (!err) {
                if (!this.props.teacher) {
                    return
                }

                this.setState({
                    addingGroup: true,
                })

                // make a copy, if you modify values.viewerIds
                // it shows up in the form as the form shares
                // the values.viewerIds list
                let viewerIdsCopy = []
                values.viewerIds.map((viewerId) => {
                    return viewerIdsCopy.push(viewerId)
                })
                viewerIdsCopy.push(this.props.teacher.id)

                db.collection(ColType.chatGroup)
                    .add({
                        groupName: values.groupName,
                        viewerIds: viewerIdsCopy,
                        groupCreatedById: this.props.teacher.id,
                        // don't actually delete in case a group needs
                        // to be recovered later
                        isDeleted: false,
                        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
                    })
                    .then(() => {
                        this.setState({
                            addingGroup: false,
                        })
                        successMessage("Group created successfully.")
                        this.setState({
                            addGroupModalVisible: false,
                        }, () => {
                            // clear form
                            this.props.form.resetFields()
                        })
                    })
                    .catch((e) => {
                        console.log(e)
                        this.setState({
                            addingGroup: false,
                        })
                        errorMessage("Something went wrong. Try again or email support@dotit.app")
                        this.onGroupModalCancel()
                    })
            }
        })
    }

    render() {
        const { getFieldDecorator } = this.props.form
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        }

        return (
            <div>
                <Button size="large" onClick={(e) => {
                    this.setState({
                        addGroupModalVisible: true,
                    })
                }}>
                    <Icon type="plus" />
                    <span>
                        Add team
                    </span>
                </Button>
                <Modal
                    title="Add team"
                    visible={this.state.addGroupModalVisible}
                    onCancel={this.onGroupModalCancel}
                    footer={null}
                >
                    <Form onSubmit={this.handleSubmit} className="block-label-form">
                        <FormItem {...formItemLayout} label="Team name">
                            {getFieldDecorator('groupName', {
                                rules: [{ required: true, message: 'Team name is required.' }],
                            })(
                                <Input placeholder="Team name..." size="large" />
                            )}
                        </FormItem>
                        <FormItem {...formItemLayout} label="Invite people to this team">
                            {getFieldDecorator('viewerIds', {
                                rules: [{ required: true, message: 'An invitation is required.' }],
                            })(
                                <Select size="large" mode="multiple" className="ant-select-vlg-options"
                                    ref={(el) => {
                                        this.selectRef = el
                                        if (this.selectRef &&
                                            this.selectRef.hasOwnProperty('rcSelect') &&
                                            this.selectRef.rcSelect &&
                                            this.selectRef.rcSelect.hasOwnProperty('inputRef') &&
                                            this.selectRef.rcSelect.inputRef) {
                                            // prevent user input in the select and hide the input cursor (this
                                            // is called once when the select is first rendered)
                                            this.selectRef.rcSelect.inputRef.classList.add("input-cursor-display-none")
                                            this.selectRef.rcSelect.inputRef.addEventListener("keydown", this.inputRefPreventKeydown)
                                        }
                                    }}>
                                    {Object.keys(this.props.schoolPersonnelDict).map((schoolId, idx) => {
                                        let schoolPersonnelDict = this.props.schoolPersonnelDict[schoolId]
                                        return <Select.OptGroup label={<div>{schoolPersonnelDict.school.schoolName}</div>} key={schoolId}>
                                            <Select.OptGroup label={<div>Teachers</div>} key={schoolId + '-teachers'}>
                                                {schoolPersonnelDict.teachers.map((teacher, idx) => {
                                                    // can't invite yourself (invited by default)
                                                    if (teacher.id === this.props.teacher.id) {
                                                        return false
                                                    }
                                                    return <Option key={teacher.id} value={teacher.id}>
                                                        <div className='flex flex-v-center w-100'>
                                                            <PersonAvatar
                                                                person={teacher}
                                                                size={'large'}
                                                                containerClassName="font-14 mr-1"
                                                                avatarClassName="font-14 font-bold mr-1"
                                                                personClassName="font-black font-14 ellipsis max-w-150"
                                                            />
                                                            <div className='ml-auto pr-1 font-14'>
                                                                {schoolPersonnelDict.school.schoolName}
                                                            </div>
                                                        </div>
                                                    </Option>
                                                })}
                                            </Select.OptGroup>
                                            <Select.OptGroup label={<div>Instructional and School Administrators</div>} key={schoolId + '-instructional-school-admins'}>
                                                {schoolPersonnelDict.instructionalFacilitators.concat(schoolPersonnelDict.schoolAdmins).map((teacher, idx) => {
                                                    // can't invite yourself (invited by default)
                                                    if (teacher.id === this.props.teacher.id) {
                                                        return false
                                                    }
                                                    return <Option key={teacher.id} value={teacher.id}>
                                                        <div className='flex flex-v-center w-100'>
                                                            <PersonAvatar
                                                                person={teacher}
                                                                size={'large'}
                                                                containerClassName="font-14"
                                                                avatarClassName="font-14 font-bold mr-1"
                                                                personClassName="font-black font-14 ellipsis max-w-150"
                                                            />
                                                            <div className='ml-auto pr-1 font-14'>
                                                                {schoolPersonnelDict.school.schoolName}
                                                            </div>
                                                        </div>
                                                    </Option>
                                                })}
                                            </Select.OptGroup>
                                        </Select.OptGroup>
                                    })}

                                </Select>
                            )}
                        </FormItem>
                        <FormItem className="mb-0">
                            <div className="flex">
                                <Button
                                    className="ant-btn ml-auto mr-1"
                                    onClick={this.onGroupModalCancel}
                                    size="large"
                                    disabled={this.state.addingGroup}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    htmlType="submit"
                                    className="ant-btn ant-btn-primary"
                                    size="large"
                                    disabled={this.state.addingGroup}
                                >
                                    {this.state.addingGroup ? "Adding team..." : "Add team"}
                                </Button>
                            </div>
                        </FormItem>
                    </Form>
                </Modal>
            </div>
        )
    }
}

export default Form.create()(AddGroupForm)