import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../../styles/GlobalStyle.css'
import CustomFooter from '../../login/CustomFooter'
//import SubjectHistogram from '../admin/SubjectHistogram'
//import SupportPieChartTiers from '../admin/SupportPieChartTiers'
//import VideoContainer from '../video/VideoContainer'
import PersonAvatar from '../../customcomponents/PersonAvatar'
//import Empty from '../../customcomponents/Empty'
import { getIDFromURL, getQueryStringParam } from '../.././Util'
import { Layout, Row, Col, Icon, Tabs } from 'antd'
import NoteDisplay from '../progressmonitoring/NotesDisplay'
import TeacherStudentUploadView from '../reports/TeacherStudentUploadView'
import SubjectNoteDisplay from '../progressmonitoring/SubjectNoteDisplay'
//import TransferGoals from './student/TransferGoals'
const { Content } = Layout
const TabPane = Tabs.TabPane

class TierOneHome extends Component {
    state = {
        teacherId: '',
        studentId: '',
    }

    componentWillReceiveProps(nextProps) {
        var teacherId = getIDFromURL(window.location)
        var studentId = getQueryStringParam('student')

        this.setState({
            teacherId: teacherId,
            studentId: studentId,
        })
    }

    // Do fetch here
    componentDidMount() {
        document.title = 'Plans - Dot It'

        var teacherId = getIDFromURL(window.location)
        var studentId = getQueryStringParam('student')

        this.setState({
            teacherId: teacherId,
            studentId: studentId,
        })
    }

    render() {
        return (
            <div>
                <Layout className="content layout-header-mt">
                    <Layout className="mt-0">
                        <Content className="mt-0">

                            <div className="sub-menu-width m-lr-auto mt-4">
                                {this.state.teacherId &&
                                    this.state.studentId &&
                                    this.props.teacherBaseProps.studentDict &&
                                    this.props.teacherBaseProps.studentDict.hasOwnProperty(this.state.studentId) ?
                                    <div>
                                        <Row gutter={50}>
                                            <Col xs={24} sm={24} md={18} lg={18} xl={18}>
                                                <div className="w-100 flex flex-v-center font-16 font-bold mb-2">
                                                    <Link to={'/teacher/tier-one/' + this.state.teacherId}>
                                                        {'Conference notes'}
                                                    </Link>
                                                    <Icon type="right" className="ml-1 mr-1 font-16" />
                                                    <span className="ellipsis" style={{ maxWidth: '350px' }}>
                                                        {this.props.teacherBaseProps.studentDict[this.state.studentId].firstName + " " + this.props.teacherBaseProps.studentDict[this.state.studentId].lastName}
                                                    </span>
                                                </div>

                                                <div className='br-20 ant-shadow no-border-bottom-radius overflow-hidden'>
                                                    <div className='sub-menu-width m-lr-auto br-4 relative pl-3 pr-3 pt-3 relative'
                                                        style={{ height: '252px', }}>

                                                        <div className="absolute-tl w-100 h-100 background-darker-blue">
                                                        </div>

                                                        <div className="absolute-tl w-100 h-100">
                                                        </div>

                                                        <div className="flex w-100 h-100 mt-4">
                                                            <Row className='font-fff'>
                                                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                                    <div className="w-100">
                                                                        <div className="flex flex-v-center">
                                                                            <div className="br-50 flex flex-h-center flex-v-center mr-4 font-30 font-bold" style={{ background: '#52c41a', minWidth: '140px', width: '140px', height: '140px' }}>
                                                                                1
                                                                            </div>

                                                                            <div>
                                                                                <div className='font-30 font-bold'>
                                                                                    {this.props.teacherBaseProps.studentDict[this.state.studentId].firstName + "'s conference notes"}
                                                                                </div>
                                                                                <div className="font-20">

                                                                                    {''}
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='background-fff ant-shadow br-20 no-border-top-radius p-4'>
                                                    <Tabs size="large" animated={false} className="mt-1">
                                                        <TabPane tab={"ELA Notes"} key={"ela-notes"}>
                                                            <SubjectNoteDisplay
                                                                teacherBaseProps={this.props.teacherBaseProps}
                                                                teacherId={this.state.teacherId}
                                                                student={this.props.teacherBaseProps.studentDict[this.state.studentId]}
                                                                studentId={this.state.studentId ? this.state.studentId : null}
                                                                IEPId={'tier1'}
                                                                subject={'ela'}
                                                                isAdmin={false}
                                                            />
                                                        </TabPane>
                                                        <TabPane tab={"Math Notes"} key={"math-notes"}>
                                                            <SubjectNoteDisplay
                                                                teacherBaseProps={this.props.teacherBaseProps}
                                                                teacherId={this.state.teacherId}
                                                                student={this.props.teacherBaseProps.studentDict[this.state.studentId]}
                                                                studentId={this.state.studentId ? this.state.studentId : null}
                                                                IEPId={'tier1'}
                                                                subject={'math'}
                                                                isAdmin={false}
                                                            />
                                                        </TabPane>
                                                        <TabPane tab={"SEL Notes"} key={"general-notes"}>
                                                            <NoteDisplay
                                                                teacherBaseProps={this.props.teacherBaseProps}
                                                                teacherId={this.state.teacherId}
                                                                student={this.props.teacherBaseProps.studentDict[this.state.studentId]}
                                                                studentId={this.state.studentId ? this.state.studentId : null}
                                                                IEPId={'tier1'}
                                                                isAdmin={false}
                                                            />
                                                        </TabPane>
                                                        <TabPane tab={"Uploads"} key={"upload-tab"}>
                                                            <TeacherStudentUploadView
                                                                teacherBaseProps={this.props.teacherBaseProps}
                                                                teacherId={this.state.teacherId}
                                                                student={this.props.teacherBaseProps.studentDict[this.state.studentId]}
                                                                studentId={this.state.studentId ? this.state.studentId : null}
                                                                IEPId={'tier1'}
                                                                isAdmin={false}
                                                            />
                                                        </TabPane>
                                                    </Tabs>
                                                </div>
                                            </Col>
                                            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                                                <h1 className='font-24 font-bold mb-1 mt-4'>Students</h1>
                                                {this.props.teacherBaseProps.students.map((student, idx) => {
                                                    return <Link to={"/teacher/tier-one-plan/" + this.props.teacherBaseProps.teacher.id + "?student=" + student.id}
                                                        id={'nav-' + student.id}>
                                                        <div className={"p-1 br-20 background-fff mb-1 ant-shadow ant-shadow-more-hover parent-hover relative flex flex-v-center" +
                                                            (this.state.studentId === student.id ? ' side-menu-item-active' : '')}>
                                                            <PersonAvatar
                                                                person={student}
                                                                size={'large'}
                                                                containerClassName="font-20"
                                                                avatarClassName="font-20 font-bold mr-2"
                                                                personClassName="font-black font-20 ellipsis"
                                                                personStyle={{ maxWidth: '139px' }}
                                                            />

                                                        </div>
                                                    </Link>
                                                })}
                                            </Col>

                                        </Row>
                                    </div>
                                    : ''}
                            </div>
                        </Content>
                    </Layout>
                </Layout>
                <CustomFooter teacherId={this.state.teacherId} />
            </div>
        )
    }
}

export default TierOneHome
