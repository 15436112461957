import React, { Component } from 'react'
//import { Link } from 'react-router-dom'
import '../../styles/GlobalStyle.css'
//import { firebase, db, firebaseStorage } from '../../firebase/Firebase'
import LessonTemplate from './LessonTemplate'
//import MTSSLessonTemplate from './MTSSLessonTemplate'
import MTSSTierPlanTemplate from './MTSSTierPlanTemplate'
import PlanView from './PlanView'
import GoalViewer from './GoalViewer'
import ProgressMonitoring from '../progressmonitoring/ProgressMonitoring'
import NoteDisplay from '../progressmonitoring/NotesDisplay'
//import { capitalizeFirstChar, getIconFromMainSubject } from '../.././Util'
//import ColType from '../../Types'
import { Tabs } from 'antd'
import TeacherStudentUploadView from '../reports/TeacherStudentUploadView'
import CopyText from './CopyText'
import Keywords from './Keywords'
import ExtendGoalModal from './ExtendGoalModal'
//import StandardNumber from '../../customcomponents/StandardNumber'
const TabPane = Tabs.TabPane

class MainPlanView extends Component {
    render() {
        let isMTSSPlan = this.props.iep.hasOwnProperty("isMTSS") && this.props.iep.isMTSS

        return <div className="br-20 ant-shadow no-border-top-radius p-4 overflow-hidden">
            <div>

                <div>
                    <div className='flex flex-v-center mb-1'>
                        <h2 className="font-bold mb-0 font-24 mr-2">
                            {(this.props.iep.hasOwnProperty("isMTSS") && this.props.iep.isMTSS) ?
                                this.props.iep.iep.tier + " Goal" : "IEP Goal"}
                        </h2>
                        {this.props.hasOwnProperty('showCopyButtons') && this.props.showCopyButtons ?
                            <CopyText text={this.props.iep.iep.iepParagraph} />
                            : ''}
                        <ExtendGoalModal
                            iep={this.props.iep}
                            student={this.props.student}
                            teacherBaseProps={this.props.teacherBaseProps}
                        />
                    </div>


                    <h3 className="mb-4 font-20">
                        <Keywords paragraph={this.props.iep.iep.iepParagraph} />
                    </h3>

                    <Tabs size="large" animated={false}>
                        <TabPane tab={"Plan"} key={"0"}>
                            <PlanView iep={this.props.iep} student={this.props.student} showCopyButtons={true} />


                        </TabPane>
                        <TabPane tab={"Progress monitoring"} key={"1"}>
                            <ProgressMonitoring
                                teacherBaseProps={this.props.teacherBaseProps}
                                studentId={this.props.student.id}
                                IEPId={this.props.iep.id}
                            />
                        </TabPane>
                        <TabPane tab={"Notes"} key={"2"}>
                            {this.props.teacherBaseProps &&
                                this.props.teacherBaseProps.teacher &&
                                this.props.student &&
                                this.props.iep ?
                                <NoteDisplay
                                    teacherBaseProps={this.props.teacherBaseProps}
                                    student={this.props.student}
                                    teacherId={this.props.teacherBaseProps.teacher.id}
                                    studentId={this.props.student.id}
                                    IEPId={this.props.iep.id}
                                    isAdmin={false}
                                />
                                : ''}
                        </TabPane>
                        <TabPane tab={"Uploads"} key={"3"}>
                            <TeacherStudentUploadView
                                teacherBaseProps={this.props.teacherBaseProps}
                                student={this.props.student}
                                studentId={this.props.student ? this.props.student.id : null}
                                IEPId={this.props.iep.id}
                            />
                        </TabPane>
                        <TabPane tab="Reports" key={"4"}>
                            <div className='font-24 font-bold mt-4'>
                                Reports
                            </div>
                            <div className="flex flex-v-center mt-3 mb-1">

                                <div className="mr-2">
                                    <GoalViewer
                                        teacherBaseProps={this.props.teacherBaseProps}
                                        iep={this.props.iep}
                                        student={this.props.student}
                                    />
                                </div>
                                <div>
                                    {!isMTSSPlan ?
                                        <div className="mr-2">
                                            <LessonTemplate
                                                iep={this.props.iep}
                                                student={this.props.teacherBaseProps.studentDict[this.props.studentId]}
                                                teacherBaseProps={this.props.teacherBaseProps}
                                            />
                                        </div>
                                        : ''}
                                </div>
                                {isMTSSPlan ?
                                    <div className="mr-2">
                                        <MTSSTierPlanTemplate
                                            iep={this.props.iep}
                                            student={this.props.student}
                                            teacherBaseProps={this.props.teacherBaseProps}
                                        />
                                    </div>
                                    : ''}
                                {isMTSSPlan ?
                                    <div>
                                        <LessonTemplate
                                            iep={this.props.iep}
                                            student={this.props.teacherBaseProps.studentDict[this.props.studentId]}
                                            teacherBaseProps={this.props.teacherBaseProps}
                                        />
                                    </div>
                                    : ''}
                            </div>
                        </TabPane>
                    </Tabs>
                </div>
            </div>
        </div>
    }
}

export default MainPlanView