import React, { Component } from 'react'
//import { Link } from 'react-router-dom'
import '../styles/GlobalStyle.css'
//import { firebase, db } from '../firebase/Firebase'
import { CLEVER_CLIENT_ID, CLEVER_REDIRECT_URI } from '.././Util'
import CustomFooter from './CustomFooter'
//import ColType from '.././Types'
//import { Helmet } from "react-helmet"
//import { message } from 'antd'

class SignIn extends Component {
  state = {
    user: {},
    admin: {},
    signingIn: false,
    hideEmail: false,
  }

  componentDidMount() {
    document.title = "Sign in - Dot It"
  }

  render() {
    return (
      <div className="pt-4 pb-70 relative" style={{ minHeight: '100%' }}>
        <div className='absolute-tl w-100 h-100'>
        </div>

        <div className='relative'>
          <div className="text-center">
            <a href="https://dotit.app/">
              <img src='/logo-icon.png' alt='logo' height='120' />
            </a>
          </div>
          <div className="p-4 w-500 m-lr-auto br-20 ant-shadow background-fff">
            <div className="block w-100 m-lr-auto mb-1 text-center">
              <img src="/dot-it-sign-in.png" alt="sign-in" height="300" />
            </div>
            <div className="mb-3 text-center pt-3 font-30 font-bold">Welcome to Dot It</div>
            <a href={`https://clever.com/oauth/authorize?response_type=code&client_id=${CLEVER_CLIENT_ID}&redirect_uri=${CLEVER_REDIRECT_URI}`}>
              <img src="https://files.readme.io/efb3f5d-LogInWithClever2x.png" alt="Clever log in button" className="" align="" caption="" height="auto" width="auto" loading="lazy" />
            </a>

          </div>
        </div>

        <CustomFooter
          theme='dark'
        />
      </div>

    )
  }
}

export default SignIn