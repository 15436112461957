import React from 'react';
import { Empty, Spin, Button } from 'antd';
import { db } from '../firebase/Firebase';
import AssessmentCountView from './AssessmentCountView';
import ProfessionalLearningPlanView from './ProfessionalLearningPlanView';
import StudentVoiceView from './StudentVoiceView';
import AssessmentBreakdownView from './AssessmentBreakdownView';
import GradeLevelTeamsView from './GradeLevelTeamsView';
import CoachNoteView from './CoachNoteView';
import { exportDistrictStats } from './excel-export-utils';


class DistrictStatsV3 extends React.Component {
    _isMounted = false;

    state = {
        assessmentData: [],
        teacherData: {},
        learningReports: [],
        loading: true,
        error: null,
        activeTab: 'gradeBreakdown'
    };

    componentDidMount() {
        this._isMounted = true;
        let districtId = '';
        if (this.props.adminBaseProps && this.props.adminBaseProps.district) {
            districtId = this.props.adminBaseProps.district.id;
        } else if (this.props.instructionalFacilitatorBaseProps &&
            this.props.instructionalFacilitatorBaseProps.district) {
            districtId = this.props.instructionalFacilitatorBaseProps.district.id;
        }

        if (districtId) {
            this.fetchData(districtId);
        } else {
            this.safeSetState({ loading: false, error: 'No district ID found' });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    safeSetState = (newState) => {
        if (this._isMounted) {
            this.setState(newState);
        }
    };

    fetchData = async (districtId) => {
        // if adminBaseProps is passed in, the user is an admin
        const isAdmin = this.props.adminBaseProps;
        let assessmentSnapshot = null;
        let learningReportSnapshot = null;
        let coachingNotesSnapshot = null;
        let iepSnapshot = null;
        let teachersSnapshot = null;
        try {
            if (isAdmin) {
                [assessmentSnapshot, learningReportSnapshot, coachingNotesSnapshot, iepSnapshot, teachersSnapshot] = await Promise.all([
                    db.collection('cleverAssessmentUsage')
                        .where('districtId', '==', districtId)
                        .get(),
                    db.collection('professionalLearningReport')
                        .where('districtId', '==', districtId)
                        .get(),
                    db.collection('coachingReports')
                        .where('districtId', '==', districtId)
                        .get(),
                    db.collection('iep')
                        .where('districtId', '==', districtId)
                        .where('folder', '==', 'current')
                        .get(),
                    db.collection('teachers')
                        .where('districtId', '==', districtId)
                        .get()
                ]);
            } else {
                const schoolIds = this.props.instructionalFacilitatorBaseProps.schools.map(school => school.id);
                [assessmentSnapshot, learningReportSnapshot, coachingNotesSnapshot, iepSnapshot, teachersSnapshot] = await Promise.all([
                    db.collection('cleverAssessmentUsage')
                        .where('districtId', '==', districtId)
                        .get(),
                    db.collection('professionalLearningReport')
                        .where('districtId', '==', districtId)
                        .where("schoolId", "in", schoolIds)
                        .get(),
                    db.collection('coachingReports')
                        .where('districtId', '==', districtId)
                        .where("schoolId", "in", schoolIds)
                        .get(),
                    db.collection('iep')
                        .where('districtId', '==', districtId)
                        .where("schoolId", "in", schoolIds)
                        .where('folder', '==', 'current')
                        .get(),
                    db.collection('teachers')
                        .where('districtId', '==', districtId)
                        .where("schoolId", "in", schoolIds)
                        .get()
                ]);
            }

            if (!(assessmentSnapshot && learningReportSnapshot &&
                coachingNotesSnapshot && iepSnapshot && teachersSnapshot)) {
                throw new Error('An error occurred');
            }

            const assessmentData = [];
            const teacherIds = new Set();
            const learningReports = [];
            const schoolIds = new Set();

            // Process assessment data
            assessmentSnapshot.forEach(doc => {
                const assessment = doc.data();

                if (!isAdmin) {
                    assessment.schools = assessment.schools.filter(school => {
                        return this.props.instructionalFacilitatorBaseProps.schoolsDict.hasOwnProperty(school.schoolId);
                    });
                }

                assessmentData.push(assessment);
                this.collectTeacherIds(assessment, teacherIds);
            });

            // Collect school IDs from learning reports
            learningReportSnapshot.forEach(doc => {
                const report = doc.data();
                if (report.schoolId) {
                    schoolIds.add(report.schoolId);
                }
            });

            // Fetch school names
            const schoolData = {};
            const schoolPromises = Array.from(schoolIds).map(async (schoolId) => {
                const schoolDoc = await db.collection('schools').doc(schoolId).get();
                if (schoolDoc.exists) {
                    schoolData[schoolId] = schoolDoc.data().name || 'Unknown School';
                }
            });

            await Promise.all(schoolPromises);

            // Create a map of school names from assessment data
            const schoolNames = {};
            assessmentSnapshot.forEach(doc => {
                const assessment = doc.data();
                if (assessment.districtId === districtId) {
                    assessment.schools?.forEach(school => {
                        if (school.schoolId && school.schoolName) {
                            schoolNames[school.schoolId] = school.schoolName;
                        }
                    });
                }
            });

            // Process learning reports with school names
            learningReportSnapshot.forEach(doc => {
                const report = {
                    id: doc.id,
                    ...doc.data(),
                    timeStamp: doc.data().timeStamp?.toDate(),
                    schoolName: schoolNames[doc.data().schoolId] || 'Unknown School'
                };
                learningReports.push(report);
            });

            // Process coaching notes
            const coachingNotes = [];
            coachingNotesSnapshot.forEach(doc => {
                const note = doc.data();
                coachingNotes.push({
                    id: doc.id,
                    ...note,
                    timeStamp: note.timeStamp?.toDate(),
                    schoolName: schoolNames[note.schoolId] || 'Unknown School'
                });
            });

            // Process teacher data directly from the snapshot
            const teacherData = {};
            teachersSnapshot.forEach(doc => {
                const data = doc.data();
                teacherData[doc.id] = {
                    firstName: data.firstName || '',
                    lastName: data.lastName || '',
                    fullName: `${data.firstName || ''} ${data.lastName || ''}`.trim(),
                    schoolId: data.schoolId
                };
            });

            const iepData = [];
            iepSnapshot.forEach(doc => {
                iepData.push({
                    id: doc.id,
                    ...doc.data()
                });
            });

            this.safeSetState({
                assessmentData,
                teacherData,
                learningReports,
                coachingNotes,
                iepData,
                loading: false,
                error: null
            });
        } catch (error) {
            console.error("Error fetching data:", error);
            this.safeSetState({
                loading: false,
                error: error.message || 'Failed to load data'
            });
        }
    };

    collectTeacherIds = (assessment, teacherIds) => {
        assessment.schools?.forEach(school => {
            school.subjects?.forEach(subject => {
                subject.grades?.forEach(grade => {
                    grade.teachers?.forEach(teacher => {
                        teacherIds.add(teacher.teacherId);
                    });
                });
            });
        });
    };

    getViewComponent = () => {
        const { activeTab, assessmentData, teacherData, learningReports, coachingNotes, iepData } = this.state;
        const props = { assessmentData, teacherData, learningReports, coachingNotes, iepData };

        switch (activeTab) {
            case 'gradeBreakdown':
                return <AssessmentBreakdownView {...props} />;
            case 'assessments':
                return <AssessmentCountView {...props} />;
            case 'plcs':
                return <ProfessionalLearningPlanView {...props} />;
            case 'studentVoice':
                return <StudentVoiceView {...props} />;
            case 'coaching':
                return <CoachNoteView {...props} />;
            default:
                return <Empty description="Select a view" />;
        }
    };

    handleExport = () => {
        const { assessmentData, teacherData, learningReports, iepData, coachingNotes } = this.state;
        exportDistrictStats(assessmentData, teacherData, learningReports, iepData, coachingNotes);
    };

    render() {
        const { loading, error, activeTab, assessmentData, teacherData, learningReports, coachingNotes, iepData } = this.state;
        const props = { assessmentData, teacherData, learningReports, coachingNotes, iepData };
        if (loading) return <div style={{ textAlign: 'center', padding: '50px' }}><Spin size="large" /></div>;
        if (error) return <Empty description={error} />;

        return (
            <div>
                <div className='br-20 overflow-hidden mb-2 mt-4 pt-1'>
                    <div className='br-15 relative pl-3 pr-3 pt-3 relative overflow-hidden'
                        style={{ height: '220px', }}>

                        <div className="absolute-tl w-100 h-100 background-darker-blue">
                        </div>
                        <div className="absolute-tl w-100 h-100"
                        >
                        </div>
                        <div className='absolute-tl w-100 h-100'>
                            <div className='h-100'>
                                <div className='flex flex-v-center font-40 font-fff h-100 p-4'>
                                    <div>
                                        <div className='font-40 font-bold font-fff'>Dashboard</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{ padding: '20px' }}>
                    <div style={{
                        marginBottom: '24px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}>
                        <div>
                            <Button
                                onClick={() => this.setState({ activeTab: 'gradeBreakdown' })}
                                type={activeTab === 'gradeBreakdown' ? 'primary' : 'default'}
                                style={{ marginRight: '8px' }}
                            >
                                Grade Breakdown
                            </Button>
                            <Button
                                onClick={() => this.setState({ activeTab: 'assessments' })}
                                type={activeTab === 'assessments' ? 'primary' : 'default'}
                                style={{ marginRight: '8px' }}
                            >
                                # of Assessments
                            </Button>
                            <Button
                                onClick={() => this.setState({ activeTab: 'plcs' })}
                                type={activeTab === 'plcs' ? 'primary' : 'default'}
                                style={{ marginRight: '8px' }}
                            >
                                Professional Learning Plans
                            </Button>
                            <Button
                                onClick={() => this.setState({ activeTab: 'studentVoice' })}
                                type={activeTab === 'studentVoice' ? 'primary' : 'default'}
                                style={{ marginRight: '8px' }}
                            >
                                Student Voice
                            </Button>
                            <Button
                                onClick={() => this.setState({ activeTab: 'teams' })}
                                type={activeTab === 'teams' ? 'primary' : 'default'}
                                style={{ marginRight: '8px' }}
                            >
                                MTSS/SPED
                            </Button>
                        </div>
                        <Button
                            type="primary"
                            onClick={this.handleExport}
                        >
                            📥 Export to Excel
                        </Button>
                    </div>

                    <div style={{ display: activeTab === 'assessments' ? 'block' : 'none' }}>
                        <AssessmentCountView {...props} />
                    </div>
                    <div style={{ display: activeTab === 'plcs' ? 'block' : 'none' }}>
                        <ProfessionalLearningPlanView {...props} />
                    </div>
                    <div style={{ display: activeTab === 'studentVoice' ? 'block' : 'none' }}>
                        <StudentVoiceView {...props} />
                    </div>
                    <div style={{ display: activeTab === 'gradeBreakdown' ? 'block' : 'none' }}>
                        <AssessmentBreakdownView {...props} />
                    </div>
                    <div style={{ display: activeTab === 'teams' ? 'block' : 'none' }}>
                        <GradeLevelTeamsView {...props} />
                    </div>
                    <div style={{ display: activeTab === 'coaching' ? 'block' : 'none' }}>
                        <CoachNoteView {...props} />
                    </div>
                </div>

                <div className='mt-2 mb-2 ml-3'>The dashboard displays original assessment scores only. Student retakes are not included in this data visualization.</div>
            </div>
        );
    }
}

export default DistrictStatsV3;
