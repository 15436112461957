import React, { Component } from 'react'
import '../../styles/GlobalStyle.css'
import { firebase, db } from '../../firebase/Firebase'
import ColType from '../.././Types'
//import { awardsIncNotes, isReadOnlyTeacherId } from '../.././Util'
import { Button, Form, Input, message, Radio } from 'antd'
const FormItem = Form.Item
const { TextArea } = Input

/*
const errorMessage = (description) => {
  message.error(description)
}
*/

class AssessmentSurveyForm extends Component {
    state = {
        teacherId: '',
        teacher: [],
        student: null,
        submitting: false
    }

    componentDidMount() {

    }

    handleSubmit = (e) => {
        if (!this.props.quiz) {
            return;
        }

        e.preventDefault()
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({
                    submitting: true,
                }, () => {
                    //console.log('Received values of form: ', values)

                    const survey = {
                        qualitative: values.qualitative,
                        completed: true,
                    };

                    db.collection(ColType.cleverStudentAssessments)
                        .doc(this.props.quiz.id)
                        .update({
                            'survey': survey,
                        })
                        .then(() => {
                            console.log('survey submitted successfully')
                        })
                        .catch((error) => {
                            console.log('error submitting survey', error)
                        })
                })
            }
        })
    }

    render() {
        const { getFieldDecorator } = this.props.form
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        }

        return (
            <div>
                {this.props.quiz ?
                    <Form onSubmit={this.handleSubmit} className="block-label-form background-fff ant-shadow p-4 br-20">
                        <FormItem {...formItemLayout} label={<div className='font-24 text-center mb-2'>
                            How do you feel about this test?</div>} className='text-center'>
                            {getFieldDecorator('qualitative', {
                                rules: [{ required: true, message: 'Please fill out this item.' }],
                            })(
                                <Radio.Group size='large' className='mt-1'>
                                    <Radio.Button value={'good'}
                                        className='flex-v-center flex-h-center'
                                        style={{
                                            height: '100px',
                                            width: '100px',
                                            padding: '0px',
                                            display: 'inline-flex',
                                        }}>
                                        <div>
                                            <div style={{ fontSize: '64px' }}>😀</div>
                                        </div>
                                    </Radio.Button>
                                    <Radio.Button value={'somewhatGood'}
                                        className='flex-v-center flex-h-center'
                                        style={{
                                            height: '100px',
                                            width: '100px',
                                            padding: '0px',
                                            display: 'inline-flex',
                                        }}>
                                        <div>
                                            <div style={{ fontSize: '64px' }}>😐</div>
                                        </div>
                                    </Radio.Button>
                                    <Radio.Button value={'notGood'}
                                        className='flex-v-center flex-h-center'
                                        style={{
                                            height: '100px',
                                            width: '100px',
                                            padding: '0px',
                                            display: 'inline-flex',
                                        }}>
                                        <div>
                                            <div style={{ fontSize: '64px' }}>☹️</div>
                                        </div>
                                    </Radio.Button>
                                </Radio.Group>
                            )}
                        </FormItem>

                        <div className="flex flex-v-center">
                            <Button
                                type="primary"
                                className='lg-btn ml-auto'
                                size={'large'}
                                onClick={this.handleSubmit}
                                disabled={this.state.submitting}
                            >
                                All Done!
                            </Button>
                        </div>
                    </Form> : ''}
            </div>
        )
    }
}

export default Form.create()(AssessmentSurveyForm)
