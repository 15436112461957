import React, { Component } from 'react'
import '../styles/GlobalStyle.css'
import { getAvatarColor, getInitials } from '.././Util'
import { Avatar } from 'antd'

class PersonAvatar extends Component {
  state = {

  }

  componentDidMount() {

  }

  render() {
    let grade = "N/A"
    if (this.props.person && this.props.person.hasOwnProperty("grade")) {
      grade = this.props.person.grade
    }
    if (grade.toLowerCase() === 'kindergarten') {
      grade = 'K'
    } else if (grade.toLowerCase() === 'prekindergarten') {
      grade = 'PK'
    }
    let alphaStr = '33'
    return (
      <div
        className={"inline-flex" +
          (this.props.containerClassName ? ' ' + this.props.containerClassName : '')}
        id={this.props.containerId}
      >
        {this.props.person ?
          <span className={"flex flex-v-center"}>
            <Avatar
              size={this.props.size}
              className={"mr-8 inline-flex flex-v-center flex-h-center font-16 font-bold " +
                (this.props.avatarClassName ? ' ' + this.props.avatarClassName : '')}
              style={{
                backgroundColor: (this.props.hasOwnProperty('avatarColor') ?
                  this.props.avatarColor :
                  getAvatarColor(grade) === '#b3e9ff' ? '#b3e9ff' :
                    getAvatarColor(grade) + alphaStr),
                color: (this.props.person.hasOwnProperty('grade') ? (this.props.hasOwnProperty('avatarColor') ?
                  this.props.avatarColor : getAvatarColor(grade)) : '#000'),
              }}
            >
              <span style={{
                color: (this.props.hasOwnProperty('avatarTextColor') ?
                  this.props.avatarTextColor :
                  this.props.person.hasOwnProperty('grade') ?

                    this.props.hasOwnProperty('avatarColor') ?
                      this.props.avatarColor : getAvatarColor(grade) : '#000')
              }}>
                {this.props.person.hasOwnProperty("grade") ?
                  grade : getInitials(this.props.person)}
              </span>
            </Avatar>
            {!this.props.hasOwnProperty('displayUserName') ||
              (this.props.hasOwnProperty('displayUserName') && this.props.displayUserName) ?
              <span className={"" +
                (this.props.personClassName ? ' ' + this.props.personClassName : '')}
                style={this.props.personStyle ? this.props.personStyle : {}}
              >
                {this.props.person.firstName + " " + this.props.person.lastName}
              </span>
              : ''}
          </span>
          : ''}
      </div>
    )
  }
}

export default PersonAvatar